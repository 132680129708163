// Chakra imports
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Information(props: { title: string; value: number | string; [x: string]: any }) {
	const { title, value, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const bg = useColorModeValue('white', 'navy.700');
	return (
		<Card bg={"#24003e"} padding={"5px"}  borderRadius={"7px"}>
			<Box marginLeft={"10px"}>
				<Text fontWeight='500'
				//  color={"WHITE"}
				 color={"white"}
				 fontSize='12px'>
					{title}
				</Text>
				<Text 
				// color={textColorPrimary}
				color={"white"}
				fontWeight='500' fontSize='11px'>
					{value}
				</Text>
			</Box>
		</Card>
	);
}
