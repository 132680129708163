import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import imageHolder from "assets/images/imageHolder.png";
import { formatDateTime, formatDateTimeShort } from "views/site/helpers";
import { MdCalendarViewMonth } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";

export default function Project(props: {
  title: string;
  category: string;
  ranking: number | string;
  eventType: string;
  item: any;
  image: string;
  [x: string]: any;
}) {
  const { category, title, item, ranking, eventType, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory();
  const bg = useColorModeValue("white", "navy.700");

  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log(item);
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");

  return (
    <Card bg={"#49007a"} 
     marginBottom={"5px"} p="14px" borderRadius={"10px"}>
      <Flex align="center" direction={"row"}>


      <Flex align="center" direction={"row"}>
     <Flex align="center" direction={"column"}>
    
      <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}
            marginBottom={"30px"}
            
            me="4px"
          >
            {formatDateTimeShort(item.startDateTime)}</Text>

          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}

            me="4px"
          >{formatDateTimeShort(item.endDateTime)}
          </Text>
      </Flex>
        
      <Flex align="center" direction={"column"}>
      <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />
  <Box

width="3px"
 height="50px"
 backgroundColor="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />
   <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />

      </Flex>
      </Flex> 
     
        
       
        <Box paddingRight={"2px"} display={"flex"} flexDirection={"column"}>
          <Button
            onClick={() => {
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              // console.log("SEND VALUESSS");
              console.log(item);
              if(item?.eventType === "PRIVATE"){return}

              

              localStorage.setItem(
                "contentItem",
                JSON.stringify(item?.content)
              );
     
              history.push({
                pathname: "/site/content",
                state: {
                  itemId: item?.contentId,
                  update: false, //exist now update
                  isPromo: false,
                },
              });
            }}
            variant={item?.eventType === "PRIVATE" ? "":"link"}
            // color="b"
            alignSelf={"start"}
           

            // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
            // maxHeight={"50%"}
            // fontWeight="500"
            fontSize="16px"
            // mb="4px"
            // fontWeight="500"
            // borderRadius="70px"
            // px="24px"
            // py="1px"
            // marginRight={"-5%"}
          > <Text
          fontWeight="500"
                      width={"160px"}

          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          color={"white"}
          textAlign={"start"}
          fontSize="14px"
          // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

          // me="4px"
        >{title.toUpperCase()}</Text>
            
          </Button>
          <Button

          onClick={() => {

            if(item?.eventType === "PRIVATE"){return}
    
            localStorage.setItem(
              "contentOwner",
              JSON.stringify({id:item?.data?.getContent?.author?.id})
            );
            history.push(`/site/cnt_owner_page?id=${item?.data?.getContent?.author?.id}&zIx=890`,);
          
          }}
            variant={item?.eventType === "PRIVATE" ? "":"link"}
          // color="b"
          alignSelf={"start"}
          // marginLeft={item?.eventType === "PRIVATE" ? "4%" :"4%"}
          // fontWeight="500"
         // fontSize="larger"
          // mb="4px"


            fontWeight="500"
            color={item?.eventType === "PRIVATE" ? "gray" :"pink.400"}
            fontSize="12px"
           // me="4px"
          >by {item?.eventType === "PRIVATE" ? "Private banda event":item?.content?.author?.username}</Button>
                    <Button

onClick={() => {

  if(item?.eventType === "PRIVATE"){return}
  localStorage.setItem("sponsorItem", JSON.stringify({id :item?.sponsorId}));

  history.push(`/site/sponsor_details?id=${item?.sponsorId}&zIx=890`, {
    itemData: "qqsad",
    update: true,
  });

}}
  variant={item?.eventType === "PRIVATE" ? "":"link"}
// color="b"
alignSelf={"start"}
// marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
// fontWeight="500"
// fontSize="larger"
// mb="4px"


  fontWeight="500"
  color={item?.eventType === "PRIVATE" ? "gray" :"green.300"}
  fontSize="12px"
 // me="4px"
>sponsored by {item?.eventType === "PRIVATE" ? "Private banda event":item?.sponsor?.anonymous? "anonymous":item?.sponsor?.isCompany ? item?.sponsor?.companyName.trim()!=="" ? item?.sponsor?.companyName :"anonymous" :item?.sponsor?.sponsorName.trim()!==""?item?.sponsor?.sponsorName:"anonymous"}</Button>
       
        </Box>
      </Flex>
    </Card>
  );
}
