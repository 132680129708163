import * as actionTypes from "./actionTypes";




async function save(key: string, value: any): Promise<void> {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    console.log(`${key} saved successfully.`);
  } catch (error:any) {
    console.error(`Error saving ${key}: ${error.message}`);
  }
}

// Retrieving data from local storage
function retrieve<T>(key: string): T | null {
  try {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      return JSON.parse(storedValue) as T;
    }
    console.log(`${key} not found in local storage.`);
    return null;
  } catch (error:any) {
    console.error(`Error retrieving ${key}: ${error.message}`);
    return null;
  }
}



// const userdata: any =  jwtDecode(accessToken);
const retrievedAccountStatus: string | null = retrieve<string>("accountStatus");
const retrievedAccessToken: string | null = retrieve<string>("accessToken");


// Function to set the language value in localStorage
const setLanguageLocalStorage = (language: string): void => {
  localStorage.setItem("language", language);
};

// Function to get the language value from localStorage
const getLanguageLocalStorage = (): string => {
  return localStorage.getItem("language") || "en";
};

const initialLanguageValue = getLanguageLocalStorage(); // Get the language value from localStorage

// Function to set the darkMode value in localStorage
const setDarkModeLocalStorage = (isDarkMode: boolean): void => {
  localStorage.setItem("isDarkMode", isDarkMode.toString());
};

// Function to get the darkMode value from localStorage
const getDarkModeLocalStorage = (): boolean => {
  const isDarkMode = localStorage.getItem("isDarkMode");
  return isDarkMode === "true";
};
const initialDarkModeValue = getDarkModeLocalStorage(); // Get the darkMode value from localStorage
const userName = localStorage.getItem("userName") || ""; // If the item doesn't exist, it will return an empty string
const userEmail = localStorage.getItem("userEmail") || "";
const userAccessType = retrievedAccountStatus
 // localStorage.getItem("userAccessType")! ||
  //assuming loogged in user is parter and this is data from api
//  "";
//and token
const userLoginStatus = localStorage.getItem("userLoginStatus")! === "true"; // Convert the string "true" to a boolean

const initialState: UserState = {
  user: {
    id: "",
    //from api name email and accessType
    name: userName,
    email: userEmail,
    bandaCode:  "",
    accessType: " ", //role
    mode: userAccessType,
    contentStatus:false,
    loginStatus: false,
    accountStat:"",
    //api
    language: initialLanguageValue,
    darkMode: initialDarkModeValue, // Use the value from localStorage here
    loading: false, // Use the value from localStorage here
  },
};

const userReducer = (
  state: UserState = initialState,
  action: UserActions
): UserState => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.user?.name!,
          email: action.user?.email!,
          sponsorId:action.user?.sponsorId!,
          id: action.user?.id!,
          bandaCode:  action.user?.bandaCode!,
          //#check access type is this right
          accessType: action.user?.accessType!, // userData?.accessType!,
          mode: action.user?.mode!, 
          sponsorStatus: action.user?.sponsorStatus!, // userData?.accessType!,
          contentStatus: action.user?.contentStatus!,
          accountStat:action.user?.accountStat!,
          bandaStatus:action.user?.bandaStatus!,
          //#check where to get this value from a token or where
          loginStatus: true, //userData?.loginStatus!,
          language: state.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.REGISTER_USER:
      localStorage.setItem("userName", action.user?.name!);
      localStorage.setItem("userEmail", action.user?.email!);
      localStorage.setItem("userAccessType", "ACL2");
      localStorage.setItem("userLoginStatus", "true");
      return {
        ...state,
        user: {
          ...state.user,
          name: action.user?.name!,
          email: action.user?.email!,
          id: action.user?.id!,
          sponsorId:action.user?.sponsorId!,
          //#check access type is this right
          accessType: "", // userData?.accessType!,
          //#check where to get this value from a token or where
          loginStatus: true, //userData?.loginStatus!,
          language: state.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.VERIFY:
      localStorage.setItem("userName",action.user?.name!);
      localStorage.setItem("userEmail", action.user?.email!);
      localStorage.setItem("userAccessType", "ACL2");
      localStorage.setItem("userLoginStatus", "true");
      // console.log("VERIFY");
      // console.log("VERIFY");
      // console.log("VERIFY");
      // console.log("VERIFY");
      // console.log("VERIFY");
      // console.log("VERIFY");
      // console.log("VERIFY");

      return {
        ...state,
        user: {
          ...state.user,
          name: action.user?.name!,
          email: action.user?.email!,
          id: action.user?.id!,
          //#check access type is this right
          accessType: "", //state.user?.accessType!,// userData?.accessType!,
          //#check where to get this value from a token or where
          loginStatus: true, //userData?.loginStatus!,
          accountStat:action.user?.accountStat!,
          language: state.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.LOGOUT_USER:
      // logout user
    
      return {
        ...state,
        user: {
          ...state.user,
          name: "",
          email: "",
          sponsorId:"",
          id: "",
          bandaCode:"",
          //#Check should i leave this here like this
          accessType: "", // userData?.accessType!,
          mode: "", // userData?.accessType!,
          accountStat:"",
          contentStatus:false,
          //#check where to get this value from a token or where
          loginStatus: false, //userData?.loginStatus!,
          language: state.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.REGISTER_PARTNERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          accountStat:action.user?.accountStat!,
        },
      };

    case actionTypes.CANCEL_PARTNERSHIP:
      //also change api and initialstate status
      return {
        ...state,
        user: {
          ...state.user,
          name: state.user?.name!,
          //#check where to get this value from a token or where
          loginStatus: true,
          email: state.user?.email!,
          id: state.user?.id!,
          accessType: "ACL2",
          language: state.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.CHANGE_LANGUAGE:
      setLanguageLocalStorage(action.user?.language!);
      return {
        ...state,
        user: {
          ...state.user,
          name: state.user?.name!,
          //#check where to get this value from a token or where
          loginStatus: true,
          email: state.user?.email!,
          id: state.user?.id!,
          accessType: state.user?.accessType!,
          language: action.user?.language!,
          darkMode: state.user?.darkMode!,
          loading: false,
        },
      };

    case actionTypes.DARK_MODE:
      console.log(localStorage.getItem("isDarkMode"));
      console.log(localStorage.getItem("isDarkMode"));
      setDarkModeLocalStorage(!state.user?.darkMode!);
      // setDarkModeLocalStorage(true);
      console.log("in actionTypes.DARK_MODE");
      console.log("in actionTypes.DARK_MODE");
      console.log(state.user?.darkMode);
      console.log(state.user?.darkMode);
      console.log("in actionTypes.DARK_MODE");
      console.log("in actionTypes.DARK_MODE");
      console.log(localStorage.getItem("isDarkMode"));
      console.log(localStorage.getItem("isDarkMode"));

      return {
        ...state,
        user: {
          ...state.user,
          name: state.user?.name!,
          //#check where to get this value from a token or where
          loginStatus: true,
          email: state.user?.email!,
          id: state.user?.id!,

          accessType: state.user?.accessType!,
          language: state.user?.language!,
          darkMode: !state.user?.darkMode!,
          loading: false,
        },
      };
  }

  return state;
};

export default userReducer;
