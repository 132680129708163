// Chakra imports
import { Box, Grid, Text } from "@chakra-ui/react";

// Custom components
import Banner from "views/site/profileUser/components/Banner";

// Assets
import avatar from "assets/images/imageHolder.png";
import AllAdds from "./components/AllAdds";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  useRegistrationContentOwnerMutation,
  useUserQuery,
} from "generated/graphql";
import { findCurrentBandaStatus } from "../helpers";
import Card from "components/card/Card";

export interface ContentFormData {
  title?: string;
  body?: string;
}

export interface ContentFormDataResponse {
  error?: string;
  success?: string;
}

export default function ProfileUser() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const { loading, error, data, refetch } = useUserQuery({
    fetchPolicy: "network-only",
    variables: {
      getUserProfileInput: {
        id: user.id,
      },
    },
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {user.loginStatus === true ? (
        <Grid
          mb="20px"
          templateColumns={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            //  "2xl": "1.34fr 1.62fr 1fr",
          }}
          templateRows={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            "2xl": "1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <Banner
            gridArea="1 / 1 / 2 / 2"
            banner={"banner"}
            avatar={avatar}
            bandaAccountInfo={
              data?.user?.accountFeedBacks
                ? JSON.parse(data?.user?.accountFeedBacks)
                : []
            }
            name={
              data?.user?.username ? data?.user?.username : data?.user?.email
            }
            job="" //"Developer"
            posts="0"
            followers="0"
            following="0"
          />
          {/* <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        /> */}

          <AllAdds
            banner={"banner"}
            avatar={avatar}
            name="Jovin Jestas"
            job="Developer"
            posts="17"
            followers="9"
            following="274"
          />
        </Grid>
      ) : (
        <Card>
          <Text color={"black"} fontSize="md" me="26px" mb="0px" mt="20px">
            Login here
          </Text>
        </Card>
      )}
    </Box>
  );
}
