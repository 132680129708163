// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import { MdAdd, MdAddBox, MdAddTask, MdEdit, MdPostAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "views/site/helpers";

export default function NotificationItem(props: {
  title: string;
  description: string;
  ranking: number | string;
  link: string;
  suspended:string;
  image: string;
  [x: string]: any;
}) {
  const { title, suspended,ranking, link,item, description,image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory()
  const bg = useColorModeValue("white", "navy.700");
  return (
<Card bg={"#49007a"} 
     marginBottom={"5px"} p="14px" borderRadius={"10px"}>
            <Flex align="center" direction={"row"}>
       
        <Box display={"flex"} 
                   width={"77%"}
                  //  backgroundColor={"red"}
                   height={"50px"}


 flexDirection={"column"}>
          <Button
            color={textColorPrimary}
            // alignSelf={"start"}
            // fontWeight="500"
            variant={"link"}
            marginLeft={"-0%"}
            width={"100%"}

            // backgroundColor={"blue"}

            // fontSize="md"
            // mb="4px"
            onClick={() => {
              // console.log(cart);
              // console.log(cart);
              // console.log(cart);
              localStorage.setItem("contentItem", item);

              history.push("/site/content", {
                itemData: "qqsad",
                update: true,
              });
            }}
          ><Text
          fontWeight="500"
                      // width={"300px"}
                      // backgroundColor={"orange"}
                      // marginLeft={"-5%"}

          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          wordBreak={"break-word"}
          color={"white"}
          textAlign={"start"}
          _hover={{
            textDecoration:"underline"
          }}
          fontSize="14px"
          // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

          // me="4px"
        >{title}</Text></Button>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >{formatDateTime(ranking)}
          </Text>

          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >{description}
          </Text>

          <Box
          me="16px"
          ms="auto"
          p="0px !important"
        >
          <Button
            fontSize="12px"
            variant="link"
            fontWeight="500"
            
            bg={suspended ? "red.400" : "gray.200"}
            color={suspended ? "white" : "black"}
            w="100"
            // h="50"
            mb="6px"

            onClick={()=>{
              localStorage.setItem(
                "contentItem",
                JSON.stringify(item)
              );

              history.push("/site/m_cont_edit")

            }}
           
          >
            {suspended ? "Was Suspended" : "Edit"}
          </Button>
        </Box>
        </Box>
        
      </Flex>
    </Card>
  );
}
