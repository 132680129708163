import { Box, Flex, Text, SimpleGrid, Button, Grid } from "@chakra-ui/react";
import Card from "components/card/Card";
import { PayCart } from "components_app/PayCart/PayCart";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch, useEffect, useState } from "react";
import React from "react";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import { removeCart } from "redux.store/cart/actionCreators";
import { useAddCartMutation, useSponsorQuery } from "generated/graphql";
import PayBox from "components_app/PayBox/PayBox";
import { useHistory } from "react-router-dom";
import { removePromo } from "redux.store/promo/actionCreators";
import { sumAndWeightPrices } from "../helpers";
import Banner from "../exploreBanda/components/Banner";
import BannerSide from "../exploreBanda/components/BannerSide";

export default function CartReview() {
  // Chakra Color Mode

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const { promo }: readonly ICart[] | any = useSelector(
    (state: PromoState) => state.promo,
    shallowEqual
  );

  // reading the state
  //#Check remove any
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  console.log(user);
  console.log(user);
  console.log(user);
  console.log(user);

  // const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  // const saveCurrentRoute = React.useCallback(
  //   (navData: INavigate) => dispatch(navigateThePage(navData)),
  //   [dispatch]
  // );
  const navigateThroughLinks = (navData: INavigate) => {
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`);
  };

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );
  const deletePromo = React.useCallback(
    (promo: ICart) => dispatch(removePromo(promo)),
    [dispatch, removeCart]
  );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    theTotalPrice();
    theTotalPriceAdds();
    // Check the window width on mount and resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 868);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [totalPrice, setTotalPrice] = useState(0);

  // const calculateTotalPrice = (): number => {
  //   let totalPrice = 0;

  //   if (!(cart?.bandaumizaList?.length > 0)) {
  //     // If bandaumizaList is empty, use pricex and frequencyx
  //     return cart.price ? cart.price * cart.frequency : 0;
  //   } else {
  //     // If bandaumizaList has items, calculate total price from bandalist
  //     totalPrice = cart.bandaumizaList.reduce(
  //       (total, banda) => total + (banda.price || 0) * banda.frequency,
  //       0
  //     );
  //   }

  //   return totalPrice;
  // };

  // const calculateTotalPriceAdd = (): number => {
  //   let totalPrice = 0;

  //   if (!(cart?.bandaumizaList?.length > 0)) {
  //     // If bandaumizaList is empty, use pricex and frequencyx
  //     return cart.price ? cart.price * cart.frequency : 0;
  //   } else {
  //     // If bandaumizaList has items, calculate total price from bandalist
  //     totalPrice = cart.bandaumizaList.reduce(
  //       (total, banda) => total + (banda.price || 0) * banda.frequency,
  //       0
  //     );
  //   }

  //   return totalPrice;
  // };

  const history = useHistory();
  const [overalPrice, setOveralPrice] = useState(0);
  const [overalPriceAdd, setOveralPriceAdd] = useState(0);

  const theTotalPrice = (): number => {
    let totalPrice = 0;

    for (const cart of carts) {
      if (cart && cart.bandaumizaList && cart.bandaumizaList.length > 0) {
        totalPrice +=
          cart.price +
          cart.bandaumizaList.reduce((acc: number, item: IBanda) => {
            let cost = 0;
            if (item.type === "banda") {
              cost =
                sumAndWeightPrices(item.dates, item.price) +
                (item.isPromoted ? 3000 * JSON.parse(item.dates).length : 0);
            } else {
              cost =
                item.frequency * item.price +
                (item.isPromoted ? 3000 * item.frequency : 0);
            }
            return acc + cost;
          }, 0);
      }
    }

    setOveralPrice(totalPrice);
    return totalPrice;
  };

  const theTotalPriceAdds = (): number => {
    let totalPrice = 0;

    for (const prom of promo) {
      if (prom && prom.bandaumizaList && prom.bandaumizaList.length > 0) {
        totalPrice +=
          prom.price +
          prom?.bandaumizaList?.reduce((acc: number, item: any) => {
            return (
              acc + (item.type === "banda" ? 1 : item.frequency) * item.price
            );
          }, 0);
      }
    }

    setOveralPriceAdd(totalPrice);
    return totalPrice;
  };

  //  const thzeTotalPrice = (cart: ICart): number => {
  //   let totalPrice = 0;

  //   if (!(carts?.length > 0)) {

  //    const xxx =  carts.reduce((accX:number, cart:ICart)=>{

  //     cart?.bandaumizaList?.reduce((acc, item) => {
  //       let cost = 0;
  //       if (item.type === "banda") {
  //         cost =  acc +   sumAndWeightPrices(item.dates, item.price) +
  //          (item.isPromoted ? (3000 * JSON.parse(item.dates).length) : 0)
  //       } else {
  //         console.log("item.frequency");
  //         console.log(item.frequency);
  //         console.log("item.frequency");

  //         cost =  acc + (((item.frequency) * (item.price)) +  (item?.isPromoted ? 3000 * (item.frequency) : 0));
  //       }
  //       accX + cost
  //       return cost   ;
  //     }, 0);

  //     },0)

  //     return cart.price ? cart.price * cart.frequency : 0;
  //   } else {
  //     return 0
  //   }

  //   return totalPrice;
  // };

  const [addCartServiceApi, loading] = useAddCartMutation();

  const onSubmitAll = async () => {
    if (window.confirm("Are you sure?")) {
      function generateInvoiceNumber(): string {
        const date = new Date();
        const year = date.getFullYear().toString().substr(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);
        const milliseconds = ("00" + date.getMilliseconds()).slice(-3);

        // You can customize the format of your invoice number here
        return `INV-${year}${month}${day}-${hours}${minutes}${seconds}-${milliseconds}`;
      }
      const invoiceNumber = generateInvoiceNumber();
      onSubmit(invoiceNumber);
      onSubmitPromo(invoiceNumber);
    }
  };

  const onSubmit = async (invoiceId: string, data?: any) => {
    try {
      // console.clear()
      // console.log(sponsorData);
      // console.log("sponsorData");
      // console.log("sponsorData");
      // console.log("sponsorData");

      //@ts-ignore

      // Create an array of promises for each cart item
      const cartPromises = carts.map(async (item: any) => {
        // if (item.id !== activeCart.item.id) {
        //   return;
        // }

        const totalPriceInList: number = item?.bandaumizaList?.reduce(
          (acc: any, bandaItem: any) => {
            return (
              acc +
              ((bandaItem.frequency || 1) * (bandaItem.price || 0) +
                (bandaItem?.isPromoted ? 3000 * (bandaItem.frequency || 0) : 0))
            );
          },
          0
        );

        const request = addCartServiceApi({
          variables: {
            addCartInput: {
              sponsorId: user?.sponsorId,
              userId: user?.id,
              contentId: item.id,
              invoiceId: invoiceId,
              totalAmount: overalPriceAdd + overalPrice,
              currency: "Tsh",
              isPromo: item.isPromo,
              itemName: item.title,
              promoId: item.promoId,
              bandaAndAreaItems: item.bandaumizaList,
            },
          },
        });
        //  console.clear()
        console.log(data?.addCart);
        console.log(data?.addCart);
        console.log(data?.addCart);

        if ((await request).data?.addCart?.status) {
          deleteCart(item);

          // localStorage.setItem("cartAdd", JSON.stringify({}));
          // localStorage.setItem("contentItem", JSON.stringify({}));
        }

        return request;
      });

      // Wait for all promises to resolve
      const responses = await Promise.all(cartPromises);

      // Log the responses
      console.log(responses);

      history.push("/site/explore");
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitPromo = async (invoiceId: string, data?: any) => {
    try {
      // console.clear()
      // console.log(sponsorData);

      //@ts-ignore

      // Create an array of promises for each cart item
      const cartPromises = promo.map(async (item: any) => {
        // if (item.id !== activeCart.item.id) {
        //   return;
        // }

        const totalPriceInListPromo: number = item?.bandaumizaList?.reduce(
          (acc: any, itemPromo: any) => {
            return acc + (itemPromo.frequency || 1) * (itemPromo.price || 0);
          },
          0
        );
        // console.log(item);
        // console.log("sponsorData");
        // console.log("sponsorData");
        // return

        // const filteredArray = item.bandaumizaList.filter((item: any) => item.color !== 'orange');

        const request = addCartServiceApi({
          variables: {
            addCartInput: {
              sponsorId: user?.sponsorId,
              userId: user?.id,
              contentId: item.id,
              invoiceId: invoiceId,
              totalAmount: overalPriceAdd + overalPrice,
              currency: "Tsh",
              itemName: item.body + " on " + item.title,
              isPromo: true,
              promoId: item.promoId,
              bandaAndAreaItems: item.bandaumizaList,
            },
          },
        });
        //  console.clear()
        console.log(data?.addCart);
        console.log(data?.addCart);
        console.log(data?.addCart);

        if ((await request).data?.addCart?.status) {
          deletePromo(item);

          // localStorage.setItem("cartAdd", JSON.stringify({}));
          // localStorage.setItem("eventItem", JSON.stringify({}));
        }

        return request;
      });

      // Wait for all promises to resolve
      const responses = await Promise.all(cartPromises);

      // Log the responses
      console.log(responses);

      history.push("/site/explore");
    } catch (error) {
      console.error(error);
    }
  };

  // const history = useHistory()

  useEffect(() => {
    if (
      ([...carts, ...promo].filter((cart: any) => {
        return cart.activeCart === true;
      }).length = 0)
    ) {
      history.push("./site/explore");
    }
  }, [carts, promo]);
  // const activeCart = JSON.parse(localStorage.getItem("activeCart"));

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>







      <Grid
        mb={10}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner />
        <BannerSide />
      </Grid>

      {true && (
        <SimpleGrid
          mb="1px"
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Card
            margin={"0px"}
            padding={"0px"}
            pt={"10px"}
            style={{
              // paddingLeft: 30,
              // paddingTop: 30,
              // paddingBottom: 30,
              // height:1500,
              // position
              width: "100%",
              // position: "static",
              // right:"290px",
              // marginTop: 50,
              backgroundColor: "transparent",
            }}
          >
            <div>
              <div
                style={{
                  // paddingLeft: 30,
                  // paddingTop: 30,
                  // paddingBottom: 30,
                  // height:1500,
                  // position
                  width: "100%",
                  // position: "static",
                  // right:"290px",
                  // marginTop: 50,
                  // backgroundColor: "green",
                }}
              >
                {/* {filteredCart && !activeCart.isPromo && (
                  // const existingCart = carts.find(
                  //   (item: ICart) => item.id === cart.id
                  // );
                  // console.log(cart);

                  <PayCart
                    key={filteredCart.id}
                    cart={filteredCart}
                    removeCart={removeCart}
                    selected={filteredCart}
                  />
                )}

                {filteredCartPromo && activeCart.isPromo && (
                  <PayCart
                    key={filteredCartPromo.id}
                    cart={filteredCartPromo}
                    removeCart={removeCart}
                    selected={filteredCartPromo}
                  />
                )} */}

                {[...carts, ...promo]
                  .filter((cart: any) => {
                    return cart.activeCart === true;
                  })
                  .map((cart: any) => {
                    const existingCart = carts.find(
                      (item: ICart) => item.id === cart.id
                    );
                    // console.log(cart);

                    return (
                      <PayCart
                        key={cart.id}
                        cart={cart}
                        removeCart={removeCart}
                        selected={existingCart}
                      />
                    );
                  })}
              </div>
            </div>
          </Card>

          <Card
            margin={"0px"}
            padding={"0px"}
            pt={"10px"}
            style={{
              // paddingLeft: 30,
              // paddingTop: 30,
              // paddingBottom: 30,
              // height:1500,
              // position
              width: "100%",
              // position: "static",
              // right:"290px",
              // marginTop: 50,
              backgroundColor: "transparent",
            }}
          >
            <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
              <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm   sm:p-6">
                <p className="text-xl font-semibold text-gray-900 ">
                  Order summary
                </p>

                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 ">
                        Original price
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        Tsh{overalPriceAdd + overalPrice}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 ">
                        Adds
                      </dt>
                      <dd className="text-base font-medium text-green-600">
                        Tsh {overalPriceAdd}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 ">
                        Fundings
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        Tsh {overalPrice}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 ">
                        Overal Tax
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        + Tsh {(overalPriceAdd + overalPrice) * 0.18}
                      </dd>
                    </dl>
                  </div>

                  {[...carts, ...promo].filter((cart: any) => {
                    return cart.activeCart === true;
                  }).length > 0 && (
                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                      <dt className="text-base font-bold text-gray-900 ">
                        Total
                      </dt>
                      <dd className="text-base font-bold text-gray-900 ">
                        Tsh{" "}
                        {overalPriceAdd +
                          overalPrice +
                          (overalPriceAdd + overalPrice) * 0.18}
                      </dd>
                    </dl>
                  )}
                </div>

                {[...carts, ...promo].filter((cart: any) => {
                  return cart.activeCart === true;
                }).length > 0 && (
                  <a
                    onClick={() => {
                      onSubmitAll();
                    }}
                    className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 "
                  >
                    Submit Order
                  </a>
                )}
                <div className="flex items-center justify-center gap-2">
                  <span className="text-sm font-normal text-gray-500 ">
                    {" "}
                    or{" "}
                  </span>
                  <a
                    onClick={() => {
                      history.push("/site/explore");
                    }}
                    title=""
                    className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline "
                  >
                    Continue Shopping
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </Card>

          {/* <Card>

          </Card> */}

          {/* {promo.length > 0 && (
          <Card mb={{ base: "0px", "2xl": "20px" }}>
            
            <div>
              <div
                style={{
                  paddingLeft: 30,
                  paddingTop: 30,
                  paddingBottom: 30,
                  // height:1500,
                  // position
                  width: "100%",
                  position: "static",
                  // right:"290px",
                  marginTop: 50,
                  // backgroundColor: "green",
                }}
              >
                {promo.map((addData: any) => {
                  const existingPromo = promo?.find(
                    (item: ICart) => item.id === addData.id
                  );
                  return (
                    <PayCart
                      key={addData.id}
                      cart={addData}
                      removeCart={removeCart}
                      selected={existingPromo}
                    />
                  );
                })}
              </div>
            </div>
          </Card>
        )} */}
        </SimpleGrid>
      )}
    </Box>
  );
}
