/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/imageHolder.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  useLoginBandaMutation,
  useLoginMobileMutation,
} from "generated/graphql";
import { loginUser } from "redux.store/user/actionCreators";
import { Dispatch } from "redux";
import { jwtDecode } from "jwt-decode";
import { clearCart } from "redux.store/cart/actionCreators";
import { clearCartPromo } from "redux.store/promo/actionCreators";

interface FormData {
  email: string;
  password: string;
}

interface FormErrors {
  [key: string]: string;
}

function SignIn() {
  const history = useHistory();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  // const textColor = useColorModeValue("white", "white");
  const textColorSecondary = "gray.400";
  // const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  // const textColorDetails = useColorModeValue("white", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  // const textColorBrand = useColorModeValue("white", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // #################################

  const dispatch: Dispatch<any> = useDispatch();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const [login, loading] = useLoginMobileMutation();
  const [bandaLogin] = useLoginBandaMutation();

  const [success, setSuccess] = useState(false);
  //Login
  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  const loginX = (user: IUser) => {
    loginService(user);
  };

  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const bandaCodeRegex = /^[A-Za-z]\d{4}$/;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      loginResponseError: "",
    }));

    checkInputType(value);

    // Validate the field based on its name
    switch (name) {
      case "email":
        if (bandaCodeRegex.test(value)) {
          validateBandaCode(value);
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        } else {
          validateEmail(value);
        }
        break;
      case "password":
        validatePassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    console.log("formData");
    console.log(errors);
    if (errors.email && errors.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        loginResponseError: "Please put proper details",
      }));

      return;
    } else if (!formData.email || !formData.password) {
      console.log("Please enter both email and password");
      setErrors((prevErrors) => ({
        ...prevErrors,
        loginResponseError: "Please put proper details",
      }));
      return; // Exit if fields are empty
    } else {
      setLoadingState(true)
      console.log("1312vsvf");
      console.log(formData);
      if (isBanda) {
        loginBanda();
      } else {
        loginF();
      }
    }

  };

  const loginBanda = async () => {
    validateBandaCode(formData.email);
    validatePassword(formData.password);
    //loginBanda

    if (!errors.email && !errors.password) {
      try {
        const response = await bandaLogin({
          variables: {
            authLoginReg: {
              inputData: formData.email.trim(),
              password: formData.password.trim(),
            },
          },
        });
        console.log(response);

        if (
          response.data &&
          response.data.loginBanda &&
          !response.data.loginBanda.log
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "Login failed",
          }));
          return;
        } else {
          // Clear the error for loginBanda if login is successful or no loginBanda property is present
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "",
          }));
        }

        const accessToken: any = response.data?.loginBanda.access_token;
        const refreshToken: any = response.data?.loginBanda.refresh_token;
        const userdata: any = jwtDecode(accessToken);
        const accountStatus: any = response.data?.loginBanda.account_status;
        const isLegFromQuery: any = response.data?.loginBanda.log; //boolean

        console.log(userdata);
        console.log(userdata.status);

        save("accountStatus", accountStatus);
        save("accessToken", accessToken);
        save("refreshToken", refreshToken);
        console.log("accssTokenFromStore");

        //update redux state
        // localStorage.setItem("refreshToken", refreshToken);

        loginX({
          id: userdata.bandaId,
          name: userdata.username,
          loginStatus: userdata.status.login,
          email: userdata.email,
          bandaCode: userdata.bandaCode,
          accessType: userdata.status.isContentOwner ? "content" : "none",
          sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
          contentStatus: userdata.status.isContentOwner,
          accountStat: !userdata.accountStat
            ? JSON.stringify([])
            : userdata.accountStat,
          mode: userdata.status.isBanda ? "banda" : "none",
          bandaStatus: userdata.status.isBanda ? "banda" : "none",
          language: user.language,
          darkMode: user.darkMode,
          loading: false,
        });

        setSuccess(true);

        history.push(`/site/explore`);
      } catch (error) {
        console.log("LOGIN FAILED ");
        setErrors((prevErrors) => ({
          ...prevErrors,
          loginResponseError: "Login failed",
        }));
      }
    }
    setLoadingState(false)

  };

  const clearPromoCart = React.useCallback(
    () => dispatch(clearCartPromo()),
    [dispatch]
  );

  const clearFundCart = React.useCallback(
    () => dispatch(clearCart()),
    [dispatch]
  );

  const loginF = async () => {
    validateEmail(formData.email);
    validatePassword(formData.password);
    //login user

    if (!errors.email && !errors.password) {
      try {
        const response = await login({
          variables: {
            authLoginReg: {
              inputData: formData.email.trim(),
              password: formData.password.trim(),
            },
          },
        });

        console.log(response);

        if (
          response.data &&
          response.data.loginMobile &&
          !response.data.loginMobile.log
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "Login failed",
          }));
          setLoadingState(false)

          return;
        } else {
          // Clear the error for loginMobile if login is successful or no loginMobile property is present
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "",
          }));

        }

        const accessToken: any = response.data?.loginMobile.access_token;
        const refreshToken: any = response.data?.loginMobile.refresh_token;
        const userdata: any = jwtDecode(accessToken);
        const accountStatus: any = response.data?.loginMobile.account_status;
        const isLegFromQuery: any = response.data?.loginMobile.log; //boolean

        console.log(userdata);
        console.log(userdata.status);

        save("accountStatus", accountStatus);
        save("accessToken", accessToken);
        save("refreshToken", refreshToken);
        console.log("accssTokenFromStore");


        //  return

        //update redux state
        // localStorage.setItem("refreshToken", refreshToken);


        //update redux state
        clearPromoCart();
        clearFundCart();
        loginX({
          id: userdata.id,
          name: userdata.username,
          loginStatus: userdata.status.login,
          bandaCode: userdata.bandaCode,
          email: userdata.email,
          sponsorId: userdata?.sponsorId,
          accessType: userdata.status.isContentOwner ? "content" : "none",
          sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
          contentStatus: userdata.status.isContentOwner,
          accountStat: !userdata.accountStat
            ? JSON.stringify([])
            : userdata.accountStat,
          mode: "none",
          bandaStatus: userdata.status.isBanda ? "banda" : "none",
          language: user.language,
          darkMode: user.darkMode,
          loading: false,
        });

        setSuccess(true);

        history.push(`/site/explore`);
        setLoadingState(false)

      } catch (error) {

        console.log("LOGIN FAILED ");
        setErrors((prevErrors) => ({
          ...prevErrors,
          loginResponseError: "Login failed",
        }));
        setLoadingState(false)

      }
    }
    setLoadingState(false)

  };

  useEffect(() => {
    if (
      success
      //  && user.loginStatus
    ) {
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      console.log(user.loginStatus);
      // history.push(`/site/explore`);
    }
  }, [success]);
  //Login

  async function save(key: string, value: any): Promise<void> {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`${key} saved successfully.`);
    } catch (error: any) {
      console.error(`Error saving ${key}: ${error.message}`);
    }
  }

  const [isBanda, setIsBanda] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const validateBandaCode = (bandaCode: string) => {
    const bandaCodeRegex = /^[A-Za-z]\d{4}$/;
    if (!bandaCode.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "required",
      }));
    } else if (!bandaCodeRegex.test(bandaCode)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid BandaCode format. Please use a format like B1234",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const checkInputType = (input: string) => {
    const bandaCodeRegex = /^[A-Za-z]\d{4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (bandaCodeRegex.test(input)) {
      setIsBanda(true);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    } else if (emailRegex.test(input)) {
      setIsBanda(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[^\s]{8,}$/;
    if (!password.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
    } else if (password.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 8 characters long",
      }));
    } else if (!passwordRegex.test(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {!loadingState ? (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              BandaJamii Signin
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Signin with Google
          </Button> */}

            {/* <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}

            <FormControl>
              {errors.email && (
                <Text style={{ color: "red" }}>Wrong format</Text>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email or BandaCode<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@mail.com or A0001"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.password && (
                <Text style={{ color: "red" }}>
                  {" "}
                  Should have 8 chars with a capital,symbol,number and no space{" "}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Flex justifyContent="space-between" align="center" mb="24px">
                {/* <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl> */}
                {/* <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink> */}
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#461177",
                }}
              >
                Sign in
              </Button>
              {errors.loginResponseError && (
                <Text style={{ color: "red" }}>Incorrect credentials</Text>
              )}
            </FormControl>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Not registered yet?
                <Link
                  onClick={() => history.push("/auth/sign-up")}
                  mt={{
                    base: "0px",
                    md: "10px",
                    lg: "0px",
                    xl: "10px",
                    "2xl": "0px",
                  }}
                >
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Create an Account
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        // bg={"orange"}
        flexDirection="column"
        style={{
          width:"100%"
        }}
      >
       
          <div className="flex justify-center mt-20  p-12" style={{
            width:"100%"
          }}>
            <div
              className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
       
      </Flex>
      
      )}
    </DefaultAuth>
  );
}

export default SignIn;
