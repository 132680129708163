import React, { FormEvent, useEffect, useState } from "react";

import Banner from "views/site/registerContentAccount/components/Banner";
import { FormControl, FormLabel, Input, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import {
  useAutoLoginMobileMutation,
  useRegistrationContentOwnerMutation,
  useRegistrationSponsorMutation,
  useSponsorQuery,
} from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import { Dispatch } from "redux";
import {
  loginUser,
  logoutUser,
  registerPartnership,
} from "redux.store/user/actionCreators";
import {
  findCurrentAccountStateByKey,
  isInList,
  pushOperation,
} from "../helpers";
import regionData from "utils/geo_data.json";
import { jwtDecode } from "jwt-decode";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];
export interface FormData {
  phoneNumber?: string;
  companyName?: string;
  industry?: string;
  idType?: string;
  region: string;
  district: string;
  ward: string;
  address?: string;
  physicalResidency: string;
  mtaaVillage: string;

  nationalID?: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function RegisterContentAccount() {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [formData, setFormData] = useState<FormData>({
    companyName: "",
    idType: "",
    phoneNumber: "",
    industry: "",
    region: "",
    district: "",
    ward: "",
    address: "",
    physicalResidency: "",
    mtaaVillage: "",
    nationalID: "",
  });

  const dispatch: Dispatch<any> = useDispatch();

  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );

  const logout = (user: IUser) => {
    logoutService();
    localStorage.setItem("accessToken", "");
    history.push("/landing/landing");
  };

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "companyName":
        validateCompanyName(value);
        break;
      case "phoneNumber":
        validatePhone(value);
        break;
      case "physicalResidency":
        validatePhysicalResidency(value);
        break;
      case "address":
        validateAddress(value);
        break;
      default:
        break;
    }
  };

  // Function to validate a phoneNumber number
  const validatePhone = (phoneNumber: string) => {
    const phoneRegex = /^(\+\d{1,3}\d{9,12}|07\d{8})$/;
    if (!phoneNumber.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Phone number is required",
      }));
    } else if (!phoneRegex.test(phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phoneNumber number format",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }
  };

  const validateNIDA = (text: string) => {
    const textRegex = /^\d{20}$/;
    if (!text.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text is required",
      }));
    } else if (!textRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text must contain exactly 20 numbers",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "",
      }));
    }
  };

  const validatePhysicalResidency = (physicalResidency: string) => {
    const textRegex = /^(.+)$/;
    if (!physicalResidency.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "physical Residency is required",
      }));
    } else if (!textRegex.test(physicalResidency)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "Wrong Residency",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "",
      }));
    }
  };

  const validateCompanyName = (companyName: string) => {
    const textRegex = /^(.+)$/;
    if (!companyName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "companyName is required",
      }));
    } else if (!textRegex.test(companyName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "companyName is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "",
      }));
    }
  };
  const validateAddress = (address: string) => {
    const textRegex = /^(.+)$/;
    if (!address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is required",
      }));
    } else if (!textRegex.test(address)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
      }));
    }
  };

  const [registerContentOwner] = useRegistrationContentOwnerMutation();

  const [isCompany, setIsCompany] = useState(false);

  const isDataCompany = (e: any) => {
    console.log(e);
    console.log(e);
    console.log(e);
    setIsCompany(e);
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   // Handle form submission, you can send formData to your backend or perform any necessary actions here
  //   console.log(formData);
  // };

  const history = useHistory();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [latestContentOwnerApplication, setLatestContentOwnerApplication] =
    useState(null);

  useEffect(() => {
    if (user && user.accountStat) {
      const latest = findCurrentAccountStateByKey(
        JSON.parse(user.accountStat),
        "ContentOwnerApplication"
      );
      setLatestContentOwnerApplication(latest);
    }
  }, [user]);

  //Login
  const registerPartnershipService = React.useCallback(
    (user: IUser) => dispatch(registerPartnership(user)),
    [dispatch]
  );

  const [userId, setUserId] = useState(user.id);
  const [loading, setLoading] = useState(false);

  const { error, data, refetch } = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: userId,
      },
    },
  });

  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  const loginX = (user: IUser) => {
    loginService(user);
  };

  console.log("user");
  console.log(user);

  async function save(key: string, value: any): Promise<void> {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`${key} saved successfully.`);
    } catch (error: any) {
      console.error(`Error saving ${key}: ${error.message}`);
    }
  }

  const [autoLogin] = useAutoLoginMobileMutation();

  const loginF = async () => {
    //login user

    if (!errors.email && !errors.password) {
      try {
        const response = await autoLogin({
          variables: {
            autoLoginMobileInput: {
              userId: user.id,
              inputData: "",
            },
          },
        });

        console.log(response);

        if (
          response.data &&
          response.data.autoLoginMobile &&
          !response.data.autoLoginMobile.log
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "Login failed",
          }));

          return;
        } else {
          // Clear the error for loginMobile if login is successful or no loginMobile property is present
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "",
          }));
        }

        const accessToken: any = response.data?.autoLoginMobile.access_token;
        const refreshToken: any = response.data?.autoLoginMobile.refresh_token;
        const userdata: any = jwtDecode(accessToken);
        const accountStatus: any =
          response.data?.autoLoginMobile.account_status;
        const isLegFromQuery: any = response.data?.autoLoginMobile.log; //boolean

        console.log(userdata);
        console.log(userdata.status);

        save("accountStatus", accountStatus);
        save("accessToken", accessToken);
        save("refreshToken", refreshToken);
        console.log("accssTokenFromStore");

        // return

        //update redux state
        // localStorage.setItem("refreshToken", refreshToken);

        loginX({
          id: userdata.id,
          name: userdata.username,
          loginStatus: userdata.status.login,
          bandaCode: userdata.bandaCode,
          email: userdata.email,
          sponsorId: userdata?.sponsorId,
          accessType: userdata.status.isContentOwner ? "content" : "none",
          sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
          contentStatus: userdata.status.isContentOwner,
          accountStat: !userdata.accountStat
            ? JSON.stringify([])
            : userdata.accountStat,
          mode: "none",
          bandaStatus: userdata.status.isBanda ? "banda" : "none",
          language: user.language,
          darkMode: user.darkMode,
          loading: false,
        });

        // setSuccess(true);

        // history.push(`/site/explore`);
        setLoading(false);
      } catch (error) {
        console.log("LOGIN FAILED ");
        setErrors((prevErrors) => ({
          ...prevErrors,
          loginResponseError: "Login failed",
        }));
        setLoading(false);
        history.push("/site/register");
      }
    }
    setLoading(false);
    history.push("/site/register");
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);

    console.log(user);

    e.preventDefault();

    // validateAddress(formData.address);
    // validatePhysicalResidency(formData.physicalResidency);
    // validateNIDA(formData.nationalID);
    validatePhone(formData.phoneNumber);

    //Only phone number is required
    if (
      errors.phoneNumber
      //&& errors.companyName
    ) {
      return;
    }

    if (
      !formData.phoneNumber
      //&& !formData.companyName
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Fill in required fields",
      }));
      return;
    }

    let uploadingData = {
      userId: user.id,
      nationalID: formData?.nationalID, //or company id
      phoneNumber: formData?.phoneNumber,
      idType: formData.idType,
      address: formData?.address,
      physicalResidency: formData?.physicalResidency,
      isCompany: isCompany,
      region: formData?.region,
      district: formData?.district,
      ward: formData?.ward,
      companyName: formData?.companyName,
      industry: formData?.industry,
    };

    console.log(formData);

    try {
      const response = await registerContentOwner({
        variables: {
          registerContentInput: uploadingData,
        },
      });
      console.log(response);
      console.log(response);
      console.log(response);
      // localStorage.setItem("accessToken", "");

      if (
        response.data &&
        response.data.registrationContentOwner &&
        !response.data.registrationContentOwner.log
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: response.data.registrationContentOwner.mode,
        }));
        // window.location.reload();
        history.push("/site/register");
        return;
      } else {
        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      console.log(response);
      console.log(response);
      console.log(response);

      //#TODO in db prevent double registration
      if (response.data?.registrationContentOwner.log) {
        // addSponsorState({
        //   id: user.id,
        //   name: user.name,
        //   loginStatus: user.loginStatus,
        //   email: "",
        //   accessType: response.data?.registrationContentOwner.mode,
        //   language: user.language,
        //   mode: !isInList(user.mode, "contentWait") ? pushOperation(user.mode, "contentWait") :user.mode,
        //   darkMode: user.darkMode,
        //   accountStat:response.data.registrationContentOwner.mode,
        //   loading: false,
        // });

        // loginX({
        //   id: user.id,
        //   name: user.name,
        //   loginStatus: user.loginStatus,
        //   email: user.email,
        //   accessType: user.accessType, //user.role === "SPONSOR" ? "sponsor" :"content",
        //   accountStat: response.data.registrationContentOwner.mode,
        //   mode: user.mode,
        //   contentStatus: true,
        //   language: user.language,
        //   darkMode: user.darkMode,
        //   loading: false,
        // });

        // handleSubmitLocal();
        // Reset form fields after submission if needed

        setFormData({
          phoneNumber: "",
          idType: "",
          address: "",
          region: "",
          district: "",
          ward: "",
          mtaaVillage: "",

          physicalResidency: "",
          nationalID: "",
        });
        setUserId(user.id);
        // logout(user);
        // window.location.reload();
        loginF();
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));

      setLoading(false);
      // history.push("/site/register");
    }
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      district: "",
      ward: "",
    }));
  }, [formData.region]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ward: "",
    }));
  }, [formData.district]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        gridTemplateColumns={{
          xl: "repeat(1, 1fr)",
          // "2xl": "1fr 0.46fr"
        }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        {!loading ? (
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            {/* <Banner
            isDataCompany={isDataCompany}
            accountStatus={latestContentOwnerApplication?.status}
          /> */}

            {!(latestContentOwnerApplication?.status === "PENDING") && (
              <Box>
                {/* {isCompany ? (
              <Text
                style={{
                  marginTop: 10,
                  marginBottom: 50,
                }}
                color={textColor}
                fontSize="2xl"
                ms="24px"
                fontWeight="700"
              >
                COMPANY DETAILS{" "}
              </Text>
            ) : (
              <Text
                style={{
                  marginTop: 10,
                  marginBottom: 50,
                }}
                color={textColor}
                fontSize="2xl"
                ms="24px"
                fontWeight="700"
              >
              </Text>
            )} */}
                <Card mb={{ base: "0px", "2xl": "20px" }}>
                  <Text
                    style={{
                      marginTop: 10,
                      marginBottom: 50,
                    }}
                    color={textColor}
                    fontSize="2xl"
                    ms="24px"
                    fontWeight="700"
                  >
                    Content owner account
                  </Text>
                  {false ? (
                    <Box as="form" onSubmit={handleSubmit}></Box>
                  ) : (
                    <Box as="form" onSubmit={handleSubmit}>
                      <Flex align="start" mb={"30px"}>
                        <Button
                          bg={isCompany ? "green.500" : "transparent"}
                          color={isCompany ? "white" : "black"}
                          _hover={{ bg: "#1a759f" }}
                          _active={{ bg: "green.500" }}
                          // _focus={{ bg: "white" }}
                          fontWeight="800"
                          fontSize="14px"
                          py="20px"
                          px="17"
                          me="18px"
                          onClick={() => {
                            setIsCompany(true);
                          }}
                        >
                          Organisation
                        </Button>
                        <Button
                          bg={!isCompany ? "green.500" : "transparent"}
                          color={!isCompany ? "white" : "black"}
                          _hover={{ bg: "#1a759f" }}
                          _active={{ bg: "green.500" }}
                          fontWeight="800"
                          fontSize="14px"
                          py="20px"
                          px="17"
                          me="18px"
                          onClick={() => {
                            setIsCompany(false);
                          }}
                        >
                          Private
                        </Button>
                      </Flex>
                      <SimpleGrid minChildWidth="300px" spacing="20px">
                        {isCompany ? (
                          <div>
                            {errors.companyName && (
                              <Text style={{ color: "red" }}>
                                Wrong Format of company name
                              </Text>
                            )}
                            <FormLabel>Organisation Name</FormLabel>
                            <Input
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        ) : (
                          <div>
                            {errors.companyName && (
                              <Text style={{ color: "red" }}>
                                Wrong Format of name
                              </Text>
                            )}
                            <FormLabel>Name </FormLabel>
                            <Input
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        )}

                        <div>
                          {errors.phoneNumber && (
                            <Text style={{ color: "red" }}>
                              Wrong Format of number
                            </Text>
                          )}

                          <FormLabel>Phone Number:</FormLabel>
                          <Input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>

                        <div>
                          {isCompany ? (
                            <div>
                              {errors.idType && (
                                <Text style={{ color: "red" }}>
                                  Wrong Format
                                </Text>
                              )}
                              <FormLabel>Id Type :</FormLabel>

                              <Select
                                name="idType"
                                value={formData.idType}
                                onChange={handleChange}
                                placeholder="Select idType"
                              >
                                {[
                                  { mode: "COMPANY_ID", label: "COMPANY ID" },
                                  { mode: "TIN", label: "TIN" },
                                ].map((cat: any) => (
                                  <option key={cat.mode} value={cat.mode}>
                                    {cat.label}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          ) : (
                            <div>
                              {errors.idType && (
                                <Text style={{ color: "red" }}>
                                  Wrong Format
                                </Text>
                              )}
                              <FormLabel>Id Type :</FormLabel>

                              <Select
                                name="idType"
                                value={formData.idType}
                                onChange={handleChange}
                                placeholder="Select idType"
                              >
                                {[
                                  { mode: "NIDA", label: "NIDA" },
                                  // { mode: "TIN", label: "TIN" },
                                  // { mode: "TIN", label: "TIN" },
                                  { mode: "PASSPORT", label: "PASSPORT" },
                                  {
                                    mode: "DRIVER_LICENCE",
                                    label: "DRIVER LICENCE",
                                  },
                                ].map((cat: any) => (
                                  <option key={cat.mode} value={cat.mode}>
                                    {cat.label}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          )}
                        </div>

                        <div>
                          {errors.nationalID && (
                            <Text style={{ color: "red" }}>Wrong ID</Text>
                          )}

                          <FormLabel>ID (optional)</FormLabel>
                          <Input
                            type="text"
                            name="nationalID"
                            value={formData.nationalID}
                            onChange={handleChange}
                          />
                        </div>

                        {/* AREA LOCATION SELECTION */}
                        <div>
                          {errors.region && (
                            <Text style={{ color: "red" }}>
                              {errors.region}
                            </Text>
                          )}

                          <Select
                            name="region"
                            value={formData.region}
                            onChange={handleChange}
                            placeholder="Select region"
                          >
                            {regionData?.map((cat: any) => (
                              <option key={cat.region} value={cat.region}>
                                {cat.region}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div>
                          {errors.district && (
                            <Text style={{ color: "red" }}>
                              {errors.district}
                            </Text>
                          )}

                          <Select
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            placeholder="Select district"
                          >
                            {regionData
                              ?.find(
                                (cat: any) => cat.region === formData.region
                              )
                              ?.data?.map((cat_district: any) => (
                                <option
                                  key={
                                    formData.region +
                                    "_" +
                                    cat_district.district
                                  }
                                  // value={cat_district.district}
                                  value={
                                    cat_district.district +
                                    "_" +
                                    cat_district.postCode
                                  }
                                >
                                  {cat_district.district}
                                </option>
                              ))}
                          </Select>
                        </div>

                        <div>
                          {errors.ward && (
                            <Text style={{ color: "red" }}>{errors.ward}</Text>
                          )}
                          <Select
                            name="ward"
                            value={formData.ward}
                            onChange={handleChange}
                            placeholder="Select ward"
                          >
                            {regionData
                              ?.find(
                                (cat: any) => cat.region === formData.region
                              )
                              // @ts-ignore

                              ?.data?.find(
                                (cat_district: any) =>
                                  cat_district.district +
                                    "_" +
                                    cat_district.postCode ===
                                  formData.district
                              )
                              ?.data?.map((cat_ward: any) => {
                                console.log("cat_ward");
                                console.log(cat_ward);
                                console.log("cat_ward");

                                return (
                                  <option
                                    key={
                                      formData.district + "_" + cat_ward.ward
                                    }
                                    // value={cat_ward.ward}
                                    value={
                                      cat_ward.ward + "_" + cat_ward.postCode
                                    }
                                  >
                                    {cat_ward.ward}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <div>
                          {errors.mtaaVillage && (
                            <Text style={{ color: "red" }}>
                              {errors.mtaaVillage}
                            </Text>
                          )}
                          <Select
                            name="mtaaVillage"
                            value={formData.mtaaVillage}
                            onChange={handleChange}
                            placeholder="Select mtaa"
                          >
                            {regionData
                              // @ts-ignore
                              ?.find(
                                (cat: any) => cat?.region === formData.region
                              )
                              // @ts-ignore
                              ?.data?.find(
                                (cat_district: any) =>
                                  `${cat_district?.district}_${cat_district?.postCode}` ===
                                  formData.district
                              )
                              ?.data?.find(
                                (cat_ward: any) =>
                                  `${cat_ward?.ward}_${cat_ward?.postCode}` ===
                                  formData.ward
                              )
                              ?.data?.map((mtaaVillage: any) => (
                                <option
                                  key={
                                    formData.district +
                                    "_" +
                                    mtaaVillage.mtaaVillage
                                  }
                                  value={
                                    mtaaVillage.mtaaVillage
                                      ? `Mtaa/Kijiji_${mtaaVillage.mtaaVillage}`
                                      : //@ts-ignore
                                        `Kitongoji_${mtaaVillage.kitongoji}`
                                  }
                                >
                                  <span className="text-blue-300">
                                    {mtaaVillage.mtaaVillage
                                      ? "Mtaa/Kijiji cha"
                                      : "Kitongoji cha"}{" "}
                                  </span>
                                  {mtaaVillage.mtaaVillage}
                                  {
                                    //@ts-ignore

                                    mtaaVillage.kitongoji
                                  }
                                </option>
                              ))}
                          </Select>
                        </div>
                        {/*  */}

                        <div>
                          {/* {errors.address && (
                        <Text style={{ color: "red" }}>
                          Address cant be empty
                        </Text>
                      )} */}

                          <FormLabel>Address:</FormLabel>
                          <Input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </div>

                        <div>
                          <FormLabel>Physical Residency:</FormLabel>
                          <Input
                            type="text"
                            name="physicalResidency"
                            value={formData.physicalResidency}
                            onChange={handleChange}
                          />
                        </div>

                        {isCompany && (
                          <div>
                            {errors.industry && (
                              <Text style={{ color: "red" }}>
                                Choose Industry
                              </Text>
                            )}

                            <FormLabel>Industry:</FormLabel>
                            <Select
                              name="industry"
                              value={formData.industry}
                              onChange={handleChange}
                              placeholder="Select Industry"
                            >
                              {filtersLabels.map((cat: any) => (
                                <option key={cat.mode} value={cat.mode}>
                                  {cat.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        )}
                      </SimpleGrid>
                      <Button type="submit" colorScheme="green" marginTop="4">
                        Submit
                      </Button>
                      {errors.response && (
                        <Text style={{ color: "red" }}>
                          Opeartion Failed contact us..
                        </Text>
                      )}
                    </Box>
                  )}
                </Card>
              </Box>
            )}
          </Flex>
        ) : (
          <div>
            <p>LOADING .....</p>
          </div>
        )}
      </Grid>
    </Box>
  );
}
