import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  MenuButton,
  MenuList,
  Icon,
  Button,
} from "@chakra-ui/react";
import { Menu } from "@chakra-ui/react";
import * as React from "react";
import generatePDF, { Resolution, usePDF, Margin, Options } from "react-to-pdf";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { MdClose, MdCloseFullscreen, MdDescription } from "react-icons/md";
import { useMyPaymentCartQuery } from "generated/graphql";
import { useSelector } from "react-redux";
import { formatDateTime } from "views/site/helpers";
import { PDFViewer } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function ColumnTable() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  let menuBg = useColorModeValue("white", "navy.800");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [invoiveView, setInvoiveView] = React.useState(false);
  const [invoiveViewItem, setInvoiveViewItem] = React.useState({});
  const [orderViewItem, setOrderViewItem] = React.useState({});
  const [orderViewItemId, setOrderViewItemId] = React.useState("");
  const [orderView, setOrderView] = React.useState(false);
  const [invoiveViewItemId, setInvoiveViewItemId] = React.useState("");

  const [page, setPage] = React.useState(1);
  const [pageTemp, setPageTemp] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [category, setCategory] = React.useState("all");

  const contentData = useMyPaymentCartQuery({
    fetchPolicy: "network-only",
    variables: {
      ordersInput: {
        userId: user.id,
        page,
        pageSize,
        category,
      },
    },
  });

  const foundCart = (id: string) =>
    contentData?.data?.myPaymentCart.find((item: any) => item.id === id);

  let defaultData = contentData?.data?.myPaymentCart
    ? contentData?.data?.myPaymentCart
    : [];
  React.useEffect(() => {
    setData(() => [...defaultData]);
  }, [contentData?.data?.myPaymentCart]);

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          INVOICE
        </Text>
      ),
      cell: (info: any) => (
          <Button
            onClick={() => {
              // foundCart(info.getValue()).activated
              setInvoiveView(true);
              setOrderView(true);
              setInvoiveViewItemId(info.getValue());
              setOrderViewItemId(info.getValue());
            }}
            color={textColor}
            fontSize="sm"
            fontWeight="700"
          >
            {foundCart(info.getValue())?.invoiceId?.toUpperCase()}
          </Button>
      ),
    }),

    // columnHelper.accessor("itemName", {
    //   id: "itemName",
    //   header: () => (
    //     <Text
    //       // justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       TITLE
    //     </Text>
    //   ),
    //   cell: (info: any) => (
    //     <Text color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue()}
    //       </Text>
    //   ),
    // }),

    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDateTime(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("totalAmount", {
      id: "totalAmount",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          AMOUNT (Tsh)
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()} 
        </Text>
      ),
    }),
    // columnHelper.accessor("currency", {
    //   id: "currency",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       Currency
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Text color={textColor} fontSize="sm" fontWeight="700">
    //       {info.getValue()}
    //     </Text>
    //   ),
    // }),
    columnHelper.accessor("isPromo", {
      id: "isPromo",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TYPE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ? "PROMO" : "FUNDED"}
        </Text>
      ),
    }),
    // columnHelper.accessor("activated", {
    //   id: "activated",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     ></Text>
    //   ),
    //   cell: (info) => (
    //     <div
    //       style={{
    //         zIndex: 999,
    //       }}
    //     >
    //       {info.getValue() && (
    //         <div>
    //           <Text
    //             justifyContent="space-between"
    //             align="center"
    //             fontSize={{ sm: "10px", lg: "12px" }}
    //             color="gray.400"
    //           >
    //             Invoice sent
    //           </Text>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // }),
    columnHelper.accessor("closed", {
      id: "closed",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Order
        </Text>
      ),
      cell: (info) => (
        <div
          style={{
            zIndex: 999,
          }}
        >
          {!info.getValue() ? (
            <Text color={"gray.200"} fontSize="sm" fontWeight="700">
              On process
            </Text>
          ) : (
            <Text color={"green"} fontSize="sm" fontWeight="700">
              PAID
            </Text>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("orderSteps", {
      id: "orderSteps",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <div
          style={{
            zIndex: 999,
          }}
        >
          <Text
            color={"red"}
            fontSize="sm"
            fontWeight="700"
            style={{
              color: info.getValue() === "INVOICE" ? "blue" :  info.getValue() === "SUCCESS" ?"green" :  info.getValue() === "REJECTED" ?"red":"grey",
            }}
          >
            {info.getValue()}
          </Text>
        </div>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          // justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        ></Text>
      ),
      cell: (info) => (
        <div
          style={{
            zIndex: 999,
          }}
        >
          {foundCart(info.getValue()).activated && (foundCart(info.getValue()).orderSteps !== "SUCCESS") && (
            <Button
              onClick={() => {
                setInvoiveView(true);
                setOrderView(false);
                setInvoiveViewItemId(info.getValue());
                setOrderViewItemId(info.getValue());

              }}
              p="0px"
            >
              <Flex align="center">
                <Icon
                  as={MdDescription}
                  color="black"
                  w="18px"
                  h="18px"
                  me="10px"
                />
              </Flex>
            </Button>
          )}
        </div>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const targetRef = React.useRef();

  React.useEffect(() => {
    const selectedItem = contentData?.data?.myPaymentCart?.find(
      (item: any) => item.id === invoiveViewItemId
    );

    console.log("setting");
    console.log("setting");
    console.log(selectedItem);
    console.log("setting");
    console.log("setting");

    setInvoiveViewItem(selectedItem);
    setOrderViewItem(selectedItem);

  }, [invoiveViewItemId, invoiveView,orderViewItemId, orderView]);

  React.useEffect(() => {
    const selectedItem = contentData?.data?.myPaymentCart?.find(
      (item: any) => item.id === orderViewItemId
    );

    console.log("setting");
    console.log("setting");
    console.log(selectedItem);
    console.log("setting");
    console.log("setting");

    setOrderViewItem(selectedItem);
  }, [orderViewItemId, orderView]);
  
  // const contentData = useMyPaymentCartQuery({
  //   fetchPolicy: "network-only",
  //   variables: {
  //     ordersInput: {
  //       userId: user.id,
  //       page,
  //       pageSize,
  //       category,
  //     },
  //   },
  // });

  return (
    <div>
      {!invoiveView ? (
        <Card flexDirection="column" w="100%" px="0px">
          <Flex
            px="25px"
            mb="8px"
            justifyContent="space-between"
            align="center"
          ></Flex>
          <Box>
            <Table variant="simple" color="gray.500" mb="24px" mt="12px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                          cursor="pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Flex
                            justifyContent="space-between"
                            align="center"
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table
                  .getRowModel()
                  .rows.slice(0, 11)
                  .map((row) => {
                    return (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Td
                              key={cell.id}
                              fontSize={{ sm: "14px" }}
                              minW={{ sm: "150px", md: "200px", lg: "auto" }}
                              borderColor="transparent"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      ) : (
        <>
          {orderView ? (
            <Card flexDirection="column" w="100%" px="0px">
              <div ref={targetRef}>
                <Card bg={"f9f9f9"} flexDirection="column" w="100%" px="0px">
                  <Flex 
                //  justifyContent={"space-between"}
                width={"100%"} 
                  flexDirection={"column"}>
                    {/* <Text
                      justifyContent="space-between"
                      align="center"
                      marginLeft={"30px"}
                      fontSize={{ sm: "13px", lg: "16px" }}
                      color={
                        //@ts-ignore
                        orderViewItem?.activated ? "green.400" : "gray.400"
                      }
                    >
                      {
                        //@ts-ignore
                        orderViewItem?.activated ? "Approved invoice " : `Your order request ${orderViewItem?.orderSteps}`
                      }
                    </Text> */}
                    <Button
                      onClick={() => {
                        setInvoiveView(false);
                        setOrderView(false);
                        setInvoiveViewItem({});
                        setOrderViewItem({});

                      }}
                      p="0px"
                      width={"30px"}
                      alignContent={"center"}
                      alignSelf={"end"}
                      display={"flex"}
                      justifyContent={"center"}
                      height={"30px"}
                    >
                      <Icon
                        as={MdClose}
                        color="gray"
                        // w="18px"
                        alignSelf={"center"}
                        // h="18px"
                        // me="10px"
                      />
                    </Button>
                  </Flex>

                  <Flex
                    // justifyContent={"space-between"}
                    flexDirection={"column"}
                  >
                    {/* <div
              style={{
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                borderRadius: "10px",
                maxWidth: "400px",
                margin: "auto",
                position: "relative",
                zIndex: 999,
              }}
            >
             
            </div> */}
                    <Flex
                      flexDirection={"row"}
                      w="100%"
                      mb="20px"
                      style={{ marginLeft: "0%", height: "140px" }}
                    >
                      <Flex
                        flexDirection={"column"}
                        w="70%"
                        // bg={"pink"}
                        paddingLeft={"3%"}
                        // justifyContent={"center"}
                        mb="20px"
                      >
                        <Text
                          align={"start"}
                          marginTop={"30px"}
                          fontSize="20px"
                          fontWeight="800"
                          color={"black"}
                        >
                          BANDAJAMII
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray"
                        >
                          Physical residency
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray"
                        >
                          Address
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        w="30%"
                        // bg={"red"}
                        paddingTop={"2%"}
                        paddingRight={"1%"}
                        paddingLeft={"10%"}
                        mb="20px"
                        style={{ marginLeft: "0%", height: "100px" }}
                      >
                        <Text
                          align="start"
                          fontSize="sm"
                          fontWeight="600"
                          color={textColor}
                        >
                          Invoice Details{" "}
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            orderViewItem?.id.toUpperCase()
                          }
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            formatDateTime(orderViewItem?.createdAt)
                          }
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"3%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"95%"}
                      justifyContent={"space-between"}
                    >
                      <div>
                        <Text fontSize="14px" color={textColor}>
                          Billing to {user.name}
                        </Text>

                        <Text
                          fontSize="24px"
                          fontWeight="600"
                          color={textColor}
                        >
                          {
                            //@ts-ignore
                            orderViewItem?.itemName?.toUpperCase()
                          }
                        </Text>
                      </div>

                      <Text fontSize="24px" fontWeight="600" color={textColor}>
                        {
                          //@ts-ignore
                          orderViewItem?.totalAmount
                        }{" "}
                        {
                          //@ts-ignore
                          orderViewItem?.currency
                        }
                      </Text>
                    </Flex>

                    <Text
                      justifyContent="space-between"
                      align="start"
                      marginLeft={"3%"}
                      marginTop={"3%"}
                      marginBottom={"1%"}
                      fontSize={{ sm: "14px", lg: "16px" }}
                      color="gray.400"
                      // borderBottomWidth={"1px"}
                      // borderColor={"gray.300"}
                    >
                      Description
                      {/* Description:{ price , currency  ,promoted ,number of quantityu} */}
                    </Text>

                   {
                    //@ts-ignore
                    orderViewItem?.carts?.map(
                    (itemIN:any)=>{return(<Flex
                      // bg={"#f7f7f7"}
                      marginLeft={"10%"}
                      padding={"10px"}
                      flexDirection="column"
                    >
                      <Text>Content: {itemIN.title}</Text>
                      {
                        //@ts-ignore
                        itemIN?.bucketFund?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              {item.orderType === "BUCKET" ? (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              ) : (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.frequency}
                                  {" banda @ "}
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              )}
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        itemIN?.events?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.bandaCode}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {" banda for "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        itemIN?.promoBucket?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        itemIN?.promoEvent?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }
                    </Flex>)})}

                    <Flex
                      w="100%"
                      marginLeft={"2%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"93%"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="14px" color={textColor}>
                        Total
                      </Text>

                      <Flex flexDirection={"column"}>
                        <Text fontSize="16px" color={textColor}>
                          {
                            //@ts-ignore
                            orderViewItem?.totalAmount
                          }{" "}
                          {
                            //@ts-ignore
                            orderViewItem?.currency
                          }
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
              </div>

              {/* <Button
                onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
                fontSize="12px"
                color={textColor}
              >
                Download pdf{" "}
              </Button> */}
            </Card>
          ) : (
            <Card flexDirection="column" w="100%" px="0px">
              <div >
                <Card ref={targetRef} bg={"f9f9f9"} flexDirection="column" w="100%" px="0px">
                  <Flex justifyContent={"space-between"}>
                    <Text
                      justifyContent="space-between"
                      align="center"
                      marginLeft={"30px"}
                      fontSize={{ sm: "13px", lg: "16px" }}
                      color={
                        //@ts-ignore
                        invoiveViewItem?.activated ? "green.400" : "gray.400"
                      }
                    >
                      {
                        //@ts-ignore
                        invoiveViewItem?.activated ? "Received Invoice ": `Your order request ${invoiveViewItem?.orderSteps}`
                      }
                    </Text>
                    <Button
                      onClick={() => {
                        setInvoiveView(false);
                        setOrderView(false);
                        setInvoiveViewItem({});
                        setOrderViewItem({});

                      }}
                      p="0px"
                      width={"36px"}
                      alignContent={"center"}
                      display={"flex"}
                      justifyContent={"center"}
                      height={"36px"}
                    >
                      <Icon
                        as={MdClose}
                        color="black"
                        w="30px"
                        alignSelf={"center"}
                        // h="18px"
                        // me="10px"
                      />
                    </Button>
                  </Flex>

                  <Flex
                    // justifyContent={"space-between"}
                    flexDirection={"column"}
                  > <Flex
                      flexDirection={"row"}
                      w="100%"
                      mb="20px"
                      style={{ marginLeft: "0%", height: "140px" }}
                    >
                      <Flex
                        flexDirection={"column"}
                        w="70%"
                        // bg={"pink"}
                        paddingLeft={"3%"}
                        // justifyContent={"center"}
                        mb="20px"
                      >
                        <Text
                          align={"start"}
                          marginTop={"30px"}
                          fontSize="20px"
                          fontWeight="800"
                          color={"black"}
                        >
                          BANDAJAMII
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Physical residency
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Address
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        w="30%"
                        // bg={"red"}
                        paddingTop={"2%"}
                        paddingRight={"1%"}
                        paddingLeft={"10%"}
                        mb="20px"
                        style={{ marginLeft: "0%", height: "100px" }}
                      >
                        <Text
                          align="start"
                          fontSize="sm"
                          fontWeight="600"
                          color={textColor}
                        >
                          Invoice Details{" "}
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            invoiveViewItem?.id.toUpperCase()
                          }
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            formatDateTime(invoiveViewItem?.createdAt)
                          }
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"3%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"95%"}
                      justifyContent={"space-between"}
                    >
                      <div>
                        <Text fontSize="14px" color={textColor}>
                          Billing to {user.name}
                        </Text>

                        <Text
                          fontSize="24px"
                          fontWeight="600"
                          color={textColor}
                        >
                          {
                            //@ts-ignore
                            invoiveViewItem?.itemName?.toUpperCase()
                          }
                        </Text>
                      </div>

                      <Text fontSize="24px" fontWeight="600" color={textColor}>
                        {
                          //@ts-ignore
                          invoiveViewItem?.totalAmount
                        }{" "}
                        {
                          //@ts-ignore
                          invoiveViewItem?.currency
                        }
                      </Text>
                    </Flex>

                    <Text
                      justifyContent="space-between"
                      align="start"
                      marginLeft={"3%"}
                      marginTop={"3%"}
                      marginBottom={"1%"}
                      fontSize={{ sm: "14px", lg: "16px" }}
                      color="gray.400"
                      // borderBottomWidth={"1px"}
                      // borderColor={"gray.300"}
                    >
                      Description
                      {/* Description:{ price , currency  ,promoted ,number of quantityu} */}
                    </Text>

                    <Flex
                      // bg={"#f7f7f7"}
                      marginLeft={"10%"}
                      padding={"10px"}
                      flexDirection="column"
                    >
                      {
                        //@ts-ignore
                        invoiveViewItem?.bucketFund?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              {item.orderType === "BUCKET" ? (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              ) : (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.frequency}
                                  {" banda @ "}
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              )}
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        invoiveViewItem?.events?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.bandaCode}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {" banda for "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        invoiveViewItem?.promoBucket?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        invoiveViewItem?.promoEvent?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"2%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"93%"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="12px" color={textColor}>
                        VAT
                      </Text>

                      <Flex flexDirection={"column"}>
                        <Text fontSize="16px" color={textColor}>0 Tsh</Text>
                      </Flex>
                    </Flex>


                    <Flex
                      w="100%"
                      marginLeft={"2%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"93%"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="12px" color={textColor}>
                        Fees
                      </Text>

                      <Flex flexDirection={"column"}>
                        <Text fontSize="16px" color={textColor}>0 Tsh</Text>
                      </Flex>
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"2%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"93%"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="16px" fontWeight={"bolder"} color={textColor}>
                        Total
                      </Text>

                      <Flex flexDirection={"column"}>
                        <Text fontSize="16px" color={textColor}>
                          {
                            //@ts-ignore
                            invoiveViewItem?.totalAmount
                          }{" "}
                          {
                            //@ts-ignore
                            invoiveViewItem?.currency
                          }
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
              </div>

              <Button
                onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
                fontSize="12px"
                color={textColor}
              >
                Download pdf{" "}
              </Button>
            </Card>
          )}
        </>
      )}
    </div>
  );
}
