// Chakra imports
import { Button, Flex, Link, Text, Image } from "@chakra-ui/react";
import { userInfo } from "os";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import add_ruge from "assets/adds/Ruge Mutahaba Foundation Official Logo Png.jpg";

// Assets

export default function BannerSide() {
  // Chakra Color Mode
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();
  return (
    <Flex
      direction="column"
      // bgImage={banner}
      // backgroundColor={"#461177"}
      backgroundColor={"#38b000"}
      bgSize="cover"
      py={{ base: "10px", md: "36px" }}
      px={{ base: "10px", md: "34px" }}
      borderRadius="10px"
    >
      <Text
        fontSize="sm"
        color="#333"
        // maxW={{
        //   base: "100%",
        //   md: "64%",
        //   lg: "40%",
        //   xl: "56%",
        //   "2xl": "46%",
        //   "3xl": "34%",
        // }}
        fontWeight="500"
        // mb="40px"
        display={"absolute"}
        textAlign={"end"}
        top={"0px"}
        // lineHeight="24px"
      >
        Sponsored
      </Text>
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
          md: "94%",
          lg: "96%",
          xl: "100%",
          "2xl": "100%",
          "3xl": "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        Ruge Mutahaba Foundation
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="24px"
      >
        Valuable sponsors
      </Text>

      {
        <Flex align="center">
          {/* 
<Image 
          width={"50%"}
          height={"50%"}
          src={add_ruge}
          /> */}
          {/* <Link
            onClick={() => {
              //  const history = useHistory()

              history.push("/site/my_adds");
            }}
            mt={{
              base: "0px",
              md: "10px",
              lg: "0px",
              xl: "10px",
              "2xl": "0px",
            }}
          >
            <Button
              bg="white"
              color="black"
              _hover={{ bg: "whiteAlpha.900" }}
              _active={{ bg: "white" }}
              _focus={{ bg: "white" }}
              fontWeight="500"
              fontSize="14px"
              py="20px"
              px="27"
              me="38px"
            >
              Advertise Here
            </Button>
          </Link>

          <Link>
            <Text color="white" fontSize="sm" fontWeight="500">
              Explore Bandajamii
            </Text>
          </Link> */}
        </Flex>
      }
    </Flex>
  );
}
