// Chakra imports
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Banner() {
  // Chakra Color Mode
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();

  return (
    <Flex
      direction="column"
      backgroundColor={"#4361ee"}
      bgSize="cover"
      py={{ base: "10px", md: "36px" }}
      px={{ base: "10px", md: "34px" }}
      borderRadius="10px"
    >
      <Text
        fontSize="sm"
        color="#333"
        fontWeight="500"
        display={"absolute"}
        textAlign={"end"}
        top={"0px"}
      >
        Add
      </Text>

      <Text
        fontSize={{ base: "14px", md: "18px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
          md: "94%",
          lg: "96%",
          xl: "100%",
          "2xl": "100%",
          "3xl": "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        Kamata Fursa Twenzetu
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="24px"
      >
        Hakimiliki yako hailiki
      </Text>
      <Flex align="center">
      </Flex>
    </Flex>
  );
}
