import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  MenuButton,
  MenuList,
  Icon,
  Button,
} from "@chakra-ui/react";
import { Menu } from "@chakra-ui/react";
import * as React from "react";
import generatePDF, { Resolution, usePDF, Margin, Options } from "react-to-pdf";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { MdClose, MdCloseFullscreen, MdDescription } from "react-icons/md";
import { useMyPaymentCartQuery } from "generated/graphql";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "views/site/helpers";
import { PDFViewer } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { removePromo, updatePromo } from "redux.store/promo/actionCreators";
import { removeCart, updateCart } from "redux.store/cart/actionCreators";

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function ColumnTable() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const dispatch: Dispatch<any> = useDispatch();

  const foundCart = (id: string) =>
    [...carts, ...promo].find((item: any) => item.id === id);

  const updateCartState = React.useCallback(
    (cart: ICart) => dispatch(updateCart(cart)),
    [dispatch]
  );

  const updatePromoState = React.useCallback(
    (promo: ICart) => dispatch(updatePromo(promo)),
    [dispatch]
  );

  let menuBg = useColorModeValue("white", "navy.800");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  const { promo }: readonly ICart[] | any = useSelector(
    (state: PromoState) => state.promo,
    shallowEqual
  );
  const [invoiveView, setInvoiveView] = React.useState(false);
  const [invoiveViewItem, setInvoiveViewItem] = React.useState({});
  const [invoiveViewItemId, setInvoiveViewItemId] = React.useState("");

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );
  const deletePromo = React.useCallback(
    (cart: ICart) => dispatch(removePromo(cart)),
    [dispatch, removePromo]
  );
  const [page, setPage] = React.useState(1);
  const [pageTemp, setPageTemp] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [category, setCategory] = React.useState("all");

  // const contentData = useMyPaymentCartQuery({
  //   fetchPolicy: "network-only",
  //   variables: {
  //     ordersInput: {
  //       userId: user.id,
  //       page,
  //       pageSize,
  //       category,
  //     },
  //   },
  // });
  const history = useHistory();

  let defaultData = [...carts, ...promo];

  React.useEffect(() => {
    setData(() => [...carts, ...promo]);
  }, [carts, promo]);

  console.log([...carts, ...promo]);

  const columns = [
    // columnHelper.accessor("id", {
    //   id: "id",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       ID
    //     </Text>
    //   ),
    //   cell: (info: any) => (
    //     <Flex align="center">
    //       <Text color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue().toUpperCase()}
    //       </Text>
    //     </Flex>
    //   ),
    // }),

    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Title
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue().toUpperCase()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("isPromo", {
      id: "isPromo",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TYPE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ? "PROMO" : "FUNDED"}
        </Text>
      ),
    }),
    // columnHelper.accessor("activated", {
    //   id: "activated",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     ></Text>
    //   ),
    //   cell: (info) => (
    //     <div
    //       style={{
    //         zIndex: 999,
    //       }}
    //     >
    //       {info.getValue() && (
    //         <div>
    //           <Text
    //             justifyContent="space-between"
    //             align="center"
    //             fontSize={{ sm: "10px", lg: "12px" }}
    //             color="gray.400"
    //           >
    //             Invoice sent
    //           </Text>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          CLEAR
        </Text>
      ),
      cell: (info) => (
        <div
          style={{
            zIndex: 999,
          }}
        >
          {info.getValue() && (
            <Button
              onClick={() => {
                setInvoiveView(true);
                setInvoiveViewItemId(info.getValue());
              }}
              p="0px"
            >
              <Flex align="center">
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "activeCart",
                      JSON.stringify({ item: {}, isPromo: false })
                    );

                    if (foundCart(info.getValue())?.isPromo) {
                      deletePromo(foundCart(info.getValue()));
                    } else {
                      deleteCart(foundCart(info.getValue()));
                    }

                    // localStorage.setItem(
                    //   "reservationDataContent",
                    //   JSON.stringify({ reservationData: {}, update: false })
                    // );
                  }}
                  variant={"link"}
                  justifyContent="space-between"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="red.400"
                >
                  {foundCart(info.getValue())?.isPromo
                    ? "Delete Promo"
                    : "Delete"}
                </Button>
              </Flex>
            </Button>
          )}
        </div>
      ),
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          ACTION
        </Text>
      ),
      cell: (info) => (
        <div
          style={{
            zIndex: 999,
          }}
        >
          {info.getValue() && (
            <Button
              onClick={() => {
                setInvoiveView(true);
                setInvoiveViewItemId(info.getValue());
              }}
              p="0px"
            >
              <Flex align="center">
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "activeCart",
                      JSON.stringify({
                        item: foundCart(info.getValue()),
                        isPromo: foundCart(info.getValue()).isPromo,
                      })
                    );

                    if (foundCart(info.getValue()).isPromo) {
                      updatePromoState({
                        ...foundCart(info.getValue()),
                        activeCart: true,
                      });
                    } else {
                      updateCartState({
                        ...foundCart(info.getValue()),
                        activeCart: true,
                      });
                    }
                    console.log(foundCart(info.getValue()));

                    history.push("/site/cart_review");
                  }}
                  variant={"link"}
                  justifyContent="space-between"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  To cart
                </Button>
              </Flex>
            </Button>
          )}
        </div>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const targetRef = React.useRef();

  return (
    <Card flexDirection="column" w="100%" px="0px">
      <Flex
        px="25px"
        mb="8px"
        justifyContent="space-between"
        align="center"
      ></Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
