import { Button, Flex, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { findCurrentAccountStateByKey, isInList } from "views/site/helpers";
import { useHistory } from "react-router-dom";

interface BannerProps {
  data: any;
  bandaInfo?: any;
}

export default function Banner({ data, bandaInfo }: BannerProps) {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const history = useHistory();
  console.log(data);
  console.log(bandaInfo);

  const banda = findCurrentAccountStateByKey(
    user.accountStat,
    "BandaApplication"
  );
  console.log("banda");
  console.log("banda");
  //  console.log(banda?.details);
  //  console.log(banda);
  //  console.log("banda");
  //  console.log("banda");

  return (
    <>
      {data?.status === "PENDING" ? (
        <>
          <Flex
            direction="column"
            backgroundColor="#461177"
            bgSize="cover"
            py={{ base: "30px", md: "56px" }}
            px={{ base: "30px", md: "64px" }}
            borderRadius="30px"
            style={{ marginBottom: 20 }}
          >
            <Text
              fontSize={{ base: "24px", md: "34px" }}
              color="white"
              mb="14px"
              maxW={{
                base: "100%",
                md: "64%",
                lg: "46%",
                xl: "70%",
                "2xl": "50%",
                "3xl": "42%",
              }}
              fontWeight="700"
              lineHeight={{ base: "32px", md: "42px" }}
            >
              {data?.key === "SponsorApplication" &&
                "Sponsor Application is on  Review"}

              {data?.key === "BandaApplication" &&
                `Bandajamii ${
                  data?.details ? bandaInfo?.details : " "
                }  Registration is on  Review`}

              {data?.key === "ContentOwnerApplication" &&
                "Content Application is on  Review"}
            </Text>

            <Text
              fontSize="md"
              color="#E3DAFF"
              maxW={{
                base: "100%",
                md: "64%",
                lg: "40%",
                xl: "56%",
                "2xl": "46%",
                "3xl": "34%",
              }}
              fontWeight="500"
              mb="40px"
              lineHeight="28px"
            >
              Kindly wait ... This might take up to 24 hours
            </Text>
            {/* {true && (
        <Flex align="center">
          <Link>
            <Button
              bg={"green.500"}
              color={"white"}
              _hover={{ bg: "#1a759f" }}
              _active={{ bg: "green.500" }}
              // _focus={{ bg: "white" }}
              fontWeight="800"
              fontSize="14px"
              py="20px"
              px="27"
              me="38px"
              onClick={() => {
                history.push("/site/register_sponsor");
              }}
            >
              For Organisation
            </Button>
          </Link>
        </Flex>
      )} */}
          </Flex>
        </>
      ) : (
        <>
          {data?.status === "REJECTED" && (
            <Flex
              direction="column"
              backgroundColor="#461177"
              bgSize="cover"
              py={{ base: "30px", md: "56px" }}
              px={{ base: "30px", md: "64px" }}
              borderRadius="30px"
              style={{ marginBottom: 20 }}
            >
              <Text
                fontSize={{ base: "24px", md: "34px" }}
                color="white"
                mb="14px"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "46%",
                  xl: "70%",
                  "2xl": "50%",
                  "3xl": "42%",
                }}
                fontWeight="700"
                lineHeight={{ base: "32px", md: "42px" }}
              >
                {data?.key === "SponsorApplication" &&
                  "Sponsor Application was REJECTED"}

                {data?.key === "BandaApplication" &&
                  "Bandajamii Registration was REJECTED"}

                {data?.key === "ContentOwnerApplication" &&
                  "Content Application was REJECTED"}
              </Text>

              <Text
                fontSize="md"
                color="#E3DAFF"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "40%",
                  xl: "56%",
                  "2xl": "46%",
                  "3xl": "34%",
                }}
                fontWeight="500"
                mb="40px"
                lineHeight="28px"
              >
                {data?.status}
              </Text>
            </Flex>
          )}
          {data?.status === "SUSPENDED" && (
            <Flex
              direction="column"
              backgroundColor="#461177"
              bgSize="cover"
              py={{ base: "30px", md: "56px" }}
              px={{ base: "30px", md: "64px" }}
              borderRadius="30px"
              style={{ marginBottom: 20 }}
            >
              <Text
                fontSize={{ base: "24px", md: "34px" }}
                color="white"
                mb="14px"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "46%",
                  xl: "70%",
                  "2xl": "50%",
                  "3xl": "42%",
                }}
                fontWeight="700"
                lineHeight={{ base: "32px", md: "42px" }}
              >
                {data?.key === "SponsorApplication" &&
                  "Sponsorship account was suspended"}

                {data?.key === "BandaApplication" &&
                  `Bandajamii ${
                    data?.details ? bandaInfo?.details : " "
                  }  was suspended`}

                {data?.key === "ContentOwnerApplication" &&
                  "Content Account was suspended "}
              </Text>

              <Text
                fontSize="md"
                color="#E3DAFF"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "40%",
                  xl: "56%",
                  "2xl": "46%",
                  "3xl": "34%",
                }}
                fontWeight="500"
                mb="40px"
                lineHeight="28px"
              >
                EXPLORE MORE
              </Text>
            </Flex>
          )}
          {data?.status === "ACTIVE" && (
            <Flex
              direction="column"
              backgroundColor="#461177"
              bgSize="cover"
              py={{ base: "30px", md: "56px" }}
              px={{ base: "30px", md: "64px" }}
              borderRadius="30px"
              style={{ marginBottom: 20 }}
            >
              <Text
                fontSize={{ base: "24px", md: "34px" }}
                color="white"
                mb="14px"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "46%",
                  xl: "70%",
                  "2xl": "50%",
                  "3xl": "42%",
                }}
                fontWeight="700"
                lineHeight={{ base: "32px", md: "42px" }}
              >
                {data?.key === "SponsorApplication" && "Active Sponsor Account"}

                {data?.key === "BandaApplication" &&
                  `Banda ${data?.details ? data?.details : " "} ${
                    data?.message ? "  " + data?.message + "  " : " "
                  }`}

                {data?.key === "ContentOwnerApplication" &&
                  "Active Content Application "}
              </Text>

              <Text
                fontSize="md"
                color="#E3DAFF"
                maxW={{
                  base: "100%",
                  md: "64%",
                  lg: "40%",
                  xl: "56%",
                  "2xl": "46%",
                  "3xl": "34%",
                }}
                fontWeight="500"
                mb="40px"
                lineHeight="28px"
              >
                Go and make change
              </Text>
            </Flex>
          )}
        </>
      )}
    </>
  );
}
