// Chakra imports
import { Box } from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import {
  useColorModeValue,
} from "@chakra-ui/react";
import {
  useAddUserProfileImageMutation,
  useSponsorEventsListQuery,
  useSponsorQuery,
} from "generated/graphql";
import imageHolder from "assets/images/imageHolder.png";

// Assets
import { useSelector } from "react-redux";
import { useUserQuery } from "generated/graphql";
import AWS from "aws-sdk";
import Dashboard from "../exploreBanda/components/BGIS";

export interface FormData {
  title: string;
  description: string;
  link: string;

  verifiedBy: string;
  duration: string;

  price: string;
  currency: string;

  contentType: string;
  languages: string;
  categories: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function SiteReports() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "description is required",
      // }));
    } else if (!textRegex.test(description)) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "Wrong Residency",
      // }));
    } else {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "",
      // }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const validateLink = (link: string) => {
    const textRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/;

    if (!link.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is required",
      }));
    } else if (!textRegex.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    link: "",
    contentType: "",
    languages: "",
    categories: "",
    price: "0",
    currency: "Tsh",
    verifiedBy: "",
    duration: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "title":
        validateTitle(value);
        break;
      case "description":
        validateDescription(value);
        break;
      case "link":
        validateLink(value);
        break;
      default:
        break;
    }
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const userData = useUserQuery({
    fetchPolicy: "network-only",
    variables: {
      getUserProfileInput: {
        id: user.id,
      },
    },
  });

  const sponsorEvents = useSponsorEventsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentInput: {
        contentId: user.sponsorId, // sponsor?.id,
        pageSize: 100,
        page: 0,
      },
    },
  });

  const storedCartItemString = localStorage.getItem("sponsorItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);

  const [imageView, setImageView] = useState("");
  const [first, setFirst] = useState(false);
  const [refreshSelected, setRefreshSelected] = useState(false);

  const contentData = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: user.sponsorId,
      },
    },
  });
  console.log(user);
  console.log(user);
  console.log(user);

  const [isEdit, setIsEdit] = useState(false);
  const [appMode, setAppMode] = useState("reservations");
  const addedItemMiddle = (data: any) => {
    console.log(data);
    setRefreshSelected(!refreshSelected);
  };

  useEffect(() => {
    contentData.refetch();
    //setFirst(false)
  }, [refreshSelected]);

  useEffect(() => {
    if (!first && contentData?.data?.sponsor?.media.length > 0) {
      setImageView(contentData?.data?.sponsor?.media[0]);
      //   setFirst(true)
    }
  }, [contentData, refreshSelected]);
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [coverImageMode, setCoverImageMode] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const avatarUrl = useRef(imageHolder);
  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar = (imgSrc: string) => {
    // Update the avatar URL reference
    avatarUrl.current = imgSrc;

    // Convert the updated avatar URL to a File object
    fetch(imgSrc)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a File object with the blob and assign it to state
        const file = new File([blob], "avatar.jpg", { type: "image/jpeg" });
        setImageFile(file);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const [profileImageService] = useAddUserProfileImageMutation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [uploadingProcess, setUploadingProcess] = useState(false);

  const handleSubmit = async (action: string, type: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      if (action === "add") {
        imageUrll = await uploadFile(imageFile);
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrll = "";
      setImageUrl(null);
      setImageFile(null);
      setImageData(null);
    }

    let imageUrlData = imageUrl;

    if (action === "delete") {
      imageUrlData = "";
      imageUrll = "";
      setImageUrl(null);
      setImageData(null);
      setImageFile(null);
    }

    try {
      const response = await profileImageService({
        variables: {
          imageInput: {
            id: user.sponsorId,
            url: imageUrll,
          },
        },
      });

      if (
        response.data &&
        response.data.addUserProfileImage &&
        !response.data.addUserProfileImage.status
      ) {
        console.log(response.data.addUserProfileImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.addUserProfileImage);
        if ((action = "delete")) {
          // setRemovedSuccessfully(true);
          setImageUrl(null);
          setImageData(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        // refresh(true);
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      return;
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = `https://${key}`;
      setImageUrl(imageUrl);
      return data.Location;
      // alert("File uploaded successfully.");
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Dashboard address={"Yombo"} />
    </Box>
  );
}
