// Chakra imports
import {
  Text,
  Box,
  Grid,
  useColorModeValue,
  Button,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "./gallery.css";
import "./gall.css";
import { useEffect, useRef, useState } from "react";

import Card from "components/card/Card";
import Banner from "./components/Banner";
import {
  useGetBandaQuery,
  useMyBandaQuery,
  useSponsorGalleryQuery,
} from "generated/graphql";
import { Gallery } from "generated/graphql";
import { useSelector } from "react-redux";
import UploadPost from "./UploadContent";
import { formatDateTimeShort } from "views/site/helpers";

export default function GalleryDiv() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [imageView, setImageView] = useState("");

  const storedCartItemString = localStorage.getItem("bandaItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);

  const contentData = useGetBandaQuery({
    fetchPolicy: "network-only",
    variables: {
      findBandaumizaInput: {
        bandaId: storedCartItem.id,
      },
    },
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const imagesGalleryData = useSponsorGalleryQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        userId: user.sponsorId,
        category: "",
        page,
        pageSize,
      },
    },
  });

  const [first, setFirst] = useState(false);

  useEffect(() => {
    if (!first && contentData?.data?.getBanda?.gallery.length > 0) {
      setImageView(contentData?.data?.getBanda?.gallery[0]);
      setFirst(true);
    }
  }, [contentData?.data, contentData.loading]);

  const [activeTab, setActiveTab] = useState("tabs-with-icon-1");

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };


  

  return (
    <div>
      <div className="tabs">
        <div className="flex">
          <ul className="flex min-w-max overflow-x-auto transition-all duration-300 overflow-hidden">
            {["tabs-with-icon-1", "tabs-with-icon-2"].map((tab, index) => (
              <li key={index}>
                <a
                  href="javascript:void(0)"
                  className={`group flex items-center gap-2 py-3 px-6 text-gray-500 border-b border-transparent hover:text-gray-800 font-medium ${
                    activeTab === tab
                      ? "tab-active:bg-indigo-50 tab-active:text-indigo-600 tab-active:rounded-xl"
                      : ""
                  } tablink whitespace-nowrap`}
                  onClick={() => handleTabClick(tab)}
                  role="tab"
                >
                  <svg
                    className={`stroke-gray-500 ${
                      activeTab === tab
                        ? "tab-active:stroke-indigo-600"
                        : "group-hover:stroke-gray-900"
                    } transition-none`}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_11860_5401)">
                      <path
                        d="M1.66699 4.99984C1.66699 3.15889 3.15938 1.6665 5.00033 1.6665C6.84127 1.6665 8.33366 3.15889 8.33366 4.99984C8.33366 6.84079 6.84127 8.33317 5.00033 8.33317C3.15938 8.33317 1.66699 6.84079 1.66699 4.99984Z"
                        stroke="currentColor"
                        strokeWidth="1.6"
                      />
                      <path
                        d="M1.66699 14.9998C1.66699 13.4285 1.66699 12.6428 2.15515 12.1547C2.6433 11.6665 3.42898 11.6665 5.00033 11.6665C6.57167 11.6665 7.35735 11.6665 7.8455 12.1547C8.33366 12.6428 8.33366 13.4285 8.33366 14.9998C8.33366 16.5712 8.33366 17.3569 7.8455 17.845C7.35735 18.3332 6.57167 18.3332 5.00033 18.3332C3.42898 18.3332 2.6433 18.3332 2.15515 17.845C1.66699 17.3569 1.66699 16.5712 1.66699 14.9998Z"
                        stroke="currentColor"
                        strokeWidth="1.6"
                      />
                      <path
                        d="M11.667 4.99984C11.667 3.42849 11.667 2.64281 12.1551 2.15466C12.6433 1.6665 13.429 1.6665 15.0003 1.6665C16.5717 1.6665 17.3573 1.6665 17.8455 2.15466C18.3337 2.64281 18.3337 3.42849 18.3337 4.99984C18.3337 6.57119 18.3337 7.35686 17.8455 7.84502C17.3573 8.33317 16.5717 8.33317 15.0003 8.33317C13.429 8.33317 12.6433 8.33317 12.1551 7.84502C11.667 7.35686 11.667 6.57119 11.667 4.99984Z"
                        stroke="currentColor"
                        strokeWidth="1.6"
                      />
                      <path
                        d="M11.667 14.9998C11.667 13.1589 13.1594 11.6665 15.0003 11.6665C16.8413 11.6665 18.3337 13.1589 18.3337 14.9998C18.3337 16.8408 16.8413 18.3332 15.0003 18.3332C13.1594 18.3332 11.667 16.8408 11.667 14.9998Z"
                        stroke="currentColor"
                        strokeWidth="1.6"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11860_5401">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {index === 0 && "Gallery"}
                  {index === 1 && "Upload Post"}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-3">
          <div
            id="tabs-with-icon-1"
            role="tabpanel"
            aria-labelledby="tabs-with-icon-item-1"
            className={`tabcontent ${
              activeTab === "tabs-with-icon-1" ? "" : "hidden"
            }`}
          >
            <Text
              // color="white"
              fontSize="xl"
              mb="4px"
              fontWeight={"bold"}
              textAlign="start"
              ml={"1.7%"}
            >
              Gallery
            </Text>

            {!imagesGalleryData.loading ? <div className="grid grid-cols-2 gap-6 py-2">
            {imagesGalleryData?.data?.sponsorGallery?.filter((item: Gallery)=> item.coverImage !== "")?.map(
                (galleryItem: Gallery) => {
                  // console.log(galleryItem);
                  // console.log(galleryItem);
                  // console.log(galleryItem);
                  // console.log(galleryItem);
                  // console.log(galleryItem);
                  
                  return (
                    <div>
                      {/* <Image
                        src={galleryItem.coverImage}
                        boxSize="210px"
                        objectFit="cover"
                        borderRadius="20px"
                      /> */}
                            <img 
                            className="h-auto max-w-full rounded-lg" 
                            src={galleryItem.coverImage}
                            // src="https://pagedone.io/asset/uploads/1688025668.png" 
                            alt="Gallery image" />

<div className="p-1 sm:p-2">
<div className="sm:flex  flex-col sm:flex-row sm:justify-between">
<p className="m-1 text-xl font-bold w-5/6 sm:w-full  ">{galleryItem.title}</p>
                        <p className="m-1 text-sm font-bold w-full sm:w-1/6 py-0 sm:py-4 ">{formatDateTimeShort(galleryItem.createdAt)}</p>
                        </div>

                      <p className="m-1 text-lg ">{galleryItem.description}</p>
                      </div>
                      </div>
                    );
                }
              )}

      {/* <div>
      <img className="h-auto max-w-full rounded-lg" src="https://pagedone.io/asset/uploads/1688025668.png" alt="Gallery image" />
      </div>
      <div>
      <img className="h-auto max-w-full rounded-lg" src="https://pagedone.io/asset/uploads/1688029344.png" alt="Gallery image" />
      </div>
      <div>
      <img className="h-auto max-w-full rounded-lg" src="https://pagedone.io/asset/uploads/1688029370.png" alt="Gallery image" />
      </div>
      <div>
      <img className="h-auto max-w-full rounded-lg" src="https://pagedone.io/asset/uploads/1688029384.png" alt="Gallery image" />
      </div> */}


      </div>:<div>
                        <div className="flex justify-center mt-20 ">
                          <div
                            className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>}

            
             

           
          </div>
          <div
            id="tabs-with-icon-2"
            className={`tabcontent ${
              activeTab === "tabs-with-icon-2" ? "" : "hidden"
            }`}
            role="tabpanel"
            aria-labelledby="tabs-with-icon-item-2"
          >
            <Text
              // color="white"
              fontSize="xl"
              mb="4px"
              fontWeight={"bold"}
              textAlign="start"
              ml={"1.7%"}
            >
              Post your events
            </Text>

            <UploadPost />
          </div>
        </div>
      </div>
    </div>
  );
}
