import React from 'react';
import { Box, Button,SimpleGrid, UnorderedList, ListItem } from '@chakra-ui/react';

interface ListProps {
  items: string[];
  onRemove: (item: string) => void;
}

const List: React.FC<ListProps> = ({ items, onRemove }) => (
  <SimpleGrid  minChildWidth={"120px"}  >
    {items.map((item, index) => (
      <div   style={{
        backgroundColor:"#f1f1f1",
        // height:100,
        padding:5,
        // width:100,
        // position:"static",
        margin:5,
        borderRadius:10,
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"row",

      }}
      >
        <Box>{item}</Box>
        <Button colorScheme="red" size="sm" onClick={() => onRemove(item)} style={{
           backgroundColor:"gray",
        // height:100,
        padding:5,
        // width:100,
        // position:"static",
      //  borderRadius:33,
      
          }}>X</Button>
      </div>
    ))}
  </SimpleGrid>
);

export default List;
