// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Project1 from "assets/images/imageHolder.png";

import {
  useCreateEventMutation,
  useRemoveEventMutation,
} from "generated/graphql";
import { useState } from "react";
// Assets
import { MdAddBox, MdEdit, MdVerifiedUser } from "react-icons/md";
import { useSelector } from "react-redux";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTimeShort } from "views/site/helpers";
import { IoCalendarOutline } from "react-icons/io5";
interface FormErrors {
  [key: string]: string;
}

export default function ContentItem(props: {
  category: string;
  title: string;
  home: string;
  id: string;
  dateStart: string;
  dateEnd: string;
  sponsorName: string;
  sponsorId: string;
  away: string;
  item: any;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const {
    id,
    title,
    category,
    dateEnd,

    sponsorName,
    sponsorId,
    dateStart,
    home,
    away,
    ranking,
    item,
    link,
    image,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [removeEvent] = useRemoveEventMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const history = useHistory();

  console.log(item);
  // console.log(sponsorName);

  return (
    <div className="bg-gray-50 rounded-2xl mt-5">
      <li className="flex justify-between gap-x-6 py-5 px-2">
        <div className="flex min-w-0 gap-x-4">
          {/* <img alt="" src={item?.coverImage ? item?.coverImage : imageHolder} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
          <div className="min-w-0 flex-auto">
            <p
             onClick={() => {
              
              history.push(`/site/content?id=${item?.id}&zIx=890`);
                          }}
              className="text-sm ml-2 font-semibold leading-6 text-gray-900"
             
            >
              {title}
            </p>
            <p className="mt-1 ml-2 text-start truncate text-xs leading-5 text-gray-500">
              {formatDateTimeShort(item?.createdAt)}
            </p>
          </div>
        </div>
        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
          {/* <p className="text-sm leading-6 text-gray-900">{"role"}</p> */}
        </div>
      </li>
      <Card
        bg={"#f2ebfb"}
        width={"95%"}
        marginLeft={"2.5%"}
        alignSelf={"center"}
        marginBottom={"5px"}
        p="14px"
        borderRadius={"15px"}
        justifyContent={"space-between"}
      >
        <Flex align="center" direction={"row"}>
          <Flex align="center" direction={"row"}>
            <Flex align="center" direction={"column"} className="w-max h-min">
              {/* <img alt="" src={item?.coverImage ? item?.coverImage : imageHolder} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
              <img
                alt=""
                src={image}
                className="h-[50px] w-[50px] mr-2 flex-none rounded-xl bg-gray-50"
              />
              {/* <img alt="" src={image ? image : imageHolder} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
            </Flex>

            <Flex align="center" direction={"column"}>
              {/* <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="#3c0663"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 /> */}
              <Box
                width="3px"
                height="50px"
                backgroundColor="#3c0663"
                //  position={"absolute"}
                //  alignSelf={"end"}
                //  fontSize="50px"
                //  borderRadius="70px"
                marginRight={"2%"}
                //  mb={"23%"}
              />
              {/* <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="#3c0663"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 /> */}
            </Flex>
          </Flex>

          <Box paddingRight={"2px"} display={"flex"} flexDirection={"column"}>
            <Text
                // fontWeight="500"
                // width={"240px"}
                // height={"160px"}
                // textOverflow={"truncate"}
                // whiteSpace={"nowrap"}
                // overflow={"hidden"}
                color={"#333"}
                textAlign={"start"}
                fontSize="12px"
                className="ml-2 line-clamp-3"
              
                // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

                // me="4px"
              >
                {item?.description}
              </Text>
            {/* <Text
              onClick={() => {
              
                history.push(`/site/content?id=${item?.id}&zIx=890`);
                            }}
              // variant={item?.eventType === "PRIVATE" ? "":"link"}
              // color="b"
              alignSelf={"start"}
              marginLeft={"20px"}
              // fontWeight="500"
              // fontSize="larger"
              // mb="4px"

              fontWeight="500"
              color={item?.eventType === "PRIVATE" ? "gray" : "green.300"}
              fontSize="12px"
              // me="4px"
            >
              {item?.description}
            </Text> */}

            <Flex
              align="center"
              direction={"row"}
              marginTop={"10px"}
              marginLeft={"9%"}
            >
              <Box
                mt={{ base: "0px", md: "0" }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                alignContent={"start"}
              >
                <Button
                  onClick={() => {
                    // localStorage.setItem("bandaItem", JSON.stringify(item));
                    // history.push("/site/e_gallery");
                  }}
                  color={"#5cba47"}
                  fontSize="14px"
                  variant={"link"}
                  alignSelf={"start"}
                  // mb="4px"
                  marginLeft={"0%"}
                >
                  123{" "}
                  <Icon
                    as={MdVerifiedUser}
                    width="15px"
                    height="15px"
                    color="#5cba47"
                    //  position={"absolute"}
                    alignSelf={"end"}
                    fontSize="12px"
                    fontWeight="500"
                    borderRadius="70px"
                    //  marginLeft={"50%"}
                    mb={"33%"}
                  />
                </Button>

                {/* <Button
             onClick={() => {
if(!sponsorId){return}
              // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
            
              // history.push("/site/sponsor_details", {
              //   itemData: "qqsad",
              //   update: true,
              // });
            
            }}
              fontWeight="500"
              alignSelf={"start"}

              color={"green.300"}
              fontSize="12px"
              me="4px"
              variant={"link"}
              // mb="4px"
              marginLeft={"-1%"}
            > Attendees</Button> */}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Card>
    </div>
  );
}
