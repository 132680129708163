import React from "react";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/site";
import RTLLayout from "./layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import Landing from "layouts/Landing";
import { useSelector } from "react-redux"; // Import useSelector hook
import "./assets/fonts/Lexend/Lexend-VariableFont_wght.ttf";

//for testing only no longer usable
const data = {
  logindata: false,
  role: "", 
};

const AppRoot: React.FC = () => {
  // const { user }: IUser | any = useSelector((state: UserState) => state.user);

  return (
    <HashRouter>
      <Switch>
  
        <Route
          path="/landing"
          render={(props) => <Landing {...props} data={data} />}
        />
        <Route
          path="/auth"
          render={(props) => <AuthLayout {...props} data={data} />}
        />
        {/* <Route path="/banda" render={(props) => <BandaLayout {...props} data={data} />} /> */}
        <Route
          path="/site"
          render={(props) => <AdminLayout {...props} data={data} />}
        />
        {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} data={data} />} /> */}
        <Route path="/*" render={(props) => <Landing {...props} data={data} />} />
        {/* {user.loginStatus ? (
          <Redirect to="/landing" />
        ) : (
          <Redirect to="/auth" />
        )} */}
      </Switch>
    </HashRouter>
  );
};

export default AppRoot;
