import { useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  WrapItem,
  Wrap,
  Card,
  Select,
  Input,
  Icon,
} from "@chakra-ui/react";
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
import imageHolder from "assets/images/imageHolder.png";

import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { useContentOwnersListQuery, useSponsorsQuery } from "generated/graphql";
import { ContentOwnerItem } from "components_app/ContentOwnerItem/ContentOwnerItem";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import Banner from "./components/Banner";
import BannerSide from "./components/BannerSide";
import { useHistory } from "react-router-dom";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];

export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function ExploreContentOwners() {
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [category, setCategory] = useState("all");
  const handleChange = (e: any) => {
    setCategory(e.target.value);
  };

  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { data } = useContentOwnersListQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorsInput: {
        page,
        pageSize,
        category,
        searchQuery: searchQuery,
      },
    },
  });
  const [filtering, setFiltering] = useState(false);

  console.log(data);

  const [show, setShow] = useState(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };
  const history = useHistory();
  console.log(data);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid
        mb={10}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner />
        <BannerSide />
      </Grid>

      {true && (
        <Grid
          mb="20px"
          gridTemplateColumns={{ xl: "repeat(1, 1fr)",
             //"2xl": "1fr 0.46fr" 
            }}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            <Flex direction="column">
              <Card className="p-8 m-0  bg-white rounded-3xl">
                <div className="flex flex-col sm:flex-row justify-between">
                  <Select
                    className="w-1/2 h-[40px] m-3 pr-3"
                    name="category"
                    // variant={"auth"}
                    value={category} // Set value to selected category state
                    onChange={handleChange} // Call handleChange function when selection changes
                  >
                    {[{ mode: "all", label: "All" }, ...filtersLabels].map(
                      (cat: any) => (
                        <option key={cat.mode} value={cat.mode}>
                          {cat.label}
                        </option>
                      )
                    )}
                  </Select>

                  <div className="w-[20px] h-[40px] " />
                  <Input
                    className="w-1/2 h-[40px] m-3 ml-3"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    name="Search"
                    // variant={"auth"}
                    placeholder={"Search here by name"}
                    style={{
                      // borderColor:"#eef0f2",
                      // marginTop: "20px",

                      border: "1px solid #eef0f2",
                      // position:"relative",
                      // color: "#333",
                      // height: "60px",
                    }}
                  />
                </div>
                <ul role="list" className="divide-y divide-gray-100">
                  {data?.contentOwnersList?.map((person) => (
                    <li
                      key={person.id}
                      className="flex justify-between gap-x-6 py-5"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <img
                          alt=""
                          src={
                            person.coverImage ? person.coverImage : imageHolder
                          }
                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                        />
                        <div className="min-w-0 flex-auto">
                          <p
                            onClick={() => {
                              localStorage.setItem(
                                "contentOwner",
                                JSON.stringify({ id: person?.id })
                              );
                              history.push(
                                `/site/cnt_owner_page?id=${person?.id}&zIx=890`
                              );
                            }}
                            className="text-sm text-start font-semibold leading-6 text-gray-900"
                          >
                            {!person.isCompany
                              ? person.contentOwnershipName
                                ? person.contentOwnershipName
                                : person.username
                              : person.companyName}
                          </p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                            {person.address}
                          </p>
                        </div>
                      </div>
                      <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm leading-6 text-gray-900">
                          content owner
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          Since{" "}
                          <time
                          //dateTime={}
                          >
                            3 month ago
                          </time>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card>

              <SimpleGrid mt={"20px"} columns={{ base: 1, md: 3 }} gap="20px">
                {false &&
                  data?.contentOwnersList?.map((c_owner: any) => {
                    const existingCart = carts.find(
                      (item: ICart) => item.id === c_owner.id
                    );
                    return (
                      <ContentOwnerItem
                        key={c_owner.id}
                        c_owner={c_owner}
                        removeCart={removeCart}
                        selected={existingCart}
                        handleShow={handleShow}
                      />
                    );
                  })}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Grid>
      )}
    </Box>
  );
}
