import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import Banner from "views/site/registerNow/components/Banner";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  useRegistrationSponsorMutation,
  useSponsorQuery,
} from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import { Dispatch } from "redux";
import {
  loginUser,
  registerPartnership,
} from "redux.store/user/actionCreators";
import {
  findCurrentAccountStateByKey,
  findCurrentBandaStatus,
  isInList,
  pushOperation,
} from "../helpers";
import BannerContent from "./components/BannerContent";
import BannerBanda from "./components/BannerBanda";
import BannerInfo from "./components/BannerInfo";
import BannerLogin from "./components/BannerLogin";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];
export interface FormData {
  username?: string;
  companyName?: string;
  email: string;
  phoneNumber: string;
  address: string;
  physicalResidency: string;
  nationalID?: string;
  industry?: string;
  organisationDoc?: string;
}

export default function RegisterNow() {
  const [isCompany, setIsCompany] = useState(false);

  const isDataCompany = (e: any) => {
    console.log(e);
    console.log(e);
    console.log(e);
    setIsCompany(e);
  };

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  //Partnership cancel

  console.log("user");
  console.log(user);
  console.log("user");

  const [latestSponsorApplication, setLatestSponsorApplication] = useState({});

  const [latestBandaApplication, setLatestBandaApplication] = useState({});

  const [latestContentApplication, setLatestContentApplication] = useState({});

  const [bandaAccountInfo, setBandaAccountInfo] = useState({});
  // console.log("user  =>  " + user.accountStat);
  console.log("content  =>  " + latestContentApplication);
  console.log("bandaData  =>  " + bandaAccountInfo);

  console.log("banda  =>  " + latestBandaApplication);
  console.log("sponsor  =>  " + latestSponsorApplication);

  useEffect(() => {
    if (user && user.accountStat) {
      setLatestSponsorApplication(
        findCurrentAccountStateByKey(
          JSON.parse(user.accountStat),
          "SponsorApplication"
        )
      );

      setLatestBandaApplication(
        findCurrentAccountStateByKey(
          JSON.parse(user.accountStat),
          "BandaApplication"
        )
      );

      setLatestContentApplication(
        findCurrentAccountStateByKey(
          JSON.parse(user.accountStat),
          "ContentOwnerApplication"
        )
      );

      setBandaAccountInfo(
        findCurrentBandaStatus(JSON.parse(user.accountStat), "BandaApplication")
      );
    }
  }, [user]);

  console.log(user);
  if (!user.loginStatus) {
    return (
      <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
        <BannerLogin />
      </Box>
    );
  }

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        gridTemplateColumns={{
          xl: "repeat(1, 1fr)",
          // "2xl": "1fr 0.46fr"
        }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
        >
          {!(user.bandaStatus === "banda") ? (
            <BannerBanda isDataCompany={isDataCompany} />
          ) : (
            <BannerInfo
              data={latestBandaApplication}
              bandaInfo={bandaAccountInfo}
            />
          )}

          {!user.contentStatus ? (
            <BannerContent isDataCompany={isDataCompany} />
          ) : (
            <BannerInfo data={latestContentApplication} />
          )}

          {!(user.sponsorStatus === "sponsor") ? (
            <Banner isDataCompany={isDataCompany} />
          ) : (
            <BannerInfo data={latestSponsorApplication} />
          )}
        </Flex>
      </Grid>
    </Box>
  );
}
