export default {
  title: "Content",
  items: [
    {
      name: "Afya ya Akili",
      description: "Afya ya akili ni muhimu katika kila hatua ya maisha, kutoka utoto na ujana hadi utu uzima.",
      avatar:
        "https://pbs.twimg.com/profile_images/1470742164024008706/k-eXHtu0_normal.jpg",
      children: (
        <>
          “Afya ya akili ni muhimu katika kila hatua ya maisha, kutoka utoto na ujana hadi utu uzima.”
        </>
      ),
    },
    {
      name: "Elimu ya Uzazi",
      description: "Kutoa elimu, ujuzi na stadi kwa watoa huduma za afya katika ngazi ya jamii ili waweze kuboresha huduma za uzazi.",
      avatar: "/static/images/avatar2.jpg",
      children: (
        <>
          “Kutoa elimu, ujuzi na stadi kwa watoa huduma za afya katika ngazi ya
          jamii ili waweze kuboresha huduma za uzazi.”
        </>
      ),
    },
    {
      name: "Man U vs  Liverpool",
      description: "England premier League",
      avatar: "/static/images/avatar3.jpg",
      children: <>“England premier League.”</>,
    },
  ],
};
