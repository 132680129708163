// Chakra imports
import { Flex, Text, Link, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { BandajamiiLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import { useHistory, useLocation } from "react-router-dom";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("#5a189a", "white");
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("#5a189a", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
      const history = useHistory();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  return (
    <Flex 
    //alignItems="center" 
    flexDirection="column">
      {/* <BandajamiiLogo h="26px" w="175px" my="32px" color={logoColor} /> */}
      <Link
        fontWeight="500"
        onClick={()=>{
          //const history = useHistory();

          history.push("/landing/landing")
        }}
        fontSize="sm"
      >
        <Text
          me="auto"
          color={textColor}
          fontWeight={"bold"}
          style={{
            fontSize: 18,
            fontWeight: 800,
            marginLeft:30,
            paddingBottom: 20,
            // color:"#240046"
          }}
        >
          BandaJamii
        </Text>
      </Link>
      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
}

export default SidebarBrand;
