import React from "react";
import "./Button.css";
import {  Text } from "@chakra-ui/react";

interface Theme {
  body?: string;
  text?: string;
}

interface Styles {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  [key: string]: any;
}

interface ButtonProps {
  text: string;
  className?: string;
  href?: string;
  theme?: Theme;
  styles?: Styles;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const onMouseEnter = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, color: string, bgColor: string) => {
  const el = event.currentTarget;
  el.style.color = "white";
  el.style.backgroundColor = "#7b2cbf";
};

const onMouseOut = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, color: string, bgColor: string) => {
  const el = event.currentTarget;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

const ButtonCustom: React.FC<ButtonProps> = ({
  text,
  className = "",
  href,
  theme = {}, // Default theme values
  styles = {},
  onClick
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <div className={className}>
      <a
        className="main-button"
        href={href}
        style={{
          color: "white", // Use styles.color if provided, otherwise fallback to theme.body
          backgroundColor: styles.backgroundColor || theme.text, // Use styles.backgroundColor if provided, otherwise fallback to theme.text
          border: `solid 1px ${styles.borderColor || theme.text}`, // Use styles.borderColor if provided, otherwise fallback to theme.text
          ...styles // Merge other styles provided in styles prop
        }}
        onMouseEnter={(event) =>
          onMouseEnter(event, styles.color || theme.text || "", styles.backgroundColor || theme.body || "")
        }
        onMouseOut={(event) =>
          onMouseOut(event, styles.color || theme.body || "", styles.backgroundColor || theme.text || "")
        }
        onClick={handleClick}
      >
        {text}
      </a>
    </div>
  );
}

export default ButtonCustom;
