// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/images/imageHolder.png";
import Project2 from "assets/images/bajaji.jpeg";
import Project3 from "assets/images/pepsi.jpg";
// Custom components
import Card from "components/card/Card";
import Project from "views/site/profileContent/components/Project";
import { filtersLabels } from "views/site/marketplace";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMyContentsListQuery } from "generated/graphql";

export default function AllAdds(props: { [x: string]: any }) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [category, setCategory] = useState("all");

  const contentData = useMyContentsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getMyContentInput: {
        userId: user?.id,
        contentType: "NORMAL",
        page,
        pageSize,
        category,
      },
    },
  });

  return (
    <Card
      mb={{ base: "0px", "2xl": "20px" }}
      {...rest}
      style={{
        maxHeight: 800,
      }}
    >
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        My Content promo
      </Text>
      {/* <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Filter contents here !{" "}
      </Text> */}
      {/* <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Filtered Button Active Reserved Content
      </Text> */}

      {contentData?.data?.myContentsList?.map((item: any) => {
        return (
          <>
            <Project
              boxShadow={cardShadow}
              mb="20px"
              item={JSON.stringify(item)}
              image={Project1}
              ranking={item.createdAt}
              link="#"
              title={item.title}
            />
          </>
        );
      })}
    </Card>
  );
}
