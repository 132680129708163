// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/images/imageHolder.png";
import Project2 from "assets/images/bajaji.jpeg";
import Project3 from "assets/images/pepsi.jpg";
// Custom components
import Card from "components/card/Card";
import Project from "views/site/profileBanda/components/Project";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  useCheckSportsListQuery,
  useContentListQuery,
  useMyBandaEventsListQuery,
  useMyBandaEventsPrivateQuery,
} from "generated/graphql";

type Props = {
  refresh: boolean;
  removedItemInList: (data: boolean) => void;
};

export const Projects: React.FC<Props & { [x: string]: any }> = (props) => {
  const { refresh, removedItemInList, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dateStart = new Date().toISOString();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  // console.log(user);
  // console.log(user);

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [category, setCategory] = useState("all");

  const { loading, error, data, refetch } = useMyBandaEventsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getMyBandaEventsInput: {
        contentId: user.id, //banda id
      },
    },
  });

  const privateEvents = useMyBandaEventsPrivateQuery({
    fetchPolicy: "network-only",
    variables: {
      getMyBandaInput: {
        bandaId: user.id, //bandai d
      },
    },
  });

  const [updateNow, setUpdateNow] = useState(false);

  const addedItemMiddle = (data: any) => {
    // console.log(data);
    removedItemInList(data);
    setUpdateNow(!updateNow);
  };

  useEffect(() => {
    refetch();
    privateEvents.refetch();
  }, [refresh, updateNow]);

  // console.log(data?.myBandaEventsList);
  // console.log(privateEvents?.data?.myBandaEventsPrivate);

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        My events
      </Text>

      {/* <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        You can cancel an event !
      </Text> */}

      {privateEvents?.data?.myBandaEventsPrivate?.map((item: any) => {
        return (
          <Project
            boxShadow={cardShadow}
            mb="20px"
            image={Project1}
            ranking="1"
            dateStart={dateStart}
            removedItem={addedItemMiddle}
            id={item.id}
            link="#"
            title={item.title}
            category={item.description}
            home={"Barcelona"}
            away={"Napoli"}
          />
        );
      })}

      {data?.myBandaEventsList?.map((item: any) => {
        return (
          <Project
            boxShadow={cardShadow}
            mb="20px"
            image={Project1}
            ranking="1"
            dateStart={dateStart}
            removedItem={addedItemMiddle}
            id={item.id}
            link="#"
            title={item.title}
            category={item.description}
            home={"Barcelona"}
            away={"Napoli"}
          />
        );
      })}
    </Card>
  );
};
