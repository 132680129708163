// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Projects from "views/site/BandaReservations/components/Projects";

// Assets
import avatar from "assets/images/imageHolder.png";
import AllAdds from "./components/AllAdds";

//reservations
export default function BandaEvents() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Projects
          banner={"banner"}
          avatar={avatar}
          name="Private Company12"
          job="Transport Company"
          posts="17"
          followers="9.7k"
          following="274"
        />
        <AllAdds
          banner={"banner"}
          avatar={avatar}
          name="Private Company12"
          job="Transport Company"
          posts="17"
          followers="9"
          following="274"
        />
      </Grid>
    </Box>
  );
}
