import React, { useEffect, useState } from "react";

import Banner from "views/site/registerBanda/components/Banner";
import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import {
  useAutoLoginMobileMutation,
  useRegistrationBandaMutation,
  useRegistrationMutation,
} from "generated/graphql";
import { jwtDecode } from "jwt-decode";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import { Dispatch } from "redux";
import {
  loginUser,
  logoutUser,
  registerPartnership,
} from "redux.store/user/actionCreators";
import { findCurrentAccountStateByKey } from "../helpers";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import List from "./components/List";
import regionData from "utils/geo_data.json";
import { clearCart } from "redux.store/cart/actionCreators";
import { clearCartPromo } from "redux.store/promo/actionCreators";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];

interface FormData {
  email: string;
  password: string;
  username: string;
  repeatPassword: string;
}

export interface BandaFormData {
  bandaName?: string;
  email: string;
  phoneNumber: string;
  ageRestriction: string;
  address: string;
  physicalResidency: string;
  nationalID?: string;
  ownerName?: string;
  lastName?: string;
  middleName?: string;
  bandaPassword?: string;
  repeatBandaPassword?: string;
  numberOfTv?: string;
  decoders?: [string];
  capacity?: string;

  region?: string;
  district?: string;
  ward?: string;
  kitongoji?: string;
  longitude?: number;
  latitude?: number;

  tvSize?: [string];
  bandaSize?: string;
  mtaaVillage: string;
  districtPostcode: string;
  wardPostcode: string;
  typeOfSeats?: string;
  openingTime?: string;
  closingTime?: string;
  //opening and closing customizable
  drinks?: boolean;
  umeme?: boolean;
  
  food?: boolean;
  airConditioner?: boolean;
  projector?: boolean;
  feni?: boolean;
  generator?: boolean;
  wifi?: boolean;
  vikalio?: boolean;
  nafasiWazi?:boolean;
  laptop?: boolean;
}

interface FormErrors {
  [key: string]: string;
}

export default function RegisterBandaLogedIn() {
  const [formData, setFormData] = useState<BandaFormData>({
    bandaName: "",
    email: "",
    phoneNumber: "",
    address: "",
    physicalResidency: "",
    nationalID: "",
    ownerName: "",
    ageRestriction: "",
    umeme: false,
    lastName: "",
    middleName: "",

    longitude: 0,
  latitude: 0,

    numberOfTv: "3",
    repeatBandaPassword: "",
    bandaPassword: "",
    decoders: [""],
    capacity: "100",
    drinks: false,
    food: false,
    airConditioner: false,
    projector: false,
    nafasiWazi:false,
    feni: false,
    mtaaVillage: "",
    districtPostcode: "",
    wardPostcode: "",
    kitongoji: "",

    generator: false,
    wifi: false,
    vikalio: false,
    laptop: false,
    tvSize: [""],
    bandaSize: "",
    typeOfSeats: "",
    region: "",
    district: "",
    ward: "",
    openingTime: "",
    closingTime: "",
  });
  const [formDataReg, setFormDataReg] = useState<FormData>({
    email: "",
    password: "",
    repeatPassword: "",
    username: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [errorsReg, setErrorsReg] = useState<FormErrors>({});

  // Function to validate a phoneNumber number
  const validatePhone = (phoneNumber: string) => {
    const phoneRegex = /^(\+\d{1,3}\d{9,12}|07\d{8})$/;
    if (!phoneNumber.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Phone number is required",
        formStatus: "error",
      }));
    } else if (!phoneRegex.test(phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phoneNumber number format",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
        // formStatus: "",
      }));
    }
  };

  const validateNIDA = (text: string) => {
    const textRegex = /^\d{20}$/;
    if (!text.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text is required",
        formStatus: "error",
      }));
    } else if (!textRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text must contain exactly 20 numbers",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "",
        // formStatus: "",
      }));
    }
  };

  const validatePhysicalResidency = (physicalResidency: string) => {
    const textRegex = /^(.+)$/;
    if (!physicalResidency.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "physical Residency is required",
        formStatus: "error",
      }));
    } else if (!textRegex.test(physicalResidency)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "Wrong Residency",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "",
        // formStatus: "",
      }));
    }
  };

  const validateAddress = (address: string) => {
    const textRegex = /^(.+)$/;
    if (!address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is required",
        formStatus: "error",
      }));
    } else if (!textRegex.test(address)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is invalid",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
        // formStatus: "",
      }));
    }
  };

  const validateName = (bandaName: string) => {
    const textRegex = /^.+$/;
    if (!bandaName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is required",
        formStatus: "error",
      }));
    } else if (!textRegex.test(bandaName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is invalid",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "",
        // formStatus: "",
      }));
    }
  };
  const validateOwnerName = (ownerName: string, name: string) => {
    // const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;
    const textRegex = /^[a-zA-Z]+$/;

    if (name === "ownerName" && !textRegex.test(ownerName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "First name is invalid",
        formStatus: "error",
      }));
    } else if (name === "middleName" && !textRegex.test(ownerName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        middleName: "MiddleName is invalid",
        formStatus: "error",
      }));
    } else if (name === "lastName" && !textRegex.test(ownerName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "Last Name is invalid",
        formStatus: "error",
      }));
    } else {
      if (name === "ownerName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ownerName: "",
          // formStatus: "",
        }));
      } else if (name === "middleName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          middleName: "",
          // formStatus: "",
        }));
      } else if (name === "lastName") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "",
          // formStatus: "",
        }));
      }
    }
  };

  const comparePasswords = (
    bandaPassword: string,
    repeatBandaPassword: string
  ): boolean => {
    if (bandaPassword !== repeatBandaPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatBandaPassword: "Passwords do not match",
        formStatus: "error",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatBandaPassword: "",
        // formStatus: "",
      }));
      return true;
    }
  };

  const validatePassword = (bandaPassword: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[^\s]{8,}$/;

    if (!bandaPassword.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaPassword: "Password is required",
        formStatus: "error",
      }));
    } else if (bandaPassword.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaPassword: "Password must be at least 8 characters long",
        formStatus: "error",
      }));
    } else if (!passwordRegex.test(bandaPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaPassword:
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaPassword: "",
        // formStatus: "",
      }));
    }
  };

  const comparePasswordsReg = (
    password: string,
    repeatPassword: string
  ): boolean => {
    if (password !== repeatPassword) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "Passwords do not match",
      }));
      return false;
    } else {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "",
      }));
      return true;
    }
  };

  const validateEmailReg = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (!emailRegex.test(email)) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const validateUsernameReg = (username: string) => {
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!username.trim()) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "Username is required",
      }));
    } else if (!usernameRegex.test(username)) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "Invalid username",
      }));
    } else {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "",
      }));
    }
  };

  const validatePasswordReg = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[^\s]{8,}$/;
    if (!password.trim()) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
    } else if (password.length < 8) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        password: "Must have 8 char, Capital leter , sign and a number",
      }));
    } else if (!passwordRegex.test(password)) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must have 8 chars that contain at least one lowercase,uppercase,digit,special character and no space",
      }));
    } else {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const validateUsername = (username: string) => {
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!username.trim()) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "Username is required",
      }));
    } else if (!usernameRegex.test(username)) {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "Invalid username",
      }));
    } else {
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        username: "",
      }));
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
        formStatus: "error",
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
        formStatus: "error",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
        // formStatus: "",
      }));
    }
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [isCompany, setIsCompany] = useState(false);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
      formStatus: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "nationalID":
        validateNIDA(value);
        break;
      case "phoneNumber":
        validatePhone(value);
        break;
      case "physicalResidency":
        validatePhysicalResidency(value);
        break;
      case "address":
        validateAddress(value);
        break;
      case "email":
        validateEmail(value);
        break;
      case "bandaName":
        validateName(value);
        break;
      case "ownerName":
        validateOwnerName(value, "ownerName");
        break;
      case "middleName":
        validateOwnerName(value, "middleName");
        break;
      case "lastName":
        validateOwnerName(value, "lastName");
        break;
      case "repeatBandaPassword":
        comparePasswords(formData.bandaPassword, value);
        break;
      case "bandaPassword":
        validatePassword(value);
        break;
      default:
        break;
    }
  };

  const handleChangeReg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormDataReg((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrorsReg((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrorsReg((prevErrors) => ({
      ...prevErrors,
      loginMobileResponse: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "email":
        validateEmailReg(value);
        break;
      case "username":
        validateUsernameReg(value);
        break;
      case "password":
        validatePasswordReg(value);
        comparePasswordsReg(formDataReg.password, value);
        break;
      case "repeatPassword":
        comparePasswordsReg(formDataReg.password, value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      district: "",
      ward: "",
    }));
  }, [formData.region]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ward: "",
    }));
  }, [formData.district]);


  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      mtaaVillage: "",
    }));
  }, [formData.ward]);

  // useEffect(()=>{

  // },[formData.ward])

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const registerPartnershipService = React.useCallback(
    (user: IUser) => dispatch(registerPartnership(user)),
    [dispatch]
  );

  const [userId, setUserId] = useState(user.id);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (formData.bandaPassword === formData.repeatBandaPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatBandaPassword: "",
        bandaPassword: "",
      }));
    }
  }, [formData.repeatBandaPassword, formData.bandaPassword]);

  const [registerBandaService] = useRegistrationBandaMutation();
  const [successReg, setSuccessReg] = useState(false);
  const [successRegBanda, setSuccessRegBanda] = useState(false);
  const [accountExistOrErr, setAccountExistOrErr] = useState(false);

  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );
  const [loadingState, setLoadingState] = useState(false);


  const logout = (user: IUser) => {
    logoutService();
    localStorage.setItem("accessToken", "");
    history.push("/landing/landing");
  };











  async function save(key: string, value: any): Promise<void> {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`${key} saved successfully.`);
    } catch (error: any) {
      console.error(`Error saving ${key}: ${error.message}`);
    }
  }


  const [autoLogin] = useAutoLoginMobileMutation();


  const loginF = async () => {
    //login user

    if (!errors.email && !errors.password) {
      try {
        const response = await autoLogin({
          variables: {
            autoLoginMobileInput: {
              userId: user.id,
              inputData: "",
            },
          },
        });

        console.log(response);

        if (
          response.data &&
          response.data.autoLoginMobile &&
          !response.data.autoLoginMobile.log
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "Login failed",
          }));

          return;
        } else {
          // Clear the error for loginMobile if login is successful or no loginMobile property is present
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "",
          }));

        }

        const accessToken: any = response.data?.autoLoginMobile.access_token;
        const refreshToken: any = response.data?.autoLoginMobile.refresh_token;
        const userdata: any = jwtDecode(accessToken);
        const accountStatus: any = response.data?.autoLoginMobile.account_status;
        const isLegFromQuery: any = response.data?.autoLoginMobile.log; //boolean

        console.log(userdata);
        console.log(userdata.status);

        save("accountStatus", accountStatus);
        save("accessToken", accessToken);
        save("refreshToken", refreshToken);
        console.log("accssTokenFromStore");


        // return

        //update redux state
        // localStorage.setItem("refreshToken", refreshToken);


       
        loginX({
          id: userdata.id,
          name: userdata.username,
          bandaCode: userdata.bandaCode,

          loginStatus: userdata.status.login,
          email: userdata.email,
          sponsorId: userdata?.sponsorId,
          accessType: userdata.status.isContentOwner ? "content" : "none",
          sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
          contentStatus: userdata.status.isContentOwner,
          accountStat: !userdata.accountStat
            ? JSON.stringify([])
            : userdata.accountStat,
          mode: "none",
          bandaStatus: userdata.status.isBanda ? "banda" : "none",
          language: user.language,
          darkMode: user.darkMode,
          loading: false,
        });

        // setSuccess(true);

        // history.push(`/site/explore`);
        setLoadingState(false)
        history.push("/site/register");

      } catch (error) {

        console.log("LOGIN FAILED ");
        setErrors((prevErrors) => ({
          ...prevErrors,
          loginResponseError: "Login failed",
        }));
        setLoadingState(false)
        history.push("/site/register");


      }
    }
  


  };




  
  const handleSubmit = async () => {
    setLoadingState(true);
    let reg;

    if (!user.loginStatus) {
      reg = await handleSubmitRegister();
    }

    validateAddress(formData.address);
    validatePhysicalResidency(formData.physicalResidency);
    validateNIDA(formData.nationalID);
    validatePhone(formData.phoneNumber);
    validateName(formData.bandaName);
    validateOwnerName(formData.ownerName, "ownerName");
    validateOwnerName(formData.middleName, "middleName");
    validateOwnerName(formData.lastName, "lastName");
    validatePassword(formData.bandaPassword);
    validatePassword(formData.repeatBandaPassword);
    validateEmail(formData.email);
    //  return

    if (
      errors.Address ||
      errors.ownerName ||
      errors.middleName ||
      errors.lastName ||
      errors.ownerName ||
      errors.ownerName ||
      errors.Address ||
      errors.physicalResidency ||
      errors.nationalID ||
      errors.phoneNumber ||
      errors.bandaName ||
      formData.bandaPassword.trim() === "" ||
      formData.repeatBandaPassword.trim() === "" ||
      errors.bandaPassword ||
      errors.repeatBandaPassword ||
      errors.email ||
      formData.region.trim() === "" ||
      formData.district.trim() === "" ||
      formData.typeOfSeats.trim() === "" ||
      formData.ward.trim() === ""
    ) {
      if (formData.typeOfSeats.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          typeOfSeats: "Type of seats cant be empty",
          formStatus: "error",
        }));
      }

      if (formData.region.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          region: "Region cant be empty",
          formStatus: "error",
        }));
      }
      if (formData.district.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          district: "District cant be empty",
          formStatus: "error",
        }));
      }

      if (formData.ward.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ward: "Ward cant be empty",
          formStatus: "error",
        }));
      }
      setLoadingState(false);
      return;
    }

    console.log("formData");
    console.log("formData");
    console.log("formData");
    console.log(formData);
    console.log(user);
    console.log("formData");
    console.log("formData");
    console.log("formData");

    console.log(reg);
    console.log(
      successReg
        ? userId
        : !user.loginStatus && reg && reg.status
        ? reg.userId
        : user.id
    );
    console.log(successReg && !user.loginStatus ? userId : user.id);
    console.log(!user.loginStatus ? userId : user.id);

    // return

    let uploadingData = {
      userId: user.id, // successReg  ? userId :  (!user.loginStatus && reg && reg.status) ? reg.userId :  user.id,
      nationalID: formData?.nationalID,
      phoneNumber: formData?.phoneNumber,
      address: formData?.address,
      physicalResidency: formData?.physicalResidency,
      bandaName: formData?.bandaName,
      bandaPassword: formData?.bandaPassword,
      email: formData?.email,
      capacity: parseInt(formData?.capacity),
      tvSize: selectedTv,
      decoders: selectedDecoders,
      ageRestriction: formData?.ageRestriction,
      umeme: formData?.umeme,
      ownerName:
        formData?.ownerName +
        " " +
        formData?.middleName +
        " " +
        formData?.lastName,
      drinks: formData?.drinks,

      food: formData?.food,
      airConditioner: formData?.airConditioner,
      nafasiWazi:formData?.nafasiWazi,
      projector: formData?.projector,
      feni: formData?.feni,
      generator: formData?.generator,
      region: formData?.region,
      district: formData?.district,
      ward: formData?.ward,
      mtaaName: formData?.mtaaVillage,
      districtPostcode: formData?.districtPostcode,
      wardPostcode: formData?.wardPostcode,
      wifi: formData?.wifi,
      vikalioKutoka: formData?.vikalio,
      laptop: formData?.laptop,
      bandaSize: formData?.bandaSize,
      typeOfSeats: formData?.typeOfSeats,
      openingTime: formData?.openingTime,
      closingTime: formData?.closingTime,
      longitude: 0,
      latitude: 0,
    };

    try {
      const response = await registerBandaService({
        variables: {
          registerBandaInput: uploadingData,
        },
      });

      console.log(response);

      if (
        response.data &&
        response.data.registrationBanda &&
        !response.data.registrationBanda.log
      ) {
        console.log(response.data.registrationBanda.mode);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: response.data.registrationBanda.mode,
          formStatus: "error",
        }));
        setAccountExistOrErr(true);
        setLoadingState(false);
        return false;
      } else {
        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
          // formStatus: "",
        }));
      }
      // console.log(response);
      // console.log(response);
      // console.log(response);

      // return

      //#TODO in db prevent double registration
      if (response.data?.registrationBanda.log) {
        // loginX({
        //   id: user.id,
        //   name: user.name,
        //   loginStatus: user.loginStatus,
        //   email: user.email,
        //   accessType: user.accessType, //user.role === "SPONSOR" ? "sponsor" :"content",
        //   accountStat: response.data.registrationBanda.mode,
        //   mode: user.mode,
        //   contentStatus: user.contentStatus,
        //   language: user.language,
        //   darkMode: user.darkMode,
        //   loading: false,
        // });

        // setFormData({
        //   bandaName: "",
        //   email: "",
        //   phoneNumber: "",
        //   address: "",
        //   physicalResidency: "",
        //   nationalID: "",
        //   ageRestriction: "G",
        //   umeme:false,
        //   ownerName: "",
        //   lastName: "",
        //   middleName: "",
        //   numberOfTv: "3",
        //   repeatBandaPassword: "",
        //   bandaPassword: "",
        //   decoders: [""],
        //   capacity: "100",
        //   drinks: false,
        //   food: false,
        //   airConditioner: false,
        //   projector: false,
        //   feni: false,
        //   generator: false,
        //   wifi: false,
        //   vikalio: false,
        //   laptop: false,
        //   tvSize: [""],
        //   bandaSize: "",
        //   typeOfSeats: "",
        //   region: "",
        //   district: "",
        //   ward: "",
        //   openingTime: "",
        //   closingTime: "",
        // });

        setSuccessRegBanda(true);
        // logout(user);
        loginF()
        // history.push("/site/register");  
        setLoadingState(false)
        history.push("/site/register");
      }
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Banda Registration failed",
        formStatus: "error",
      }));
      setLoadingState(false);

    }
  };

  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  const loginX = (user: IUser) => {
    loginService(user);
  };

  const [selectedTv, setSelectedTv] = useState([]);
  const [selectedDecoders, setSelectedDecoders] = useState([]);
  const [selectedItemDecoder, setSelectedItemDecoder] = useState("");

  const [selectedItemTv, setSelectedItemTv] = useState("");
  
  const handleChangeDecoder = (e: any) => {
    setSelectedItemDecoder(e.target.value);
  };

  const handleDecoders = () => {
    if (
      selectedItemDecoder &&
      !selectedDecoders.includes(selectedItemDecoder)
    ) {
      setSelectedDecoders([...selectedDecoders, selectedItemDecoder]);
      setSelectedItemDecoder("");
    }
  };

  const removeDecoder = (decoderToRemove: string) => {
    setSelectedDecoders(
      selectedDecoders.filter((decoder) => decoder !== decoderToRemove)
    );
  };

  const handleChangeTv = (e: any) => {
    setSelectedItemTv(e.target.value);
  };

  const handleTv = () => {
    if (selectedItemTv) {
      setSelectedTv([...selectedTv, selectedItemTv]);
      setSelectedItemTv("");
    }
  };

  // const removeTv = (decoderToRemove: string) => {
  //   setSelectedTv(selectedTv.filter((decoder) => decoder !== decoderToRemove));
  // };
  const removeTv = (decoderToRemove: string) => {
    const index = selectedTv.findIndex(
      (decoder) => decoder === decoderToRemove
    );
    if (index !== -1) {
      const updatedSelectedTv = [...selectedTv];
      updatedSelectedTv.splice(index, 1);
      setSelectedTv(updatedSelectedTv);
    }
  };

  const [latestBandaApplication, setLatestBandaApplication] = useState(null);
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  useEffect(() => {
    if (user && user.accountStat) {
      const latest = findCurrentAccountStateByKey(
        JSON.parse(user.accountStat),
        "BandaApplication"
      );
      setLatestBandaApplication(latest);
    }
  }, [user]);
  const [show, setShow] = React.useState(false);
  const [showRepeat, setShowRepeat] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClickRepeat = () => setShowRepeat(!showRepeat);

  //REGISTER FIRST
  const [register, loading] = useRegistrationMutation();
  const clearPromoCart = React.useCallback(
    () => dispatch(clearCartPromo()),
    [dispatch]
  );

  const clearFundCart = React.useCallback(
    () => dispatch(clearCart()),
    [dispatch]
  );

 

  // NAVIGATION FUNCTIONS

  const onAddUserClick = async () => {
    try {
      const response = await register({
        variables: {
          registerInput: {
            username: formDataReg.username,
            password: formDataReg.password,
            regData: formDataReg.email,
          },
        },
      });

      if (
        response.data &&
        response.data.registration &&
        !response.data.registration.log
      ) {
        setErrorsReg((prevErrors) => ({
          ...prevErrors,
          registrationResponseError: response?.data?.registration?.message,
        }));
        setDone(false);
        setLoadingState(false)

        return {
          status: false,
          userId: "",
        }; // Exit if fields are empty
      } else {
        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrorsReg((prevErrors) => ({
          ...prevErrors,
          registrationResponseError: "",
        }));
        // console.log(response);
        // console.log(response);
        console.log(response?.data?.registration?.message);
        // console.log(response?.data);
        console.log(response?.data?.registration?.message);
        setUserId(response?.data?.registration?.message);
        // setFails(response?.data?.registration?.message)
        setSuccessReg(true);
        setDone(true);
        setLoadingState(false)

        return {
          status: true,
          userId: response?.data?.registration?.message,
        }; // Exit if fields are empty
      }
    } catch (e) {
      console.log("Error failed");
      setErrorsReg((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Login failed",
      }));
      setDone(false);
      setLoadingState(false)

      return {
        status: false,
        userId: "",
      }; // Exit if fields are empty
    }
    //  }
  };

  // const XCXUserLogin = async () => {

  //      if(!successReg){return false}

  //       try {
  //         const response = await login({
  //           variables: {
  //             authLoginReg: {
  //               inputData: formDataReg.email.trim(),
  //               password: formDataReg.password.trim(),
  //             },
  //           },
  //         });

  //         console.log(response);

  //         if (
  //           response.data &&
  //           response.data.loginMobile &&
  //           !response.data.loginMobile.log
  //         ) {
  //           setErrors((prevErrors) => ({
  //             ...prevErrors,
  //             loginResponseError: "Login failed",
  //           }));
  //           return false;
  //         } else {
  //           // Clear the error for loginMobile if login is successful or no loginMobile property is present
  //           setErrors((prevErrors) => ({
  //             ...prevErrors,
  //             loginResponseError: "",
  //           }));

  //         }

  //         const accessToken: any = response.data?.loginMobile.access_token;
  //         const refreshToken: any = response.data?.loginMobile.refresh_token;
  //         const userdata: any = jwtDecode(accessToken);
  //         const accountStatus: any = response.data?.loginMobile.account_status;
  //         const isLegFromQuery: any = response.data?.loginMobile.log; //boolean

  //         console.log(userdata);
  //         console.log(userdata.status);

  //         save("accountStatus", accountStatus);
  //         save("accessToken", accessToken);
  //         save("refreshToken", refreshToken);
  //         console.log("accssTokenFromStore");

  //         //update redux state
  //         clearPromoCart();
  //         clearFundCart();
  //         loginX({
  //           id: userdata.status.isSponsor ? userdata.id : userdata.id,
  //           name: userdata.username,
  //           loginStatus: userdata.status.login,
  //           email: userdata.email,
  //           sponsorId: userdata?.status?.sponsorId,
  //           accessType: userdata.status.isContentOwner ? "content" : "none",
  //           sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
  //           contentStatus: userdata.status.isContentOwner,
  //           accountStat: !userdata.accountStat ? JSON.stringify([]): userdata.accountStat,
  //           mode: "none",
  //           bandaStatus: userdata.status.isBanda ? "banda" : "none",
  //           language: user.language,
  //           darkMode: user.darkMode,
  //           loading: false,
  //         });
  //         setSuccessLogedIn(true)
  //         return true;

  //       } catch (error) {
  //         console.log("LOGIN FAILED ");
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           loginResponseError: "Login failed",
  //         }));
  //         return false;

  //       }
  //   };

  const handleSubmitRegister = async () => {
    // console.log(formDataReg);

    validateEmailReg(formDataReg.email);
    validateUsernameReg(formDataReg.username);
    validatePasswordReg(formDataReg.password);
    comparePasswordsReg(formDataReg.password, formDataReg.repeatPassword);

    if (
      errorsReg.email ||
      errorsReg.username ||
      errorsReg.password ||
      errorsReg.repeatPassword
    ) {
      console.log("Put proper details");
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Please use valid credentials",
      }));
      return {
        status: false,
        userId: "",
      }; // Exit if fields are empty
    } else if (
      !formDataReg.email ||
      !formDataReg.username ||
      !formDataReg.password ||
      !formDataReg.repeatPassword
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Please Put valid credentials",
      }));
      console.log("Please enter both email and password");
      return {
        status: false,
        userId: "",
      }; // Exit if fields are empty
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "",
      }));
      try {
        if (!successReg) {
          setLoadingState(true)
          const addingRe = await onAddUserClick();
          console.log(addingRe);

          //   if(addingRe ){
          //     XCXUserLogin()
          //  }
          return addingRe;
        }
        return {
          status: false,
          userId: "",
        };
        // if(successReg && userId.trim() !== ""){
        //   const addingRe = XCXUserLogin();

        //   return addingRe
        // }
      } catch (e) {
        return {
          status: false,
          userId: "",
        }; // Exit if fields are empty
      }
    }
  };

  useEffect(() => {
    if (formDataReg.password === formDataReg.repeatPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "",
        password: "",
      }));
    }
  }, [formDataReg.password, formDataReg.repeatPassword]);


  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
       

        setFormData((prevState) => ({
          ...prevState,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,        }));
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };


  return (
    <div>
  {!loadingState  ? <div>
    {!user.loginStatus && (
        <>
          {" "}
          {
            // true
            //   !successRegBanda
            // &&
            !successReg ? (
              // || !(errorsReg.registrationResponseError && errorsReg.registrationResponseError.trim() !== "")
              <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Grid
                  mb="20px"
                  gridTemplateColumns={{
                    xl: "repeat(1, 1fr)",
                    "2xl": "1fr 0.46fr",
                  }}
                  gap={{ base: "20px", xl: "20px" }}
                  display={{ base: "block", xl: "grid" }}
                >
                  <Flex
                    flexDirection="column"
                    gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                  >
                    <Card
                      mb={{ base: "0px", "2xl": "20px" }}
                      // alignItems="center"
                      // flexDirection="column"
                      // w="100%"
                      // px="0px"
                      // overflowX={{ sm: "scroll", lg: "hidden" }}
                      // style={{ width: "100%" }}
                    >
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                        color={textColor}
                        fontSize="2xl"
                        ms="24px"
                        fontWeight="700"
                      >
                        User account registration
                      </Text>
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 50,
                        }}
                        color={textColor}
                        fontSize="sm"
                        ms="24px"
                        fontWeight="700"
                      >
                        Add user details this user will have details of the
                        respective banda
                      </Text>
                      <FormControl>
                        {errorsReg.email && (
                          <Text style={{ color: "red" }}>
                            Please use a valid email
                          </Text>
                        )}

                        <FormLabel
                          display="flex"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          mb="8px"
                        >
                          Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="email"
                          placeholder="mail@gmail.com"
                          mb="24px"
                          fontWeight="500"
                          size="lg"
                          name="email"
                          value={formDataReg.email}
                          onChange={handleChangeReg}
                        />
                        {errorsReg.username && (
                          <Text style={{ color: "red" }}>
                            Please use a valid username
                          </Text>
                        )}

                        <FormLabel
                          display="flex"
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          mb="8px"
                        >
                          Username<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="text"
                          placeholder="username"
                          mb="24px"
                          fontWeight="500"
                          size="lg"
                          name="username"
                          value={formDataReg.username}
                          onChange={handleChangeReg}
                        />
                        {errorsReg.password && (
                          <Text style={{ color: "red" }}>
                            {errorsReg.password}
                          </Text>
                        )}

                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Password<Text color={brandStars}>*</Text>
                        </FormLabel>

                        <InputGroup size="md">
                          <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Min. 8 characters"
                            mb="24px"
                            size="lg"
                            type={show ? "text" : "password"}
                            name="password"
                            value={formDataReg.password}
                            onChange={handleChangeReg}
                          />
                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                              onClick={handleClick}
                            />
                          </InputRightElement>
                        </InputGroup>
                        {errorsReg.repeatPassword && (
                          <Text style={{ color: "red" }}>
                            {errorsReg.repeatPassword}
                          </Text>
                        )}

                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          Confirm Password<Text color={brandStars}>*</Text>
                        </FormLabel>

                        <InputGroup size="md">
                          <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder="Min. 8 characters"
                            mb="24px"
                            size="lg"
                            type={showRepeat ? "text" : "password"}
                            name="repeatPassword"
                            value={formDataReg.repeatPassword}
                            onChange={handleChangeReg}
                          />
                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: "pointer" }}
                              as={
                                showRepeat
                                  ? RiEyeCloseLine
                                  : MdOutlineRemoveRedEye
                              }
                              onClick={handleClickRepeat}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          mb="24px"
                        ></Flex>

                        {errorsReg.registrationResponseError && (
                          <Text style={{ color: "red" }}>
                            {errorsReg.registrationResponseError}
                          </Text>
                        )}
                      </FormControl>
                    </Card>
                  </Flex>
                </Grid>
              </Box>
            ) : (
              <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
                <Grid
                  mb="20px"
                  gridTemplateColumns={{
                    xl: "repeat(1, 1fr)",
                    "2xl": "1fr 0.46fr",
                  }}
                  gap={{ base: "20px", xl: "20px" }}
                  display={{ base: "block", xl: "grid" }}
                >
                  <Flex
                    flexDirection="column"
                    gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                  >
                    <Card
                      mb={{ base: "0px", "2xl": "20px" }}
                      // alignItems="center"
                      // flexDirection="column"
                      // w="100%"
                      // px="0px"
                      // overflowX={{ sm: "scroll", lg: "hidden" }}
                      // style={{ width: "100%" }}
                    >
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                        color={textColor}
                        fontSize="2xl"
                        ms="24px"
                        fontWeight="700"
                      >
                        User account was Registered successfully
                      </Text>
                      {!errorsReg.registrationResponseError && (
                        <Text
                          style={{
                            marginTop: 10,
                            marginBottom: 50,
                          }}
                          color={textColor}
                          fontSize="sm"
                          ms="24px"
                          fontWeight="700"
                        >
                          Kindly complete banda registration
                        </Text>
                      )}

                      {errorsReg.registrationResponseError && (
                        <>
                          {" "}
                          <Text style={{ color: "red" }}>
                            {errorsReg.registrationResponseError}
                          </Text>
                          <Text style={{ color: "red" }}>
                            Please Login to your account to finish registration
                          </Text>
                        </>
                      )}

                      {errorsReg.registrationResponseError && (
                        <>
                          <Button
                            onClick={() => {
                              setSuccessReg(false);
                              setFormDataReg({
                                email: "",
                                password: "",
                                repeatPassword: "",
                                username: "",
                              });
                              setErrors({});
                              setErrorsReg({});
                            }}
                            variant={"link"}
                            style={{ color: "green" }}
                          >
                            Use other details
                          </Button>
                        </>
                      )}

                      {errorsReg.registrationResponseError && (
                        <>
                          <Button
                            onClick={() => {
                              history.push("/auth/sign-in");
                            }}
                            variant={"link"}
                            style={{ color: "green" }}
                          >
                            Cancel and Login
                          </Button>
                        </>
                      )}
                    </Card>
                  </Flex>
                </Grid>
              </Box>
            )
          }
        </>
      )}

      {
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
          {!successRegBanda ? (
            <Grid
              mb="20px"
              gridTemplateColumns={{
                xl: "repeat(1, 1fr)",
                "2xl": "1fr 0.46fr",
              }}
              gap={{ base: "20px", xl: "20px" }}
              display={{ base: "block", xl: "grid" }}
            >
              <Flex
                flexDirection="column"
                gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
              >
                {/* <Banner accountStatus={latestBandaApplication?.status} /> */}

                {!(
                  latestBandaApplication?.status === "PENDING" ||
                  latestBandaApplication?.status === "ACTIVE"
                ) && (
                  <Box>
                    {/* {isCompany ? (
              <Text
                style={{
                  marginTop: 10,
                  marginBottom: 50,
                }}
                color={textColor}
                fontSize="2xl"
                ms="24px"
                fontWeight="700"
              >
                COMPANY DETAILS{" "}
              </Text>
            ) : (
              <Text
                style={{
                  marginTop: 10,
                  marginBottom: 50,
                }}
                color={textColor}
                fontSize="2xl"
                ms="24px"
                fontWeight="700"
              >
              </Text>
            )} */}
                    <Card
                      mb={{ base: "0px", "2xl": "20px" }}
                      // alignItems="center"
                      // flexDirection="column"
                      // w="100%"
                      // px="0px"
                      // overflowX={{ sm: "scroll", lg: "hidden" }}
                      // style={{ width: "100%" }}
                    >
                      {" "}
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                        color={textColor}
                        fontSize="2xl"
                        ms="24px"
                        fontWeight="700"
                      >
                        Banda Registration
                      </Text>
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 50,
                        }}
                        color={textColor}
                        fontSize="sm"
                        ms="24px"
                        fontWeight="700"
                      >
                        Add Banda details, use contacts deatils that will be
                        used by banda operations
                      </Text>
                      {!(
                        latestBandaApplication?.status === "PENDING" ||
                        latestBandaApplication?.status === "ACTIVE"
                      ) && (
                        <div>
                          <SimpleGrid minChildWidth="240px" spacing="20px">
                            <div>
                              {errors.ownerName && (
                                <Text style={{ color: "red" }}>
                                  {errors.ownerName}
                                </Text>
                              )}
                              <Input
                                type="text"
                                name="ownerName"
                                placeholder="FirstName"
                                value={formData.ownerName}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              {errors.middleName && (
                                <Text style={{ color: "red" }}>
                                  {errors.middleName}
                                </Text>
                              )}
                              <Input
                                type="text"
                                name="middleName"
                                placeholder="MiddleName"
                                value={formData.middleName}
                                onChange={handleChange}
                              />{" "}
                            </div>
                            <div>
                              {errors.lastName && (
                                <Text style={{ color: "red" }}>
                                  {errors.lastName}
                                </Text>
                              )}
                              <Input
                                type="text"
                                name="lastName"
                                placeholder="LastName"
                                value={formData.lastName}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              {errors.nationalID && (
                                <Text style={{ color: "red" }}>
                                  {errors.nationalID}
                                </Text>
                              )}
                               

                              <Input
                                type="text"
                                name="nationalID"
                                placeholder="owner NIDA"
                                value={formData.nationalID}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              {errors.bandaName && (
                                <Text style={{ color: "red" }}>
                                  {errors.bandaName}
                                </Text>
                              )}
                              <Input
                                type="text"
                                name="bandaName"
                                placeholder="BandaName"
                                value={formData.bandaName}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              {errors.email && (
                                <Text style={{ color: "red" }}>
                                  {errors.email}
                                </Text>
                              )}

                              <Input
                                type="email"
                                name="email"
                                placeholder="bandaEmail@email.com"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              {errors.phoneNumber && (
                                <Text style={{ color: "red" }}>
                                  {errors.phoneNumber}
                                </Text>
                              )}
                              <Input
                                type="tel"
                                name="phoneNumber"
                                placeholder="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              {errors.bandaPassword && (
                                <Text style={{ color: "red" }}>
                                  {errors.bandaPassword}
                                </Text>
                              )}

                              <InputGroup size="md">
                                <Input
                                  isRequired={true}
                                  fontSize="sm"
                                  placeholder="password Min. 8 characters"
                                  mb="24px"
                                  size="lg"
                                  type={show ? "text" : "password"}
                                  name="bandaPassword"
                                  value={formData.bandaPassword}
                                  onChange={handleChange}
                                />
                                <InputRightElement
                                  display="flex"
                                  alignItems="center"
                                  mt="4px"
                                >
                                  <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={
                                      show
                                        ? RiEyeCloseLine
                                        : MdOutlineRemoveRedEye
                                    }
                                    onClick={handleClick}
                                  />
                                </InputRightElement>
                              </InputGroup>
                            </div>
                            <div>
                              {errors.repeatBandaPassword && (
                                <Text style={{ color: "red" }}>
                                  {errors.repeatBandaPassword}
                                </Text>
                              )}

                              <InputGroup size="md">
                                <Input
                                  isRequired={true}
                                  fontSize="sm"
                                  placeholder="Repeat password .Min. 8 characters"
                                  mb="24px"
                                  size="lg"
                                  type={showRepeat ? "text" : "password"}
                                  name="repeatBandaPassword"
                                  value={formData.repeatBandaPassword}
                                  onChange={handleChange}
                                />
                                <InputRightElement
                                  display="flex"
                                  alignItems="center"
                                  mt="4px"
                                >
                                  <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={
                                      showRepeat
                                        ? RiEyeCloseLine
                                        : MdOutlineRemoveRedEye
                                    }
                                    onClick={handleClickRepeat}
                                  />
                                </InputRightElement>
                              </InputGroup>
                            </div>
                            <div>
                              {errors.ageRestriction && (
                                <Text style={{ color: "red" }}>
                                  {errors.ageRestriction}
                                </Text>
                              )}

                              <Select
                                name="ageRestriction"
                                value={formData.ageRestriction}
                                onChange={handleChange}
                                placeholder="Age restriction: if applies"
                              >
                                {[
                                  { label: "General Audience", value: "G" },
                                  { label: "Parental Guidance", value: "PG" },
                                  {
                                    label: "Parents Strongly Cautioned",
                                    value: "PG-13",
                                  },
                                  { label: "Restricted", value: "R" },
                                  { label: "Adults Only", value: "12" },
                                ].map((cat: any) => (
                                  <option key={cat.label} value={cat.value}>
                                    {cat.value}
                                    {" : "}
                                    {cat.label}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            {/* AREA LOCATION SELECTION */}

                            <div>
                              {errors.region && (
                                <Text style={{ color: "red" }}>
                                  {errors.region}
                                </Text>
                              )}

                              <Select
                                name="region"
                                value={formData.region}
                                onChange={handleChange}
                                placeholder="Select region"
                              >
                                {regionData?.map((cat: any) => (
                                  <option key={cat.region} value={cat.region}>
                                    {cat.region}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              {errors.district && (
                                <Text style={{ color: "red" }}>
                                  {errors.district}
                                </Text>
                              )}

                              <Select
                                name="district"
                                value={formData.district}
                                onChange={handleChange}
                                placeholder="Select district"
                              >
                                {regionData
                                  ?.find(
                                    (cat: any) => cat.region === formData.region
                                  )
                                  ?.data?.map((cat_district: any) => (
                                    <option
                                      key={
                                        formData.region +
                                        "_" +
                                        cat_district.district
                                      }
                                      // value={cat_district.district}
                                      value={
                                        cat_district.district +
                                        "_" +
                                        cat_district.postCode
                                      }
                                    >
                                      {cat_district.district}
                                    </option>
                                  ))}
                              </Select>
                            </div>

                            <div>
                              {errors.ward && (
                                <Text style={{ color: "red" }}>
                                  {errors.ward}
                                </Text>
                              )}
                              <Select
                                name="ward"
                                value={formData.ward}
                                onChange={handleChange}
                                placeholder="Select ward"
                              >
                                {regionData
                                  ?.find(
                                    (cat: any) => cat.region === formData.region
                                  )
                                                                  // @ts-ignore

                                  ?.data?.find(
                                    (cat_district: any) =>
                                      cat_district.district +
                                        "_" +
                                        cat_district.postCode ===
                                      formData.district
                                  )
                                  ?.data?.map((cat_ward: any) => {
                                    console.log("cat_ward");
                                    console.log(cat_ward);
                                    console.log("cat_ward");

                                    return (
                                      <option
                                        key={
                                          formData.district +
                                          "_" +
                                          cat_ward.ward
                                        }
                                        // value={cat_ward.ward}
                                        value={
                                          cat_ward.ward +
                                          "_" +
                                          cat_ward.postCode
                                        }
                                      >
                                        {cat_ward.ward}
                                      </option>
                                    );
                                  })}
                              </Select>
                            </div>

                            <div>
                              {errors.mtaaVillage && (
                                <Text style={{ color: "red" }}>
                                  {errors.mtaaVillage}
                                </Text>
                              )}
                              <Select
                                name="mtaaVillage"
                                value={formData.mtaaVillage}
                                onChange={handleChange}
                                placeholder="Select mtaa"
                              >
                                {regionData
                                // @ts-ignore
                                    ?.find((cat: any) => cat?.region === formData.region)
                                                                    // @ts-ignore
                                    ?.data?.find((cat_district: any) => 
                                      `${cat_district?.district}_${cat_district?.postCode}` === formData.district
                                    )                                  
                                    ?.data?.find((cat_ward: any) => 
                                      `${cat_ward?.ward}_${cat_ward?.postCode}` === formData.ward
                                    )
                                  ?.data?.map((mtaaVillage: any) => (
                                    <option
                                      key={
                                        formData.district +
                                        "_" +
                                        mtaaVillage.mtaaVillage
                                      }
                                      value={
                                        mtaaVillage.mtaaVillage
                                          ? `MtaaKijiji_${mtaaVillage.mtaaVillage}`
                                          : //@ts-ignore
                                            `Kitongoji_${mtaaVillage.kitongoji}`
                                      }
                                    >
                                      <span className="text-blue-300">
                                        {mtaaVillage.mtaaVillage
                                          && `${"Mtaa/Kijiji  "+mtaaVillage.mtaaVillage}`
                                        }
                                      </span>
                                    
                                      
                                      {mtaaVillage.kitongoji &&
                                        //@ts-ignore

                                       `${ "   Kitongoji cha   "+mtaaVillage.kitongoji }`

                                      }
                                    </option>
                                  ))}
                              </Select>
                            </div>

                            {/* <div>
                        {errors.kitongoji && (
                          <Text style={{ color: "red" }}>
                            {errors.kitongoji}
                          </Text>
                        )}
                        <Select
                          name="kitongoji"
                           
                          value={formData.kitongoji}
                          onChange={handleChange}
                          placeholder="Select kitongoji"
                        >
                          {regionData
                            ?.find((cat:any) => cat.region === formData.region)?.data
                            ?.find((cat_district:any) => cat_district.district+"_"+cat_district.postCode === formData.district)?.data
                            ?.find((cat_ward:any) => cat_ward.ward+"_"+cat_ward.postCode === formData.ward)?.data
                            ?.map((kitongoji) => (
                              <option
                                key={formData.district + "_" + kitongoji}
                                value={kitongoji.kitongoji}
                              >
                                {kitongoji.kitongoji}
                              </option>
                            ))}
                        </Select>
                      </div> */}

                            {/*  */}

                            <div>
                              {errors.physicalResidency && (
                                <Text style={{ color: "red" }}>
                                  {errors.physicalResidency}
                                </Text>
                              )}
                              <Input
                                type="text"
                                name="physicalResidency"
                                placeholder="Mtaa 123 JinaLaMtaa"
                                value={formData.physicalResidency}
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              {errors.capacity && (
                                <Text style={{ color: "red" }}>
                                  {errors.capacity}
                                </Text>
                              )}

                              <InputGroup size="md">
                                <Input
                                  type="number"
                                  name="capacity"
                                  placeholder="Capacity"
                                  value={formData.capacity}
                                  onChange={handleChange}
                                />
                              </InputGroup>
                            </div>

                            <div>
                              <List
                                items={selectedDecoders}
                                onRemove={removeDecoder}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  // name="contentType"
                                  value={selectedItemDecoder}
                                  onChange={handleChangeDecoder}
                                  variant={"auth"}
                                  placeholder="Choose Decoder"
                                >
                                  {[
                                    { mode: "azam", label: "Azam" },
                                    { mode: "dstv", label: "Dstv" },
                                    { mode: "startimes", label: "Startimes" },
                                    { mode: "zuku", label: "Zuku" },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>

                                <Button
                                  type="submit"
                                  onClick={() => {
                                    handleDecoders();
                                  }}
                                  colorScheme={"gray"}
                                  variant={"solid"}
                                  fontSize={"2xs"}
                                  margin={"1px"}
                                  padding={"10px"}
                                  backgroundColor={"#f1f1f1"}
                                  // marginTop="4"
                                >
                                  Add decoder
                                </Button>
                              </div>
                            </div>

                            <div>
                              <List items={selectedTv} onRemove={removeTv} />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  value={selectedItemTv}
                                  onChange={handleChangeTv}
                                  variant={"auth"}
                                  placeholder="Choose Tv To Add"
                                >
                                  {[
                                    // { mode: '40"', label: '42"' },
                                    { mode: '42"', label: '42"' },
                                    { mode: '49"', label: '49"' },
                                    { mode: '55"', label: '55"' },
                                    { mode: '65"', label: '65"' },
                                    { mode: '75"', label: '75"' },
                                    { mode: '85+"', label: '85+"' },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>

                                <Button
                                  type="submit"
                                  onClick={() => {
                                    handleTv();
                                  }}
                                  colorScheme={"gray"}
                                  variant={"solid"}
                                  fontSize={"2xs"}
                                  margin={"1px"}
                                  padding={"10px"}
                                  backgroundColor={"#f1f1f1"}
                                  // marginTop="4"
                                >
                                  Add Tv
                                </Button>
                              </div>
                            </div>

                            <div>
                              {errors.bandaSize && (
                                <Text style={{ color: "red" }}>Add Size</Text>
                              )}
                              <div>
                                <Input
                                  type="text"
                                  placeholder="Size in : 10m * 10m"
                                  name="bandaSize"
                                  value={formData.bandaSize}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div>
                              {errors.typeOfSeats && (
                                <Text style={{ color: "red" }}>
                                  {errors.typeOfSeats}
                                </Text>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  name="typeOfSeats"
                                  value={formData.typeOfSeats}
                                  onChange={handleChange}
                                  variant={"auth"}
                                  placeholder="Type of seats"
                                >
                                  {[
                                    { mode: "Benchi", label: "Benchi" },
                                    { mode: "Viti", label: "Viti" },
                                    {
                                      mode: "Viti na Benchi",
                                      label: "Viti na Benchi",
                                    },
                                    {
                                      mode: "Customizable",
                                      label: "Customizable",
                                    },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div style={{ marginLeft: 2, width: "100%" }}>
                              {errors.startingTime && (
                                <Text style={{ color: "red" }}>
                                  Add atleast one Tv
                                </Text>
                              )}
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Working time start {"  "}
                                {formData.openingTime}
                              </FormLabel>
                              <Input
                                name="openingTime"
                                value={formData.openingTime}
                                onChange={handleChange}
                                variant={"auth"}
                                type={"time"}
                                placeholder="Working time start"
                              />
                            </div>

                            <div style={{ marginLeft: 2, width: "100%" }}>
                              {errors.closingTime && (
                                <Text style={{ color: "red" }}>
                                  Add atleast one Tv
                                </Text>
                              )}
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Working time end {"  "}
                                {formData.closingTime}
                              </FormLabel>
                              <Input
                                name="closingTime"
                                type="time"
                                value={formData.closingTime}
                                onChange={handleChange}
                                variant={"auth"}
                                placeholder="Working time end"
                              />
                            </div>


                            <div className="flex flex-row ">
                             
                              <Input
                                type="text"
                                name="longitude"
                                placeholder="longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                              />
                            
                             
                              <Input
                                type="text"
                                name="latitude"
                                placeholder="latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                              />
                            </div>

                            <button type="button" className="bg-red-500 text-white rounded-xl " onClick={handleGetLocation}>Auto-fill Location</button>




                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Drinks{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="drinks"
                                checked={formData.drinks}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Umeme{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="umeme"
                                checked={formData.umeme}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Food{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="food"
                                checked={formData.food}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Feni{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="feni"
                                checked={formData.feni}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Generator{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="generator"
                                checked={formData.generator}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Wifi{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="wifi"
                                checked={formData.wifi}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Laptop{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="laptop"
                                checked={formData.laptop}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Vikalio vinatoka ?
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="vikalio"
                                checked={formData.vikalio}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Nafasi wazi ?
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="nafasiWazi"
                                checked={formData.nafasiWazi}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                air conditioner
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="airConditioner"
                                checked={formData.airConditioner}
                                onChange={handleChange}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                              >
                                Projector{" "}
                              </FormLabel>
                              <input
                                type="checkbox"
                                name="projector"
                                checked={formData.projector}
                                onChange={handleChange}
                              />
                            </div>
                          </SimpleGrid>
                          <Button
                            type="submit"
                            onClick={() => {
                              handleSubmit();
                            }}
                            colorScheme="green"
                            marginTop="50px"
                            width={"300px"}
                          >
                            Submit
                          </Button>
                          {errors.responseError && (
                            <Text style={{ color: "red" }}>
                              {errors.responseError}
                            </Text>
                          )}


                        </div>

                      )}
                    </Card>
                  </Box>
                )}
              </Flex>
            </Grid>
          ) : (
            <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
              <Grid
                mb="20px"
                gridTemplateColumns={{
                  xl: "repeat(1, 1fr)",
                  "2xl": "1fr 0.46fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}
              >
                <Flex
                  flexDirection="column"
                  gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                >
                  <Card
                    mb={{ base: "0px", "2xl": "20px" }}
                    // alignItems="center"
                    // flexDirection="column"
                    // w="100%"
                    // px="0px"
                    // overflowX={{ sm: "scroll", lg: "hidden" }}
                    // style={{ width: "100%" }}
                  >
                    {
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                        color={textColor}
                        fontSize="2xl"
                        ms="24px"
                        fontWeight="700"
                      >
                        Hellow ...{" "}
                        {errors.responseError
                          ? "Banda registration failed"
                          : "Banda  was registered successfully"}
                      </Text>
                    }
                    {errors.responseError && (
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 50,
                        }}
                        color={textColor}
                        fontSize="sm"
                        ms="24px"
                        fontWeight="700"
                      >
                        Kindly complete banda registration
                      </Text>
                    )}

                    {errors.responseError && (
                      <>
                        {" "}
                        <Text style={{ color: "red" }}>
                          {errors.responseError}
                        </Text>
                      </>
                    )}

                    {errors.responseError && (
                      <>
                        <Button
                          onClick={() => {
                            setSuccessRegBanda(false);
                          }}
                          variant={"link"}
                          style={{ color: "blue" }}
                        >
                          Edit form
                        </Button>
                      </>
                    )}

                    {!errors.responseError && (
                      <>
                        <Button
                          onClick={() => {
                            history.push("/auth/sign-in");
                          }}
                          variant={"link"}
                          style={{ color: "green" }}
                        >
                          Exit
                        </Button>
                      </>
                    )}
                  </Card>
                </Flex>
              </Grid>
            </Box>
          )}
        </Box>
      }
    </div>:<div>
    <div className="flex justify-center mt-20 h-screen">
            <div
              className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
    </div>
    }
    </div>
  );
}
