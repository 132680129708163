// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/images/imageHolder.png";
import Project2 from "assets/images/bajaji.jpeg";
import Project3 from "assets/images/pepsi.jpg";
// Custom components
import Card from "components/card/Card";
import Project from "views/site/BandaReservations/components/Project";
import {
  useGetBandaFundedQuery,
  useGetBandaReservationsQuery,
} from "generated/graphql";
import { useState } from "react";
import { useSelector } from "react-redux";
import ItemAdds from "./itemsAdds";

export default function AllAdds(props: { [x: string]: any }) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  // console.log(user);
  // console.log(user);

  // GetBandaReservations
  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [category, setCategory] = useState("FUNDED");

  const reservationData = useGetBandaFundedQuery({
    fetchPolicy: "cache-first",
    variables: {
      getEventsInput: {
        userId: user.id,
        page,
        pageSize,
        category,
      },
    },
  });

  console.log(reservationData?.data?.getBandaFunded);
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Content
      </Text>
      {/* <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        get by date, week etc ! most recently
      </Text> */}
      <Card mb={{ base: "0px", "2xl": "20px" }} maxHeight={"650px"} overflowX={"auto"}>

      {reservationData?.data?.getBandaFunded?.map((item) => {
        return (
          <ItemAdds
            boxShadow={cardShadow}
            mb="20px"
            image={Project1}
            ranking={item.createdAt}
            link={item.eventType}
            title={item.title}
          />
        );
      })}
    </Card>
    </Card>
  );
}
