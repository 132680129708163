import { Button, Flex, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isInList } from "views/site/helpers";
import { useHistory } from "react-router-dom";

interface BannerProps {
  isDataCompany: (isCompany: boolean) => void;
}

export default function Banner({ isDataCompany }: BannerProps) {
  const [isCompany, setIsCompany] = useState(false);
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();

  const handleCompanySponsorship = () => {
    setIsCompany(true);
    isDataCompany(true);
    // Your callback logic for company sponsorship
    // For example: sendCompanySponsorship();
  };

  const handleIndividualSponsorship = () => {
    setIsCompany(false);
    isDataCompany(false);
    // Your callback logic for individual sponsorship
    // For example: sendIndividualSponsorship();
  };

  return (
    <Flex
      direction="column"
      backgroundColor="#461177"
      bgSize="cover"
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius="30px"
      style={{ marginBottom: 20 }}
    >
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "46%",
          xl: "70%",
          "2xl": "50%",
          "3xl": "42%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        {!isInList(user.mode, "sponsorWait")
          ? "Become a Sponsor"
          : "Your Request is being processed"}
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="28px"
      >
        Embark on the journey to reach out community{" "}
      </Text>
      {!isInList(user.mode, "sponsorWait") && (
        <Flex align="center">
          {/* <Link>
          <Button
            bg={isCompany ? "green.500" : "transparent"}
            color={isCompany ? "white" : "white"}
            _hover={{ bg: "#1a759f" }}
            _active={{ bg: "green.500" }}
            // _focus={{ bg: "white" }}
            fontWeight="800"
            fontSize="14px"
            py="20px"
            px="27"
            me="38px"
            onClick={()=>{history.push("/site/register_sponsor")}}
          >
            For Organisation
          </Button>
        </Link> */}
          <Link>
            <Button
              bg={"green.500"}
              color={"white"}
              _hover={{ bg: "#1a759f" }}
              _active={{ bg: "green.500" }}
              fontWeight="800"
              fontSize="14px"
              py="20px"
              px="27"
              me="38px"
              onClick={() => {
                history.push("/site/register_sponsor");
              }}
            >
              Register Sponsor
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
}
