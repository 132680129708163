import { Box, Grid } from "@chakra-ui/react";
import DevelopmentTable from "views/site/sponsorDataTables/components/DevelopmentTable";
import Banner from "../exploreBanda/components/Banner";
import BannerSide from "../exploreBanda/components/BannerSide";

export default function SponsorDatatable() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb={10}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner />
        <BannerSide />
      </Grid>
      <DevelopmentTable />
    </Box>
  );
}
