// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import {
  useCreateEventMutation,
  useRemoveEventMutation,
} from "generated/graphql";
import { useState } from "react";
// Assets
import { MdAddBox, MdEdit, MdVerifiedUser } from "react-icons/md";
import { useSelector } from "react-redux";
import imageHolder from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";
import { formatDateTimeShort } from "views/site/helpers";
import { IoCalendarOutline } from "react-icons/io5";
interface FormErrors {
  [key: string]: string;
}

export default function Hosting(props: {
  category: string;
  title: string;
  home: string;
  id: string;
  dateStart: string;
  dateEnd:string;
  sponsorName: string;
  sponsorId: string;
  away: string;
  item: any;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const {
    id,
    title,
    category,
    dateEnd,

    sponsorName,
    sponsorId,
    dateStart,
    home,
    away,
    ranking,
    item,
    link,
    image,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [removeEvent] = useRemoveEventMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

 

  

  const history = useHistory();

  // console.log(item);
  // console.log(sponsorName);

  return (
    <Card bg={"#49007a"} 
     marginBottom={"5px"} p="14px" borderRadius={"15px"} justifyContent={"space-between"}>
      <Flex align="center" direction={"row"}>


      <Flex align="center" direction={"row"}>
     <Flex align="center" direction={"column"}>
    
      <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}
            marginBottom={"30px"}
            
            me="4px"
          >
            {formatDateTimeShort(dateStart)}</Text>

          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="12px"
            textAlign={"start"}

            me="4px"
          >{formatDateTimeShort(dateEnd)}
          </Text>
      </Flex>
        
      <Flex align="center" direction={"column"}>
      <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />
  <Box

width="3px"
 height="50px"
 backgroundColor="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />
   <Icon

as={IoCalendarOutline} 
width="20px"
 height="20px"
 color="white"
//  position={"absolute"}
//  alignSelf={"end"}
//  fontSize="50px"
 fontWeight="500"
//  borderRadius="70px"
 marginRight={"2%"}
//  mb={"23%"} 
 />

      </Flex>
      </Flex> 
     
        
       
        <Box paddingRight={"2px"} display={"flex"} flexDirection={"column"}>
          <Button
             onClick={() => {
              // console.log(banda);
              // console.log(banda);
              // console.log(banda);
              localStorage.setItem("bandaItem", JSON.stringify(item));

              history.push("/site/banda_details");
            }}
            variant={item?.eventType === "PRIVATE" ? "":"link"}
            // color="b"
            alignSelf={"start"}
           

            // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
            // maxHeight={"50%"}
            // fontWeight="500"
            fontSize="16px"
            // mb="4px"
            // fontWeight="500"
            // borderRadius="70px"
            // px="24px"
            // py="1px"
            // marginRight={"-5%"}
          > <Text
          fontWeight="500"
                      width={"160px"}

          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          color={"white"}
          textAlign={"start"}
          fontSize="14px"
          // marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}

          // me="4px"
        >{title.toUpperCase()}</Text>
            
          </Button>
           <Button

onClick={() => {
  if(!sponsorId){return}
                  localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
                
                  history.push("/site/sponsor_details", {
                    itemData: "qqsad",
                    update: true,
                  });
                
                }}
  variant={item?.eventType === "PRIVATE" ? "":"link"}
// color="b"
alignSelf={"start"}
// marginLeft={item?.eventType === "PRIVATE" ? "-4%" :"0%"}
// fontWeight="500"
// fontSize="larger"
// mb="4px"


  fontWeight="500"
  color={item?.eventType === "PRIVATE" ? "gray" :"green.300"}
  fontSize="12px"
 // me="4px"
>by { sponsorName ? sponsorName :"anonymous"}</Button>
       
<Flex align="center" direction={"row" } 
marginTop={"10px"}
marginLeft={"9%"}>
          
          <Box mt={{ base: "0px", md: "0" }} display={"flex"} flexDirection={"column"}
          justifyContent={"start"}
          alignContent={"start"}>
            <Button
              onClick={() => {
               
                // localStorage.setItem("bandaItem", JSON.stringify(item));
                // history.push("/site/e_gallery");
                
              }}
              color={"#5cba47"}
              fontSize="14px"
              variant={"link"}
              alignSelf={"start"}
              // mb="4px"
              marginLeft={"0%"}
            >123 <Icon
           as={MdVerifiedUser} 
            width="15px"
             height="15px"
             color="#5cba47"
            //  position={"absolute"}
             alignSelf={"end"}
             fontSize="12px"
             fontWeight="500"
             borderRadius="70px"
            //  marginLeft={"50%"}
             mb={"33%"} 
             /></Button>
           
            {/* <Button
             onClick={() => {
if(!sponsorId){return}
              // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
            
              // history.push("/site/sponsor_details", {
              //   itemData: "qqsad",
              //   update: true,
              // });
            
            }}
              fontWeight="500"
              alignSelf={"start"}

              color={"green.300"}
              fontSize="12px"
              me="4px"
              variant={"link"}
              // mb="4px"
              marginLeft={"-1%"}
            > Attendees</Button> */}



          </Box>

        </Flex>
        
          </Box>

       
      </Flex>
    </Card>
  );

  return (
    <>
      {!removedSuccessfully ? (
        <Card bg={bg} {...rest} p="14px" flexDirection={"row"} justifyContent={"space-between"}>
          <Flex align="center" direction={{ base: "column", md: "row" }}>
            
            <Box mt={{ base: "0px", md: "0" }} display={"flex"} flexDirection={"column"}
            justifyContent={"start"}
            alignContent={"start"}>
              <Button
                onClick={() => {
                  // console.log(banda);
                  // console.log(banda);
                  // console.log(banda);
                  localStorage.setItem("bandaItem", JSON.stringify(item));

                  history.push(`/site/banda_details?id=${item?.id}&zIx=890`);
                }}
                color={textColorPrimary}
                fontSize="larger"
                variant={"link"}
                alignSelf={"start"}
                // mb="4px"
                marginLeft={"0%"}
              >
                {title}
              </Button>
             
              <Button
               onClick={() => {
if(!sponsorId){return}
                localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
              
                history.push("/site/sponsor_details", {
                  itemData: "qqsad",
                  update: true,
                });
              
              }}
                fontWeight="500"
                alignSelf={"start"}

                color={"blue.300"}
                fontSize="sm"
                me="4px"
                variant={"link"}
                // mb="4px"
                marginLeft={"0%"}
              >
                sponsor: { sponsorName ? sponsorName :"anonymous"}
              </Button>


              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                 {formatDateTimeShort(dateStart)} to  {formatDateTimeShort(dateEnd)}
              </Text>

              {errors.responseError && (
                <Text fontWeight="500" color={"red"} fontSize="sm" me="4px">
                  Adding Event Failed
                </Text>
              )}
            </Box>
            {/* <Link
              // href={link}
              variant="no-hover"
              me="16px"
              ms="auto"
              p="0px !important"
            >
              <Button
                type="submit"
                onClick={() => {

                  handleSubmit();
                }}
                colorScheme="red"
                marginTop="0"
                style={{
                  fontSize: 12,
                  borderRadius: 10,
                  height: 30,
                  padding: 10,
                  width:80,
                  marginTop:20,
                  alignSelf: "center",
                }}
              >CANCEL</Button>
            </Link> */}
          </Flex>
          <Flex align="center" direction={{ base: "column", md: "row" }}>
          
            <Box mt={{ base: "0px", md: "0" }} display={"flex"} flexDirection={"column"}
            justifyContent={"start"}
            alignContent={"start"}>
              <Button
                onClick={() => {
                 
                  // localStorage.setItem("bandaItem", JSON.stringify(item));
                  // history.push("/site/e_gallery");
                  
                }}
                color={"green.600"}
                fontSize="larger"
                variant={"link"}
                alignSelf={"start"}
                // mb="4px"
                marginLeft={"0%"}
              >123 <Icon
             as={MdVerifiedUser} 
              width="15px"
               height="15px"
               color="green"
              //  position={"absolute"}
               alignSelf={"end"}
               fontSize="12px"
               fontWeight="500"
               borderRadius="70px"
              //  marginLeft={"50%"}
               mb={"33%"} 
               /></Button>
             
              <Button
               onClick={() => {
if(!sponsorId){return}
                // localStorage.setItem("sponsorItem", JSON.stringify({id :sponsorId}));
              
                // history.push("/site/sponsor_details", {
                //   itemData: "qqsad",
                //   update: true,
                // });
              
              }}
                fontWeight="500"
                alignSelf={"start"}

                color={"green.300"}
                fontSize="12px"
                me="4px"
                variant={"link"}
                // mb="4px"
                marginLeft={"-1%"}
              > Attendees</Button>



            </Box>

          </Flex>

        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
