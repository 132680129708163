import React, { useState } from "react";
import "./Filter.css";
import "./buttonBounce.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import Checkbox from "./Checkbox";

interface ContentFilteringProps {
  onFilterChange: (filters: ContentFilters) => void;
  headerText: string;
  theSelectedMode: (data: string) => void;
  buttonText: string;
  optionsLabels: any;
  optionsQnz: any;

}


export interface Choice {
  name: string;
  checked: boolean;
}

export interface Question {
  label: string;
  choices: Choice[];
}

export interface FilterLabel {
  mode: string;
  label: string;
}


interface ContentFilters {
  brand: string[];
  processor: string[];
  memory: string[];
  storage: string[];
  // Add more filter options as needed
}

const ReservationArea: React.FC<ContentFilteringProps> = ({
  onFilterChange,
  headerText,
  buttonText,
  optionsLabels,
  optionsQnz,
  theSelectedMode
}) => {
  // State for Content filters
  const [filters, setFilters] = useState<ContentFilters>({
    brand: [],
    processor: [],
    memory: [],
    storage: [],
    // Initialize other filters as needed
  });

  // Handler for checkbox changes
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterType: keyof ContentFilters,

  ) => {
    const { name, checked } = event.target;
    const updatedFilters = { ...filters };
    const index = updatedFilters[filterType].indexOf(name);

    if (checked && index === -1) {
      updatedFilters[filterType] = [...updatedFilters[filterType], name];
    } else if (!checked && index !== -1) {
      updatedFilters[filterType].splice(index, 1);
    }

    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
    onFilterChange(filters); 
  };

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  useEffect(() => {
    // Check the window width on mount and resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 868);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const marginTop = isSmallScreen ? "-85px" : "-20px";
  const isDarkMode = useSelector((state) => user?.darkMode);

  // CXXXXXXXXXXXXXXXXXXXXXX
  const [isMobile, setIsMobile] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 868px)");
    const handleMediaQueryChange = (e: any) => {
      setIsMobile(e.matches);
    };

    setIsMobile(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

 


  const [selectedMode, setSelectedMode] = useState<string>('REGION');
  const [questions, setQuestions] = useState(optionsQnz);
  const [filteredQuestions, setFilteredQuestions] = useState<Choice[]>([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Function to filter questions based on the selected category
  const filterQuestions = (mode: string) => {
    const filtered = questions.find((q:any) => q.label.toLowerCase() === mode.toLowerCase());
    setFilteredQuestions(filtered ? filtered.choices : []);
  };

  const handleSelected = (
    questionLabel: string,
    choiceName: string,
    checked: boolean
  ) => {
    // Create a new array of questions based on the current state
    const updatedQuestions = questions.map((question:any) => {
      if (question.label.toLowerCase() === questionLabel.toLowerCase()) {
        // If the label matches, update the choices to mark the appropriate checkbox as checked
        const updatedChoices = question.choices.map((choice:any) => {
          if (choice.name.toLowerCase() === choiceName.toLowerCase()) {
            console.log(choice.name);
            console.log(choice.name);
            
            return {
              ...choice,
              checked: checked
            };
          }
          return choice;
        });
  
        return {
          ...question,
          choices: updatedChoices,
        };
      }
      return question;
    });
    // Set the updated questions state
    setQuestions(updatedQuestions);
    };

  // Update filtered questions when selectedMode changes
  useEffect(() => {
    filterQuestions(selectedMode);
  }, [selectedMode,questions]);

  return (
    <div 
    style={{
      width: isMobile ? "100%" : "inherit",
    }}>
      <div>
        <div
          style={{
            // height: isMobile ? "50%" : 150,
            width: isMobile ? "100%" : "inherit",
            // alignSelf: "center",
            marginTop: 10, //marginTop,
            marginBottom: 20, //marginTop,
            // paddingRight: 50,
            marginRight: "1%",
            right: 0,
            // marginLeft:"2%",
            // backgroundColor: "blue",
            // position: "fixed",
            flexDirection: "row",
            zIndex: 990,
            border:"none",
            
            // borderLeft: "1px solid #dee2e6",

            //  display:"none",
          }}
        >
          <div
            style={{
              left: 20,
              marginTop: 20,
              // position: "absolute",
              width: "100%",
              right: 0,
              // backgroundColor:"pink"

              //  display:"none",
              // zIndex: 999,
            }}
          >
            <h3 
            style={{
              marginLeft: 10,
              color:"#222",
             
            }}>{headerText}</h3>
            <div
            className="filter-grid-container"
            style={{
              width: "80%",
              // backgroundColor:"red",
              marginTop: 0,
              left:30,
              // position: "absolute",
            }}
          >
            {
              //@ts-ignore
            optionsLabels.map((filter, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedMode(filter.mode);
                  theSelectedMode(filter.mode)
                }}
                style={{
                  border:   selectedMode === filter.mode ? "1px solid #d699ff " : "none",
                  padding: "10px",
                  cursor: "pointer",
                }}
              >
                <p style={{
                  color: selectedMode === filter.mode ? "#222":"#ccc",
                }}>{filter.label}</p>
              </div>
            ))}
          </div>

          </div>
         
        </div>
      </div>
    </div>
  );
};

export default ReservationArea;
