import { useState } from "react";
import {
  Tabs,
  Tab,
  Card,
  CardBody,
  RadioGroup,
  Radio,
} from "@nextui-org/react";
// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormLabel,
  Input,
  Select,
  Icon,
  Button,
} from "@chakra-ui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";

// Custom components

// Assets
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { useBandaListFilterQuery } from "generated/graphql";
import { BandaItem } from "components_app/BandaItem/BandaItem";
import MapAreas from "components/Map/mapAreas";

import List from "../registerBanda/components/List";
import districts from "assets/geo/Districts.json";
import regions from "assets/geo/Regions.json";
import wards from "assets/geo/Wards.json";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import Banner from "./components/Banner";
import BannerSide from "./components/BannerSide";
import DrawerFilter from "./components/draw";
import { FiFilter } from "react-icons/fi";
import { BgisModal } from "./components/bgisModal";

export const filtersLabels = [
  { mode: "Afya", label: "Afya" },
  { mode: "Vocational", label: "Vocational" },
  // { mode: "Recently", label: "Recently" },
  { mode: "Legal", label: "Legal" },
  { mode: "Health and Wellness", label: "Health and Wellness" },
  { mode: "Community Development", label: "Community Development" },
  { mode: "Food and Nutrition", label: "Food and Nutrition" },
  { mode: "Entertainment", label: "Entertainment" }, // music and art
  { mode: "Entrepreneurship", label: "Entrepreneurship" },
  { mode: "Sports", label: "Sports" },
  { mode: "Spiritual", label: "Spiritual" },
  { mode: "Education", label: "Education" },
  { mode: "Environment", label: "Environment" },
  { mode: "Technology and ICT", label: "Technology and ICT" },
  { mode: "Others", label: "Others" },
];
export const sampleData2 = [
  {
    id: "asb",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "CONTENT",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "ansd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "an90sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2nsdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "as2n087sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "as2n875sdd",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "CONTENT",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export interface BandaFormData {
  bandaName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  physicalResidency?: string;
  nationalID?: string;
  ownerName?: string;
  bandaPassword?: string;
  ageRestriction: string;
  umeme: boolean;
  repeatBandaPassword?: string;
  numberOfTv?: string;
  decoders?: [string];
  capacity?: string;
  tvSize?: [string];
  bandaSize?: string;
  typeOfSeats?: string;
  openingTime?: string;

  region?: string;
  district?: string;
  ward?: string;

  closingTime?: string;
  drinks?: boolean;
  food?: boolean;
  airConditioner?: boolean;
  nafasiWazi?: boolean;
  projector?: boolean;
  feni?: boolean;
  generator?: boolean;
  wifi?: boolean;
  vikalio?: boolean;
  laptop?: boolean;
}
interface FormErrors {
  [key: string]: string;
}

export default function ExploreBanda() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [errors, setErrors] = useState<FormErrors>({});

  const [formData, setFormData] = useState<BandaFormData>({
    bandaName: "",
    email: "",
    phoneNumber: "",
    address: "",
    physicalResidency: "",
    ageRestriction: "",
    umeme: false,
    nationalID: "",
    ownerName: "",
    numberOfTv: "3",
    repeatBandaPassword: "",
    region: "",
    district: "",
    ward: "",
    bandaPassword: "",
    decoders: [""],
    capacity: "",
    drinks: false,
    food: false,
    airConditioner: false,
    projector: false,
    nafasiWazi: false,
    feni: false,
    generator: false,
    wifi: false,
    vikalio: false,
    laptop: false,
    tvSize: [""],
    bandaSize: "",
    typeOfSeats: "",
    openingTime: "",
    closingTime: "",
  });

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));
  };

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [category, setCategory] = useState("all");

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const { loading, error, data, refetch } = useBandaListFilterQuery({
    fetchPolicy: "network-only",
    variables: {
      bandaListFilterInput: {
        userId: user.id,
        page,
        pageSize,
        category,
        nationalID: formData?.nationalID,
        phoneNumber: formData?.phoneNumber,
        address: formData?.address,
        physicalResidency: formData?.physicalResidency,
        bandaName: formData?.bandaName,
        bandaPassword: formData?.bandaPassword,
        email: formData?.email,
        capacity: parseInt(formData?.capacity),
        region: formData?.region,
        district: formData?.district,
        ward: formData?.ward,
        ageRestriction: formData?.ageRestriction,
        umeme: formData?.umeme,
        // tvSize: selectedTv,
        // decoders: selectedDecoders,
        tvSize: formData?.tvSize,
        decoders: formData?.decoders,
        ownerName: formData?.ownerName,
        drinks: formData?.drinks,
        food: formData?.food,
        airConditioner: formData?.airConditioner,
        nafasiWazi: formData?.nafasiWazi,
        projector: formData?.projector,
        feni: formData?.feni,
        generator: formData?.generator,
        wifi: formData?.wifi,
        vikalioKutoka: formData?.vikalio,
        laptop: formData?.laptop,
        bandaSize: formData?.bandaSize,
        typeOfSeats: formData?.typeOfSeats,
        openingTime: formData?.openingTime,
        closingTime: formData?.closingTime,
      },
    },
  });

  console.log(data);

  const [show, setShow] = useState(false);
  const [banda, setBanda] = useState({
    region: "",
    district: "",
    ward: "",
    mtaaVillage: "",
    mtaa: {
      name: "",
    },
    districtPostcode: "",
    wardPostcode: "",
  });
  const [ongezaKikundi, setOngezaKikundi] = useState(false);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };

  const UpdateSetOngezaKikundi = () => {
    setOngezaKikundi(true);
  };
  const UpdateSeMtaa = (data: BandaFormData) => {
    // @ts-ignore
    setBanda(data);
  };

  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log(banda);
  console.log(banda);
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%");

  const [selectedTv, setSelectedTv] = useState([]);
  const [selectedDecoders, setSelectedDecoders] = useState([]);
  const [selectedItemDecoder, setSelectedItemDecoder] = useState("");

  const [selectedItemTv, setSelectedItemTv] = useState("");
  const [filtering, setFiltering] = useState(false);

  const handleChangeDecoder = (e: any) => {
    setSelectedItemDecoder(e.target.value);
  };

  const handleDecoders = () => {
    if (
      selectedItemDecoder &&
      !selectedDecoders.includes(selectedItemDecoder)
    ) {
      setSelectedDecoders([...selectedDecoders, selectedItemDecoder]);
      setSelectedItemDecoder("");
    }
  };

  const removeDecoder = (decoderToRemove: string) => {
    setSelectedDecoders(
      selectedDecoders.filter((decoder) => decoder !== decoderToRemove)
    );
  };

  const handleChangeTv = (e: any) => {
    setSelectedItemTv(e.target.value);
  };

  const handleTv = () => {
    if (selectedItemTv) {
      setSelectedTv([...selectedTv, selectedItemTv]);
      setSelectedItemTv("");
    }
  };

  // const removeTv = (decoderToRemove: string) => {
  //   setSelectedTv(selectedTv.filter((decoder) => decoder !== decoderToRemove));
  // };
  const removeTv = (decoderToRemove: string) => {
    const index = selectedTv.findIndex(
      (decoder) => decoder === decoderToRemove
    );
    if (index !== -1) {
      const updatedSelectedTv = [...selectedTv];
      updatedSelectedTv.splice(index, 1);
      setSelectedTv(updatedSelectedTv);
    }
  };

  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(true);

  return (
    <Box pt={{ base: "50px", md: "80px", xl: "80px" }}>
      {/* <DrawerFilter 
      open={mobileFiltersOpen} 
      setOpen={setMobileFiltersOpen} 
    />  */}

      <Dialog open={filtering} onClose={setFiltering} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto relative w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <TransitionChild>
                  <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      onClick={() => setFiltering(false)}
                      className="z-50 relative rounded-md text-gray-300 hover:text-violet-600 focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="absolute -inset-2.5" />
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon
                        aria-hidden="true"
                        className="h-6 w-6 ml-3 mt-2 color-neutral-700 "
                      />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <DialogTitle className="text-2xl font-semibold leading-6 text-gray-900 ml-4">
                      Filter banda
                    </DialogTitle>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    {filtering && (
                      <SimpleGrid minChildWidth="250px" spacing="20px">
                        <Input
                          type="text"
                          placeholder="Banda name or code"
                          name="bandaName"
                          borderColor={"#6b00b3"}
                          _hover={{
                            borderColor: "#8a00e6",
                          }}
                          color={"#ccc"}
                          borderRadius={"5px"}
                          value={formData.bandaName}
                          onChange={handleChange}
                        />

                        <Input
                          type="number"
                          name="capacity"
                          placeholder="Minimum Capacity "
                          borderColor={"#6b00b3"}
                          _hover={{
                            borderColor: "#8a00e6",
                          }}
                          color={"white"}
                          borderRadius={"5px"}
                          value={formData.capacity}
                          onChange={handleChange}
                        />

                        <Select
                          name="region"
                          borderColor={"#6b00b3"}
                          _hover={{
                            borderColor: "#8a00e6",
                          }}
                          value={formData.region}
                          color={"#ccc"}
                          onChange={handleChange}
                          placeholder="Select region"
                        >
                          {regions.features.map((cat: any) => (
                            <option key={cat.region} value={cat.region}>
                              {cat.region}
                            </option>
                          ))}
                        </Select>

                        <Select
                          name="district"
                          borderColor={"#6b00b3"}
                          _hover={{
                            borderColor: "#8a00e6",
                          }}
                          color={"#ccc"}
                          value={formData.district}
                          onChange={handleChange}
                          placeholder="Select district"
                        >
                          {districts.features
                            .filter(
                              (cat: any) => cat.region === formData.region
                            )
                            .map((cat: any) => (
                              <option
                                key={cat.region + "_" + cat.district}
                                value={cat.district}
                              >
                                {cat.district}
                              </option>
                            ))}
                        </Select>

                        <Select
                          name="ward"
                          borderColor={"#6b00b3"}
                          _hover={{
                            borderColor: "#8a00e6",
                          }}
                          color={"#ccc"}
                          value={formData.ward}
                          onChange={handleChange}
                          placeholder="Select ward"
                        >
                          {wards.features
                            .filter(
                              (cat: any) => cat.district === formData.district
                            )
                            .map((cat: any) => (
                              <option
                                key={cat.district + "_" + cat.ward}
                                value={cat.ward}
                              >
                                {cat.ward}
                              </option>
                            ))}
                        </Select>

                        {/* <Input
              type="text"
              placeholder="Area size eg 15 * 15"
              name="bandaSize"
 borderColor={"#6b00b3"}
            _hover={{
              borderColor:"#8a00e6",

            }}              value={formData.bandaSize}
              onChange={handleChange}
            /> */}

                        <div>
                          <List
                            items={selectedDecoders}
                            onRemove={removeDecoder}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Select
                              // name="contentType"
                              // value={selectedItemDecoder}
                              // onChange={handleChangeDecoder}
                              name="decoders"
                              value={formData.decoders}
                              color={"#ccc"}
                              onChange={handleChange}
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              placeholder="Decoder"
                            >
                              {[
                                { mode: "azam", label: "Azam" },
                                { mode: "dstv", label: "Dstv" },
                                { mode: "startimes", label: "Startimes" },
                                { mode: "zuku", label: "Zuku" },
                              ].map((cat: any) => (
                                <option key={cat.mode} value={cat.mode}>
                                  {cat.label}
                                </option>
                              ))}
                            </Select>

                            {/* <Button
                type="submit"
                onClick={() => {
                  handleDecoders();
                }}
                colorScheme={"gray"}
                variant={"solid"}
                fontSize={"2xs"}
                margin={"1px"}
                padding={"10px"}
                backgroundColor={"#f1f1f1"}
                // marginTop="4"
              >
                Add decoder
              </Button> */}
                          </div>
                        </div>

                        <div>
                          {errors.ageRestriction && (
                            <Text style={{ color: "red" }}>
                              Age Restriction
                            </Text>
                          )}

                          <Select
                            name="ageRestriction"
                            borderColor={"#6b00b3"}
                            _hover={{
                              borderColor: "#8a00e6",
                            }}
                            value={formData.ageRestriction}
                            color={"#ccc"}
                            onChange={handleChange}
                            placeholder="Age restriction"
                          >
                            {[{ label: "12", value: "12" }].map((cat: any) => (
                              <option key={cat.label} value={cat.value}>
                                {cat.label}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div>
                          <List items={selectedTv} onRemove={removeTv} />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Select
                              name="tvSize"
                              value={formData.tvSize}
                              onChange={handleChange}
                              // value={selectedItemTv}
                              // onChange={handleChangeTv}
                              color={"#ccc"}
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              placeholder="Tv's size"
                            >
                              {[
                                { mode: '24"', label: '24"' },
                                { mode: '36"', label: '36"' },
                                { mode: '42"', label: '42"' },
                                { mode: '86"', label: '86"' },
                                { mode: '100+"', label: '100+"' },
                              ].map((cat: any) => (
                                <option key={cat.mode} value={cat.mode}>
                                  {cat.label}
                                </option>
                              ))}
                            </Select>

                            {/* <Button
                type="submit"
                onClick={() => {
                  handleTv();
                }}
                colorScheme={"gray"}
                variant={"solid"}
                fontSize={"2xs"}
                margin={"1px"}
                padding={"10px"}
                backgroundColor={"#f1f1f1"}
                // marginTop="4"
              >
                Add Tv
              </Button> */}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Select
                              name="typeOfSeats"
                              value={formData.typeOfSeats}
                              color={"#ccc"}
                              onChange={handleChange}
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              placeholder="Type of seats"
                            >
                              {[
                                { mode: "Benchi", label: "Benchi" },
                                { mode: "Viti", label: "Viti" },
                                {
                                  mode: "Viti na Benchi",
                                  label: "Viti na Benchi",
                                },
                                { mode: "Customizable", label: "Customizable" },
                              ].map((cat: any) => (
                                <option key={cat.mode} value={cat.mode}>
                                  {cat.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>

                        <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Drinks
                            </label>

                            <input
                              type="checkbox"
                              name="drinks"
                              checked={formData.drinks}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Food
                            </label>

                            <input
                              type="checkbox"
                              name="food"
                              checked={formData.food}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Umeme
                            </label>

                            <input
                              type="checkbox"
                              name="umeme"
                              checked={formData.umeme}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Feni
                            </label>

                            <input
                              type="checkbox"
                              name="feni"
                              checked={formData.feni}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Generator
                            </label>

                            <input
                              type="checkbox"
                              name="generator"
                              checked={formData.generator}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Wifi
                            </label>

                            <input
                              type="checkbox"
                              name="wifi"
                              checked={formData.wifi}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Laptop
                            </label>

                            <input
                              type="checkbox"
                              name="laptop"
                              checked={formData.laptop}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Vikalio kutoka
                            </label>

                            <input
                              type="checkbox"
                              name="vikalio"
                              checked={formData.vikalio}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              nafasi wazi
                            </label>

                            <input
                              type="checkbox"
                              name="nafasiWazi"
                              checked={formData.nafasiWazi}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              AC
                            </label>

                            <input
                              type="checkbox"
                              name="airConditioner"
                              checked={formData.airConditioner}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Projector
                            </label>

                            <input
                              type="checkbox"
                              name="projector"
                              checked={formData.projector}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </div>
                      </SimpleGrid>
                    )}

                    <button
                      type="button"
                      onClick={() => setFiltering(false)}
                      className="z-50 relative mt-5 rounded-md text-gray-300 bg-gray-200  px-5 py-2 hover:text-violet-600 focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="absolute -inset-2.5" />
                      <p className=" text-gray-600">Close</p>
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Main Fields */}
      <Grid
        mb={3}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "10px", xl: "10px" }}
      >
        <Banner />
        <BannerSide />
      </Grid>

      <div className="flex w-full flex-col ">
        <Tabs aria-label="Options" placement={"top"}>
          <Tab key="list" title="Bandajamii List">
            <Card>
              <CardBody>
                <Grid
                  mb="2px"
                >
                  <Card
                    className="px-12"
                    style={{
                      //  width: "100%",
                      backgroundColor: "#37005d",
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Text
                        fontSize={{ base: "14px", md: "18px" }}
                        color={"white"}
                        // mb="24px"
                        mt="12px"
                        maxW={{
                          base: "100%",
                          md: "64%",
                          lg: "46%",
                          xl: "70%",
                          "2xl": "50%",
                          "3xl": "42%",
                        }}
                        // fontWeight="700"
                        lineHeight={{ base: "16px", md: "21px" }}
                      >
                        Filtering
                      </Text>

                      {filtering ? (
                        <Button
                          bg={"inherit"}
                          marginBottom={"5px"}
                          marginRight={"-1%"}
                          _hover={{
                            bg: "#1f0033",
                            marginRight: "0%",
                          }}
                          onClick={() => {
                            setFiltering(false);
                          }}
                        >
                          <Icon
                            as={FiFilter}
                            width="20px"
                            height="20px"
                            color="white"
                          />
                        </Button>
                      ) : (
                        <Button
                          bg={"inherit"}
                          marginRight={"-1%"}
                          marginBottom={"5px"}
                          _hover={{
                            bg: "#1f0033",
                            marginRight: "0%",
                          }}
                          onClick={() => {
                            setFiltering(true);
                          }}
                        >
                          <Icon
                            as={FiFilter}
                            width="20px"
                            height="20px"
                            color="white"
                          />
                        </Button>
                      )}
                    </Box>
                  </Card>
                </Grid>

                {true && (
                  <div className="">
                    {!loading ? (
                      <>
                        <Grid
                          mb="20px"
                          gridTemplateColumns={{
                            xl: "repeat(1, 1fr)",
                            //"2xl": "1fr 0.46fr"
                          }}
                          gap={{ base: "20px", xl: "20px" }}
                          display={{ base: "block", xl: "grid" }}
                        >
                          <Flex
                            flexDirection="column"
                            gridArea={{
                              xl: "1 / 1 / 2 / 3",
                              "2xl": "1 / 1 / 2 / 2",
                            }}
                          >
                            <Flex direction="column">
                              {/* <Text
              style={{
                marginTop: 50,
              }}
               color={"#ccc"}
              fontSize="2xl"
              ms="24px"
              fontWeight="700"
            >
              BandaUmiza
            </Text> */}

                              <SimpleGrid
                                mt={"20px"}
                                columns={{ base: 1, md: 3 }}
                                gap="20px"
                              >
                                {data?.bandaListFilter?.map((banda: any) => {
                                  const existingCart = carts.find(
                                    (item: ICart) => item.id === banda.id
                                  );

                                  return (
                                    <>
                                      <BandaItem
                                        key={banda.id}
                                        banda={banda}
                                        removeCart={removeCart}
                                        selected={existingCart}
                                        ongezaKikundi={UpdateSetOngezaKikundi}
                                        setBanda={UpdateSeMtaa}
                                        handleShow={handleShow}
                                      />
                                    </>
                                  );
                                })}
                              </SimpleGrid>
                            </Flex>
                          </Flex>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center mt-20 h-screen">
                          <div
                            className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </Tab>
          <Tab key="map" title="Map View">
            <Card>
              <CardBody>
                <div className="mt-0 grid grid-cols-1 md:grid-cols-6  auto-rows gap-1 my-0">
                  <div
                    className={` bg-neutral-100 p-0 rounded-xl   md:col-span-2`}
                  >
                    <div className="flex h-full flex-col  bg-white py-0 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <p className=" text-2xl font-semibold leading-6 text-gray-900 ml-4">
                          Filter banda
                        </p>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {true && (
                          <SimpleGrid minChildWidth="150px" spacing="20px">
                            <Input
                              type="text"
                              placeholder="Banda name or code"
                              name="bandaName"
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              color={"#ccc"}
                              borderRadius={"5px"}
                              value={formData.bandaName}
                              onChange={handleChange}
                            />

                            <Input
                              type="number"
                              name="capacity"
                              placeholder="Minimum Capacity "
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              color={"white"}
                              borderRadius={"5px"}
                              value={formData.capacity}
                              onChange={handleChange}
                            />

                            <Select
                              name="region"
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              value={formData.region}
                              color={"#ccc"}
                              onChange={handleChange}
                              placeholder="Select region"
                            >
                              {regions.features.map((cat: any) => (
                                <option key={cat.region} value={cat.region}>
                                  {cat.region}
                                </option>
                              ))}
                            </Select>

                            <Select
                              name="district"
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              color={"#ccc"}
                              value={formData.district}
                              onChange={handleChange}
                              placeholder="Select district"
                            >
                              {districts.features
                                .filter(
                                  (cat: any) => cat.region === formData.region
                                )
                                .map((cat: any) => (
                                  <option
                                    key={cat.region + "_" + cat.district}
                                    value={cat.district}
                                  >
                                    {cat.district}
                                  </option>
                                ))}
                            </Select>

                            <Select
                              name="ward"
                              borderColor={"#6b00b3"}
                              _hover={{
                                borderColor: "#8a00e6",
                              }}
                              color={"#ccc"}
                              value={formData.ward}
                              onChange={handleChange}
                              placeholder="Select ward"
                            >
                              {wards.features
                                .filter(
                                  (cat: any) =>
                                    cat.district === formData.district
                                )
                                .map((cat: any) => (
                                  <option
                                    key={cat.district + "_" + cat.ward}
                                    value={cat.ward}
                                  >
                                    {cat.ward}
                                  </option>
                                ))}
                            </Select>

                            {/* <Input
              type="text"
              placeholder="Area size eg 15 * 15"
              name="bandaSize"
 borderColor={"#6b00b3"}
            _hover={{
              borderColor:"#8a00e6",

            }}              value={formData.bandaSize}
              onChange={handleChange}
            /> */}

                            <div>
                              <List
                                items={selectedDecoders}
                                onRemove={removeDecoder}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  // name="contentType"
                                  // value={selectedItemDecoder}
                                  // onChange={handleChangeDecoder}
                                  name="decoders"
                                  value={formData.decoders}
                                  color={"#ccc"}
                                  onChange={handleChange}
                                  borderColor={"#6b00b3"}
                                  _hover={{
                                    borderColor: "#8a00e6",
                                  }}
                                  placeholder="Decoder"
                                >
                                  {[
                                    { mode: "azam", label: "Azam" },
                                    { mode: "dstv", label: "Dstv" },
                                    { mode: "startimes", label: "Startimes" },
                                    { mode: "zuku", label: "Zuku" },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>

                                {/* <Button
                type="submit"
                onClick={() => {
                  handleDecoders();
                }}
                colorScheme={"gray"}
                variant={"solid"}
                fontSize={"2xs"}
                margin={"1px"}
                padding={"10px"}
                backgroundColor={"#f1f1f1"}
                // marginTop="4"
              >
                Add decoder
              </Button> */}
                              </div>
                            </div>

                            <div>
                              {errors.ageRestriction && (
                                <Text style={{ color: "red" }}>
                                  Age Restriction
                                </Text>
                              )}

                              <Select
                                name="ageRestriction"
                                borderColor={"#6b00b3"}
                                _hover={{
                                  borderColor: "#8a00e6",
                                }}
                                value={formData.ageRestriction}
                                color={"#ccc"}
                                onChange={handleChange}
                                placeholder="Age restriction"
                              >
                                {[{ label: "12", value: "12" }].map(
                                  (cat: any) => (
                                    <option key={cat.label} value={cat.value}>
                                      {cat.label}
                                    </option>
                                  )
                                )}
                              </Select>
                            </div>

                            <div>
                              <List items={selectedTv} onRemove={removeTv} />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  name="tvSize"
                                  value={formData.tvSize}
                                  onChange={handleChange}
                                  // value={selectedItemTv}
                                  // onChange={handleChangeTv}
                                  color={"#ccc"}
                                  borderColor={"#6b00b3"}
                                  _hover={{
                                    borderColor: "#8a00e6",
                                  }}
                                  placeholder="Tv's size"
                                >
                                  {[
                                    { mode: '24"', label: '24"' },
                                    { mode: '36"', label: '36"' },
                                    { mode: '42"', label: '42"' },
                                    { mode: '86"', label: '86"' },
                                    { mode: '100+"', label: '100+"' },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>

                                {/* <Button
                type="submit"
                onClick={() => {
                  handleTv();
                }}
                colorScheme={"gray"}
                variant={"solid"}
                fontSize={"2xs"}
                margin={"1px"}
                padding={"10px"}
                backgroundColor={"#f1f1f1"}
                // marginTop="4"
              >
                Add Tv
              </Button> */}
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Select
                                  name="typeOfSeats"
                                  value={formData.typeOfSeats}
                                  color={"#ccc"}
                                  onChange={handleChange}
                                  borderColor={"#6b00b3"}
                                  _hover={{
                                    borderColor: "#8a00e6",
                                  }}
                                  placeholder="Type of seats"
                                >
                                  {[
                                    { mode: "Benchi", label: "Benchi" },
                                    { mode: "Viti", label: "Viti" },
                                    {
                                      mode: "Viti na Benchi",
                                      label: "Viti na Benchi",
                                    },
                                    {
                                      mode: "Customizable",
                                      label: "Customizable",
                                    },
                                  ].map((cat: any) => (
                                    <option key={cat.mode} value={cat.mode}>
                                      {cat.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Drinks
                                </label>

                                <input
                                  type="checkbox"
                                  name="drinks"
                                  checked={formData.drinks}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Food
                                </label>

                                <input
                                  type="checkbox"
                                  name="food"
                                  checked={formData.food}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Umeme
                                </label>

                                <input
                                  type="checkbox"
                                  name="umeme"
                                  checked={formData.umeme}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Feni
                                </label>

                                <input
                                  type="checkbox"
                                  name="feni"
                                  checked={formData.feni}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Generator
                                </label>

                                <input
                                  type="checkbox"
                                  name="generator"
                                  checked={formData.generator}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Wifi
                                </label>

                                <input
                                  type="checkbox"
                                  name="wifi"
                                  checked={formData.wifi}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Laptop
                                </label>

                                <input
                                  type="checkbox"
                                  name="laptop"
                                  checked={formData.laptop}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Vikalio kutoka
                                </label>

                                <input
                                  type="checkbox"
                                  name="vikalio"
                                  checked={formData.vikalio}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  nafasi wazi
                                </label>

                                <input
                                  type="checkbox"
                                  name="nafasiWazi"
                                  checked={formData.nafasiWazi}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  AC
                                </label>

                                <input
                                  type="checkbox"
                                  name="airConditioner"
                                  checked={formData.airConditioner}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>

                              <div className="flex items-center mb-4">
                                <label className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                  Projector
                                </label>

                                <input
                                  type="checkbox"
                                  name="projector"
                                  checked={formData.projector}
                                  onChange={handleChange}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </div>
                          </SimpleGrid>
                        )}

                        {/* <button
                      type="button"
                      onClick={() => setFiltering(false)}
                      className="z-50 relative mt-5 rounded-md text-gray-300 bg-gray-200  px-5 py-2 hover:text-violet-600 focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="absolute -inset-2.5" />
                      <p className=" text-gray-600">Close</p>
                    </button> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className={` bg-neutral-600 p-1 rounded-xl   md:col-span-4`}
                  >
                    <MapAreas />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Tab>
        </Tabs>
      </div>

      {false && (
        <div className="">
          {!loading ? (
            <>
              <Grid
                mb="20px"
                gridTemplateColumns={{
                  xl: "repeat(1, 1fr)",
                  //"2xl": "1fr 0.46fr"
                }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}
              >
                <Flex
                  flexDirection="column"
                  gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
                >
                  <Flex direction="column">
                    {/* <Text
              style={{
                marginTop: 50,
              }}
               color={"#ccc"}
              fontSize="2xl"
              ms="24px"
              fontWeight="700"
            >
              BandaUmiza
            </Text> */}

                    <SimpleGrid
                      mt={"20px"}
                      columns={{ base: 1, md: 3 }}
                      gap="20px"
                    >
                      {data?.bandaListFilter?.map((banda: any) => {
                        const existingCart = carts.find(
                          (item: ICart) => item.id === banda.id
                        );

                        return (
                          <>
                            <BandaItem
                              key={banda.id}
                              banda={banda}
                              removeCart={removeCart}
                              selected={existingCart}
                              ongezaKikundi={UpdateSetOngezaKikundi}
                              setBanda={UpdateSeMtaa}
                              handleShow={handleShow}
                            />
                          </>
                        );
                      })}
                    </SimpleGrid>
                  </Flex>
                </Flex>
              </Grid>
            </>
          ) : (
            <>
              <div className="flex justify-center mt-20 h-screen">
                <div
                  className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <BgisModal
        setOpen={setOngezaKikundi}
        open={ongezaKikundi}
        address={{
          region: banda?.mtaa?.name,
          district: banda?.district,
          ward: banda?.ward,
          mtaaVillage: banda?.mtaaVillage,
          districtPostcode: banda?.mtaaVillage,
          wardPostcode: banda?.mtaaVillage,
        }}
      />
    </Box>
  );
}
