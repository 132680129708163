import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import "./Cart.css";
import "./login_button.css";
import "./login_inputs.css";
import { addCart, updateCart } from "../../redux.store/cart/actionCreators";
// import { navigateThePage } from "../../redux.store/navigation/actionCreators";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  calculateHourDifference,
  formatDateTimeShort,
  sumAndWeightPrices,
} from "views/site/helpers";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { updatePromo } from "redux.store/promo/actionCreators";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
};

export const PayCart: React.FC<Props> = ({ cart, selected, removeCart }) => {
  const history = useHistory();

  // const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
 
  const storedContentItem = JSON.parse(localStorage.getItem("contentItem"));
  const reservationDataContent = JSON.parse(
    localStorage.getItem("reservationDataContent")
  );

  console.log("###################");
  console.log(cart);
  console.log("###################");
  
  
  const updateCartState = React.useCallback(
    (cart: ICart) => dispatch(updateCart(cart)),
    [dispatch]
  );
  
  const updatePromoState = React.useCallback(
    (promo: ICart) => dispatch(updatePromo(promo)),
    [dispatch]
  );


  const saveToTheCart = React.useCallback(
    (cart: ICart) => dispatch(addCart(cart)),
    [dispatch]
  );
  const getTypeColor = (type: any) => {
    switch (type) {
      case "busket":
        return "#ffc2d1";
      case "district":
        return "#b2d6ff";
      case "region":
        return "#7282c8";
      case "ward":
        return "#7fdeff";
      case "banda":
        return "#fde12d";

      default:
        return "#f5f5f5"; // banda is undefined
    }
  };
  // console.log("cart");
  // console.log("cart");
  // console.log(cart);
  // console.log("cart");
  // console.log("cart");

  const [expandBanda, setExpandBanda] = React.useState("");
  const [firstBusketCount, setFirstBusketCount] = React.useState(0);

  React.useEffect(() => {
    // Assuming cart changes over time, update the firstBusketCount whenever cart changes
    const firstBusketItem = cart?.bandaumizaList?.find(
      (item: any) => item.type === "busket"
    );
    const newFirstBusketCount = firstBusketItem?.price;
    setFirstBusketCount(newFirstBusketCount);
  }, [cart]);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleButtonClick = () => {
    // Toggle the state variable to expand/close
    setIsExpanded((prev) => !prev);
  };

  const bandaumizaCount: number = cart?.bandaumizaList?.filter(
    (item: any) => item.type === "banda"
  ).length;

  const areaCount: number = cart?.bandaumizaList?.filter(
    (item: any) =>
      item.type === "region" || item.type === "district" || item.type === "ward" //|| item.type !== "busket" //|| item.type !== "busket"
  ).length;

  

  //@ts-ignore
  const totalPriceInList: number = cart?.bandaumizaList?.reduce((acc, item) => {
    let cost = 0;
    let cost2 = 0;
    if (item.type === "banda") {
      cost =  acc +   sumAndWeightPrices(item.dates, item.price) +
       (item.isPromoted ? (3000 * JSON.parse(item.dates).length) : 0)
    } else {
      console.log("item.frequency");
      console.log(item.frequency);
      console.log("item.frequency");
      
      cost =  acc + ((parseInt(item.frequency) * parseInt(item.price)) +  (item?.isPromoted ? 3000 * parseInt(item.frequency) : 0));
          cost2 = cost
    }
    return cost   ;
  }, 0);

  const totalPriceInListAdd: number = cart?.bandaumizaList?.reduce(
    (acc: number, item: any) => {
      return (
        acc +
        ((item.type === "banda" ? 1 : item.frequency) * item.price)
      );
    },
    0
  );

  // console.log(cart);

  return (
    <Card
      // className="cart"
      // bg={"red"}
      width={"96%"}
      margin={"0px"}
      mb={"30px"}
      padding={"0px"}
      style={{
        marginTop: 0,
        // marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        marginLeft:"2%",
        marginRight:"2%",
        // borderRadius: 20,
        backgroundColor: cart.isPromo ? "#f5f3f4" : "#e9ecef",
        // borderColor: cart.type ==="ADD" ? "#660708" : "transparent",
        // borderWidth: 2, // You can adjust the border width as needed
        // borderStyle: "solid",
      }}
    >

<div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6"
style={{
  display:"flex",
  flexDirection:"row",
}}>
            <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
            <a className="shrink-0 "
            style={{marginLeft:0,
              padding:0,
            }}>
  <img 
    className=" h-30 w-20  border-2 border-gray-100" 
    src={cart?.coverImage} 
    alt="Content image" 
    style={{
      objectFit:"cover",
      borderRadius:5,
    }}
  />
</a>

              {/* <label htmlFor="counter-input" className="sr-only">Choose quantity:</label> */}
              {/* <div className="flex items-center justify-between md:order-3 md:justify-end">
                <div className="flex items-center">
                  <button type="button" id="decrement-button-2" data-input-counter-decrement="counter-input-2" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                    <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                    </svg>
                  </button>
                  <input type="text" id="counter-input-2" data-input-counter className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white" placeholder="" value="1" required />
                  <button type="button" id="increment-button-2" data-input-counter-increment="counter-input-2" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                    <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                    </svg>
                  </button>
                </div>
                <div className="text-end md:order-4 md:w-32">
                  <p className="text-base font-bold text-gray-900 dark:text-white">$598</p>
                </div>
              </div> */}

              <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                <a className="text-base font-medium text-gray-900 hover:underline " 
                style={{
                  textAlign:"start",
                  justifyContent:"center",
                  marginLeft:-15,
                }}>{cart?.isPromo ? cart?.body+" on " :""} {cart?.title}
                </a>

                <div
          style={{
            // paddingTop: 20,
            // marginBottom: 5,
            // height: 200,
            width: "100%",
            marginLeft: "3%",
            // left: 10,
            // paddingLeft: 20,
            // padding: 10,
            // top: 0,
            display: "flex",
            flexDirection: "column",
            // backgroundColor:"pink",
          }}
        >
        
         
          <p className="p_style">
            {cart.bandaumizaList ? (
              <div>
                <p
                  style={{
                    marginTop: 0,
                    padding: 0,
                    // fontWeight: "800",
                    fontSize: 12,
                    color: "gray",
                  }}
                >
                  {" "}
                  {areaCount} Areas specified
                </p>
                <p
                  style={{
                    marginTop: 2,
                    marginBottom: 2,
                    padding: 0,
                    fontSize: 12,
                    color: "gray",
                    // fontWeight: "800",
                  }}
                >
                  {" "}
                  {bandaumizaCount} Banda specified
                </p>

                {/* <p
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: -5,
                    marginBottom: 5,
                  }}
                >Duration {cart.duration * 60} mins
                </p> */}

                {firstBusketCount > 0 && (
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      marginTop: 5,
                      marginBottom: 5,
                      // fontWeight: "800",
                      color: "gray",
                      fontSize: 16,
                    }}
                  >
                    {" "}
                    {firstBusketCount}{" "}
                    {cart.type === "ADD" ? "Unspecified Promo" : "Busket Fund"}{" "}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
          </p>
          {!cart.isPromo && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                // marginBottom: 10,
                // padding: 8,
                // backgroundColor: "#f5ecfe",
                // borderRadius: 4,
              }}
            >
             
                <p style={{ margin: 0, color: "gray", fontSize:12, fontWeight: "bold" }}>
                  Content Price: {cart.price} {cart.currency}{" "}
                </p>
              
            </div>
          )}

          {cart.bandaumizaList ? (
            <p
              className="p_style"
              style={{
                margin: 0,
                padding: 0,
                marginTop: 5,
                color: "green",
                textAlign:"right",
                fontSize: 14,
                // fontWeight: 800,
              }}
            >{cart.isPromo
                ? totalPriceInListAdd
                : ((totalPriceInList) + parseInt(cart.contentCost))}{" "}
              {cart.currency}
            </p>
          ) : (
            <Box bg={"pink"}>
              <p
                className="p_style"
                style={{
                  margin: 0,
                  padding: 0,
                  marginTop: -2,
                  color: "green",
                  // fontWeight:"800",
                }}
              >
                {cart.price}
              </p>
            </Box>
          )}

          <p className="p_style">{cart.capacity}</p>
          <p className="p_style">{cart.date}</p>
        </div>

                <div className="flex items-center gap-4">
                  <button 
                  onClick={() => {
                    //conditional when dealing with promo
                   
                    if (cart.isPromo){
                      updatePromoState({
                        ...cart,
                        activeCart:false,
                      })
                   }else{
        
                    updateCartState({
                      ...cart,
                      activeCart:false,
                    })
                  }
                  }}  type="button" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 hover:underline ">
                    <svg className="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z" />
                    </svg>
                    Later</button>

                  <button  onClick={() => {
            //conditional when dealing with promo
            if (cart.isPromo) {
              localStorage.setItem("cartAdd", JSON.stringify(cart));

        
              history.push(`/site/promo-reserve?id=${cart?.id}&update=${true}`);

            } else {
              //for reservationdata
              localStorage.setItem(
                "reservationDataContent",
                JSON.stringify({ reservationData: cart, update: true })
              );
              //for handling content Id
              localStorage.setItem(
                "contentItem",
                JSON.stringify({ content: cart, update: true })
              );

              history.push(`/site/reserve_order?id=${cart?.id}&update=${true}`);

             
            }
          }} type="button" className="inline-flex items-center text-sm font-medium text-gray-400 hover:underline ">
                   <svg className="mr-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.862 3.487l-9.9 9.9c-.215.215-.378.48-.471.768l-1.432 4.297c-.124.372.235.73.607.607l4.297-1.432c.288-.093.553-.256.768-.471l9.9-9.9a2.125 2.125 0 1 0-3-3z" />
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.56 8.62l-3-3" />
</svg>

                    Edit
                  </button>


                  <button           onClick={handleButtonClick}
type="button" className="inline-flex items-center text-sm font-medium text-green-600 hover:underline ">
                    <svg className="ml-2 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 9l6 6 6-6" />
        </svg>
                    expand
                  </button>
                </div>
              </div>
            </div>
          </div>

      <div
        style={{
          marginTop: 0,
          // marginBottom: -10,
          alignSelf: "center",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        

       
      </div>

    
      
      {isExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor:"#e6f2ff",
            marginTop: 5,
            // border: '1px solid #ccc',
            borderRadius: 8,
            width: "100%",
            marginBottom: 20,
            padding: 10,
          }}
        >
        
          {cart?.bandaumizaList?.map((cartItem: any) => (
            <Flex
              key={cartItem.id}
              flexDirection={"row"}
              style={{
                // display: "flex",
                width: "100%",
                minHeight: 40,
                marginTop: 2,
                // justifyContent: "space-between",
                // alignItems: "center",
                marginBottom: 2,
                // padding: 8,
                // backgroundColor: "orange", // getTypeColor(cartItem.type),
                borderBottomLeftRadius: 15,
                borderBottomWidth: 1,
                borderBottomColor: getTypeColor(cartItem.type),
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5%",
                  minHeight: 40,
                  // justifyContent: "space-between",
                  // alignItems: "center",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  marginRight: "3%",
                  // marginBottom: 10,
                  // paddingRight: 80,
                  backgroundColor: getTypeColor(cartItem.type),
                  // borderRadius: 4,
                }}
              />
              <div
                style={{
                  display: "flex",
                  width: "95%",
                  minHeight: 60,
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  // marginBottom: 10,
                  // padding: 8,
                  // backgroundColor: "brown", // getTypeColor(cartItem.type),
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    // minHeight: 60,
                    // justifyContent: "space-between",
                    alignItems: "end",
                    // marginBottom: 10,
                    // padding: 8,
                    // backgroundColor: "black", // getTypeColor(cartItem.type),
                    // borderRadius: 4,
                  }}
                >
                  {cartItem.type === "busket" ? (
                    <p
                      style={{
                        margin: 0,
                        color: "#240046",
                        fontSize:12,

                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Content Busket contribution {/*cartItem.price*/}
                    </p>
                  ) : (
                    <Flex flexDirection={"column"} width={"100%"}>
                      {cartItem.type === "banda" ? (
                        <Flex
                          flexDirection={"column"}
                          // bg={"gray"}
                          width={"90%"}
                        >
                          {" "}
                          {cart.isPromo ? <p
                            style={{
                              margin: 0,
                              color: "#240046",
                              fontSize:12,


                              fontWeight: "bold",
                            }}
                          >
                            {/* {cartItem.type ? cartItem.type?.toUpperCase() : ""}{" "} */}
                            {cartItem.name} {" for "}{cartItem.price}{" "}
                            {/*cartItem.price*/}
                          </p>:<p
                            style={{
                              margin: 0,
                              color: "#240046",
                              fontSize:12,

                              fontWeight: "bold",
                            }}
                          >
                            {/* {cartItem.type ? cartItem.type?.toUpperCase() : ""}{" "} */}
                            {cartItem.name} :{" "}
                            {JSON.parse(cartItem.dates).length} reservations @ {cartItem.price}{" "}
                            pHr
                            {/*cartItem.price*/}
                          </p>}
                          {expandBanda === cartItem.id && (
                            <Flex
                              // bg={"red"}
                              width={"100%"}
                              flexDirection={"column"}
                            >
                              {JSON.parse(cartItem.dates).map(
                                (reservations: any) => {
                                  return (
                                    <Box
                                      height={"30px"}
                                      ml={"10%"}
                                      mt={"5px"}
                                      mb={"5px"}
                                      width={"100%"}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "black",
                                          fontSize:12,

                                          fontWeight: "bold",
                                        }}
                                      >
                                        {calculateHourDifference(
                                          reservations.end,
                                          reservations.start
                                        )}{" "}
                                        hrs on{" "}
                                        {formatDateTimeShort(reservations.end)}
                                      </p>
                                    </Box>
                                  );
                                }
                              )}
                            </Flex>
                          )}
                        </Flex>
                      ) : (
                        <p
                          style={{
                            margin: 0,
                            color: "#240046",
                            fontSize:12,

                            fontWeight: "bold",
                          }}
                        >
                          {/* {cartItem.type ? cartItem.type?.toUpperCase() : ""}{" "} */}
                          {cartItem.name} : {cartItem.frequency} Banda @{" "}
                          {cartItem.price}
                        </p>
                      )}
                    </Flex>
                  )}
                  {cartItem.name !== "busket" && cartItem.isPromoted && (
                    <Flex flexDirection={"column"}  width={"50%"}>
                      <p
                        style={{
                          margin: 0,
                          color: "#240046",
                          marginLeft: 10,
                          marginRight: 5,
                          textAlign: "start",
                          fontSize:12,

                          fontWeight: "bold",
                        }}
                      >
                        promo:{" "}
                        {cartItem.type === "banda"
                          ? JSON.parse(cartItem.dates).length
                          : cartItem.frequency}{" "}
                        @ {3000}
                      </p>
                    </Flex>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "#240046",
                    height: "100%",
                    width: "30%",
                    alignContent: "space-between",
                    justifyContent: "space-between",
                  }}
                >
                 
                 {cartItem.type === "banda" &&  JSON.parse(cartItem.dates).length > 0 && <Button
                 width={"20px"}
                 alignSelf={"end"}

                    onClick={() => {
                      if (expandBanda !== cartItem.id) {
                        setExpandBanda(cartItem.id);
                      } else {
                        setExpandBanda("");
                      }
                    }}
                    mb={"15px"}
                    mr={"10px"}
                  >
                    {expandBanda !== cartItem.id ? <Icon as={IoIosArrowDown} width="15px" height="15px" color="inherit" /> : <Icon as={IoIosArrowUp} width="15px" height="15px" color="inherit" />}
                  </Button>}
                  <Box width={"10px"}
                  height={"20px"}/>

                  <>
                    {cartItem.type === "banda" ? (
                      <>
                      { cart.isPromo  ? <p
                        style={{
                          margin: 0,
                          color: "#240046",
                          textAlign: "end",

                          fontWeight: "bold",
                        }}
                      >  {cartItem.price} </p>:
                      <p
                      style={{
                        margin: 0,
                        color: "#240046",
                        textAlign: "end",
                        fontSize:12,

                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {sumAndWeightPrices(cartItem.dates, cartItem.price)}
                    </p>}
                      </>
                    ) : (
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf:"end",
                      }}
                    >  <p
                        style={{
                          margin: 0,
                          color: "#240046",
                          textAlign: "end",
                          alignSelf:"end",
                          fontSize:12,

                          fontWeight: "bold",
                        }}
                      >
                        {(cartItem.type === "banda" ? 1 : cartItem.frequency ) * cartItem.price}
                      </p></div>
                    )}

                    {cartItem.isPromoted && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignSelf:"end",
                        }}
                      >
                        {cart.isPromo ? <strong
                          style={{ margin: 0, color: "#240046", fontSize: 12 }}
                        ></strong>:
                        <strong
                        style={{ margin: 0, color: "#240046", fontSize: 12 }}
                      >
                        +{" "}
                        {3000 *
                          (cartItem.type === "banda"
                            ? JSON.parse(cartItem.dates).length
                            : cartItem.frequency)}
                      </strong>}
                        {/* <p style={{ margin: 0, fontWeight: "bold" }}>+ 3000</p> */}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </Flex>
          ))}

          {/* {cart.bandaumizaList ? (
            <p
              className="p_style"
              style={{
                margin: 0,
                padding: 0,
                marginTop: 20,
                color: "green",
                marginRight: "5%",
                textAlign: "end",
                fontSize: 32,
                fontWeight: 800,
              }}
            >
              {cart.isPromo
                ? totalPriceInListAdd 
                : totalPriceInList + parseInt(cart.price)}{" "}
              {cart.currency}
            </p>
          ) : (
            <p
              className="p_style"
              style={{
                margin: 0,
                padding: 0,
                marginTop: -5,
                color: "green",
                // fontWeight:"800",
              }}
            >
              {cart.price}
            </p>
          )} */}
        </div>
      )}
    </Card>
  );
};
