// Chakra imports
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/site/profileBanda/components/Banner";
import Upload from "views/site/profileBanda/components/Upload";

// Assets
import avatar from "assets/images/imageHolder.png";
import { useSelector } from "react-redux";
import Card from "components/card/Card";
import { useEffect, useRef, useState } from "react";
import {
  Event,
  useCreateEventMutation,
  useGetBandaQuery,
  useMyBandaQuery,
  useUpdateBandaWorkingHoursMutation,
} from "generated/graphql";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AddEventsSearch } from "./components/AddEventsSearch";
import { Projects } from "./components/Projects";

export interface FormData {
  title: string;
  description: string;
  eventType: string;
  closingTime?: string;
  openingTime?: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function ProfileBanda() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  // console.log(user);
  // console.log(user);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const contentData = useMyBandaQuery({
    fetchPolicy: "network-only",
    variables: {
      getMyBandaInput: {
        bandaId: user.id,
      },
    },
  });

  console.log(contentData?.data?.myBanda);

  useEffect(() => {
    contentData.refetch();
  }, [refreshSelected]);

  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "description is required",
      }));
    } else if (!textRegex.test(description)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Wrong Residency",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "",
      }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    eventType: "",
    closingTime: "",
    openingTime: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "title":
        validateTitle(value);
        break;
      case "description":
        validateDescription(value);
        break;
      default:
        break;
    }
  };

  const addedItemMiddle = (data: any) => {
    console.log(data);
    setRefreshSelected(!refreshSelected);
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const [openCalendar, setOpenCalendar] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const calendarRefBandaProfile = useRef(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [updatedNow, setUpdatedNow] = useState(false);

  const calendarRef = useRef(null);
  // const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    if (openCalendar && contentData?.data?.myBanda) {
      if (contentData?.data?.myBanda?.eventsFunds?.length > 0) {
        const allScheduledData = contentData?.data?.myBanda?.eventsFunds?.map(
          (item: Event) => {
            //  console.log("Events :", item);
            return {
              title: item.title,
              start: item.startDateTime,
              end: item.endDateTime,
              color:
                item.eventType === "FUNDED"
                  ? "#2d6a4f"
                  : item.eventType === "PRIVATE"
                  ? "#468faf"
                  : "",
            };
          }
        );

        const initialEventsDB = allScheduledData?.filter(
          (item) => item !== undefined
        );

        setCalendarEvents([...initialEventsDB]);
      }
    }
  }, [openCalendar]);

  const [selectedDateData, setSelectedDateData] = useState<{
    start: string | null;
    end: string | null;
  }>({ start: null, end: null });
  const [selectedDate, setSelectedDate] = useState([]);

  const handleSelect = (arg: any) => {
    console.log(arg);
    // console.log(calendarEvents);

    if (
      true
      // window.confirm(
      //   `Do you want to add an event at ${arg.startStr} to ${arg.endStr}?`
      // )
    ) {
      const startISO = new Date(arg.startStr).toISOString();
      const endISO = new Date(arg.endStr).toISOString();

      setSelectedDateData({ start: startISO, end: endISO });

      setSelectedDate([
        {
          title: "",
          start: arg.startStr,
          end: arg.endStr,
          id: "",
        },
      ]);
    }
    calendarRefBandaProfile.current.getApi().unselect(); // Clears selection after adding event
  };

  const [createEvent] = useCreateEventMutation();
  const [addWorkingHoursApiService] = useUpdateBandaWorkingHoursMutation();

  const handleSubmit = async () => {
    // console.log(formData);
    validateTitle(formData.title);
    validateDescription(formData.description);
    //  return

    if (
      errors.title ||
      errors.description ||
      selectedDateData.end.trim() === "" ||
      selectedDateData.start.trim() === ""
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Put Proper Details Please",
      }));
      return;
    }
    if (formData.title.trim() === "" || formData.description.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Can't add empty fields",
      }));
      return;
    }

    let uploadingData = {
      // userId: user.id,

      title: formData.title,
      contentId: formData.title,
      description: formData.description,
      eventType: "PRIVATE",
      published: true,
      bandaId: user.id,
      startDateTime: selectedDateData.start,
      endDateTime: selectedDateData.end,
    };

    try {
      const response = await createEvent({
        variables: {
          createEventInput: uploadingData,
        },
      });

      if (
        response.data &&
        response.data.createEvent &&
        !response.data.createEvent.status
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Registration failed",
        }));
        return;
      } else {
        console.log(response.data.createEvent);
        setUploadedSuccess(true);
        setFormData({
          title: "",
          description: "",
          eventType: "",
        });
        setCalendarEvents([]);
        setSelectedDate([]);
        setSelectedDateData({ start: "", end: "" });
        setOpenCalendar(false);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      setRefreshSelected(!refreshSelected);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const addWorkingHours = async () => {
    // console.log(formData);
    // console.log(formData.closingTime.length);
    // console.log(formData.openingTime.length);

    //return
    if (
      formData.closingTime.trim() === "" ||
      formData.openingTime.trim() === ""
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Put Proper Details Please",
      }));
      return;
    }

    let uploadingData = {
      bandaId: user?.id,
      startDateTime: formData?.openingTime.toString,
      endDateTime: formData?.closingTime.toString,
    };

    try {
      const response = await addWorkingHoursApiService({
        variables: {
          bandaWorkingHoursInput: uploadingData,
        },
      });

      if (
        response.data &&
        response.data.updateBandaWorkingHours &&
        !response.data.updateBandaWorkingHours.log
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Update failed",
        }));
        return;
      } else {
        console.log(response.data.updateBandaWorkingHours);
        setUploadedSuccess(true);
        setFormData({
          title: "",
          description: "",
          eventType: "",
          closingTime: "",
          openingTime: "",
        });
        setUpdatedNow(true);
        setOpenCalendar(false);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: " failed",
      }));
    }
  };
  const [oneLeft, setOneLeft] = useState(false);

  const handleEventClick = (arg: any) => {
    console.log("CLICKED");
    console.log("CLICKED");
    console.log("CLICKED");
    console.log(selectedDate);
    // Get the ID of the clicked event arg.startStr+arg.endStr
    const exists = selectedDate.some(
      (event) => event?.id === arg?.event?.startStr + arg?.event?.endStr
      // event?.startStr === arg?.event?.startStr && event?.endStr === arg?.event?.endStr
    );

    // const bandaInList = reservationData?.bandaumizaList?.find(
    //   (bandaUmiza: IBanda) => bandaUmiza.id === banda.id
    // );

    if (exists) {
      const filterNow = selectedDate.filter(
        (event) => event?.id !== arg?.event?.startStr + arg?.event?.endStr // && event?.endStr !== arg?.event?.endStr
        // event?.startStr !== arg?.event?.startStr && event?.endStr !== arg?.event?.endStr
      );
      setSelectedDate(filterNow);

      if (filterNow.length === 1) {
        setOneLeft(true);
      } else if (filterNow.length > 1) {
        setOneLeft(false);
      }

      // updateDateReservations(
      //   JSON.stringify(filterNow),
      //   bandaInList
      // );
      return;
    }

    // Clears selection after adding event
    // calendarRef.current.getApi().unselect();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        {/* <Grid templateColumns="repeat(5, 1fr)" gap={4}> */}
        <Banner
          gridArea="1 / 1 / 2 / 1"
          banner={"banner"}
          refresh={addedItemMiddle}
          avatar={contentData?.data?.myBanda?.coverImage}
          name={contentData?.data?.myBanda?.bandaName}
          job={contentData?.data?.myBanda?.bandaCode}
          posts={
            contentData?.data?.myBanda?.eventsFunds
              ? contentData?.data?.myBanda?.eventsFunds?.length
              : "0"
          }
          followers={
            contentData?.data?.myBanda?.addsPromo
              ? contentData?.data?.myBanda?.addsPromo?.length
              : "0"
          }
          following="0"
        />
        <Card
          mb={{ base: "0px", "2xl": "20px" }}
          style={{
            //  height: 300,
            marginBottom: 20,
          }}
        >
          {/* <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Add private time
          </Text> */}
          {/* <Text
                color={textColorSecondary}
                fontSize="md"
                me="26px"
                mb="40px"
              >
                Add time when your banda is out of service{" "}
              </Text> */}

          {!openCalendar && (
            <>
              <Button
                onClick={() => {
                  setOpenCalendar(true);
                }}
                colorScheme="gray"
                marginTop="0"
                variant={"outline"}
                style={{
                  width: "50%",
                  height: 50,
                  padding: 10,
                  alignSelf: "center",
                }}
              >
                Add Private Time
              </Button>
            </>
          )}

          {openCalendar && (
            <Card>
              <Card>
                <div>
                  {errors.title && (
                    <Text style={{ color: "red" }}>Wrong Format of Title</Text>
                  )}

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Title
                  </FormLabel>
                  <Input
                    type="text"
                    name="title"
                    variant="auth"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  {errors.description && (
                    <Text style={{ color: "red" }}>
                      Wrong Format of description
                    </Text>
                  )}

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Description
                  </FormLabel>
                  <Input
                    type="text"
                    name="description"
                    variant="auth"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
              </Card>

              <FullCalendar
                ref={calendarRefBandaProfile}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                slotDuration="01:00:00"
                selectable={true}
                selectConstraint="businessHours"
                // selectAllow={(selectInfo) => {
                //   return (
                //     selectInfo.start.getHours() >= 8 &&
                //     selectInfo.end.getHours() <= 17
                //   );
                // }}
                selectAllow={(selectInfo) => {
                  const selectionStart = selectInfo.start;
                  const selectionEnd = selectInfo.end;

                  // Log the selection info for debugging
                  console.log(
                    `Selection start: ${selectionStart}, end: ${selectionEnd}`
                  );

                  // Check for overlap with existing events
                  const isOverlapping = [
                    ...calendarEvents,
                    ...selectedDate,
                  ].some((event) => {
                    const eventStart = new Date(event.start);
                    const eventEnd = new Date(event.end);

                    // Check for overlap
                    return (
                      (selectionStart >= eventStart &&
                        selectionStart < eventEnd) ||
                      (selectionEnd > eventStart && selectionEnd <= eventEnd) ||
                      (selectionStart <= eventStart && selectionEnd >= eventEnd)
                    );
                  });

                  // Log the overlap result for debugging
                  console.log(`Is overlapping: ${isOverlapping}`);

                  // Return false if the selection overlaps with any event, otherwise true
                  return !isOverlapping;
                }}
                // selectAllow={(selectInfo) => {
                //   // Check if the selection overlaps with any existing events
                //   const isOverlapping = calendarEvents.some((event) => {
                //     const eventStart = new Date(event.start);
                //     const eventEnd = new Date(event.end);
                //     const selectionStart = selectInfo.start;
                //     const selectionEnd = selectInfo.end;

                //     // Check for overlap: If either start or end of the selection is within the event's start and end times
                //     return (
                //       (selectionStart >= eventStart &&
                //         selectionStart < eventEnd) ||
                //       (selectionEnd > eventStart && selectionEnd <= eventEnd) ||
                //       (selectionStart <= eventStart && selectionEnd >= eventEnd)
                //     );
                //   });

                //   // Return false if the selection overlaps with any event, otherwise true
                //   return !isOverlapping;
                // }}
                businessHours={{
                  daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Friday
                  startTime: "08:00", // 8am
                  endTime: "23:00", // 5pm
                }}
                // events={selectedDate}
                events={[...calendarEvents, ...selectedDate]}
                select={handleSelect}
                eventClick={handleEventClick}
              />

              {!uploadedSuccess &&
                errors.responseError &&
                errors.title &&
                errors.description && (
                  <Card>
                    <Box
                      style={{
                        width: 300,
                        marginBottom: 30,
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Text
                        fontWeight="500"
                        color={textColorSecondary}
                        fontSize="sm"
                      >
                        You have uploaded Your schedule successfuly
                      </Text>
                      <Text
                        color={textColorPrimary}
                        fontWeight="500"
                        fontSize="md"
                      >
                        UPLOADED SUCCESSFULY
                      </Text>
                    </Box>
                    <Button
                      type="submit"
                      onClick={() => {
                        setUploadedSuccess(false);
                        setFormData({
                          title: "",
                          description: "",
                          eventType: "",
                        });
                      }}
                      colorScheme="blackAlpha"
                      marginTop="4"
                      style={{
                        width: "100%",
                        alignSelf: "center",
                      }}
                    >
                      Upload another Schedule
                    </Button>
                  </Card>
                )}

              {errors.responseError && (
                <Text style={{ color: "red" }}>{errors.responseError}</Text>
              )}

              {selectedDate?.length > 0 && (
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  style={{
                    backgroundColor: "#04a777",
                    // width: "100%",
                    marginTop: "20%",
                    height: 50,
                    color: "white",
                    // marginRight: "3%",
                    fontWeight: "bold",
                    marginLeft: "10%",
                    fontSize: 12,
                    borderRadius: 5,
                  }}
                >
                  Add Schedule
                </button>
              )}

              {/* Cancel */}
              {openCalendar && (
                <button
                  onClick={() => {
                    //Open calendar here
                    setCalendarEvents([]);
                    setSelectedDate([]);
                    setFormData({
                      title: "",
                      description: "",
                      eventType: "",
                    });
                    setSelectedDateData({ start: "", end: "" });
                    setOpenCalendar(false);

                    // setBanda(null);
                    // setEvents([]);
                  }}
                  style={{
                    backgroundColor: "red",
                    // width: "100%",
                    marginTop: "1%",
                    height: 50,
                    color: "white",
                    // marginRight: "3%",
                    fontWeight: "bold",
                    marginLeft: "10%",
                    fontSize: 12,
                    borderRadius: 5,
                  }}
                >
                  Cancel Schedule
                </button>
              )}
            </Card>
          )}

          {!openCalendar && (
            <Card>
              {/* <Text
                color={textColorPrimary}
                fontWeight="bold"
                // fontSize="3xs"
                mt="15px"
                mb="5px"
                style={{
                  marginLeft:-20,
                }}
              >Workig Hours Range</Text> */}

              <div>
                {errors.startingTime && (
                  <Text style={{ color: "red" }}>Add atleast one Tv</Text>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ marginLeft: 10, width: "100%" }}>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Working time start {"  "}
                      {formData.openingTime}
                    </FormLabel>
                    <Input
                      name="openingTime"
                      value={formData.openingTime}
                      onChange={handleChange}
                      variant={"auth"}
                      type={"time"}
                      placeholder="Working time start"
                    />
                  </div>

                  <div style={{ marginLeft: 10, width: "100%" }}>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Working time end {"  "}
                      {formData.closingTime}
                    </FormLabel>
                    <Input
                      name="closingTime"
                      type="time"
                      value={formData.closingTime}
                      onChange={handleChange}
                      variant={"auth"}
                      placeholder="Working time end"
                    />
                  </div>
                </div>
              </div>

              {errors.responseError && (
                <Text style={{ color: "red" }}>
                  Update of working Time Failed
                </Text>
              )}
              {formData?.closingTime?.length > 0 &&
                formData?.openingTime?.length > 0 && (
                  <Button
                    onClick={() => {
                      addWorkingHours();
                    }}
                    colorScheme="gray"
                    marginTop="10"
                    variant={"outline"}
                    style={{
                      width: "50%",
                      height: 50,
                      padding: 10,
                      alignSelf: "center",
                    }}
                  >
                    Update working time
                  </Button>
                )}
            </Card>
          )}
        </Card>
      </Grid>

      {!openCalendar && (
        <Grid
          templateColumns={{
            base: "1fr",
            lg: "repeat(2, 1fr)",
            //  "2xl": "1.34fr 1.62fr 1fr",
          }}
          gap={{ base: "20px", xl: "20px" }}
        >
          <Projects
            banner={"banner"}
            avatar={avatar}
            refresh={refreshSelected}
            removedItemInList={addedItemMiddle}
            name="Private Company12"
            job="Transport Company"
            posts="17"
            followers="9.7k"
            following="274" //updating list
          />
          <AddEventsSearch
            banner={"banner"}
            avatar={avatar}
            refresh={false}
            name="Private Company12"
            job="Transport Company"
            posts="17"
            followers="9.7k"
            following="274"
            removeItem={false}
            addItem={addedItemMiddle}
          />
        </Grid>
      )}

      <Card mb={{ base: "0px", "2xl": "20px" }} zIndex={0}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          selectable={false}
          selectConstraint="businessHours"
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday to Sunday
            startTime: "06:00", // 6:00 AM
            endTime: "00:00", // 6:00 PM
          }}
          events={calendarEvents}
          // slotDuration="01:00:00"
        />
      </Card>
    </Box>
  );
}
