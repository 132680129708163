import * as actionTypes from "./actionTypes";
import produce from "immer";

const initialState: CartsState = {
  carts: [],
};

const cartsReducer = (
  state: CartsState = initialState,
  action: CartsActions
): CartsState => {
  switch (action.type) {
    case actionTypes.ADD_ARTICLE:
      console.log("New");
      console.log("New");
      console.log("New");
      console.log("New");
      console.log("New");
      console.log(action);
      console.log("New");
      console.log("New");
      console.log("New");
      console.log("New");
      // Check if the item already exists in the cart by its ID
      const existingCartItem = state.carts.find(
        (cart) => cart.id === action.cart.id
      );

      // If the item already exists, return the state unchanged
      if (existingCartItem) {
        return state;
      }

      const newCart: ICart = {
        id: action.cart.id,
        userId: action.cart.userId,
        title: action.cart.title,
        activeCart:action.cart.activeCart,
        type: action.cart.type,
        duration: action.cart.duration,
        currency: action.cart.currency,
        coverImage:action.cart.coverImage,
        price: action.cart.price,
        body: action.cart.body,
        frequency: action.cart.frequency,
        contentCost: action.cart.contentCost,
        promoId: action.cart.promoId,
        isPromo: action.cart.isPromo,
        location: action.cart.location,
        bandaumizaList: action.cart.bandaumizaList,
      };

      return {
        ...state,
        carts: state.carts.concat(newCart),
      };

    case actionTypes.UPDATE_ARTICLE:
      console.log(action.cart.bandaumizaList);
      console.log(state.carts);

      const updatedCartsUpdate = state.carts.map((cart: ICart) =>
        cart.id === action.cart.id
          ? // action.cart
            { ...cart, ...action.cart }
            // {...cart 
            //   bandaumizaList: cart.bandaumizaList, action.cart.bandaumizaList }
          : cart
      );
      // const idExists = state.carts.some((item: any) => item.id === cart.id);
      console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
      console.log(updatedCartsUpdate);

      return {
        ...state,
        carts: updatedCartsUpdate,
      };

    case actionTypes.ACTIVE_CART:
       
        const updatedCartsUpdateC = state.carts.map((cart: ICart) =>
          cart.id === action.cart.id
            ? // action.cart
              { ...cart, ...action.cart }
            : cart
        );
        // const idExists = state.carts.some((item: any) => item.id === cart.id);
        // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
        // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
        // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
        // console.log("UPDATED :::::::: UPDATED :::::::: UPDATED ::::::::");
        console.log(updatedCartsUpdateC);
  
        return {
          ...state,
          carts: updatedCartsUpdateC,
        };
  
      
    case actionTypes.REMOVE_ARTICLE:
      const updatedCarts: ICart[] = state?.carts?.filter(
        (cart) => cart?.id !== action?.cart?.id
      );
      return {
        ...state,
        carts: updatedCarts,
      };

    case actionTypes.CLEAR_CART:
      return {
        carts: [],
      };
  }
  return state;
};

export default cartsReducer;
