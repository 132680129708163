// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  MdAddAPhoto,
  MdEmojiEvents,
  MdHdrPlus,
  MdPublish,
  MdRemove,
} from "react-icons/md";
import imageHolder from "assets/images/imageHolder.png";
import { useAddBandaProfileImageMutation } from "generated/graphql";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IoAdd, IoCloudUploadOutline } from "react-icons/io5";
import AWS from "aws-sdk";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { DeleteIcon } from "@chakra-ui/icons";

interface FormErrors {
  [key: string]: string;
}

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string;
  job: string;
  posts: number | string;
  followers: number | string;
  following: number | string;
  [x: string]: any;
}) {
  const {
    refresh,
    banner,
    avatar,
    name,
    job,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [profileImageService] = useAddBandaProfileImageMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const handleSubmit = async (action: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      if (action === "add") {
        imageUrll = await uploadFile(imageFile);
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrll = "";
      setImageUrl(null);
      setImageFile(null);
      setImageData(null);
    }

    let imageUrlData = imageUrl;

    if (action === "delete") {
      imageUrlData = "";
      imageUrll = "";
      setImageUrl(null);
      setImageData(null);
      setImageFile(null);
    }

    try {
      const response = await profileImageService({
        variables: {
          imageInput: {
            id: user.id,
            url: imageUrll,
          },
        },
      });

      if (
        response.data &&
        response.data.addBandaProfileImage &&
        !response.data.addBandaProfileImage.status
      ) {
        console.log(response.data.addBandaProfileImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.addBandaProfileImage);
        if ((action = "delete")) {
          setRemovedSuccessfully(true);
          setImageUrl(null);
          setImageData(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };
  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFile(file); // Set the image file
    }
  };

  const [uploadingProcess, setUploadingProcess] = useState(false);

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      setImageData(null);
      setImageFile(null);
      return "";
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = data.Location; //`https://${key}`;
      setImageUrl(imageUrl);
      setImageFile(null);
      setImageData(null);
      // alert("File uploaded successfully.");
      return imageUrl;
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      setImageFile(null);
      setImageData(null);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  if (uploadingProcess) {
    return (
      <Card
        mb={{ base: "0px", lg: "20px" }}
        alignItems="center"
        {...rest}
        style={{
          height: 350,
        }}
      >
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          Loading ....{" "}
        </Text>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          {errors.responseError}
        </Text>
      </Card>
    );
  }

  return (
    <Card
      mb={{ base: "0px", lg: "20px" }}
      alignItems="center"
      {...rest}
      style={{
        height: 350,
      }}
    >
      <Box
        style={{
          position: "absolute",
          right: 0,
          top: -10,
          backgroundColor: borderColor,
        }}
      >
        <IconBox
          mx="auto"
          h="60px"
          w="60px"
          icon={<Icon as={MdEmojiEvents} color={"#FFD700"} h="46px" w="46px" />}
          bg={borderColor}
        />
      </Box>

      {!avatar ? (
        <Avatar
          mx="auto"
          src={imageData ? (imageData as string) : imageHolder}
          h="87px"
          w="87px"
          mt="-23px"
          border="4px solid"
          borderColor={borderColor}
        />
      ) : (
        <Avatar
          mx="auto"
          src={imageData ? (imageData as string) : avatar}
          h="87px"
          w="87px"
          mt="-23px"
          border="4px solid"
          borderColor={borderColor}
        />
      )}

      {!(!avatar || avatar?.trim() === "") && !imageData && (
        <IconBox
          onClick={() => {
            alert("Are you sure you want to delete this image");
            handleSubmit("delete");
          }}
          mx="auto"
          h="60px"
          w="60px"
          icon={
            <Icon
              as={DeleteIcon}
              color={"red"}
              h="20px"
              w="20px"
              style={{
                position: "absolute",
                right: "39%",
                top: 10,
              }}
            />
          }
          bg={borderColor}
        ></IconBox>
      )}

      <Input
        type="file"
        accept="image/*"
        // opacity="0"
        aria-hidden="true"
        placeholder="Choose Image"
        variant={"filled"}
        style={{
          backgroundColor: "white",
          color: "white",
          height: 100,
          width: 200,
        }}
        ref={inputRef}
        onChange={handleImageChange}
        mb={4}
      />

      {imageData && (
        <Button
          type="submit"
          onClick={() => {
            handleSubmit("add");
          }}
          colorScheme="green"
          marginTop="4"
          style={{
            width: "30%",
            padding: 5,
            alignSelf: "center",
          }}
        >
          Add Image
        </Button>
      )}

      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            All Events
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Promo
          </Text>
        </Flex>
        <Flex mx="auto" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {following}
          </Text>

          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Reached
          </Text>
        </Flex>
      </Flex>

      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        Notifications
      </Text>
    </Card>
  );
}
