import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import "./Cart.css";
import { addCart } from "../../redux.store/cart/actionCreators";
import { useState } from "react";
// import { navigateThePage } from "../../redux.store/navigation/actionCreators";
// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import {
  IoBookmark,
  IoBookmarkOutline,
  IoHeart,
  IoHeartOutline,
  IoImageOutline,
  IoStar,
  IoStarOutline,
} from "react-icons/io5";

import Avatar1 from "assets/images/imageHolder.png";
import { useHistory } from "react-router-dom";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const CartAdd: React.FC<Props> = ({
  cart,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();

  // const handleNavigation = () => {
  //   // Perform some logic or data processing

  //   // Navigate to a different route
  //   history.push('/new-route');
  // };
  const dispatch: Dispatch<any> = useDispatch();
  // const saveCurrentRoute = React.useCallback(
  //   (navData: INavigate) => dispatch(navigateThePage(navData)),
  //   [dispatch]
  // );
  const navigateThroughLinks = (navData: INavigate, data: ICart) => {
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`, {
    //   state: { itemData: data, update: true },
    // });
  };

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );
  // const handleCartData = () => {
  //   setCart({
  //     id: 1,
  //     title: "title",
  //     body: "body",
  //   });
  // };

  const saveToTheCart = React.useCallback(
    (cart: ICart) => dispatch(addCart(cart)),
    [dispatch]
  );

  // const { image, name, author, bidders, download, currentbid } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");

  const textColorBid = useColorModeValue("brand.500", "white");
  const bidders = [
    Avatar1,
    Avatar1,
    Avatar1,
    Avatar1,
    Avatar1,
    Avatar1,
    Avatar1,
    Avatar1,
  ];

  return (
    <Card p="5px">
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "5px", "2xl": "5px" }} position="relative">
        <Box mb={{ base: "2px", "2xl": "2px" }} position="relative">
          {cart?.coverImage?.trim() === "" || !cart?.coverImage ? (
            <Box  
            w="100%"
            h="300px"
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignSelf={"center"}
            bg={"gray.200"}
            borderRadius={"15px"}
            ><Icon
              as={IoImageOutline}
              // w="30px"
              // h="30px"
                style={{
                alignSelf: "center",
                marginTop:"0%",
                //  marginLeft:"17%",
                // backgroundColor:"red",
                height: 150,
                width: "30%",
              }}
            /></Box>
          ) : (
           <>
           <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            // h="100vh" 
            h="auto" 
            bg="gray.100" // Background color for better visualization
          >
                  <Box
        // position="absolute"
        // top="0"
        // left="0"
        w="100%"
        h="300px"
        bgImage={`url(${cart?.coverImage})`}
        bgSize="cover"
        bgPosition="center"
        filter="blur(2px)"
        display={"flex"}



     />
            

          <Box
        position="absolute"
        // top="0"
        // left="0"
        w="100%"
        h="300px"
        bgSize="cover"
        // bgPosition="center"
        display={"flex"}
        alignSelf={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        // backgroundColor={"red"}



      >
                  <img 
                  // width="400"
                  // height="300"

                                src={cart?.coverImage}
                                className={"centerImg"}
                                ></img>
                                
                                {/* 
                                <Image
              position="absolute"
              src={cart?.coverImage}
              width={"200px"}
              // maxW="100%" 
              // maxH="auto" 
              // alignSelf={"center"}
              // display={"flex"}
              // justifyContent={"center"}
              // alignContent={"center"}
              // alignItems={"center"}
              aspectRatio={1}
              // objectFit="contain"
              // borderRadius="20px"
              // marginBottom={"25%"}
              // padding={"20px"}

              // left={"25%"}
              // top={"50%"}

            


            /> */}

      </Box>

          </Box>
            
          </>
          )}
        </Box>

       

        
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={"larger"}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {cart.name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                {cart.owner}
              </Text>
            </Flex>
            {/* <AvatarGroup
              max={3}
              color={textColorBid}
              size="sm"
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
              fontSize="12px"
            >
              {bidders.map((avt, key) => (
                <Avatar key={key} src={avt} />
              ))}
            </AvatarGroup> */}
          </Flex>
          <Flex
            flexDirection={"column"}
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="5px"
          >
            <Button
              variant="link"
              alignSelf={"start"}
              marginLeft={"-1%"}
               onClick={() => {
                // console.log(cart);
                // console.log(cart);
                // console.log(cart);
                localStorage.setItem("cartPromo", JSON.stringify(cart));
                //  const history = useHistory();

                history.push(`/site/events?id=${cart?.id}&update=${true}`);

              }}
              fontWeight="700" mt={"20px"}  ml={"10px"} mb={"20px"} fontSize="18px" color={textColorBid}>
              {cart?.title}
            </Button>

           
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );

 
};
