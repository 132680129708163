// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  MdAddAPhoto,
  MdEmojiEvents,
  MdHdrPlus,
  MdPublish,
  MdRemove,
} from "react-icons/md";
import imageHolder from "assets/images/imageHolder.png";
import {
  useAddBandaProfileImageMutation,
  useAddCoverImageMutation,
  useAddSponsorSocialLinksMutation,
  useAddUserProfileImageMutation,
  useEditSponsorDataMutation,
} from "generated/graphql";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IoAdd, IoCloudUploadOutline } from "react-icons/io5";
import AWS from "aws-sdk";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import districts from "assets/geo/Districts.json";
import regions from "assets/geo/Regions.json";
import wards from "assets/geo/Wards.json";
import { filtersLabels } from "views/site/registerSponsor";
import regionData from "utils/geo_data.json";


interface Item {
  site: string;
  link: string;
}

export interface FormData {
  username?: string; // no need
  companyName?: string;
  idType?: string;
  email: string; // no need
  phoneNumber: string;
  item: any;
  companyId: string;

  address: string;
  physicalResidency: string;
  nationalID?: string;
  industry?: string;
  anonymous?: boolean;
  region: string;
  district: string;
  ward: string;
  mtaaVillage: string;
  contentOwnership?: boolean;
}

interface FormErrors {
  [key: string]: string;
}

export default function ProfileBanner(props: {
  banner: string;
  avatar: string;
  name: string;
  item: any;
  backgroundImage: string;

  job: string;
  posts: number | string;
  followers: number | string;
  following: number | string;
  [x: string]: any;
}) {
  const {
    refresh,
    banner,
    avatar,
    name,
    job,
    backgroundImage,
    item,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  // Function to validate a phoneNumber number
  const validatePhone = (phoneNumber: string) => {
    const phoneRegex = /^(\+\d{1,3}\d{9,12}|07\d{8})$/;
    if (!phoneNumber.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Phone number is required",
      }));
    } else if (!phoneRegex.test(phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phoneNumber number format",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }
  };

  const validateNIDA = (text: string) => {
    const textRegex = /^\d{20}$/;
    if (!text.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text is required",
      }));
    } else if (!textRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text must contain exactly 20 numbers",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "",
      }));
    }
  };

  const validatePhysicalResidency = (physicalResidency: string) => {
    const textRegex = /^(.+)$/;
    if (!physicalResidency.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "physical Residency is required",
      }));
    } else if (!textRegex.test(physicalResidency)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "Wrong Residency",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "",
      }));
    }
  };

  const validateAddress = (address: string) => {
    const textRegex = /^(.+)$/;
    if (!address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is required",
      }));
    } else if (!textRegex.test(address)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
      }));
    }
  };

  const validateUsername = (bandaName: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;
    if (!bandaName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is required",
      }));
    } else if (!textRegex.test(bandaName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "",
      }));
    }
  };
  const validateCompanyName = (ownerName: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;

    if (!ownerName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "owner Name Name is required",
      }));
    } else if (!textRegex.test(ownerName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "owner Name Name is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "",
      }));
    }
  };

  const validateIndustry = (industry: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;

    if (!industry.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "Industry is required",
      }));
    } else if (!textRegex.test(industry)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "industry must not be empty",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "",
      }));
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log(item);
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");
  console.log("item");

  
  const [formData, setFormData] = useState<FormData | any>({
    username: item?.username,
    companyName: item?.companyName,
    idType: "",
    email: "",
    industry: item?.industry,
    phoneNumber: item?.phoneNumber,
    companyId: item?.companyId,
    region: item?.region,
    district: item?.district,
    ward: item?.ward,
    address: item?.address,
    mtaaVillage: item?.mtaa,
    physicalResidency: item?.physicalResidency,
    nationalID: item?.nationalID,
    anonymous: item?.anonymous,
    contentOwnership: false,
  });

  useEffect(() => {
    setFormData({
      username: item?.username,
      companyName: item?.companyName,
      idType: "",
      email: "",
      industry: item?.industry,
      phoneNumber: item?.phoneNumber,
      companyId: item?.companyId,
      region: item?.region,
      district: item?.district,
      ward: item?.ward,
      address: item?.address,
      mtaaVillage: item?.mtaa,
      physicalResidency: item?.physicalResidency,
      nationalID: item?.nationalID,
      anonymous: item?.anonymous,
      contentOwnership: false,
    });
  }, [item]);
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "nationalID":
        validateNIDA(value);
        break;
      case "phoneNumber":
        validatePhone(value);
        break;
      case "physicalResidency":
        validatePhysicalResidency(value);
        break;
      case "address":
        validateAddress(value);
        break;
      case "email":
        validateEmail(value);
        break;
      case "username":
        validateUsername(value);
        break;
      // case "companyName":
      //   validateCompanyName(value);
      // case "idType":
      //   validateCompanyName(value);

      // break;
      case "industry":
        validateIndustry(value);

        break;
      default:
        break;
    }
  };

  const [isCompany, setIsCompany] = useState(false);

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [profileImageService] = useAddUserProfileImageMutation();
  const [coverImageService] = useAddCoverImageMutation();
  const [addSponsorSocialLinks] = useAddSponsorSocialLinksMutation();
  const [editSponsor] = useEditSponsorDataMutation();

  const [errors, setErrors] = useState<FormErrors>({});
  const [aaaa, setAA] = useState("");
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);
  const [items, setItems] = useState<Item[]>([
    { site: "Example1", link: "https://example1.com" },
    { site: "Example2", link: "https://example2.com" },
  ]);

  const handleSubmit = async (action: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      if (action === "add") {
        imageUrll = await uploadFile(imageFile);
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrll = "";
      setImageUrl(null);
      setImageFile(null);
      setImageData(null);
    }

    let imageUrlData = imageUrl;

    if (action === "delete") {
      imageUrlData = "";
      imageUrll = "";
      setImageUrl(null);
      setImageData(null);
      setImageFile(null);
    }

    try {
      const response = await profileImageService({
        variables: {
          imageInput: {
            id: user.sponsorId,
            url: imageUrll,
          },
        },
      });

      if (
        response.data &&
        response.data.addUserProfileImage &&
        !response.data.addUserProfileImage.status
      ) {
        console.log(response.data.addUserProfileImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.addUserProfileImage);
        if ((action = "delete")) {
          setRemovedSuccessfully(true);
          setImageUrl(null);
          setImageData(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };

  const handleSubmitCover = async (action: string) => {
    setUploadingProcess(true);
    let imageUrllCover = "";

    console.log(imageFileCover);
    

    try {
      if (action === "add") {
        imageUrllCover = await uploadFileCover(imageFileCover);
        setAA(imageUrllCover)
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrllCover = "";
      setImageUrlCover(null);
      setImageFileCover(null);
      setImageDataCover(null);
    }

    let imageUrlData = imageUrlCover;
   
    if (action === "delete") {
      imageUrlData = "";
      imageUrllCover = "";
      setImageUrlCover(null);
      setImageDataCover(null);
      setImageFileCover(null);
    }

    try {
      const response = await coverImageService({
        variables: {
          imageInput: {
            id: user.sponsorId,
            url: imageUrllCover,
          },
        },
      });

      if (
        response.data &&
        response.data.addCoverImage &&
        !response.data.addCoverImage.status
      ) {
        console.log(response.data.addCoverImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrlCover(null);
        setImageDataCover(null);

        return;
      } else {
        console.log(response.data.addCoverImage);
        if ((action = "delete")) {
          setRemovedSuccessfully(true);
          setImageUrlCover(null);
          setImageDataCover(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
        setUploadingProcess(false);
        setImageUrlCover(null);
        setImageDataCover(null);
      }
      setUploadingProcess(false);
      setImageUrlCover(null);
      setImageDataCover(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageDataCover(null);
    }
  };

  const handleSubmitUpdate = async (action: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      const response = await editSponsor({
        variables: {
          editSponsorInput: {
            id: user.sponsorId,
            userId: user.id,
            isCompany,
            companyName: formData.companyName,
            usernameSponsor: formData.username,
            region: formData.region,
            phoneNumber: formData.phoneNumber,
            district: formData.district,
            ward: formData.ward,
            mtaa: formData.mtaaVillage,
            anonymous: formData.anonymous,
          },
        },
      });

      if (
        response.data &&
        response.data.editSponsorData &&
        !response.data.editSponsorData.log
      ) {
        console.log(response.data.editSponsorData);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Update failed",
        }));
        setUploadingProcess(false);
        // setImageUrl(null);
        // setImageData(null);

        return;
      } else {
        console.log(response.data.editSponsorData);
        setUploadingProcess(false);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
      }
      //

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const handleSubmitLink = async () => {
    setUploadingProcess(true);

    // if(socialLinks.some((link:any) => link.site ===  formData.idType) ){
    //   setUploadingProcess(false);
    //   return
    // }

    const newItem: Item = {
      site: formData.idType,
      link: formData.physicalResidency,
    };
    let parsedList: Item[];
    try {
      parsedList = JSON.parse(item?.socialLinks ? item.socialLinks : "[]");
      if (
        !Array.isArray(parsedList) ||
        !parsedList.every(
          (item) =>
            typeof item.site === "string" && typeof item.link === "string"
        )
      ) {
        parsedList = [];
      }
    } catch (error) {
      parsedList = [];
      console.error(
        "Failed to parse stringified list or invalid format:",
        error
      );
      return;
    }
    let existingD = parsedList;

    try {
      const response = await addSponsorSocialLinks({
        variables: {
          editSponsorInput: {
            id: user.sponsorId,
            userId: user.id,
            socialLinks: JSON.stringify([...existingD, newItem]),
          },
        },
      });

      if (
        response.data &&
        response.data.addSponsorSocialLinks &&
        !response.data.addSponsorSocialLinks.log
      ) {
        console.log(response.data.addSponsorSocialLinks);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseErrorLink: "Link adding failed",
        }));
        setUploadingProcess(false);
        // setImageUrl(null);
        // setImageData(null);

        return;
      } else {
        console.log(response.data.addSponsorSocialLinks);
        setUploadingProcess(false);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseErrorLink: "",
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          physicalResidency: "",
          idType: "",
        }));
        refresh(true);
      }
      //

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const handleRemoveLink = async (site: string) => {
    setUploadingProcess(true);
    // if(socialLinks.some((link:any) => link.site ===  formData.idType) ){
    //   setUploadingProcess(false);
    //   return
    // }

    const itemToRemove: Item = {
      site: formData.idType,
      link: formData.physicalResidency,
    };
    let parsedList: Item[];
    try {
      parsedList = JSON.parse(item?.socialLinks ? item.socialLinks : "[]");
      if (
        !Array.isArray(parsedList) ||
        !parsedList.every(
          (item) =>
            typeof item.site === "string" && typeof item.link === "string"
        )
      ) {
        parsedList = [];
      }
    } catch (error) {
      parsedList = [];
      console.error(
        "Failed to parse stringified list or invalid format:",
        error
      );
      setUploadingProcess(false);
      return;
    }

    // Remove items with the same site name
    let updatedList = parsedList.filter((item) => item.site !== site);

    try {
      const response = await addSponsorSocialLinks({
        variables: {
          editSponsorInput: {
            id: user.sponsorId,
            userId: user.id,
            socialLinks: JSON.stringify(updatedList),
          },
        },
      });

      if (
        response.data &&
        response.data.addSponsorSocialLinks &&
        !response.data.addSponsorSocialLinks.log
      ) {
        console.log(response.data.addSponsorSocialLinks);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseErrorLink: "Link removal failed",
        }));
        setUploadingProcess(false);

        return;
      } else {
        console.log(response.data.addSponsorSocialLinks);
        setUploadingProcess(false);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseErrorLink: "",
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          physicalResidency: "",
          idType: "",
        }));
        refresh(true);
      }

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Link removal failed",
      }));
      setUploadingProcess(false);
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlCover, setImageUrlCover] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageDataCover, setImageDataCover] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageFileCover, setImageFileCover] = useState<File | null>(null);

  // const [imageUrlUpload, setImageUrlUpload] = useState("");
  // const [imageDataUpload, setImageDataUpload] = useState<string | ArrayBuffer | null>(null);
  // const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefCover = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFile(file); // Set the image file
    }
  };
  const handleImageChangeCover = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageDataCover(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFileCover(file); // Set the image file
    }
  };

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };
  const handleClearImageCover = () => {
    setImageDataCover(null);
    setImageFileCover(null);
    if (inputRefCover.current) {
      inputRefCover.current.value = ""; // Clear file input value
    }
  };
    const history = useHistory();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpenCover, setModalIsOpenCover] = useState(false);

  const [uploadingProcess, setUploadingProcess] = useState(false);

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      setImageData(null);
      setImageFile(null);
      
      return "";
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();
    try {
      const data = await upload;
      console.log(data);
      const imageUrl = data.Location; //`https://${key}`;
      setImageUrl(imageUrl);
      setImageFile(null);
      setImageData(null);
      // alert("File uploaded successfully.");
      return imageUrl;
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      setImageFile(null);
      setImageData(null);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  const uploadFileCover = async (image: File | null) => {
    console.log(image);
    
    if (!image) {
      console.error("No image to upload");
     
      setImageDataCover(null);
      setImageFileCover(null);
      return "";
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();
    try {
      const data = await upload;
      console.log(data);
      const imageUrlCover = data.Location; //`https://${key}`;
      setImageUrlCover(imageUrlCover);
      setImageFileCover(null);
      setImageDataCover(null);
      // alert("File uploaded successfully.");
      return imageUrlCover;
    } catch (err) {
      setImageUrlCover("");
      console.error("Error uploading file:", err);
      setImageFileCover(null);
      setImageDataCover(null);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  const socialModes = [
    { mode: "fb", label: "Facebook" },
    { mode: "google", label: "Google" },
    { mode: "ig", label: "Instagram" },
    { mode: "twitter", label: "X(Twitter)" },
    { mode: "youtube", label: "Youtube" },
    { mode: "site", label: "Website" },
  ];

  //   let parsedList: any = () =>{

  //   try {
  //     parsedList = JSON.parse(item.socialLinks);
  //     if (!Array.isArray(parsedList) || !parsedList.every(item => typeof item.site === 'string' && typeof item.link === 'string')) {
  //       parsedList = [];
  //     }
  //     return parsedList
  //   } catch (error) {
  //     parsedList = [];
  //     return parsedList
  //   }
  // }

  const socialLinks = JSON.parse(item?.socialLinks ? item.socialLinks : "[]");

  const filteredLinks = socialModes?.filter(
    (socialMode: any) =>
      !socialLinks?.some((link: any) => link?.site === socialMode?.mode)
  );

  // console.log(filteredLinks);

  const google = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("google");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1115_412)">
          <path
            className="fill-blue-400 transition-all duration-500 group-hover:fill-white"
            d="M20 10.2391C20 9.56523 19.9333 8.86958 19.8222 8.21741H10.2V12.0652H15.7111C15.4889 13.3044 14.7556 14.3913 13.6667 15.087L16.9556 17.587C18.8889 15.8261 20 13.2609 20 10.2391Z"
            fill=""
          />
          <path
            className="fill-green-400 transition-all duration-500 group-hover:fill-white"
            d="M10.2 19.9783C12.9556 19.9783 15.2667 19.087 16.9556 17.5652L13.6667 15.087C12.7556 15.6957 11.5778 16.0435 10.2 16.0435C7.53337 16.0435 5.28893 14.2826 4.46671 11.9348L1.08893 14.4783C2.82226 17.8479 6.33337 19.9783 10.2 19.9783Z"
            fill="#34A353"
          />
          <path
            className="fill-yellow-400 transition-all duration-500 group-hover:fill-white"
            d="M4.46673 11.913C4.0445 10.6739 4.0445 9.32608 4.46673 8.08695L1.08895 5.52173C-0.355496 8.34782 -0.355496 11.6739 1.08895 14.4783L4.46673 11.913Z"
            fill="#F6B704"
          />
          <path
            className="fill-red-400 transition-all duration-500 group-hover:fill-white"
            d="M10.2 3.97827C11.6445 3.95653 13.0667 4.5 14.1112 5.47827L17.0223 2.6087C15.1778 0.913046 12.7334 2.58834e-06 10.2 0.0217417C6.33337 0.0217417 2.82226 2.15218 1.08893 5.52174L4.46671 8.08696C5.28893 5.7174 7.53337 3.97827 10.2 3.97827Z"
            fill="#E54335"
          />
        </g>
        <defs>
          <clipPath id="clip0_1115_412">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
  const fb = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("fb");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1115_52)">
          <path
            className="fill-indigo-600 transition-all duration-500 group-hover:fill-white"
            d="M10.0001 20C15.523 20 20.0001 15.5228 20.0001 10C20.0001 4.47715 15.523 0 10.0001 0C4.47727 0 0.00012207 4.47715 0.00012207 10C0.00012207 15.5228 4.47727 20 10.0001 20Z"
            fill=""
          />
          <path
            className="fill-white transition-all duration-500 group-hover:fill-indigo-700"
            d="M13.2516 3.06946H11.0364C9.72179 3.06946 8.25958 3.62236 8.25958 5.52793C8.266 6.1919 8.25958 6.82779 8.25958 7.54345H6.73877V9.96352H8.30665V16.9305H11.1877V9.91754H13.0893L13.2613 7.53666H11.1381C11.1381 7.53666 11.1428 6.47754 11.1381 6.16997C11.1381 5.41693 11.9216 5.46005 11.9688 5.46005C12.3416 5.46005 13.0666 5.46114 13.2527 5.46005V3.06946H13.2516V3.06946Z"
            fill=""
          />
        </g>
        <defs>
          <clipPath id="clip0_1115_52">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
  const ig = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("ig");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        className="stroke-red-600 transition-all duration-500 group-hover:stroke-white"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1667 5.83333V5.875M9.16673 17.5H10.8334C13.9761 17.5 15.5474 17.5 16.5237 16.5237C17.5001 15.5474 17.5001 13.976 17.5001 10.8333V9.16667C17.5001 6.02397 17.5001 4.45262 16.5237 3.47631C15.5474 2.5 13.9761 2.5 10.8334 2.5H9.16673C6.02403 2.5 4.45268 2.5 3.47637 3.47631C2.50006 4.45262 2.50006 6.02397 2.50006 9.16667V10.8333C2.50006 13.976 2.50006 15.5474 3.47637 16.5237C4.45268 17.5 6.02403 17.5 9.16673 17.5ZM13.3334 10C13.3334 11.8409 11.841 13.3333 10.0001 13.3333C8.15911 13.3333 6.66673 11.8409 6.66673 10C6.66673 8.15905 8.15911 6.66667 10.0001 6.66667C11.841 6.66667 13.3334 8.15905 13.3334 10Z"
          stroke=""
          stroke-width="1.6"
          stroke-linecap="round"
        />
      </svg>
    </a>
  );
  const youtube = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("youtube");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-red-600 transition-all duration-500 group-hover:fill-white"
          d="M1.40288 6.21319C1.48321 4.97646 2.47753 4.00723 3.71535 3.9459C5.5078 3.8571 8.06973 3.75 10.0001 3.75C11.9304 3.75 14.4923 3.8571 16.2848 3.9459C17.5226 4.00723 18.5169 4.97646 18.5972 6.21319C18.6742 7.39808 18.7501 8.85604 18.7501 10C18.7501 11.144 18.6742 12.6019 18.5972 13.7868C18.5169 15.0235 17.5226 15.9928 16.2848 16.0541C14.4923 16.1429 11.9304 16.25 10.0001 16.25C8.06973 16.25 5.5078 16.1429 3.71535 16.0541C2.47753 15.9928 1.48321 15.0235 1.40288 13.7868C1.32591 12.6019 1.25006 11.144 1.25006 10C1.25006 8.85604 1.32591 7.39808 1.40288 6.21319Z"
          fill="#FC0D1B"
        />
        <path
          className="fill-white transition-all duration-500 group-hover:fill-indigo-700"
          d="M8.12506 7.5V12.5L13.1251 10L8.12506 7.5Z"
          fill="white"
        />
      </svg>
    </a>
  );
  const twitter = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("twitter");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="transition-all duration-500 group-hover:fill-white"
          cx="10.0001"
          cy="10"
          r="8.75"
          fill="url(#paint0_linear_1115_481)"
        />
        <path
          className="transition-all duration-500 group-hover:fill-indigo-700"
          d="M14.3667 6.38049C14.4446 5.87707 13.9659 5.47972 13.5183 5.67625L4.60307 9.59053C4.28208 9.73146 4.30556 10.2177 4.63848 10.3237L6.47703 10.9092C6.82792 11.0209 7.20789 10.9631 7.5143 10.7514L11.6594 7.88767C11.7844 7.80131 11.9207 7.97904 11.8139 8.08914L8.83013 11.1654C8.54069 11.4638 8.59814 11.9695 8.94629 12.1878L12.2869 14.2827C12.6616 14.5176 13.1436 14.2816 13.2137 13.8288L14.3667 6.38049Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1115_481"
            x1="10.0001"
            y1="1.25"
            x2="10.0001"
            y2="18.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#37BBFE" />
            <stop offset="1" stop-color="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  );
  const site = (
    <a
      onClick={() => {
        if (window.confirm("Are you sure you want to remove this link?")) {
          handleRemoveLink("site");
        }
      }}
      className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="transition-all duration-500 group-hover:fill-white"
          cx="10.0001"
          cy="10"
          r="8.75"
          fill="url(#paint0_linear_1115_481)"
        />
        <path
          className="transition-all duration-500 group-hover:fill-indigo-700"
          d="M14.3667 6.38049C14.4446 5.87707 13.9659 5.47972 13.5183 5.67625L4.60307 9.59053C4.28208 9.73146 4.30556 10.2177 4.63848 10.3237L6.47703 10.9092C6.82792 11.0209 7.20789 10.9631 7.5143 10.7514L11.6594 7.88767C11.7844 7.80131 11.9207 7.97904 11.8139 8.08914L8.83013 11.1654C8.54069 11.4638 8.59814 11.9695 8.94629 12.1878L12.2869 14.2827C12.6616 14.5176 13.1436 14.2816 13.2137 13.8288L14.3667 6.38049Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1115_481"
            x1="10.0001"
            y1="1.25"
            x2="10.0001"
            y2="18.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#37BBFE" />
            <stop offset="1" stop-color="#007DBB" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  );

  if (uploadingProcess) {
    return (
      <Card
        mb={{ base: "0px", lg: "20px" }}
        alignItems="center"
        {...rest}
        // style={{
        //   height: 350,
        // }}
      >
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          Loading ....{" "}
        </Text>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          {errors.responseError}
        </Text>
      </Card>
    );
  }
  return (
    <Card mb={{ base: "0px", lg: "20px" }}>
      <Card
        bg="#3d0066"
        mt={"5px"}
        padding={"0px"}
        // boxShadow={cardShadow}
        margin={"0px"}
        // borderRadius={"15px"}
      >
        <Image
           src={imageDataCover ? (imageDataCover as string) :  backgroundImage ? backgroundImage : "https://pagedone.io/asset/uploads/1705471739.png"}
          // src="https://pagedone.io/asset/uploads/1705471739.png"
          alt="cover-image"
          className="w-full  top-0 left-0 z-0"
          width={"100%"}
          height={"200px"}
          borderRadius={"15px"}
          objectFit="cover"
        />
      </Card>

      <Box
        style={{
          position: "absolute",
          right: 0,
          top: -10,
          backgroundColor: borderColor,
        }}
      >
        <IconBox
          mx="auto"
          h="60px"
          w="60px"
          icon={<Icon as={MdEmojiEvents} color={"#FFD700"} h="46px" w="46px" />}
          bg={borderColor}
        />
      </Box>

      <div className="flex items-start relative z-0 mb-2.5">
        

     
        {!avatar ? (
          <Avatar
            mx="auto"
            src={imageData ? (imageData as string) : imageHolder}
            h="87px"
            w="87px"
            mt="-23px"
            border="4px solid"
            className="border-4 z-0 border-solid border-white rounded-full"
            width={"120px"}
            height={"120px"}
            borderColor={imageData ? "#fff" : "#333"}
            objectFit={"cover"}
            alignSelf={"start"}
            marginTop={"-60px"}
          />
        ) : (
          <Avatar
            mx="auto"
            src={imageData ? (imageData as string) : avatar}
            h="87px"
            w="87px"
            mt="-23px"
            border="4px solid"
            className="border-4 border-solid border-white rounded-full"
            width={"120px"}
            height={"120px"}
            borderColor={imageData ? "#fff" : "#333"}
            objectFit={"cover"}
            alignSelf={"start"}
            marginTop={"-60px"}
          />
        )}
      </div>


    {true &&  <div
        className="flex  
 text-center flex-col gap-1"
        style={{ marginTop: 50 }}
      >
        <span className="w-fit pl-0.5 text-2xl font-bold text-slate-700 ">
          Cover Picture
        </span>
        <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl border border-dashed border-slate-300 p-8 text-slate-700 dark:border-slate-700 dark:text-slate-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            fill="currentColor"
            className="w-12 h-12 opacity-75"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
              clip-rule="evenodd"
            />
          </svg>
          <div className="group">
            <label
              htmlFor="fileInputDragDrop"
              className="cursor-pointer font-medium text-green-700 group-focus-within:underline dark:text-blue-600"
            >
              {/* <Input 
                        onChange={handleImageChange}

id="fileInputDragDrop" type="file" className="sr-only" aria-describedby="validFileFormats" /> */}
              <Input
                type="file"
                accept="image/*"
                // opacity="0"
                aria-hidden="true"
                className="sr-only"
                placeholder="Choose Image"
                variant={"filled"}
                style={{
                  backgroundColor: "white",
                  color: "white",
                  height: 100,
                  width: 200,
                }}
                ref={inputRefCover}
                onChange={handleImageChangeCover}
                mb={4}
              />
              Browse
            </label>
            {"  "}or drag and drop here
          </div>
          <small id="validFileFormats">PNG, JPG - Max 5MB</small>
        </div>

        {imageDataCover && (
        <>
          <Button
            type="submit"
            onClick={() => {
              handleSubmitCover("add");
            }}
            colorScheme="green"
            marginTop="4"
            style={{
              width: "30%",
              padding: 5,
              alignSelf: "center",
            }}
          >
            Add Cover Image
          </Button>
        </>
      )}

      {imageDataCover && (
        <>
          <Button
            type="submit"
            onClick={() => {
              setImageUrlCover(null);
              setImageFileCover(null);
              setImageDataCover(null);
            }}
            colorScheme="red"
            marginTop="4"
            style={{
              width: "30%",
              padding: 5,
              alignSelf: "center",
            }}
          >
            Cancel Adding Cover Image
          </Button>
        </>
      )}
      </div>}
     

{!(!backgroundImage || backgroundImage?.trim() === "") && !imageDataCover && (
          <button
            onClick={() => setModalIsOpenCover(true)}
            type="button"
            className="  cursor-pointer z-40 whitespace-nowrap rounded-xl bg-red-700 px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-100 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700 active:opacity-100 active:outline-offset-0 dark:bg-blue-600 dark:text-slate-100 dark:focus-visible:outline-blue-600"
            style={{ marginTop: 70, backgroundColor: "red" }}
          >
            Remove Cover image
          </button>
        )}


     { true && <div
        className="flex  
 text-center flex-col gap-1"
        style={{ marginTop: 50 }}
      >
        <span className="w-fit pl-0.5 text-2xl font-bold text-slate-700 ">
        Profile Picture
        </span>
        <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl border border-dashed border-slate-300 p-8 text-slate-700 dark:border-slate-700 dark:text-slate-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            fill="currentColor"
            className="w-12 h-12 opacity-75"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
              clip-rule="evenodd"
            />
          </svg>
          <div className="group">
            <label
              htmlFor="fileInputDragDrop"
              className="cursor-pointer font-medium text-green-700 group-focus-within:underline dark:text-blue-600"
            >
              {/* <Input 
                        onChange={handleImageChange}

id="fileInputDragDrop" type="file" className="sr-only" aria-describedby="validFileFormats" /> */}
              <Input
                type="file"
                accept="image/*"
                // opacity="0"
                aria-hidden="true"
                className="sr-only"
                placeholder="Choose Image"
                variant={"filled"}
                style={{
                  backgroundColor: "white",
                  color: "white",
                  height: 100,
                  width: 200,
                }}
                ref={inputRef}
                onChange={handleImageChange}
                mb={4}
              />
              Browse
            </label>
            {"  "}or drag and drop here
          </div>
          <small id="validFileFormats">PNG, JPG - Max 5MB</small>
        </div>

        {imageData && (
        <>
          <Button
            type="submit"
            onClick={() => {
              handleSubmit("add");
            }}
            colorScheme="green"
            marginTop="4"
            style={{
              width: "30%",
              padding: 5,
              alignSelf: "center",
            }}
          >
            Add Image
          </Button>
        </>
      )}

      {imageData && (
        <>
          <Button
            type="submit"
            onClick={() => {
              setImageUrl(null);
              setImageFile(null);
              setImageData(null);
            }}
            colorScheme="red"
            marginTop="4"
            style={{
              width: "30%",
              padding: 5,
              alignSelf: "center",
            }}
          >
            Cancel Adding Image
          </Button>
        </>
      )}

      </div>}
      {!(!avatar || avatar?.trim() === "") && !imageData && (
          <button
            onClick={() => setModalIsOpen(true)}
            type="button"
            className="  cursor-pointer z-40 whitespace-nowrap rounded-xl bg-red-700 px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-100 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700 active:opacity-100 active:outline-offset-0 dark:bg-blue-600 dark:text-slate-100 dark:focus-visible:outline-blue-600"
            style={{ marginTop: 70, backgroundColor: "red" }}
          >
            Remove profile image
          </button>
        )}
     

      <Flex></Flex>

      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "10px", xl: "10px" }}
      >
        <Flex
          bg="#ebe0ff"
          mt={"10px"}
          padding={"5px"}
          border={"#c7adff solid"}
          borderWidth={"1px"}
          // boxShadow={cardShadow}
          margin={"10px"}
          borderRadius={"15px"}
          direction="column"
          // backgroundColor={"#4361ee"}
          bgSize="cover"
          py={{ base: "10px", md: "36px" }}
          px={{ base: "10px", md: "34px" }}
          // borderRadius="10px"
        >
          <Text
            style={{
              marginTop: 10,
              marginBottom: 20,
            }}
            fontSize="xl"
            ms="24px"
            fontWeight="700"
          >
            Update your details
          </Text>
          <SimpleGrid width={"100%"} minChildWidth="300px" spacing="20px">
            {isCompany ? (
              <div>
                <FormLabel>Organisation Name</FormLabel>
                <Input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div>
                {/* <FormLabel>Sponsor Name</FormLabel> */}
                <Input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            )}

            {/* <div>
        {isCompany ?<div>
            {errors.idType && (
              <Text style={{ color: "red" }}>Wrong Format</Text>
            )}
            <FormLabel>Id Type :</FormLabel>

            <Select
              name="idType"
              value={formData.idType}
              onChange={handleChange}
              placeholder="Select idType"
            >
              {[
                { mode: "COMPANY_ID", label: "COMPANY ID" },
                { mode: "TIN", label: "TIN" },
              ].map((cat:any) => (
                <option key={cat.mode} value={cat.mode}>
                  {cat.label}
                </option>
              ))}
            </Select>
          </div>
          :
          <div>
            {errors.idType && (
              <Text style={{ color: "red" }}>Wrong Format</Text>
            )}
            <FormLabel>Id Type :</FormLabel>

            <Select
              name="idType"
              value={formData.idType}
              onChange={handleChange}
              placeholder="Select idType"
            >
              {[
                { mode: "NIDA", label: "NIDA" },
                // { mode: "TIN", label: "TIN" },
                // { mode: "TIN", label: "TIN" },
                { mode: "PASSPORT", label: "PASSPORT" },
                { mode: "DRIVER_LICENCE", label: "DRIVER LICENCE" },
              ].map((cat:any) => (
                <option key={cat.mode} value={cat.mode}>
                  {cat.label}
                </option>
              ))}
            </Select>
          </div>}
        </div> */}

            {/* {isCompany ? (
          <div>
            <FormLabel>Company ID/ TIN</FormLabel>
            <Input
              type="text"
              name="companyId"
              value={formData.companyId}
              onChange={handleChange}
            />
          </div>
        ) : (
          <div>
            <FormLabel>ID Number</FormLabel>
            <Input
              type="text"
              name="nationalID"
              value={formData.nationalID}
              onChange={handleChange}
            />
          </div>
        )} */}

            {/* <div>
          {errors.email && (
            <Text style={{ color: "red" }}>
              Wrong Format of email
            </Text>
          )}
          <FormLabel>Email:</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div> */}

            <div>
              {errors.phoneNumber && (
                <Text style={{ color: "red" }}>
                  Wrong Format of Phone number
                </Text>
              )}
              {/* <FormLabel>Phone Number</FormLabel> */}
              <Input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>

            {/* <div>
          <FormLabel>Postal address</FormLabel>
          <Input
            type="text"
            name="address"
            placeholder="PO Box 000"
            value={formData.address}
            onChange={handleChange}
          />
        </div> */}
             <div>
              {errors.region && (
                <Text style={{ color: "red" }}>{errors.region}</Text>
              )}

              <Select
                name="region"
                value={formData.region}
                onChange={handleChange}
                placeholder="Select region"
              >
                {regionData?.map((cat: any) => (
                  <option key={cat.region} value={cat.region}>
                    {cat.region}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              {errors.district && (
                <Text style={{ color: "red" }}>{errors.district}</Text>
              )}

              <Select
                name="district"
                value={formData.district}
                onChange={handleChange}
                placeholder="Select district"
              >
                {regionData
                  ?.find((cat: any) => cat.region === formData.region)
                  ?.data?.map((cat_district: any) => (
                    <option
                      key={formData.region + "_" + cat_district.district}
                      // value={cat_district.district}
                      value={
                        cat_district.district + "_" + cat_district.postCode
                      }
                    >
                      {cat_district.district}
                    </option>
                  ))}
              </Select>
            </div>

            <div>
              {errors.ward && (
                <Text style={{ color: "red" }}>{errors.ward}</Text>
              )}
              <Select
                name="ward"
                value={formData.ward}
                onChange={handleChange}
                placeholder="Select ward"
              >
                {regionData
                  ?.find((cat: any) => cat.region === formData.region)
                  // @ts-ignore

                  ?.data?.find(
                    (cat_district: any) =>
                      cat_district.district + "_" + cat_district.postCode ===
                      formData.district
                  )
                  ?.data?.map((cat_ward: any) => {
                    // console.log("cat_ward");
                    // console.log(cat_ward);
                    // console.log("cat_ward");

                    return (
                      <option
                        key={formData.district + "_" + cat_ward.ward}
                        // value={cat_ward.ward}
                        value={cat_ward.ward + "_" + cat_ward.postCode}
                      >
                        {cat_ward.ward}
                      </option>
                    );
                  })}
              </Select>
            </div>

            <div>
              {errors.mtaaVillage && (
                <Text style={{ color: "red" }}>{errors.mtaaVillage}</Text>
              )}
              <Select
                name="mtaaVillage"
                value={formData.mtaaVillage}
                onChange={handleChange}
                placeholder="Select mtaa"
              >
                {regionData
                  // @ts-ignore
                  ?.find((cat: any) => cat?.region === formData.region)
                  // @ts-ignore
                  ?.data?.find(
                    (cat_district: any) =>
                      `${cat_district?.district}_${cat_district?.postCode}` ===
                      formData.district
                  )
                  ?.data?.find(
                    (cat_ward: any) =>
                      `${cat_ward?.ward}_${cat_ward?.postCode}` ===
                      formData.ward
                  )
                  ?.data?.map((mtaaVillage: any) => (
                    <option
                      key={formData.district + "_" + mtaaVillage.mtaaVillage}
                      value={
                        mtaaVillage.mtaaVillage
                          ? `${mtaaVillage.mtaaVillage}`
                          : //@ts-ignore
                            `${mtaaVillage.mtaaVillage}`
                      }
                    >
                      <span className="text-blue-300">
                        {mtaaVillage.mtaaVillage &&
                          `${"Mtaa/Kijiji  " + mtaaVillage.mtaaVillage}`}
                      </span>

                      {mtaaVillage.kitongoji &&
                        //@ts-ignore

                        `${"   Kitongoji cha   " + mtaaVillage.kitongoji}`}
                    </option>
                  ))}
              </Select>
            </div>

            {isCompany && (
              <div>
                <FormLabel>Physical address</FormLabel>
                <Input
                  type="text"
                  name="physicalResidency"
                  placeholder="21 Mizengo Street Kinondoni"
                  value={formData.physicalResidency}
                  onChange={handleChange}
                />
              </div>
            )}

            {/* {isCompany && (
          <div>
            <FormLabel>Industry</FormLabel>
            <Select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              placeholder="Select Industry"
            >
              {filtersLabels.map((cat:any) => (
                <option key={cat.mode} value={cat.mode}>
                  {cat.label}
                </option>
              ))}
            </Select>
          </div>
        )} */}

            {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormLabel>
            Do you also want to setup a content ownership account
          </FormLabel>
          <input
            type="checkbox"
            name="contentOwnership"
            checked={formData.contentOwnership}
            onChange={handleChange}
          />
        </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FormLabel>Anyonymous </FormLabel>
              <input
                type="checkbox"
                name="anonymous"
                checked={formData.anonymous}
                onChange={handleChange}
              />
            </div>

            {errors.responseError && (
              <Text color={"red"} fontWeight="bold" fontSize="xl" mt="10px">
                {errors.responseError}
              </Text>
            )}

            {true && (
              <Button
                type="submit"
                onClick={() => {
                  handleSubmitUpdate("add");
                }}
                colorScheme="green"
                marginTop="4"
                style={{
                  width: "30%",
                  padding: 5,
                  alignSelf: "center",
                }}
              >
                Update{" "}
              </Button>
            )}
          </SimpleGrid>
        </Flex>

        <Flex
          bg="#ebe0ff"
          mt={"10px"}
          padding={"5px"}
          border={"#c7adff solid"}
          borderWidth={"1px"}
          // boxShadow={cardShadow}
          margin={"10px"}
          borderRadius={"15px"}
          direction="column"
          // backgroundColor={"#4361ee"}
          bgSize="cover"
          py={{ base: "10px", md: "36px" }}
          px={{ base: "10px", md: "34px" }}
          // borderRadius="10px"
        >
          <Text
            style={{
              marginTop: 10,
              marginBottom: 20,
            }}
            fontSize="xl"
            ms="24px"
            fontWeight="700"
          >
            Add social account link
          </Text>

          <div className="flex items-end justify-end gap-5">
            {JSON.parse(item?.socialLinks ? item.socialLinks : "[]")
              .slice(0, 6)
              .map((item: any) => {
                return (
                  <>
                    {" "}
                    {item.site === "google"
                      ? google
                      : item.site === "fb"
                      ? fb
                      : item.site === "ig"
                      ? ig
                      : item.site === "youtube"
                      ? youtube
                      : item.site === "twitter"
                      ? twitter
                      : item.site === "site"
                      ? site
                      : site}
                  </>
                );
              })}
          </div>

          <div className="mt-20">
            {
              <div>
                <Select
                  name="idType"
                  value={formData.idType}
                  onChange={handleChange}
                  placeholder="Select link type"
                  mb={"20px"}
                >
                  {filteredLinks?.slice(0, 6).map((cat: any) => (
                    <option key={cat.mode} value={cat.mode}>
                      {cat.label}
                    </option>
                  ))}
                </Select>
              </div>
            }

            {
              <div>
                <Input
                  type="text"
                  name="physicalResidency"
                  placeholder="link"
                  value={formData.physicalResidency}
                  onChange={handleChange}
                />
              </div>
            }
          </div>

          {errors.responseErrorLink && (
            <Text color={"red"} fontWeight="bold" fontSize="xl" mt="10px">
              {errors.responseErrorLink}
            </Text>
          )}

          {formData.physicalResidency !== "" && formData.idType !== "" && (
            <Button
              type="submit"
              onClick={() => {
                handleSubmitLink();
              }}
              colorScheme="green"
              marginTop="4"
              style={{
                width: "30%",
                padding: 5,
                alignSelf: "center",
              }}
            >
              Add link{" "}
            </Button>
          )}
        </Flex>
      </Grid>
      {modalIsOpen && (
        <div
          className="fixed inset-0 z-999 flex items-end justify-center bg-black/20 p-4 pb-8 backdrop-blur-md sm:items-center lg:p-8"
          role="dialog"
          aria-modal="true"
          aria-labelledby="defaultModalTitle"
          onClick={() => setModalIsOpen(false)}
        >
          <div
            className="flex max-w-lg flex-col gap-4 overflow-hidden rounded-xl border border-slate-300 bg-white text-slate-700 dark:border-slate-700 dark:bg-slate-800 dark:text-slate-300"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between border-b border-slate-300 bg-slate-100/60 p-4 dark:border-slate-700 dark:bg-slate-900/20">
              <h3
                id="defaultModalTitle"
                className="font-semibold tracking-wide text-black dark:text-white"
              >
                Deleting profile image
              </h3>
              <button
                onClick={() => setModalIsOpen(false)}
                aria-label="close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="1.4"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="px-4 py-8">
              <p>Are you sure you want to delete this profile image</p>
            </div>
            <div className="flex flex-col-reverse justify-between gap-2 border-t border-slate-300 bg-slate-100/60 p-4 dark:border-slate-700 dark:bg-slate-900/20 sm:flex-row sm:items-center md:justify-end">
              <button
                onClick={() => setModalIsOpen(false)}
                type="button"
                className="cursor-pointer whitespace-nowrap rounded-xl px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-700 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700 active:opacity-100 active:outline-offset-0 dark:text-slate-300 dark:focus-visible:outline-blue-600"
              >
                Cancel{" "}
              </button>
              <Button
                onClick={() => {
                  handleSubmit("delete");
                  setModalIsOpen(false);
                }}
                type="button"
                className="cursor-pointer whitespace-nowrap rounded-xl bg-blue-700 px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-100 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700 active:opacity-100 active:outline-offset-0 dark:bg-blue-600 dark:text-slate-100 dark:focus-visible:outline-blue-600"
              >
                Delete{" "}
              </Button>
            </div>
          </div>
        </div>
      )}

{modalIsOpenCover && (
        <div
          className="fixed inset-0 z-999 flex items-end justify-center bg-black/20 p-4 pb-8 backdrop-blur-md sm:items-center lg:p-8"
          role="dialog"
          aria-modal="true"
          aria-labelledby="defaultModalTitle"
          onClick={() => setModalIsOpenCover(false)}
        >
          <div
            className="flex max-w-lg flex-col gap-4 overflow-hidden rounded-xl border border-slate-300 bg-white text-slate-700 dark:border-slate-700 dark:bg-slate-800 dark:text-slate-300"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between border-b border-slate-300 bg-slate-100/60 p-4 dark:border-slate-700 dark:bg-slate-900/20">
              <h3
                id="defaultModalTitle"
                className="font-semibold tracking-wide text-black dark:text-white"
              >
                Deleting Cover image
              </h3>
              <button
                onClick={() => setModalIsOpenCover(false)}
                aria-label="close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="1.4"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="px-4 py-8">
              <p>Are you sure you want to delete this profile image</p>
            </div>
            <div className="flex flex-col-reverse justify-between gap-2 border-t border-slate-300 bg-slate-100/60 p-4 dark:border-slate-700 dark:bg-slate-900/20 sm:flex-row sm:items-center md:justify-end">
              <button
                onClick={() => setModalIsOpenCover(false)}
                type="button"
                className="cursor-pointer whitespace-nowrap rounded-xl px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-700 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700 active:opacity-100 active:outline-offset-0 dark:text-slate-300 dark:focus-visible:outline-blue-600"
              >
                Cancel{" "}
              </button>
              <Button
                onClick={() => {
                  handleSubmitCover("delete");
                  setModalIsOpenCover(false);
                }}
                type="button"
                className="cursor-pointer whitespace-nowrap rounded-xl bg-blue-700 px-4 py-2 text-center text-sm font-medium tracking-wide text-slate-100 transition hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700 active:opacity-100 active:outline-offset-0 dark:bg-blue-600 dark:text-slate-100 dark:focus-visible:outline-blue-600"
              >
                Delete{" "}
              </Button>
            </div>
          </div>
        </div>
      )}

    </Card>
  );
}
