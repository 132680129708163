// Chakra imports
import { FormLabel, Input, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/images/imageHolder.png";
import Project2 from "assets/images/bajaji.jpeg";
import Project3 from "assets/images/pepsi.jpg";
// Custom components
import Card from "components/card/Card";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  useCheckSportsListQuery,
  useContentListQuery,
  useMyBandaEventsListQuery,
} from "generated/graphql";
import AddsEventItem from "./AddsEventItem";

type Props = {
  removeItem: boolean;
  addItem: (data: boolean) => void;
};

export const AddEventsSearch: React.FC<Props & { [x: string]: any }> = (
  props
) => {
  const { addItem, removeItem, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dateStart = new Date().toISOString();
  // State variable for search query
  const [searchQuery, setSearchQuery] = useState("");

  // Handler for updating the search query
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  // console.log(user);
  // console.log(user);

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [category, setCategory] = useState("all");
  const [updateNow, setUpdateNow] = useState(false);

  const myBandaEvents = useMyBandaEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getMyBandaEventsInput: {
        contentId: user.id,
      },
    },
  });

  const { loading, error, data, refetch } = useCheckSportsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getSportsInput: {
        bandaId: user.id, //logged in as banda
        page,
        pageSize,
        category: "all",
        matchTitle: searchQuery,
        competition: "",
        sport: "",

        // category,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [updateNow]);
  // console.log(myBandaEvents?.data?.myBandaEventsList);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const addedItemMiddle = (data: any) => {
    // console.log(data);
    addItem(data);
    setUpdateNow(!updateNow);
  };

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Search events
      </Text>
      <div>
        {/* <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Search
                </FormLabel> */}
        <Input
          type="text"
          name="title"
          variant="auth"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      {data?.checkSportsList?.map((item: any) => {
        const itemExist = myBandaEvents?.data?.myBandaEventsList?.some(
          (selected) => {
            // console.log(selected?.title);
            // console.log(item?.title);
            // console.log(selected?.title === item?.title)
            return selected?.title === item?.title;
          }
        );

        return (
          <>
            {!itemExist ? (
              <AddsEventItem
                boxShadow={cardShadow}
                mb="20px"
                image={Project1}
                ranking="1"
                dateStart={dateStart}
                addedItem={addedItemMiddle}
                id={item.id}
                link="#"
                title={item.title}
                category={item.description}
                added={itemExist}
                away={"Napoli"}
              />
            ) : (
              <></>
            )}
          </>
        );
      })}
    </Card>
  );
};
