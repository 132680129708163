import {
  Box,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useColorModeValue, Image } from "@chakra-ui/react";
// Custom components
import Information from "views/site/profileSponsor/components/Information";
import { Button, Link } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import "./content.css";
import AWS from "aws-sdk";

import { useHistory, useLocation } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import {
  addCart,
  removeCart,
  updateCart,
} from "redux.store/cart/actionCreators";
import Project1 from "assets/images/imageHolder.png";
import {
  useCreateContentMutation,
  useDisableContentMutation,
  useEditContentMutation,
  useGetContentQuery,
  useMyContentEventsListQuery,
  useRemoveContentMutation,
} from "generated/graphql";
import Hosting from "./components/Hosting";
import { formatDateTime, formatDateTimeShort } from "../helpers";
import { filtersLabels } from "../marketplace";
// Custom components

export interface FormData {
  title: string;
  description: string;
  link: string;
  verifiedBy: string;
  duration: string;
  price: number;
  currency: string;
  contentType: string;
  languages: string;
  categories: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function ContentEdit() {
  const textColorPrimary = "white"; //useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

  const [removeContent] = useRemoveContentMutation();
  const [disableContent] = useDisableContentMutation();
  const storedCartItemString = localStorage.getItem("contentItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);
  const itemData = storedCartItem;

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [reservationCost, setReservationCost] = useState(1000);
  //@ts-ignore
  const [reservationData, setReservationData] = useState<ICart>([]);
  const [first, setFirst] = useState(true);

  const [defaultFrequency, setDefaultFrequency] = useState(1);

  // console.log(location.state);

  const textColor = "white"; // useColorModeValue("secondaryGray.900", "white");
  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "description is required",
      }));
    } else if (!textRegex.test(description)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Wrong Residency",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "",
      }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const validateLink = (link: string) => {
    const textRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/;

    if (!link.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is required",
      }));
    } else if (!textRegex.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: storedCartItem.title,
    description: storedCartItem.description,
    link: storedCartItem.link,
    contentType: "",
    languages: storedCartItem.languages,
    categories: JSON.stringify(storedCartItem.categories),
    price: storedCartItem.amount,
    currency: storedCartItem.currency,
    verifiedBy: storedCartItem.verifiedBy,
    duration: storedCartItem.duration,
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "title":
        validateTitle(value);
        break;
      case "description":
        validateDescription(value);
        break;
      case "link":
        validateLink(value);
        break;
      default:
        break;
    }
  };

  const [createContent] = useEditContentMutation();
  const [load, setLoad] = useState(false);
  const [loadedData, setLoadedData] = useState(false);

  const handleSubmit = async () => {
    setLoad(true);
    console.log(formData);
    validateTitle(formData.title);
    validateDescription(formData.description);
    validateLink(formData.link);

    //  return

    let imageUrll;

    try {
      if (errors.title && errors.description && errors.link) {
        setLoad(false);
        return;
      }

      try {
        imageUrll = await uploadFile(imageFile);
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Image Upload failed",
        }));
        setLoad(false);
        return;
      }

      let uploadingData = {
        contentId: storedCartItem.id,
        coverImage: imageUrll, //Url link
        media: formData.link,
        // contentType: formData.contentType === "SCHEDULED" ? "SCHEDULED" : "NORMAL",
        contentType: "NORMAL",
        userId: user.id,
        title: formData.title,
        description: formData.description,
        link: formData.link,
        amount:  formData.price,
        currency: "Tsh", //formData.currency,
        languages: formData.languages,
        // categorySelected: formData.categories,
        verifiedBy: formData.verifiedBy,
        duration: formData.duration,
      };

      let uploadingDataNoImage = {
        contentId: storedCartItem.id,
        media: formData.link,
        // contentType: formData.contentType === "SCHEDULED" ? "SCHEDULED" : "NORMAL",
        contentType: "NORMAL",
        userId: user.id,
        title: formData.title,
        description: formData.description,
        link: formData.link,
        amount: formData.price,
        currency: "Tsh", //formData.currency,
        languages: formData.languages,
        // categorySelected: formData.categories,
        verifiedBy: formData.verifiedBy,
        duration: formData.duration,
      };
      const response = await createContent({
        variables: {
          editContentInput: imageData ? uploadingData : uploadingDataNoImage,
        },
      });

      if (
        response.data &&
        response.data.editContent &&
        !response.data.editContent.status
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Upload failed",
        }));
        setLoad(false);
        return;
      } else {
        console.log(response.data.editContent);
        setUploadedSuccess(true);
        setFormData({
          title: "",
          description: "",
          link: "",
          contentType: "",
          languages: "",
          price: 0,
          currency: "Tsh",
          categories: "",
          verifiedBy: "",
          duration: "",
        });
        handleClearImage();
        setImageUrl("");
        history.push("/site/my-content");

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      setLoad(false);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setLoad(false);
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFile(file); // Set the image file
    }
  };

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      return;
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = `https://${key}`;
      setImageUrl(imageUrl);
      return data.Location;
      // alert("File uploaded successfully.");
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getContentInput: {
        contentId: storedCartItem.id,
      },
    },
  });

  const activeOn = useMyContentEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getMyBandaEventsInput: {
        contentId: storedCartItem.id,
        page: 1,
        pageSize: 10,
        // filterQuery,
      },
    },
  });

  // useEffect(()=>{
  //   if(loadedData){
  //     setFormData({
  //       title: contentData?.data?.getContent.title,
  //       description: contentData?.data?.getContent.description,
  //       link: contentData?.data?.getContent.link,
  //       contentType: "",
  //       languages: contentData?.data?.getContent.languages,
  //       categories: JSON.stringify(contentData?.data?.getContent.categories),
  //       price: contentData?.data?.getContent.amount,
  //       currency: contentData?.data?.getContent.currency,
  //       verifiedBy: contentData?.data?.getContent.verifiedBy,
  //       duration: contentData?.data?.getContent.duration.toString(),
  //     })
  //   }
  //     },[contentData?.data?.getContent])

  // console.log(storedCartItem);

  useEffect(() => {
    if (storedCartItem?.userId !== user?.id) {
      console.log(storedCartItem?.userId);
      console.log(user.id);
      localStorage.setItem("contentItem", JSON.stringify({}));
      history.push("/site/explore");
    } else {
      console.log(storedCartItem?.userId);
      console.log(user.id);
    }
  }, []);

  const [isBusketInCart, setIsBusketInCart] = useState(false);

  useEffect(() => {
    // Check if there is any 'busket' item in the cart
    const busketExists = reservationData?.bandaumizaList?.some(
      (item: any) => item.type === "busket"
    );

    // Update the state variable accordingly
    setIsBusketInCart(busketExists);
  }, [reservationData]);

  useEffect(() => {
    const filteredCart = carts.find((item: ICart) => item.id === itemData.id);

    if (filteredCart) {
      setReservationData(filteredCart);
      setFirst(false);
      // console.log("reservationData");
      // console.log("reservationData");
      // console.log(reservationData);
      // console.log(filteredCart);
      // console.log("reservationData");
      // console.log("reservationData");
    } else {
      setReservationData(itemData);
      setFirst(false);
    }
  }, []);

  useEffect(() => {
    setReservationCost(
      1000 * reservationData?.bandaumizaList?.length * defaultFrequency
    );
  }, [reservationData]);

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );

  useEffect(() => {
    if (!(reservationData?.bandaumizaList?.length > 0) && !first) {
      deleteCart(itemData);
    } else {
      // setIsBusketFund(fals);
    }
  });

  const handleSubmitDelete = async () => {
    try {
      const response = await removeContent({
        variables: {
          deleteEvent: {
            contentId: storedCartItem.id,
          },
        },
      });

      if (
        response.data &&
        response.data.removeContent &&
        !response.data.removeContent.status
      ) {
        console.log(response.data.removeContent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        return;
      } else {
        console.log(response.data.removeContent);
        // setRemovedSuccessfully(response.data.removeContent.status);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const handleSubmitDisable = async (value: boolean) => {
    try {
      const response = await disableContent({
        variables: {
          deleteEvent: {
            contentId: storedCartItem.id,
            value: value,
          },
        },
      });

      if (
        response.data &&
        response.data.disableContent &&
        !response.data.disableContent.status
      ) {
        console.log(response.data.disableContent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        return;
      } else {
        console.log(response.data.disableContent);
        // setRemovedSuccessfully(response.data.disableContent.status);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  if (load) {
    return (
      <Card mt={"120px"}>
        {" "}
        <Text
          color={"black"}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Loading ...{" "}
        </Text>
      </Card>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }} backgroundColor={"#240046"}>
          <Textarea
            // type="text"
            name="title"
            variant="none"
            bg={"#10002b"}
            color={"white"}
            fontWeight="bold"
            fontSize="24px"
            mt="10px"
            resize="vertical"
            mb="4px"
            placeholder={contentData?.data?.getContent?.title?.toUpperCase()}
            value={formData?.title?.toUpperCase()}
            onChange={handleChange}
          />

          {contentData?.data?.getContent?.coverImage && (
            <div>
              {contentData?.data?.getContent?.coverImage.trim() !== "" && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  w="100%"
                  // h="100vh"
                  h="auto"
                  // bg="gray.100" // Background color for better visualization
                >
                  {/* <Image
      h="240px"
      w="100%"
      borderRadius="8px"
      me="20px"
      objectFit="cover" 
    /> */}

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    // h="100vh"
                    h="auto"
                    // bg="gray.100" // Background color for better visualization
                  >
                    {/* <Box
        // position="absolute"
        // top="0"
        // left="0"
        w="100%"
        h="300px"
        bgImage={`url(${contentData?.data?.getContent?.coverImage})`}
        bgSize="cover"
        bgPosition="center"
        filter="blur(8px)"
        display={"flex"}



     /> */}

                    <Card bg={cardShadow}>
                      <Box
                        style={{
                          width: 300,
                          marginBottom: 30,
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Text
                          fontWeight="500"
                          color={textColorSecondary}
                          fontSize="sm"
                        >
                          Image should be less than 10Mb Png,Jpeg or Jpg
                        </Text>
                        <Text
                          color={textColorPrimary}
                          fontWeight="500"
                          fontSize="md"
                        >
                          Choose Cover Page
                        </Text>
                        <Input
                          type="file"
                          accept="image/*"
                          variant={"filled"}
                          ref={inputRef}
                          onChange={handleImageChange}
                          mb={4}
                        />
                      </Box>
                      {imageData ? (
                        <>
                          <Button
                            color={"black"}
                            onClick={() => {
                              setImageData(null);
                              setImageFile(null);
                              handleClearImage();
                            }}
                            fontSize="16px"
                            bg={"red.400"}
                            // variant={"link"}
                            me="26px"
                            alignSelf={"start"}
                            mb="5px"
                            mt="20px"
                          >
                            Restore Image
                          </Button>

                          <Image
                            boxSize="350px"
                            objectFit="cover"
                            // src='https://bit.ly/dan-abramov'
                            src={imageData as string}
                            alt="Dan Abramov"
                            className={"centerImgX"}
                            style={{
                              borderWidth: 2,
                              borderColor: "green",
                              borderRadius: 10,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={contentData?.data?.getContent?.coverImage}
                            className={"centerImgX"}

                            // maxW="100%" // Ensures the image does not exceed the box width
                            // maxH="auto" // Ensures the image does not exceed the box height
                            // objectFit="contain"
                            // borderRadius="20px"
                          />
                        </>
                      )}
                    </Card>
                  </Box>
                </Box>
              )}
            </div>
          )}

          <Button
            color={"white"}
            onClick={() => {
              // localStorage.setItem(
              //   "contentOwner",
              //   JSON.stringify({id:contentData?.data?.getContent?.author?.id})
              // );
              history.push(
                `/site/cnt_owner_page?id=${contentData?.data?.getContent?.author?.id}&zIx=890`
              );
            }}
            fontSize="16px"
            variant={"link"}
            bg={"#10002b"}
            me="26px"
            alignSelf={"start"}
            mb="0px"
            mt="20px"
          >
            {contentData?.data?.getContent?.author?.username}
          </Button>
          <Text color={"green"} me="2px" mb="4px" mt="2px">
            {/* {contentData?.data?.getContent?.categories[0]?.name} */}
          </Text>
          <Link
            // bg="#003459"
            color="#4fea74"
            _hover={{ color: "#fff" }}
            fontWeight="500"
            fontSize="14px"
            alignSelf={"end"}
            variant={"link"}
            // py="30px"
            width={"50px"}
            mt={"-20px"}
            // px="47px"
            // me="38px"
            href={contentData?.data?.getContent?.link}
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer"
          >
            Link{" "}
          </Link>
          <Textarea
            color="#4fea74"
            // _hover={{ color: "#fff",}}
            name="link"
            variant="none"
            bg={"#10002b"}
            // color={"white"}
            // fontWeight="bold"
            fontSize="12px"
            mt="10px"
            maxH="100px"
            //  resize="vertical"
            mb="4px"
            placeholder={contentData?.data?.getContent?.link}
            value={formData.link}
            onChange={handleChange}
          />

          <Textarea
            // color="#4fea74"
            // _hover={{ color: "#fff",}}
            name="description"
            variant="none"
            bg={"#10002b"}
            color={"white"}
            height={"auto"}
            // fontWeight="bold"
            fontSize="14px"
            mt="0px"
            //  resize="vertical"
            minHeight={"200px"}
            maxHeight="500px"
            mb="4px"
            placeholder={contentData?.data?.getContent?.description}
            value={formData.description}
            onChange={handleChange}
          />

          <SimpleGrid
            style={{
              marginTop: 20,
              marginBottom: 30,
            }}
            columns={2}
            gap="20px"
          >
            {/* <div>
          <FormLabel
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            display="flex"
          >
            Categories
          </FormLabel>
          <Select
            name="categories"
            value={formData.categories}
             
            color={"white"}

            onChange={handleChange}
            placeholder="Select Category"
          >
            {[...filtersLabels].map((cat:any) => (
              <option key={cat.mode} value={cat.mode}>
                {cat.label}
              </option>
            ))}
          </Select>
        </div> */}

            <div>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Languages
              </FormLabel>
              <Select
                name="languages"
                value={formData.languages}
                bg={"#10002b"}
                color={"white"}
                onChange={handleChange}
                placeholder="Select Language"
              >
                {[
                  { mode: "English", label: "English" },
                  { mode: "Swahili", label: "Swahili" },
                  { mode: "Swahili and English", label: "Swahili and English" },
                ].map((cat: any) => (
                  <option key={cat.mode} value={cat.mode}>
                    {cat.label}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Text style={{ color: "red" }}>
                {errors.price && "Wrong Format of price"}
              </Text>

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Price
              </FormLabel>
              <Input
                type="number"
                name="price"
                color={"white"}
                bg={"#10002b"}
                value={formData.price}
                onChange={handleChange}
              />
            </div>
            <div>
              <Text style={{ color: "red" }}>
                {errors.currency && "Wrong Format of currency"}
              </Text>

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Currency
              </FormLabel>
              {/* <Input
            type="text"
            name="currency"
             
            value={formData.currency}
            onChange={handleChange}
          /> */}
              <Select
                name="currency"
                color={"white"}
                bg={"#10002b"}
                value={formData.currency}
                onChange={handleChange}
                placeholder="Select Currency"
              >
                {[
                  { mode: "Tsh", label: "Tsh" },
                  // { mode: "Usd", label: "Usd" },
                  // { mode: "GBP", label: "GBP" },
                  // { mode: "Euro", label: "Euro" },
                ].map((cat: any) => (
                  <option key={cat.mode} value={cat.mode}>
                    {cat.label}
                  </option>
                ))}
              </Select>
            </div>

            {/* <Information
              boxShadow={cardShadow}
              // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
              title="Verifier"
              value={contentData?.data?.getContent?.verifiedBy}
            /> */}
            {/* <Information
              boxShadow={cardShadow}
              // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
              title="Uploaded"
              value={formatDateTime(contentData?.data?.getContent?.createdAt)}
            />
            <Information
              boxShadow={cardShadow}
              title="Language"
              value={contentData?.data?.getContent?.languages}
            /> */}
          </SimpleGrid>

          <SimpleGrid
            style={{
              marginTop: 20,
              marginBottom: 30,
              display: "flex",
              justifyContent: "center",
            }}
            columns={2}
            gap="10px"
          >
            <Link
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
              style={{ alignSelf: "center" }}
            >
              {user.loginStatus ? (
                <>
                  <Button
                    onClick={(e) => {
                      handleSubmit();

                      // addToCart();
                      // if (idExists) {
                      //   localStorage.setItem(
                      //     "reservationDataContent",
                      //     JSON.stringify({
                      //       reservationData: foundCart,
                      //       update: true,
                      //     })
                      //   );

                      //   localStorage.setItem(
                      //     "contentItem",
                      //     JSON.stringify({
                      //       content: contentData?.data?.getContent,
                      //       update: true,
                      //     })
                      //   );
                      // } else {
                      //   localStorage.setItem(
                      //     "contentItem",
                      //     JSON.stringify({
                      //       content: contentData?.data?.getContent,
                      //       update: false,
                      //     })
                      //   );
                      // }
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log(foundCart);
                      // console.log(idExists);
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");
                      // console.log("foundCart");

                    
                    }}
                    bg="#09bc8a"
                    color="white"
                    _hover={{ bg: "green.700" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="10px"
                    px="7"
                    me="15px"
                  >
                    Update
                  </Button>

                  <Button
                    onClick={(e) => {
                      alert("are you sure ?");
                      handleSubmitDisable(
                        !contentData?.data?.getContent?.published
                      );
                      localStorage.setItem("contentItem", JSON.stringify({}));
                      history.goBack();
                    }}
                    bg={
                      !contentData?.data?.getContent?.published
                        ? "green.300"
                        : "blue.400"
                    }
                    color="white"
                    _hover={{ bg: "blue.700" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="10px"
                    px="7"
                    me="15px"
                  >
                    {!contentData?.data?.getContent?.published
                      ? "Activate"
                      : "Disable"}
                  </Button>

                  <Button
                    onClick={(e) => {
                      alert("are you sure ?");
                      handleSubmitDelete();
                      localStorage.setItem("contentItem", JSON.stringify({}));
                      history.goBack();
                    }}
                    bg="red.300"
                    color="white"
                    _hover={{ bg: "red.700" }}
                    // _active={{ bg: "white" }}
                    // _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="10px"
                    px="7"
                    me="15px"
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <Button
                  onClick={(e) => {
                    // addToCart();

                    history.push("/auth/sign-in");
                  }}
                  bg="#09bc8a"
                  color="white"
                  _hover={{ bg: "#01755c" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  fontWeight="500"
                  fontSize="14px"
                  py="10px"
                  px="7"
                  me="15px"
                >
                  Update
                </Button>
              )}
            </Link>
          </SimpleGrid>
          {errors.responseError && (
            <Text style={{ color: "red" }}>Update Failed</Text>
          )}
        </Card>

        <Card
          maxHeight={"400px"}
          overflowX={"auto"}
          margin={"0px"}
          padding={"5px"}
          //  backgroundColor={"red"}
        >
          {/* {activeOn?.data?.myContentEventsList?.length > 0 && (
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              Bandajamii hostings
            </Text>
          )} */}
          <Card
            maxHeight={"650px"}
            overflowX={"auto"}
            margin={"0px"}
            padding={"0px"}
          >
            <SimpleGrid
              // style={{
              //   // marginTop: 20,
              //   marginBottom: 30,
              //   display: "flex",
              //   justifyContent: "center",
              // }}
              minChildWidth={"250px"}
              gap="10px"
            >
              {activeOn?.data?.myContentEventsList?.length > 0 ? (
                <>
                  {activeOn?.data?.myContentEventsList?.map((item: any) => {
                    return (
                      <Hosting
                        boxShadow={cardShadow}
                        mb="20px"
                        image={Project1}
                        ranking="1"
                        item={item.banda}
                        dateStart={item?.startDateTime}
                        dateEnd={item?.endDateTime}
                        id={item.id}
                        link="#"
                        title={item?.banda?.bandaCode}
                        sponsorId={item?.sponsor?.id}
                        category={
                          item?.sponsor?.anonymous
                            ? "anonymous"
                            : item?.sponsor?.isCompany
                            ? item?.sponsor?.companyName.trim() !== ""
                              ? item?.sponsor?.companyName
                              : "anonymous"
                            : item?.sponsor?.sponsorName.trim() !== ""
                            ? item?.sponsor?.sponsorName
                            : "anonymous"
                        }
                        sponsorName={
                          item?.sponsor?.anonymous === true
                            ? "anonymous"
                            : item?.sponsor?.isCompany
                            ? item?.sponsor?.companyName.trim() !== ""
                              ? item?.sponsor?.companyName
                              : "anonymous"
                            : item?.sponsor?.sponsorName.trim() !== ""
                            ? item?.sponsor?.sponsorName
                            : "anonymous"
                        }
                        home={"Barcelona"}
                        away={"Napoli"}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="2xl"
                    mt="10px"
                    mb="4px"
                  >
                    No any Reservation made
                  </Text>
                </>
              )}
            </SimpleGrid>
          </Card>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
