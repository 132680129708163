import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdTimeline,
  MdCampaign,
  MdPayment,
  MdOutlineAppRegistration,
  MdUpload,
  MdHomeMax,
  MdEventAvailable,
  MdAddToQueue,
  MdConnectedTv,
  MdHouseSiding,
  MdOutlineImage,
  MdWallet,
  MdHandshake,
  MdCamera,
  MdOutlineDashboard,
  MdDescription,
} from "react-icons/md";

// site Imports
import Marketplace from "views/site/marketplace";
import Profile from "views/site/profileSponsor";
import Datatable from "views/site/myPayments";
// import RTL from "views/site/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import LandingView from "views/landing/land";
import SponsorDatatable from "views/site/sponsorDataTables";
import ContentReserve from "views/site/content_reserve";
import CartReview from "views/site/cart_review";
import MyAdds from "views/site/myAdds";
import AvailableEvents from "views/site/events";
import RegisterSponsor from "views/site/registerSponsor";
import RegisterBanda from "views/site/registerBanda";
import Content from "views/site/content";
import ProfileBanda from "views/site/profileBanda";
import ProfileContent from "views/site/profileContent";
import SignUp from "views/auth/signUp";
import MyBandaEvents from "views/site/gallery";
import { IoMdFilm } from "react-icons/io";
import RegisterContentAccount from "views/site/registerContentAccount";
import RegisterNow from "views/site/registerNow";
import SignInBanda from "views/auth/signInBanda";
import UploadContent from "views/site/UploadContent";
import ExploreBanda from "views/site/exploreBanda";
import Donate from "views/site/donate";
import BandaDetails from "views/site/banda_details";
import SponsorDetails from "views/site/sponsor_details";
import BandaEvents from "views/site/BandaReservations";
import { IoCameraOutline, IoListOutline } from "react-icons/io5";
import PromoReserve from "views/site/promo_reserve";
import ProfileUser from "views/site/profileUser";
import MyContents from "views/site/MyContents";
import MyContentView from "views/site/myContentView";
import MyOrders from "views/site/myOrders";
import ViewBandaGallery from "views/site/viewBandaGallery";
import { FaCameraRetro, FaDonate } from "react-icons/fa";
import SavedCart from "views/site/savedCart";
import ExploreSponsors from "views/site/exploreSponsors";
import ContentOwnerPage from "views/site/ContentOwnerPage";
import ExploreContentOwners from "views/site/exploreContentOwners";
import SponnsorAnalysis from "views/site/sponsorAnalysis";
import EventGallery from "views/site/viewBandaGallery copy";
import ContentEdit from "views/site/contentEdit";
import SponsorGallery from "views/site/sponsor_gallery";
import UploadAdd from "views/site/UploadAdd";
import SiteReports from "views/site/siteReports";
import Upload from "views/site/Upload";

const commonRoutes = [
  {
    name: "Home",
    layout: "/landing",
    parent: "home",
    path: "/landing",
    role: "all",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: LandingView,
  },

  {
    name: "Content",
    layout: "/site",
    parent: "home",
    path: "/explore",
    role: "all",
    icon: <Icon as={IoMdFilm} width="20px" height="20px" color="inherit" />,
    component: Marketplace,
    secondary: true,
  },
  {
    name: "Bandajamii",
    parent: "home",
    layout: "/site",
    path: "/banda_explore",
    role: "all",
    icon: <Icon as={MdHomeMax} width="20px" height="20px" color="inherit" />,
    component: ExploreBanda,
    secondary: true,
  },
  {
    name: "Sponsors",
    layout: "/site",
    path: "/sponsor_explore",
    role: "all",
    icon: <Icon as={MdHandshake} width="20px" height="20px" color="inherit" />,
    component: ExploreSponsors,
    secondary: true,
  },
  {
    name: "Content Owners",
    layout: "/site",
    path: "/c_owners_explore",
    role: "all",
    icon: <Icon as={IoCameraOutline} width="20px" height="20px" color="inherit" />,
    component: ExploreContentOwners,
    secondary: true,
  },
  // {
  //   name: "Donate",
  //   layout: "/site",
  //   path: "/donate_here",
  //   role: "all",
  //   icon: <Icon as={FaDonate} width="20px" height="20px" color="inherit" />,
  //   component: Donate,
  //   secondary: true,
  // },
  {
    name: "Banda Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/banda_details",
    component: BandaDetails,
  },
  {
    name: "Sponsor Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/sponsor_details",
    component: SponsorDetails,
  },
  {
    name: "Content owner",
    layout: "/site",
    path: "/cnt_owner_page",
    icon: (
      <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
    ),
    component: ContentOwnerPage,
  },
  {
    name: "Register Sponsor",
    layout: "/site",
    role: "sponsor",
    path: "/register_sponsor",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterSponsor,
    secondary: true,
  },
  {
    name: "Register Content Account",
    layout: "/site",
    role: "sponsor",
    path: "/register_content",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterContentAccount,
    secondary: true,
  },
  {
    name: "Register Bandajamii",
    layout: "/site",
    role: "sponsor",
    path: "/register_banda",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterBanda,
    secondary: true,
  },

  {
    name: "Content Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
  },
  {
    name: "Signin BandaUmiza",
    layout: "/auth",
    path: "/sign_in_bandajamii",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInBanda,
  },
  {
    name: "Content Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content-view",
    component: MyContentView,
  },
  {
    name: "Banda Gallery",
    layout: "/site",
    role: "banda",

    icon: <Icon as={IoMdFilm} width="20px" height="20px" color="inherit" />,
    path: "/view-gallery",
    component: ViewBandaGallery,
  },
  {
    name: "Event Gallery",
    layout: "/site",
    role: "banda",

    icon: <Icon as={IoMdFilm} width="20px" height="20px" color="inherit" />,
    path: "/e_gallery",
    component: EventGallery,
  },
];

const routesContent = [
  {
    name: "Profile Content",
    layout: "/site",
    path: "/profile_content",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: ProfileContent,
  },
  {
    name: "Content Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
  },
  {
    name: "Content Edit",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/m_cont_edit",
    component: ContentEdit,
  },
  {
    name: "Reservation",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/reserve_order",
    component: ContentReserve,
  },

  {
    name: "Register",
    layout: "/site",
    role: "sponsor",
    path: "/register",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterNow,
    secondary: true,
  },
  {
    name: "Upload",
    layout: "/site",
    role: "sponsor",

    path: "/upload",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Upload,
    secondary: true,
  },
  {
    name: "Upload Content",
    layout: "/site",
    path: "/upload_content",
    icon: <Icon as={MdUpload} width="20px" height="20px" color="inherit" />,
    component: UploadContent,
  },
  // {
  //   name: "My Content",
  //   layout: "/site",
  //   path: "/my-content",
  //   icon: (
  //     <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: MyContents,
  // },
  {
    name: "Content owner",
    layout: "/site",
    path: "/cnt_owner_page",
    icon: (
      <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
    ),
    component: ContentOwnerPage,
  },
];

const routesBanda = [
  ...commonRoutes,

  {
    name: "Reserved Events",
    layout: "/site",
    path: "/banda_events",
    icon: (
      <Icon as={MdEventAvailable} width="20px" height="20px" color="inherit" />
    ),
    component: BandaEvents,
  },

  {
    name: "Reservation",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/reserve_order",
    component: ContentReserve,
  },
  {
    name: "My Gallery",
    layout: "/site",
    role: "banda",

    icon: (
      <Icon as={MdOutlineImage} width="20px" height="20px" color="inherit" />
    ),
    path: "/banda-gallery",
    component: MyBandaEvents,
  },

  {
    name: "My Banda",
    layout: "/site",
    path: "/profile_banda",
    icon: (
      <Icon as={MdHouseSiding} width="20px" height="20px" color="inherit" />
    ),
    component: ProfileBanda,
  },
];

const routesSponsor = [
  {
    name: "Advertise",
    layout: "/site",
    role: "sponsor",

    path: "/my_adds",
    icon: <Icon as={MdCampaign} width="20px" height="20px" color="inherit" />,
    component: MyAdds,
    secondary: true,
  },
  // {
  //   name: "Upload",
  //   layout: "/site",
  //   role: "sponsor",

  //   path: "/upload",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: Upload,
  //   secondary: true,
  // },

  {
    name: "Choose Event to advertise",
    layout: "/site",
    role: "sponsor",

    path: "/events",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: AvailableEvents,
    secondary: true,
  },
 
    {
    name: "Content owner",
    layout: "/site",
    path: "/cnt_owner_page",
    icon: (
      <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
    ),
    component: ContentOwnerPage,
  },

  {
    name: "Content",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
  },

  {
    name: "Reserve",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/reserve_order",
    component: ContentReserve,
  },
  {
    name: "Promo Reserve",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/promo-reserve",
    component: PromoReserve,
  },

  {
    name: "My Orders",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdWallet} width="20px" height="20px" color="inherit" />,
    path: "/orders",
    component: MyOrders,
  },

  {
    name: "Cached carts",
    layout: "/site",
    role: "sponsor",

    icon: (
      <Icon as={IoListOutline} width="20px" height="20px" color="inherit" />
    ),
    path: "/saved_cart",
    component: SavedCart,
  },
  {
    name: "Cart",
    layout: "/site",
    role: "sponsor",

    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/cart_review",
    component: CartReview,
  },

  {
    name: "My Payments",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdPayment} width="20px" height="20px" color="inherit" />,
    path: "/my-payments",
    component: Datatable,
  },

  {
    name: "Register",
    layout: "/site",
    role: "sponsor",
    path: "/register",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterNow,
    secondary: true,
  },

  {
    name: "Engagements",
    layout: "/site",
    role: "sponsor",

    icon: <Icon as={MdTimeline} width="20px" height="20px" color="inherit" />,
    path: "/sponsors-tables",
    component: SponsorDatatable,
  },

  {
    name: "Upload Promo",
    layout: "/site",
    path: "/upload_promo",
    icon: <Icon as={MdAddToQueue} width="20px" height="20px" color="inherit" />,
    component: UploadAdd,
  },
  {
    name: "My Gallery",
    layout: "/site",
    role: "banda",

    icon: (
      <Icon as={MdOutlineImage} width="20px" height="20px" color="inherit" />
    ),
    path: "/sponsor_gallery",
    component: SponsorGallery,
  },
  {
    name: "Site Reports",
    layout: "/site",
    role: "site_reports",

    path: "/site_reports",
    icon: <Icon as={MdDescription} width="20px" height="20px" color="inherit" />,
    component: SiteReports,
  },
  {
    name: "My Profile",
    layout: "/site",
    role: "sponsor",

    path: "/sponsor_profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
];

const routesAuth = [
  {
    name: "Home",
    layout: "/landing",
    path: "/landing",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: LandingView,
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
  },
  {
    name: "Content owner",
    layout: "/site",
    path: "/cnt_owner_page",
    icon: (
      <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
    ),
    component: ContentOwnerPage,
  },
  {
    name: "Signin BandaUmiza",
    layout: "/auth",
    path: "/sign_in_bandajamii",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInBanda,
  },
];

const mwananchiRoutes = [
  {
    name: "Home",
    layout: "/landing",
    path: "/landing",
    role: "all",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: LandingView,
  },
  {
    name: "Content owner",
    layout: "/site",
    path: "/cnt_owner_page",
    icon: (
      <Icon as={MdConnectedTv} width="20px" height="20px" color="inherit" />
    ),
    component: ContentOwnerPage,
  },
  {
    name: "Banda Gallery",
    layout: "/site",
    role: "banda",

    icon: <Icon as={IoMdFilm} width="20px" height="20px" color="inherit" />,
    path: "/view-gallery",
    component: EventGallery,
  },
  {
    name: "Event Gallery",
    layout: "/site",
    role: "banda",

    icon: <Icon as={IoMdFilm} width="20px" height="20px" color="inherit" />,
    path: "/e_gallery",
    component: ViewBandaGallery,
  },
  {
    name: "Register",
    layout: "/site",
    role: "sponsor",
    path: "/register",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterNow,
    secondary: true,
  },
  {
    name: "Content",
    layout: "/site",
    path: "/explore",
    role: "all",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Marketplace,
    secondary: true,
  },

  // {
  //   name: "Donate",
  //   layout: "/site",
  //   path: "/donate_here",
  //   role: "all",
  //   icon: <Icon as={FaDonate} width="20px" height="20px" color="inherit" />,
  //   component: Donate,
  //   secondary: true,
  // },
  {
    name: "Bandajamii",
    layout: "/site",
    path: "/banda_explore",
    role: "all",
    icon: <Icon as={MdHomeMax} width="20px" height="20px" color="inherit" />,
    component: ExploreBanda,
    secondary: true,
  },
  {
    name: "Sponsors",
    layout: "/site",
    path: "/sponsor_explore",
    role: "all",
    icon: <Icon as={MdHandshake} width="20px" height="20px" color="inherit" />,
    component: ExploreSponsors,
    secondary: true,
  },
  {
    name: "Banda Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/banda_details",
    component: BandaDetails,
  },
  {
    name: "Sponsor Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/sponsor_details",
    component: SponsorDetails,
  },

  {
    name: "Register Sponsor",
    layout: "/site",
    role: "sponsor",
    path: "/register_sponsor",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterSponsor,
    secondary: true,
  },
  {
    name: "Register Content Account",
    layout: "/site",
    role: "sponsor",
    path: "/register_content",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterContentAccount,
    secondary: true,
  },
  {
    name: "Register Bandajamii",
    layout: "/site",
    role: "sponsor",
    path: "/register_banda",
    icon: (
      <Icon
        as={MdOutlineAppRegistration}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RegisterBanda,
    secondary: true,
  },

  {
    name: "Content Details",
    layout: "/site",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
  },
  {
    name: "Signin BandaUmiza",
    layout: "/auth",
    path: "/sign_in_bandajamii",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInBanda,
  },
];

function filterRoutes(data: any): any[] {
  // console.log("data");
  //  console.log(data.user);

  // Example condition: Filter routes based on a property in the data
  if (data.user.loginStatus === true) {
    if (!(data.user.mode === "banda")) {
      if (data.user.sponsorStatus === "sponsor") {
        if (data.user.contentStatus) {
          return [
            {
              name: "Dashboard",
              layout: "/site",
              role: "sponsor",

              icon: (
                <Icon
                  as={MdOutlineDashboard}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              path: "/dash",
              component: SponnsorAnalysis,
            },
            ...commonRoutes,
            ...routesContent,
            ...routesSponsor,
          ];
        } else {
          return [
            {
              name: "Dashboard",
              layout: "/site",
              role: "sponsor",

              icon: (
                <Icon
                  as={MdOutlineDashboard}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              path: "/dash",
              component: SponnsorAnalysis,
            },
            ...commonRoutes,
            ...routesSponsor,
          ];
        }
      } else if (data.user.accessType === "content") {
        return [...commonRoutes, ...routesContent];
      } else {
        return [
          ...mwananchiRoutes,
          {
            name: "My Profile",
            layout: "/site",
            path: "/profile_user",
            icon: (
              <Icon as={MdPerson} width="20px" height="20px" color="inherit" />
            ),
            component: ProfileUser,
          },
        ];
      }
    } else {
      return routesBanda;
    }
  } else {
    return [...routesAuth, ...mwananchiRoutes];
  }
}

export default filterRoutes;
