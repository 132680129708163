// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";

// Custom Components
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { Dispatch, useEffect, useState } from "react";
import { MdShoppingCart, MdOutlineAppRegistration } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import filterRoutes from "routes";
import ButtonCustom from "components_app/button/Button";
import { loginUser, logoutUser } from "redux.store/user/actionCreators";
import { useHistory } from "react-router-dom";
import { clearCart } from "redux.store/cart/actionCreators";
import { clearCartPromo } from "redux.store/promo/actionCreators";

export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const history = useHistory();
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const { promo }: readonly ICart[] | any = useSelector(
    (state: PromoState) => state.promo,
    shallowEqual
  );

  const routes = filterRoutes({ user: user });

  const blueTheme = {
    body: "#EDF9FE",
    text: "#001C55",
    expTxtColor: "#000a12",
    highlight: "#A6E1FA",
    dark: "#00072D",
    secondaryText: "#7F8DAA",
    imageHighlight: "#0E6BA8",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#0A2472",
    headerColor: "#0E6BA877",
    splashBg: "#001C55",
  };

  const theme = blueTheme;
  const dispatch: Dispatch<any> = useDispatch();

  //Logout
  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );

  const clearPromoCart = React.useCallback(
    () => dispatch(clearCartPromo()),
    [dispatch]
  );

  const clearFundCart = React.useCallback(
    () => dispatch(clearCart()),
    [dispatch]
  );

  const logout = (user: IUser) => {
    logoutService();
    localStorage.setItem("accessToken", "");
    history.push("/landing/landing");
  };

  const clearCarts = () => {
    clearFundCart();
  };
  const clearCartsPromo = () => {
    clearPromoCart();
  };

  const [contentStatus, setContentStatus] = useState("");
  const [sponsorStatus, setSponsorStatus] = useState("");
  const [bandaStatus, setBandaStatus] = useState("");

  useEffect(() => {
    const fetchData = () => {
      // Fetch data for content status
      const contentStatusFromLocalStorage = localStorage.getItem(
        "contentStatus" + user?.name
      );
      if (!contentStatusFromLocalStorage) {
        setContentStatus("notRegContent");
      } else {
        setContentStatus(contentStatusFromLocalStorage);
      }

      // Fetch data for sponsor status
      const sponsorStatusFromLocalStorage = localStorage.getItem(
        "sponsorStatus" + user?.name
      );
      if (!sponsorStatusFromLocalStorage) {
        setSponsorStatus("notRegSponsor");
      } else {
        setSponsorStatus(sponsorStatusFromLocalStorage);
      }

      // Fetch data for banda status
      const bandaStatusFromLocalStorage = localStorage.getItem(
        "bandaStatus" + user?.name
      );

      if (!bandaStatusFromLocalStorage) {
        setBandaStatus("notRegBanda");
      } else {
        setBandaStatus(bandaStatusFromLocalStorage);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every second
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [user?.name]);

  //Login
  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  const loginX = (user: IUser) => {
    loginService(user);
  };

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      align={`center`}
      justify={"space-between"}
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="1px"
      px={"2px"}
      borderRadius="5px"
      boxShadow={shadow}
    >
      {false && (
        <SearchBar
          mb={() => {
            if (secondary) {
              return { base: "10px", md: "unset" };
            }
            return "unset";
          }}
          me="10px"
          borderRadius="30px"
        />
      )}

      {/*  */}
      <SidebarResponsive routes={routes} />

      {/* REGISTER BUTTON */}
      {user?.loginStatus ? 
      <div>{!(user.mode === "banda") &&  <div>
          {
           !( user.contentStatus &&
            user.sponsorStatus === "sponsor" &&
            user.bandaStatus === "banda") && (
              <Flex
                bg={ethBg}
                justifyContent={"space-between"}
                borderRadius={"30px"}
                p={`2px`}
              >
                {/* <Icon
            color={ethColor}
            w="15px"
            h="16px"
            as={MdOutlineAppRegistration}
          /> */}

                <Button
                  onClick={() => {
                    history.push(
                      user.loginStatus ? "/site/register" : "/auth/sign-in"
                    );
                  }}
                >
                  <Text
                    w="max-content"
                    color={ethColor}
                    fontSize="sm"
                    // fontWeight="700"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {!user.loginStatus ? "Signin " : "Register"}
                  </Text>
                </Button>
              </Flex>
            )}
        </div>
}</div>
      
       : 
        <Flex
          bg={ethBg}
          justifyContent={"space-between"}
          borderRadius={"30px"}
          p={`2px`}
        >
          <Button
            onClick={() => {
              history.push(
                user.loginStatus ? "/site/register" : "/auth/sign-in"
              );
            }}
          >
            <Text
              w="max-content"
              color={ethColor}
              fontSize="sm"
              // fontWeight="700"
              style={{
                fontSize: 12,
              }}
            >
              {!user.loginStatus ? "Signin " : "Register"}
            </Text>
          </Button>
        </Flex>
      }

      {true &&
        user.loginStatus &&
        user.sponsorStatus === "sponsor" &&
        !(user.mode === "banda") && (
            <a onClick={() => {
              history.push("/site/cart_review");
            }} >
              <div
                style={{
                  justifyContent: "center",
                }}
              >
                {(carts.length > 0 || promo.length > 0) && (
                  <p
                    style={{
                      position: "absolute",
                      zIndex:0,
                      marginTop: 5,
                      marginLeft: 15,
                      // right:20,
                      height: 20,
                      width: 20,
                      fontSize: 10,
                      backgroundColor: "red",
                      color: "white",

                      textAlign: "center",
                      fontWeight: "bold",
                      borderRadius: 10,
                    }}
                  >
                    {carts.length + promo.length}
                  </p>
                )}
                <Icon
                  mt="6px"
                  as={MdShoppingCart}
                  color={navbarIcon}
                  w="18px"
                  h="18px"
                  me="10px"
                />
              </div>
            </a>
           
        )}

      {user.loginStatus && (
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={user.name ? user.name : user.email}
              bg="#333"
              size="sm"
              w="25px"
              h="25px"
              borderRadius={"30px"}
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                {user.name ? user.name : user.email}
              </Text>
            </Flex>
            {user.loginStatus && (
              <Flex flexDirection="column" p="10px">
                {user.sponsorStatus === "sponsor" &&
                  !(user.mode === "banda") && (
                    <ButtonCustom
                      text={"Profile"}
                      theme={theme}
                      className="portfolio-repo-btn"
                      onClick={() => {
                        history.push("/site/sponsor_profile");
                      }}
                      styles={{
                        color: "black",
                        backgroundColor: "transparent",
                        fontSize: 16,
                        border: "none",
                        margin: 0,
                        padding: 5,
                      }}
                    />
                  )}
                {user.loginStatus &&
                  user.accessType === "content" &&
                  !(user.mode === "banda") &&
                  !(user.sponsorStatus === "sponsor") && (
                    <ButtonCustom
                      text={"Profile"}
                      theme={theme}
                      className="portfolio-repo-btn"
                      onClick={() => {
                        history.push("/site/profile_content");
                      }}
                      styles={{
                        color: "black",
                        backgroundColor: "transparent",
                        fontSize: 16,
                        border: "none",
                        margin: 0,
                        padding: 5,
                      }}
                    />
                  )}
                {user.loginStatus &&
                  user.bandaStatus === "banda" &&
                  !(user.mode === "banda") && (
                    <ButtonCustom
                      text={`My Bandajamii`}
                      theme={theme}
                      className="portfolio-repo-btn"
                      onClick={() => {
                        history.push("/auth/sign_in_bandajamii");
                      }}
                      styles={{
                        color: "black",
                        backgroundColor: "transparent",
                        fontSize: 16,
                        border: "none",
                        margin: 0,
                        padding: 5,
                      }}
                    />
                  )}

                {user.loginStatus && (
                  <ButtonCustom
                    text={
                      user.mode === "banda" ? "Log out Bandajamii" : "Log out"
                    }
                    theme={theme}
                    className="portfolio-repo-btn"
                    onClick={() => {
                      clearCarts();
                      clearCartsPromo();
                      logout(user);
                    }}
                    styles={{
                      color: "red",
                      backgroundColor: "transparent",
                      fontSize: 14,
                      border: "none",
                      margin: 0,
                      marginTop: 30,
                      padding: 5,
                    }}
                  />
                )}
              </Flex>
            )}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
