// Chakra imports
import {
  Text,
  Box,
  Grid,
  useColorModeValue,
  Button,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "./gallery.css";
import { useEffect, useRef, useState } from "react";

import Card from "components/card/Card";
import Banner from "./components/Banner";
import { useGetBandaQuery, useMyBandaQuery } from "generated/graphql";
import { useSelector } from "react-redux";

export default function ViewBandaGallery() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [imageView, setImageView] = useState("");

  const storedCartItemString = localStorage.getItem("bandaItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);

  const contentData = useGetBandaQuery({
    fetchPolicy: "network-only",
    variables: {
      findBandaumizaInput: {
        bandaId: storedCartItem.id,
      },
    },
  });
  const [first, setFirst] = useState(false);

  useEffect(() => {
    if (!first && contentData?.data?.getBanda?.gallery.length > 0) {
      setImageView(contentData?.data?.getBanda?.gallery[0]);
      setFirst(true);
    }
  }, [contentData?.data, contentData.loading]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 1"
          banner={"banner"}
          name={contentData?.data?.getBanda?.bandaCode}
          avatar={imageView}
        />

        <Card
          mb={{ base: "0px", "2xl": "20px" }}
          style={{
            height: "100%",
          }}
        >
          <SimpleGrid minChildWidth="150px" spacing="1px">
            {contentData?.data?.getBanda?.gallery?.map((image: string) => {
              if (!(image.trim() === "")) {
                return (
                  <div
                    style={
                      {
                        // backgroundColor: "red",
                      }
                    }
                  >
                    <Box
                      onClick={() => {
                        setImageView(image);
                      }}
                      height="210px"
                    >
                      <Image
                        src={image}
                        boxSize="210px"
                        objectFit="cover"

                        // w={{ base: "100%", "3xl": "100%" }}
                        // h={{ base: "100%", "3xl": "100%" }}
                        // borderRadius="20px"
                      />
                    </Box>
                  </div>
                );
              }
            })}
          </SimpleGrid>
        </Card>
      </Grid>
    </Box>
  );
}
