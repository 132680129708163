import * as actionTypes from "./actionTypes"


export function simulateHttpRequest(action: UserActions) {

  return (dispatch: UserDispatchType) => {
   
    setTimeout(() => {
      // console.log("actffion");
      // console.log(action);
      // console.log("fffffff");
      
      dispatch(action)
    }, 500)
  }
}


// USER
  export function loginUser(user: IUser) {
 
    const action: UserActions = {
      type: actionTypes.LOGIN_USER,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }
  
  export function registerUser(user: IUser) {
 
    const action: UserActions = {
      type: actionTypes.REGISTER_USER,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }
  
  export function logoutUser() {
   
    const action: UserActions = {
      type: actionTypes.LOGOUT_USER,
      user: {
        id: "",
        name: "",
        bandaCode:  "",

       email:"",
       accessType:"",
       accountStat:"",
       contentStatus: false,

       //#Check
       loginStatus:false,
       language:"en",
              //#Check
       darkMode:true,
       loading: false,



      }, // Pass null for the user field since it's not relevant here.
    };
    return simulateHttpRequest(action);
  }
  



  export function registerPartnership(user: IUser) {
    //#Check 
    // validate if logged in in api
    // validate in front end
    const action: UserActions = {
      type: actionTypes.REGISTER_PARTNERSHIP,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }


  export function cancelPartnership(user: IUser) {
   //#Check 
    // validate if logged in in api
    // validate in front end
    const action: UserActions = {
      type: actionTypes.CANCEL_PARTNERSHIP,
      user: {
        ...user,
       accessType:"ACL2",
      }, // Pass null for the user field since it's not relevant here.
    };
    return simulateHttpRequest(action);
  }
  
  export function changeLanguage(user: IUser) {
    //#Check 
    // validate if logged in in api
    // validate in front end
    const action: UserActions = {
      type: actionTypes.CHANGE_LANGUAGE,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }

  export function toggleDarkModeState(user: IUser) {
    //#Check 
    // validate if logged in in api
    // validate in front end
    const action: UserActions = {
      type: actionTypes.DARK_MODE,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }

  export function verifyAccount(user: IUser) {
    //#Check 
    // validate if logged in in api
    // validate in front end
    // console.log("verfffgcjchh");
    // console.log("verfffgcjchh");
    // console.log("verfffgcjchh");
    // console.log(user);
    
    const action: UserActions = {
      type: actionTypes.VERIFY,
      user, // Set the user field with the user object you want to login.
    };
    return simulateHttpRequest(action);
  }
