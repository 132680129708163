import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import SwitchField from "components/fields/SwitchField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { findCurrentBandaStatus } from "views/site/helpers";

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string;
  bandaAccountInfo: any;
  job: string;
  posts: number | string;
  followers: number | string;
  following: number | string;
  [x: string]: any;
}) {
  const {
    bandaAccountInfo,
    banner,
    avatar,
    name,
    job,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = "white"//useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log("bandaAccountInfo");
  console.log("bandaAccountInfo");
  console.log(bandaAccountInfo);
  console.log("bandaAccountInfo");
  console.log("bandaAccountInfo");

  const [bandaList, setBandaList] = useState(bandaAccountInfo); //

  useEffect(() => {
    if (bandaAccountInfo) {
      setBandaList(bandaAccountInfo);
    }
  }, [bandaAccountInfo]);

  return (
    <Card
      mb={{ base: "0px", lg: "20px" }}
      alignItems="center"
      {...rest}
      backgroundColor={"#240046"}

      style={
        {
          height: 230,
        }
      }
    >
      
      <Box
        // bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="67px"
        w="67px"
        mt="-35px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="1px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">General Content Details</Text>

      <Flex w="max-content" mx="auto" mt="16px">
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Content
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
           Hosted 
          </Text>
        </Flex>
        <Flex mx="auto" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Promo taged
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
