/* eslint-disable */
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/imageHolder.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useRegistrationMutation } from "generated/graphql";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Card from "components/card/Card";

interface FormData {
  email: string;
  password: string;
  username: string;
  repeatPassword: string;
}

interface FormErrors {
  [key: string]: string;
}

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [loadingState, setLoadingState] = useState(false);

  // // const textColor = useColorModeValue("navy.700", "white");
  // const textColor = useColorModeValue("white", "white");
  // // const textColorSecondary = "gray.400";
  // const textColorSecondary = "white";
  // // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  // const textColorDetails = useColorModeValue("white", "secondaryGray.600");
  // // const textColorBrand = useColorModeValue("brand.500", "white");
  // const textColorBrand = useColorModeValue("white", "white");
  // const brandStars = useColorModeValue("brand.500", "brand.400");
  // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  // const googleText = useColorModeValue("navy.700", "white");

  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [showRepeat, setShowRepeat] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClickRepeat = () => setShowRepeat(!showRepeat);

  // ########################
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
    repeatPassword: "",
    username: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [success, setSuccess] = useState(false);

  // const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { id, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [id]: value,
  //   });
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      loginMobileResponse: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "email":
        validateEmail(value);
        break;
      case "username":
        validateUsername(value);
        break;
      case "password":
        validatePassword(value);
        comparePasswords(formData.password, value);
        break;
      case "repeatPassword":
        comparePasswords(formData.password, value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (formData.password === formData.repeatPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "",
        password: "",
      }));
    }
  }, [formData.password, formData.repeatPassword]);

  const comparePasswords = (
    password: string,
    repeatPassword: string
  ): boolean => {
    if (password !== repeatPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "Passwords do not match",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatPassword: "",
      }));
      return true;
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const validateUsername = (username: string) => {
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!username.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "Username is required",
      }));
    } else if (!usernameRegex.test(username)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "Invalid username",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "",
      }));
    }
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d\s])[^\s]{8,}$/;
    if (!password.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
    } else if (password.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Must have 8 char, Capital leter , sign and a number",
      }));
    } else if (!passwordRegex.test(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must have 8 chars that contain at least one lowercase,uppercase,digit,special character and no space",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleSubmit = () => {

    console.log(formData);

    validateEmail(formData.email);
    validatePassword(formData.password);
    comparePasswords(formData.password, formData.repeatPassword);

    if (
      errors.email ||
      errors.username ||
      errors.password ||
      errors.repeatPassword
    ) {
      console.log("Put proper details");
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Please use valid credentials",
      }));
      return; // Exit if fields are empty
    } else if (
      !formData.email ||
      !formData.username ||
      !formData.password ||
      !formData.repeatPassword
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Please Put valid credentials",
      }));
      console.log("Please enter both email and password");
      return; // Exit if fields are empty
    } else {
      try {
        setLoadingState(true)

        onAddUserClick();
      } catch (e) {
        return;
      }
    }


  };

  const [register, loading] = useRegistrationMutation();

  // NAVIGATION FUNCTIONS
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const onAddUserClick = async () => {
    try {
      const response = await register({
        variables: {
          registerInput: {
            username: formData.username,
            password: formData.password,
            regData: formData.email,
          },
        },
      });
      console.log(response);

      if (
        response.data &&
        response.data.registration &&
        !response.data.registration.log
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          registrationResponseError: response?.data?.registration?.message,
        }));
        setLoadingState(false)

        return;
      } else {
        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          registrationResponseError: "",
        }));
        setSuccess(true);
        setLoadingState(false)

      }
    } catch (e) {
      console.log("Error failed");
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrationResponseError: "Login failed",
      }));
      setSuccess(false);
      setLoadingState(false)

    }
    //  }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {!loadingState ? (
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
          style={{ marginTop: "2%" }}
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              Creating Account
            </Heading>
            {!success && (
              <Text
                mb="36px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                Use your email
              </Text>
            )}
          </Box>

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign Up with Google
          </Button> */}

            {/* <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}

            {!success && (
              <form>
                <FormControl>
                  {errors.email && (
                    <Text style={{ color: "red" }}>
                      Please use a valid email
                    </Text>
                  )}

                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="mail@gmail.com"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <Text style={{ color: "red" }}>
                      Please use a valid username
                    </Text>
                  )}

                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Username<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="username"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <Text style={{ color: "red" }}>{errors.password}</Text>
                  )}

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="24px"
                      size="lg"
                      type={show ? "text" : "password"}
                      variant="auth"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.repeatPassword && (
                    <Text style={{ color: "red" }}>
                      {errors.repeatPassword}
                    </Text>
                  )}

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Confirm Password<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="24px"
                      size="lg"
                      type={showRepeat ? "text" : "password"}
                      variant="auth"
                      name="repeatPassword"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={showRepeat ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClickRepeat}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    mb="24px"
                  ></Flex>

                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    style={{
                      backgroundColor: "#461177",
                    }}
                  >
                    Create
                  </Button>

                  {errors.registrationResponseError && (
                    <Text style={{ color: "red" }}>
                      {errors.registrationResponseError}
                    </Text>
                  )}
                </FormControl>
              </form>
            )}

            {success && (
              <Box
                style={{
                  marginTop: 100,
                }}
              >
                <Card>
                  <Text color={"green.800"}>Registered Successfully</Text>
                </Card>
                <Button
                  onClick={() => {
                    history.push("/auth/sign-in");
                  }}
                  fontSize="sm"
                  variant="fill"
                  backgroundColor={"green.400"}
                  color={"white"}
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  Go to Login Page
                </Button>
              </Box>
            )}
            {!success && (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                  Have an account ?
                  <Link
                    onClick={() => history.push("/auth/sign-in")}
                    mt={{
                      base: "0px",
                      md: "10px",
                      lg: "0px",
                      xl: "10px",
                      "2xl": "0px",
                    }}
                  >
                    <Text
                      color={textColorBrand}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                    >
                      Login to an existing Account
                    </Text>
                  </Link>
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
          <div className="flex justify-start  p-12">
            <div
              className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>


        </div>
      )}
    </DefaultAuth>
  );
}

export default SignUp;
