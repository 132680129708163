import "./assets/css/App.css";
import "./index.css";
import { Provider } from "react-redux";
import store from "redux.store/storage";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import "./assets/fonts/Lexend/Lexend-VariableFont_wght.ttf";
import App from "App";
import { NextUIProvider } from "@nextui-org/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <NextUIProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </NextUIProvider>
);

reportWebVitals();
