import { useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  SimpleGrid,
  WrapItem,
  Wrap,
} from "@chakra-ui/react";

// Custom components

// Assets
import Pic1 from "assets/images/imageHolder.png";
import Pic2 from "assets/images/imageHolder.png";
import Pic3 from "assets/images/imageHolder.png";
import Pic4 from "assets/images/haki.jpg";
import Pic5 from "assets/images/imageHolder.png";
import Pic6 from "assets/images/imageHolder.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
// import Avatar from "assets/img/avatars/Avatar.png";
import { removeCart } from "redux.store/cart/actionCreators";
import { shallowEqual, useSelector } from "react-redux";
import { CartEvent } from "components_app/CartEvent/Cart";
import { useContentListQuery, useEventsListQuery } from "generated/graphql";
import { useLocation } from "react-router-dom";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
import Card from "components/card/Card";

export const filtersLabels = [
  { mode: "Afya", label: "Afya" },
  { mode: "Vocational", label: "Vocational" },
  // { mode: "Recently", label: "Recently" },
  { mode: "Legal", label: "Legal" },
  { mode: "Health and Wellness", label: "Health and Wellness" },
  { mode: "Community Development", label: "Community Development" },
  { mode: "Food and Nutrition", label: "Food and Nutrition" },
  { mode: "Entertainment", label: "Entertainment" }, // music and art
  { mode: "Entrepreneurship", label: "Entrepreneurship" },
  { mode: "Sports", label: "Sports" },
  { mode: "Spiritual", label: "Spiritual" },
  { mode: "Education", label: "Education" },
  { mode: "Environment", label: "Environment" },
  { mode: "Technology and ICT", label: "Technology and ICT" },
  { mode: "Others", label: "Others" }
]

export const sampleData2 = [
  {
    id: "123khk",
    images: ["../../../assets/images/content.png", "khg"],
    image: Pic1,
    videos: ["hjgj", "fjjj"],
    title: "Elimu ya Afya",
    name: "Mazingira",
    type: "ADD",

    owner: "By Balozi ya Ufaransa",
    description: "descriptiongfch",
    body: "Afya ya uzazi, akili na mazingira",
    othersMetaData: ["tracking data"],
    price: 0,
    currency: "gj",
    discount: "5%",
    //add fee
    //status in metadata
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "43122sas",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic2,
    videos: ["hjgj", "fjjj"],
    title: "Semina ya vijana",
    owner: "Haki Elimu",
    type: "ADD",

    description: "descriptiongfch",
    body: "Vijana na kujituma",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "42323jjkh",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic3,
    videos: ["hjgj", "fjjj"],
    title: "Ujasiliamali",
    owner: "Wizara ya Afya",
    type: "ADD",

    description: "descriptiongfch",
    body: "Kuhusu kujizalishia kipato",
    othersMetaData: ["tracking data"],
    price: 0,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "12345jdsl",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic4,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "ADD",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
  {
    id: "1232ljc3121",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic5,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "ADD",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },

  {
    id: "321hk414k1",
    name: "jhj",
    images: ["../../assets/images/content.png", "khg"],
    image: Pic6,
    videos: ["hjgj", "fjjj"],
    title: "Ulinzi shirikishi",
    owner: "Usalama Tanzania",
    type: "ADD",

    description: "descriptiongfch",
    body: "Wajibu na Haki za Raia katika kudumisha amani",
    othersMetaData: ["tracking data"],
    price: 30000,
    discount: "",
    data: "mgh",
    offerData: {},
    items: 2,
    dataOnDiscountsPerItemsBought: {},
  },
];

export default function AvailableEvents() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const promoId = queryParams.get("id");

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [page, setPage] = useState(1);
  const [pageTemp, setPageTemp] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [category, setCategory] = useState("all");

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const { loading, error, data, refetch } = useEventsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getEventsInput: {
        userId: user.id,
        page,
        pageSize,
        category,
      },
    },
  });

  console.log(data?.eventsList);

  const handleShow = () => {
    console.log("information");
    console.log("information");
    return setShow(true);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filtering, setFiltering] = useState(false);


  return (
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>





      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(1, 1fr)",
           "2xl": "1fr 0.46fr"
           }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        


        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
        >
          {/* <Banner /> */}
          <Flex direction="column">
            {/* <Text
              style={{
                marginTop: 50,
              }}
              color={textColor}
              fontSize="2xl"
              ms="24px"
              fontWeight="700"
              //@ts-ignore
            >Choose Event To embed your PROMO</Text> */}
            <Flex
              mt="45px"
              mb="20px"
              justifyContent="space-between"
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}
            >
              <Flex
                align="center"
                me="20px"
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}
              >
                <Wrap
                  spacing={3}
                  style={{
                    marginTop: 0,
                    // backgroundColor: "red",
                    borderRadius: 20,
                    width: "100%",
                  }}
                >
                  {/* {filtersLabels.map((cat: any) => (
                    <WrapItem key={cat.label}>
                      <Button
                        bg="#3d5a80"
                        color={"white"}
                        _hover={{ bg: "#007bba" }}
                        _active={{ bg: "black" }}
                        _focus={{ bg: "black" }}
                        fontWeight="500"
                        fontSize="13px"
                        py="30px"
                        style={{
                          margin: 10,
                        }}
                      >
                        {cat.label}
                      </Button>
                    </WrapItem>
                  ))} */}
                </Wrap>
              </Flex>
            </Flex>

            <div className="grid sm:grid-cols-3 md:grid-cols-3 mb-5  gap-2 my-1">
          <div className={` sm:col-span-2 bg-white shadow-2xl rounded-lg `}>
        
      { !loading ? <div>
            <Card
          // mb={{ base: "0px", "2xl": "20px" }}
          borderRadius="8px"
          style={{
            padding: 7,
            width:"98%",
            margin:"1%",
            marginRight:"2%",
            backgroundColor: "#37005d",
          }}
        >
           <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={{ base: "14px", md: "24px" }}
              color={"#eee5e9"}
              mb="14px"
              maxW={{
                base: "100%",
                md: "64%",
                lg: "46%",
                xl: "70%",
                "2xl": "50%",
                "3xl": "42%",
              }}
              fontWeight="700"
              padding="5px"
              lineHeight={{ base: "16px", md: "21px" }}
            >
              Search
            </Text>
            {filtering ? (
              <Button
                bg={"inherit"}
                marginBottom={"5px"}
                // marginRight={"-1%"}
                _hover={{
                  bg: "#1f0033",
                  marginRight: "0%",
                }}
                onClick={() => {
                  setFiltering(false);
                }}
              >
                <Icon
                  as={MdArrowDownward}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Button>
            ) : (
              <Button
                bg={"inherit"}
                // marginRight={"-1%"}
                marginBottom={"5px"}
                _hover={{
                  bg: "#1f0033",
                  marginRight: "0%",
                }}
                onClick={() => {
                  setFiltering(true);
                }}
              >
                <Icon
                  as={MdArrowUpward}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </Button>
            )}
          </Box>
   
        </Card>
  

          <SimpleGrid columns={{ base: 1, md: 1 }} gap="10px">
              {data?.eventsList?.map((cart: any) => {
                const existingCart = carts.find(
                  (item: ICart) => item.id === cart.id
                );
                return (
                  <CartEvent
                    key={cart.id}
                    cart={cart}
                    //@ts-ignore
                    promoId={promoId}
                    removeCart={removeCart}
                    selected={existingCart}
                    handleShow={handleShow}
                  />
                );
              })}
            </SimpleGrid>
            </div>:   <div>
              <>
                <div className="flex justify-center mt-20 h-screen p-12">
                  <div
                    className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            </div>}
          </div>
          <div className={` sm:col-span-1 bg-white shadow-xl  rounded-lg p-4 items-end`}>
            <h5 className="text-xl ml-2 font-bold leading-none text-green-600 ">
              <span className="text-gray-900">Recommended Contents</span>
            </h5>
          </div>
        </div>


          
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
}
