import { Box, Icon, Flex, Image, SimpleGrid, Text, Wrap, Tag, Link } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useColorModeValue } from "@chakra-ui/react";
import Project1 from "assets/images/imageHolder.png";
import Information from "views/site/profileSponsor/components/Information";
import { Button } from "@chakra-ui/react";
import { Event, useGetBandaQuery } from "generated/graphql";
import Project from "./components/Project";
import { useHistory, useLocation } from "react-router-dom";
import { IoImageOutline } from "react-icons/io5";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import { BgisModal } from "../exploreBanda/components/bgisModal";


export default function BandaDetails() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();
  // const storedCartItemString = localStorage.getItem("bandaItem");


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location.state);
  const bandaIDd = queryParams.get('id');



  // const storedCartItem = JSON.parse(storedCartItemString);
  const [mtaa, setMtaa] = useState("");
  const [ongezaKikundi, setOngezaKikundi] = useState(false);

 

  const contentData = useGetBandaQuery({
    fetchPolicy: "network-only",
    variables: {
      findBandaumizaInput: {
        bandaId: bandaIDd,
      },
    },
  });

  console.log("contentData");
  console.log("contentData");
  console.log(bandaIDd);
  console.log(contentData);
  console.log("contentData");
  console.log("contentData");
  

  const funct =(bandax?:any)=>{
    setMtaa(contentData?.data?.getBanda?.ward)
    setOngezaKikundi(true)
  }

  const calendarRef = useRef(null);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
   

      if (contentData?.data?.getBanda?.eventsFunds?.length > 0) {
        // console.log("Banda:", banda);
        // console.log("Events Funds:", banda?.eventsFunds);

        const allScheduledData = contentData?.data?.getBanda?.eventsFunds?.map((item: Event) => {
          // console.log("Events :", item);
          if (!(item?.startDateTime === "") && !(item?.endDateTime === "")) {
            return {
              title: item.title,
              // eventType: item.eventType,
              start: item.startDateTime,
              end: item.endDateTime,
              color:  item.eventType === "FUNDED" ? "#2d6a4f" : item.eventType === "PRIVATE"  ? "#468faf": "",
            };
          }
        });

        const initialEventsDB = allScheduledData?.filter(
          (item) => item !== undefined
        );

       


        setCalendarEvents([...initialEventsDB]);
        
      }
    
  }, [contentData?.data?.getBanda]);

  return (
    <div>
    {!contentData.loading ? <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}
        backgroundColor={"#240046"}
        height={"max-content"}
        borderRadius={"10px"}
        paddingBottom={"30px"}
        
        >
          <Flex
            direction={"row"}
            style={{
              alignSelf: "center",
              width: "100%",
            }}
          >
            {contentData?.data?.getBanda?.coverImage?.trim() === "" ||
            !contentData?.data?.getBanda?.coverImage ? (
              <Icon
                as={IoImageOutline}
                w="30px"
                h="30px"
                style={{
                  alignSelf: "center",
                  height: 30,
                }}
              />
            ) : (
              <Image
                src={contentData?.data?.getBanda?.coverImage}
                w={"40px"}
                h={"40px"}
                borderRadius="40px"
              />
            )}
            <Text
              color={"white"}
              fontWeight="bold"
              fontSize="2xl"
              mt="1px"
              mb="4px"
              marginLeft={"10px"}
            >
              {contentData?.data?.getBanda?.bandaCode?.toUpperCase()}
              <Icon

as={MdVerifiedUser} 
width="15px"
 height="15px"
 color="green"
 position={"absolute"}
 alignSelf={"end"}
 fontSize="12px"
 fontWeight="500"
 borderRadius="70px"
//  marginLeft={"50%"}
 mb={"23%"} 
 />
            </Text>
          </Flex>
          <Text
            color={textColorSecondary}
            fontSize="md"

            me="26px"
            mb="0px"
            mt="20px"
            style={{
              fontWeight:"600"
            }}
          >
            {contentData?.data?.getBanda?.bandaName}
          </Text>
          <Flex flexDirection={"row"}>
          <Link
            // bg="#003459"
            color="#9ef01a"
            _hover={{ color: "white" }}
            // _active={{ bg: "#f4f4f4" }}
            // _focus={{ bg: "#f4f4f4" }}
            fontWeight="500"
            fontSize="14px"
            // alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // target="_blank" // Opens the link in a new tab
            onClick={()=>{
              funct()}}
           rel="noopener noreferrer"
          >{contentData?.data?.getBanda?.region}</Link>

<Link
            // bg="#003459"
            color="#9ef01a"
            _hover={{ color: "white" }}

            fontWeight="500"
            fontSize="14px"
            alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // target="_blank" // Opens the link in a new tab
            onClick={()=>{
              funct()}}
           rel="noopener noreferrer"
          > , {contentData?.data?.getBanda?.district}</Link>

<Link
            // bg="#003459"
            color="#9ef01a"
            _hover={{ color: "white" }}

            fontWeight="500"
            fontSize="14px"
            alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // target="_blank" // Opens the link in a new tab
            onClick={()=>{
              funct()}}
           rel="noopener noreferrer"
          >, {contentData?.data?.getBanda?.ward}</Link>




          
          
          
          </Flex>
          <Link
            // bg="#003459"
            color="#9ef01a"
            _hover={{ color: "white" }}

            fontWeight="500"
            fontSize="14px"
            alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // target="_blank" // Opens the link in a new tab
           rel="noopener noreferrer"
           onClick={()=>{
            funct()}}
          >{contentData?.data?.getBanda?.physicalResidency}</Link>

          {/* <Text
            color={textColorSecondary}
            fontSize="md"
            me="26px"
            mb="40px"
            mt="20px"
          >
            {contentData?.data?.getBanda?.description}
          </Text> */}

          <Wrap mt="5">
            {[
  contentData?.data?.getBanda?.drinks ? "drinks" : "null",
  contentData?.data?.getBanda?.food ? "food" : "null",
  contentData?.data?.getBanda?.projector ? "projector" : "null",
  contentData?.data?.getBanda?.wifi ? "wifi" : "null",
  contentData?.data?.getBanda?.generator ? "generator" : "null",
  contentData?.data?.getBanda?.vikalioKutoka ? "vikalio vinatoka" : "null",
  contentData?.data?.getBanda?.laptop ? "laptop" : "null",
  contentData?.data?.getBanda?.airConditioner ? "AC" : "null",
  contentData?.data?.getBanda?.feni ? "feni" : "null",
]
  .filter(facility => facility !== "null")
  .map((value, index) => (
    <Tag
      key={index}
      variant="subtle"
      colorScheme="purple"
      rounded="full"
      fontSize={"13px"}
      px="3"
    >
      {value}
    </Tag>
  ))}

          </Wrap>

          <SimpleGrid
            style={{
              marginTop: 20,
              marginBottom: 30,
            }}
            // columns={5}
            minChildWidth={"100px"}
            gap="10px"
          >
            <Information
              boxShadow={cardShadow}
              title="capacity"
              value={contentData?.data?.getBanda?.capacity}
            />
            <Information
              boxShadow={cardShadow}
              title="Screens"
              value={contentData?.data?.getBanda?.tvSize ? `${JSON.parse(contentData?.data?.getBanda?.tvSize)?.length} Size ${JSON.parse(contentData?.data?.getBanda?.tvSize)?.toString()}`:""}
            />
            <Information
              boxShadow={cardShadow}
              title="Decoders"
              value={contentData?.data?.getBanda?.decoders ? JSON.parse(contentData?.data?.getBanda?.decoders).toString():""}
              // value={"contentData?.data?.getBanda?.decoders ? JSON.parse(contentData?.data?.getBanda?.decoders).toString():"}
            />
            <Information
              boxShadow={cardShadow}
              title="Reservations"
              value={
                `${contentData?.data?.getBanda?.eventsFunds.length} ${contentData?.data?.getBanda?.eventsFunds.length > 12 ? "+": ""}`
              }
            />
             <Information
              boxShadow={cardShadow}
              title="Size"
              value={
                `${contentData?.data?.getBanda?.bandaSize}`
              }
            />
          </SimpleGrid>

          <Button
            bg="#003459"
            color="white"
            _hover={{ bg: "#00a8e8" }}
            _active={{ bg: "#007ea7" }}
            _focus={{ bg: "#007ea7" }}
            fontWeight="500"
            fontSize="14px"
            py="20px"
            width={"10%"}
            alignSelf={"center"}
            px="47px"
            me="38px"
            onClick={() => {
              history.push("/site/view-gallery");
            }}

          >
            Gallery
          </Button>
        </Card>

        <Card 
                borderRadius={"10px"}

 margin={"0px"}
 marginTop={"0px"}
//  bg={"red"}
 padding={"10px"}        
        >
           {/* <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"

            mt="10px"
            mb="4px"
          >
            Activities
          </Text> */}

          <Card 
          // mb={{ base: "0px", "2xl": "20px" }}
        maxHeight={"650px"}
        overflowX={"auto"} 
        margin={"0px"}
        padding={"0px"}
        borderRadius={"10px"}

        >
          <SimpleGrid
            style={{
              // marginTop: 20,
              marginBottom: 30,
            }}
            // columns={5}
            minChildWidth={"250px"}
            gap="10px"
            borderRadius={"10px"}
          >
 {contentData?.data?.getBanda?.eventsFunds.map((item: any) => {
     
     return (
       <>
         <Project
           boxShadow={cardShadow}
           mb="20px"
           image={Project1}
           item={item}
           ranking={item.createdAt}
           dateStart={item.id}
           id={item.id}
           eventType={item.eventType}
           title={item.title}
           category={item.description}
           added={"itemExist"}
           away={"Napoli"}
         />
       </>
     );
   })}
          </SimpleGrid>
         
        </Card>
        </Card>
      </SimpleGrid>

      <Card mb={"20px" } 
      zIndex={0} borderRadius={"10px"} backgroundColor={"#f2ebfb"}>
     
      <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              selectable={false}
              selectConstraint="businessHours"
              
height={"800px"}

              businessHours={{
                daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday to Sunday
                startTime: "06:00", // 6:00 AM
                endTime: "00:00", // 6:00 PM
              }}
              events={calendarEvents}
              // slotDuration="01:00:00"
            />
      </Card>

      {/* <BgisModal setOpen={setOngezaKikundi} open={ongezaKikundi} address={mtaa}  /> */}

    </Box>:
    <div>
   <div className="flex justify-center mt-20 h-screen">
            <div
              className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
    </div>}
    </div>
  );
}
