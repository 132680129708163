// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import { MdAdd, MdAddBox, MdAddTask, MdEdit, MdPostAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";

export default function Project(props: {
  title: string;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const { title, ranking, link,item, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory()
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        <Image
          h="80px"
          w="80px"
          src={image}
          borderRadius="8px"
          me="20px"
          objectFit="cover"
        />
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {title}
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >
            Date • {ranking.toLocaleString("en-GB").substring(0, 10)}
          </Text>
        </Box>
        <Box
          me="16px"
          ms="auto"
          p="0px !important"
        ><Button
            fontSize="sm"
            variant="solid"
            fontWeight="500"
            w="100"
            // h="50"
            mb="4px"
            onClick={() => {
              // console.log(cart);
              // console.log(cart);
              // console.log(cart);
              localStorage.setItem("contentItem", item);

              history.push("/site/content", {
                itemData: "qqsad",
                update: true,
              });
            }}
          >
            VIEW
          </Button>
        </Box>
      </Flex>
    </Card>
  );
}
