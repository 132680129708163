import { Box, SimpleGrid, Text } from "@chakra-ui/react";

import Card from "components/card/Card";
import { useColorModeValue, Image } from "@chakra-ui/react";
// Custom components
import Information from "views/site/profileSponsor/components/Information";

import { Button, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useHistory, useLocation } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import {
  addCart,
  removeCart,
  updateCart,
} from "redux.store/cart/actionCreators";
import {
  useDisableContentMutation,
  useGetContentQuery,
  useRemoveContentMutation,
} from "generated/graphql";
// Custom components

export interface FormData {
  title: string;
  description: string;
  eventType: string;
  closingTime?: string;
  openingTime?: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function MyContentView() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

  const location = useLocation();

  const storedCartItemString = localStorage.getItem("contentItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);
  const itemData = storedCartItem;

  const navigateThroughLinks = (navData: INavigate) => {
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`);
  };

  const saveToTheCart = React.useCallback(
    (cart: ICart) => dispatch(addCart(cart)),
    [dispatch]
  );

  const updateCartState = React.useCallback(
    (cart: ICart) => dispatch(updateCart(cart)),
    [dispatch]
  );

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [reservationCost, setReservationCost] = useState(1000);
  //@ts-ignore
  const [reservationData, setReservationData] = useState<ICart>([]);
  const [first, setFirst] = useState(true);

  const [defaultFrequency, setDefaultFrequency] = useState(1);

  // console.log(location.state);

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-first",
    variables: {
      getContentInput: {
        contentId: storedCartItem.id,
      },
    },
  });

  // console.log(contentData);
  // console.log(contentData);

  const [isBusketInCart, setIsBusketInCart] = useState(false);

  useEffect(() => {
    // Check if there is any 'busket' item in the cart
    const busketExists = reservationData?.bandaumizaList?.some(
      (item: any) => item.type === "busket"
    );

    // Update the state variable accordingly
    setIsBusketInCart(busketExists);
  }, [reservationData]);

  useEffect(() => {
    const filteredCart = carts.find((item: ICart) => item.id === itemData.id);

    if (filteredCart) {
      setReservationData(filteredCart);
      setFirst(false);
      // console.log("reservationData");
      // console.log("reservationData");
      // console.log(reservationData);
      // console.log(filteredCart);
      // console.log("reservationData");
      // console.log("reservationData");
    } else {
      setReservationData(itemData);
      setFirst(false);
    }
  }, []);

  useEffect(() => {
    setReservationCost(
      1000 * reservationData?.bandaumizaList?.length * defaultFrequency
    );
  }, [reservationData]);

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );

  useEffect(() => {
    if (!(reservationData?.bandaumizaList?.length > 0) && !first) {
      deleteCart(itemData);
    } else {
      // setIsBusketFund(fals);
    }
  });

  const idExists = carts?.some(
    (item: any) => item?.id === contentData?.data?.getContent?.id
  );

  const [removeContent] = useRemoveContentMutation();
  const [disableContent] = useDisableContentMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await removeContent({
        variables: {
          deleteEvent: {
            contentId: storedCartItem.id,
          },
        },
      });

      if (
        response.data &&
        response.data.removeContent &&
        !response.data.removeContent.status
      ) {
        console.log(response.data.removeContent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        return;
      } else {
        console.log(response.data.removeContent);
        setRemovedSuccessfully(response.data.removeContent.status);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  const handleSubmitDisable = async (value: boolean) => {
    try {
      const response = await disableContent({
        variables: {
          deleteEvent: {
            contentId: storedCartItem.id,
            value: value,
          },
        },
      });

      if (
        response.data &&
        response.data.disableContent &&
        !response.data.disableContent.status
      ) {
        console.log(response.data.disableContent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        return;
      } else {
        console.log(response.data.disableContent);
        setRemovedSuccessfully(response.data.disableContent.status);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            {contentData?.data?.getContent?.title}
          </Text>
          <Image
            h="240px"
            w="100%"
            src={contentData?.data?.getContent?.coverImage}
            borderRadius="8px"
            me="20px"
            objectFit="cover"
          />
          <Text
            color={textColorSecondary}
            fontSize="md"
            me="26px"
            mb="0px"
            mt="20px"
          >
            {contentData?.data?.getContent?.author?.username}
          </Text>
          <Text color={"green"} me="2px" mb="4px" mt="2px">
            {/* {contentData?.data?.getContent?.categories[0]?.name} */}
          </Text>
          <Link
            // bg="#003459"
            color="green"
            _hover={{ bg: "#00a8e8" }}
            _active={{ bg: "#007ea7" }}
            _focus={{ bg: "#007ea7" }}
            fontWeight="500"
            fontSize="14px"
            alignSelf={"end"}
            variant={"link"}
            // py="30px"
            width={"50px"}
            mt={"-20px"}
            // px="47px"
            // me="38px"
            onClick={(e) => {}}
          >Link </Link>
          <Text
            color={textColorSecondary}
            fontSize="md"
            me="26px"
            mb="40px"
            mt="20px"
          >
            {contentData?.data?.getContent?.description}
          </Text>

          <SimpleGrid
            style={{
              marginTop: 20,
              marginBottom: 30,
            }}
            columns={2}
            gap="20px"
          >
            <Information
              boxShadow={cardShadow}
              // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
              title="This Content was submitted by"
              value={contentData?.data?.getContent?.author?.username}
            />
            <Information
              boxShadow={cardShadow}
              title="Language"
              value={contentData?.data?.getContent?.languages}
            />
          </SimpleGrid>


          {user?.sponsorStatus === "sponsor" && user?.id === contentData?.data?.getContent?.author?.id && (
            <SimpleGrid
              style={{
                marginTop: 20,
                marginBottom: 30,
                display: "flex",
                justifyContent: "center",
              }}
              columns={2}
              gap="20px"
            >
              <Link
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px",
                }}
                style={{ alignSelf: "center" }}
              >
                <Button
                  onClick={(e) => {
                    // alert("are you sure ?")
                    handleSubmit();

                    // localStorage.setItem("contentItem", JSON.stringify({}));
                    history.goBack();
                  }}
                  // bg="#09bc8a"
                  color="red"
                  // _hover={{ bg: "#01755c" }}
                  _active={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  fontWeight="500"
                  fontSize="14px"
                  variant={"ghost"}
                  py="30px"
                  px="27"
                  me="38px"
                >
                  Delete This Content
                </Button>
              </Link>

              <Link
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px",
                }}
                style={{ alignSelf: "center" }}
              >
                <Button
                  onClick={(e) => {
                    alert("are you sure ?");
                    handleSubmitDisable(
                      !contentData?.data?.getContent?.published
                    );
                    // localStorage.setItem("contentItem", JSON.stringify({}));
                    history.goBack();
                  }}
                  bg={
                    contentData?.data?.getContent?.published
                      ? "gray.100"
                      : "#09bc8a"
                  }
                  color={
                    contentData?.data?.getContent?.published ? "red" : "white"
                  }
                  // // _hover={{ bg: "#01755c" }}
                  // _active={{ bg: "white" }}
                  // _focus={{ bg: "white" }}
                  fontWeight="500"
                  fontSize="14px"
                  variant={"ghost"}
                  py="30px"
                  px="27"
                  me="38px"
                >
                  {contentData?.data?.getContent?.published
                    ? "Suspend this content"
                    : "Activate this content"}
                </Button>
              </Link>
            </SimpleGrid>
          )}
        </Card>

        <Card mb={{ base: "0px", "2xl": "20px" }}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Hosts{" "}
          </Text>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
