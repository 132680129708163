import * as React from "react";
import { Dispatch } from "redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./Cart.css";
import { addCart } from "../../redux.store/cart/actionCreators";
import { useState } from "react";
// import { navigateThePage } from "../../redux.store/navigation/actionCreators";
// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import {
  IoBookmark,
  IoBookmarkOutline,
  IoHeart,
  IoHeartOutline,
  IoImageOutline,
  IoStar,
  IoStarOutline,
} from "react-icons/io5";

import { useHistory } from "react-router-dom";
import { MdVerified } from "react-icons/md";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const Cart: React.FC<Props> = ({
  cart,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();

  // const handleNavigation = () => {
  //   // Perform some logic or data processing

  //   // Navigate to a different route
  //   history.push('/new-route');
  // };
  const dispatch: Dispatch<any> = useDispatch();
  // const saveCurrentRoute = React.useCallback(
  //   (navData: INavigate) => dispatch(navigateThePage(navData)),
  //   [dispatch]
  // );
  const navigateThroughLinks = (navData: INavigate, data: ICart) => {
    // saveCurrentRoute(navData);
    // navigate(`/${navData.currentPage}`, {
    //   state: { itemData: data, update: true },
    // });
  };

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );
  // const handleCartData = () => {
  //   setCart({
  //     id: 1,
  //     title: "title",
  //     body: "body",
  //   });
  // };

  const saveToTheCart = React.useCallback(
    (cart: ICart) => dispatch(addCart(cart)),
    [dispatch]
  );

  // const { image, name, author, bidders, download, currentbid } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");

  const textColorBid = useColorModeValue("brand.500", "white");
 

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );

  // console.log(carts);
  // console.log(cart);

  const idExists = carts.some((item: any) => item.id === cart.id);

  return (
    <Card p="5px" 
    // height="auto"
    backgroundColor={"#f2ebfb"}
    paddingBottom={"20%"}

    _hover={{
      borderColor:"#b66ee8",
      // padding:"8px",
      borderWidth:"1px",


   }}
    >
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "2px", "2xl": "2px" }} position="relative">
          {cart?.coverImage?.trim() === "" || !cart?.coverImage ? (
            <Box  
            w="100%"
            // h="300px"
            aspectRatio={"1"}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignSelf={"center"}
            bg={"gray.200"}
            borderRadius={"15px"}
            ><Icon
              as={IoImageOutline}
              // w="30px"
              // h="30px"
                style={{
                alignSelf: "center",
                marginTop:"0%",
                //  marginLeft:"17%",
                // backgroundColor:"red",
                height: 50,
                // width: "100%",
                // aspectRatio:1,
              }}
            /></Box>
          ) : (
           <>
           <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            // h="100vh" 
            // h="auto" 
            backgroundColor={"transparent"}

            // bg="gray.100" 
          >
                  <Box
        // position="absolute"
        // top="0"
        // left="0"
        // w="100%"
        // h="300px"
        // bgImage={`url(${cart?.coverImage})`}
        // bgSize="cover"
        // bgPosition="center"
        // filter="blur(8px)"
        display={"flex"}



     />
            

          <Box
        // position="absolute"
        // top="0"
        // left="0"
        w="100%"
        // h="300px"
        bgSize="cover"
        // bgPosition="center"
        display={"flex"}
        alignSelf={"center"}
        alignItems={"center"}
        justifyContent={"center"}



      >
                  <img 
                  // width="400"
                  // height="300"

                                src={cart?.coverImage}
                                className={"centerImg"}
                                ></img>
                                
                                {/* 
                                <Image
              position="absolute"
              src={cart?.coverImage}
              width={"200px"}
              // maxW="100%" 
              // maxH="auto" 
              // alignSelf={"center"}
              // display={"flex"}
              // justifyContent={"center"}
              // alignContent={"center"}
              // alignItems={"center"}
              aspectRatio={1}
              // objectFit="contain"
              // borderRadius="20px"
              // marginBottom={"25%"}
              // padding={"20px"}

              // left={"25%"}
              // top={"50%"}

            


            /> */}

      </Box>

          </Box>
            
          </>
          )}
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            {/* <Flex direction="column">
              <Text
                color={"red"}
                fontSize={{
                  base: "xl",
                  md: "lg",
                  lg: "lg",
                  xl: "lg",
                  "2xl": "md",
                  "3xl": "lg",
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {cart.name}
              </Text>
              <Text
                color="blue"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                {cart.owner}
              </Text>
            </Flex> */}
            {/* <AvatarGroup
              max={3}
              color={textColorBid}
              size="sm"
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
              fontSize="12px"
            >
              {bidders.map((avt, key) => (
                <Avatar key={key} src={avt} />
              ))}
            </AvatarGroup> */}
          </Flex>
          <Icon

as={MdVerified} 
width="20px"
 height="20px"
 color="green"
 position={"absolute"}
 alignSelf={"end"}
 fontSize="12px"
 fontWeight="500"
 borderRadius="70px"
 marginRight={"5%"}              
 mb={"-95%"} 
 />
          <Flex
          flexDirection={"column"}
            // align={{
            //   base: "center",
            //   md: "start",
            //   lg: "center",
            //   xl: "start",
            //   "2xl": "center",
            // }}
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >
            {/* <div  style={{
                width:"100%",
                height:70,
                backgroundColor:"gray",
                textOverflow: "ellipsis",

                
              }} className="text-ellipsis"> 
            <Text
              fontWeight="700"
              color={textColorBid}
              ml={"5%"}
              style={{
                textTransform: "uppercase",
                fontSize: 16,
               
                textOverflow: "ellipsis",

                
              }}
              	
            >
              {cart?.title}
            </Text>
              </div> */}


<div
  style={{
    width: "98%",
    // height: 70,
    // backgroundColor: "gray",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    marginLeft:"2%",
    textOverflow: "ellipsis",
  }}
  className="text-ellipsis"
> 
  <span
    style={{
      fontWeight: 600,
      color: "#3c096c",
      // textTransform: "uppercase",
      fontSize: 14,
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {cart?.author?.username}
  </span>
</div>
            
            <div
  style={{
    width: "98%",
    height: 70,
    // backgroundColor: "gray",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    marginLeft:"2%",
    textOverflow: "ellipsis",
  }}
  className="text-ellipsis"
> 
  <span
    style={{
      fontWeight: 700,
      color: textColorBid,
      textTransform: "uppercase",
      fontSize: 16,
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {cart?.title}
  </span>
</div>


              
            <Text fontWeight="700" ml={"5%"} fontSize="sm" color={"gray.500"}>
              {cart?.events ? `In  ${cart?.events.length}${cart?.events.length > 0 ?"+":""}  ${cart?.events.length > 0 ?"events":"event"}` : ""} 
            </Text>

           

              {user.sponsorStatus === "sponsor" && !(user.mode === "banda") ? (
                <>
                  {user.loginStatus || true ? (
                    <Button
                      onClick={() => {
                        // console.log("SEND VALUESSS");
                        // console.log("SEND VALUESSS");
                        // console.log("SEND VALUESSS");
                        // console.log("SEND VALUESSS");

                        localStorage.setItem(
                          "contentItem",
                          JSON.stringify(cart)
                        );
                        localStorage.setItem(
                          "activeCart",
                          JSON.stringify({item:cart,isPromo:false})
                        );
                        // console.log(JSON.stringify(cart));
                        // console.log(cart)

                        // history.push({
                        //   pathname: `/site/content?id=${cart?.id}&zIx=890`,
                        //   state: {
                        //     itemId: cart?.id,
                        //     update: idExists, //exist now update
                        //     isPromo: false,
                        //   },
                        // });
                        history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                          itemData: "qqsad",
                          update: true,
                          state: {
                            itemId: cart?.id,
                            update: idExists, //exist now update
                            isPromo: false,
                          },
                        });
                      }}
                      variant={"darkBrand"}
                      color="white"
                      alignSelf={"end"}
                      position={"absolute"}
                      bg={idExists ? "#AF1281" : "#6B0772"}
                      fontSize="12px"
                      fontWeight="500"
                      borderRadius="70px"
                      // px="24px"
                      // py="1px"
                      marginRight={"2%"}
                      bottom={"5%"}


                    >{idExists ? "Edit order" : "Open"}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        history.push("/auth/sign-in");
                      }}
                      variant={"darkBrand"}
                      color="white"
                      alignSelf={"end"}
                      bg={idExists ? "#AF1281" : "#6B0772"}
                      fontSize="12px"
                      fontWeight="500"
                      borderRadius="70px"
                      px="24px"
                      py="1px"
                      marginRight={"2%"}
                      mb={"5%"}

                    >
                      View
                    </Button>
                  )}
                </>
              ) : (
                <Button
                // mr={"2%"}
                onClick={() => {
                    localStorage.setItem("contentItem", JSON.stringify(cart));

                    history.push(`/site/content?id=${cart?.id}&zIx=890`, {
                      itemData: cart.id,
                      update: idExists, //exist now update
                    });
                  }}
                  variant={"darkBrand"}
                      color="white"
                      alignSelf={"end"}
                      bg={idExists ? "#AF1281" : "#6B0772"}
                      fontSize="12px"
                      fontWeight="500"
                      borderRadius="70px"
                      px="24px"
                      py="1px"
                      marginRight={"2%"}
                      mb={"5%"}
                >
                  View
                </Button>
              )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
