import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import * as React from "react";
import { useMyEventsListQuery } from "generated/graphql";
import { useSelector } from "react-redux";
// Assets

type RowObj = {
  name: string;
  tech: any;
  status?: any;
  date: string;
  progress: number;
};

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function ComplexTable() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [page, setPage] = React.useState(1);
  const [pageTemp, setPageTemp] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(-1);
  const [category, setCategory] = React.useState("all");

  const contentData = useMyEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getContentInput: {
        contentId: user.id,
        page,
        pageSize,
      },
    },
  });

  console.log(contentData?.data?.myEventsList);
  
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = contentData?.data?.myEventsList ? contentData?.data?.myEventsList : [];
  React.useEffect(()=>{
    setData(() => [...defaultData])
  },[contentData?.data?.myEventsList])


  const columns = [
    columnHelper.accessor("title", {
      id: "title",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TITLE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("bandaCode", {
      id: "bandaCode",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          BANDA
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("eventType", {
      id: "eventType",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          TYPE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  
 

    

    columnHelper.accessor("startDateTime", {
      id: "startDateTime",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {/* {formatDateTime(info.getValue())} */}
          {(info.getValue())}
        </Text>
      ),
    }),
    // columnHelper.accessor("progress", {
    //   id: "progress",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       WAITING TIME
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center">
    //       <Text me="10px" color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue()}%
    //       </Text>
    //       <Progress
    //         variant="table"
    //         colorScheme="brandScheme"
    //         h="8px"
    //         w="63px"
    //         value={info.getValue()}
    //       />
    //     </Flex>
    //   ),
    // }),
    // columnHelper.accessor("progress", {
    //   id: "progress",
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: "10px", lg: "12px" }}
    //       color="gray.400"
    //     >
    //       Attendee
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center">
    //       <Text me="10px" color={textColor} fontSize="sm" fontWeight="700">
    //         {info.getValue()}%
    //       </Text>
    //       <Progress
    //         variant="table"
    //         colorScheme="brandScheme"
    //         h="8px"
    //         w="63px"
    //         value={info.getValue()}
    //       />
    //     </Flex>
    //   ),
    // }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        {/* <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Not Viewed
        </Text>
        <Menu /> */}
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
