import * as React from "react";
import { IoImageOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

type Props = {
  cart: any;
  promoId: string;
  removeCart: (cart: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const CartEvent: React.FC<Props> = ({ cart,promoId }) => {
  const history = useHistory();


  

  return (
    <div className="p-2 my-1 mx-5 bg-white " style={{
      borderBottomWidth:1,
      borderColor:"#f3f3f3",
    }}>
      <div className="flex flex-row sm:flex-row">
        <div style={{width:"10%"}}
        className="mb-5 flex justify-start items-center">
          {cart?.coverImage?.trim() === "" || !cart?.coverImage ? (
            <div className="w-full h-72 flex  bg-gray-200 rounded-md">
              <IoImageOutline className="w-16 h-16" />
            </div>
          ) : (
            <div className="w-full h-auto aspect-square bg-gray-100 ">
              <img src={cart?.coverImage} className="centerImg" />
            </div>
          )}
        </div>

        <div style={{width:"90%"}} className=" flex flex-col ml-2">
          <div className="flex flex-col mb-auto">
            <p className="text-xl font-bold text-navy-700 mb-1">{cart.name}</p>
            <p className="text-sm font-normal text-secondaryGray-600">
              {cart.owner}
            </p>
          </div>

          <div className="flex flex-col h-full p-4 ">
            <div className="w-full  overflow-hidden text-ellipsis">
              <p
                className="text-sm font-bold underline text-brand-500 line-clamp-2 cursor-pointer"
                onClick={() => {

                  
                  localStorage.setItem("eventItem", JSON.stringify(cart));
                  localStorage.setItem(
                    "activeCart",
                    JSON.stringify({ item: cart, isPromo: true })
                  );

                  history.push(`/site/promo-reserve?id=${cart?.id}_${promoId}&update=${false}`);

                }}
              >
                {cart?.title}
              </p>
            </div>

            <div className="w-full text-sm text-brand-500 line-clamp-3">
  <span>{cart?.description}</span>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};
