import { combineReducers } from "redux";
import cartsReducer from "./cart/reducer";
import userReducer from "./user/reducer";
import promoReducer from "./promo/reducer";

const rootReducer = combineReducers<AppRootState>({
  //@ts-ignore
  carts: cartsReducer,
    //@ts-ignore
  promo:promoReducer,
  //@ts-ignore
  user: userReducer,
  //@ts-ignore
});

export default rootReducer;
