// import { jwtDecode } from 'jwt-decode';
// import { SystemModeTypes } from "./state/reducers/navigation/navigationReducer";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { loginUser, logoutUser } from "redux.store/user/actionCreators";


let accessToken: any;

export const setAppMode = (s: any) => {
  // accessToken = s;
  // console.log(s);
  // console.log("Set new access token");
  // console.log(s);
  localStorage.setItem("appMode", s);
};

export const getAppMode  =  ():any => {
  const appMode =  localStorage.getItem("appMode");
//  console.log(appMode);
  return appMode!;
};


export const setAccessToken = (s: any,dispatch :Dispatch<any>) => {
  // accessToken = s;
  // console.log(s);
  console.log("Set new access token");
  console.log("Set new access token");
  console.log("Set new access token");
  console.log("Set new access token");
  // console.log(s);

  // const history = useHistory();
  // const { user }: IUser | any = useSelector((state: UserState) => state.user);

  // const logoutService = React.useCallback(
  //   () => dispatch(logoutUser()),
  //   [dispatch]
  // );
  
  

  const logout = () => {
    // logoutService();
    logoutUser()
    // history.push("/landing/landing");

  };



  // localStorage.setItem("accessToken", s);
  // logout()

};


export const logOUTServ = () => {
  // accessToken = s;
  // console.log(s);
  console.log("Set new access token Logout");
  console.log("Set new access token Logout");
  console.log("Set new access token Logout");
  console.log("Set new access token Logout");
  console.log("Set new access token Logout");
  console.log("Set new access token Logout");


  // const history = useHistory();
  // const { user }: IUser | any = useSelector((state: UserState) => state.user);

  // const logoutService = React.useCallback(
  //   () => dispatch(logoutUser()),
  //   [dispatch]
  // );
  
  

  const logout = () => {
    // logoutService();
    logoutUser()
    // history.push("/landing/landing");

  };



  // localStorage.setItem("accessToken", s);
  logout()

};


export const setAccessToken2 = (s: any) => {
  // accessToken = s;
  console.log("Set new access token");
  console.log(s);
  localStorage.setItem("accessToken", s);
};

export const getAccessToken = () => {
  const token = localStorage.getItem("accessToken");
 // console.log(token);
  return token;
};
