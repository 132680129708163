// Chakra imports
import {
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  useAddSponsorGalleryImageMutation,
  useRemoveSponsorGalleryImageMutation,
  
} from "generated/graphql";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import AWS from "aws-sdk";

interface FormErrors {
  [key: string]: string;
}

export default function BannerImage(props: {
  banner?: string;
  avatar?: string;
  name?: string;
  userId?: string;
  

  job?: string;
  posts?: number | string;
  followers?: number | string;
  following?: number | string;
  [x: string]: any;
}) {
  const {
    refresh,
    banner,
    avatar,
    userId,
    name,
    job,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );


  const [galleryeService] = useAddSponsorGalleryImageMutation();
  const [removeBandaGalleryImage] = useRemoveSponsorGalleryImageMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);



  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFile(file); // Set the image file
    }
  };

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    setImageUrl(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const [uploadingProcess, setUploadingProcess] = useState(false);

  

  return (
    <Card mb={{ base: "0px", lg: "10px" }} alignItems="center">
      {avatar && (
        <Image
          src={imageData ? (imageData as string) : avatar}
          objectFit="cover"
          // boxSize="510px"
          className={`border-4 border-solid `}
          maxHeight={"400px"}

          borderColor={"#9fa0ff"}
          borderWidth={ 5}
          borderRadius={"15px"}


          // w={{ base: "100%", "3xl": "100%" }}
          // h={{ base: "100%", "3xl": "100%" }}
          // style={{
          //   border:"none",
          // }}
          // borderRadius="20px"
        />
      )}

      {/* {!(!avatar || avatar?.trim() === "") && !imageData && (
        <IconBox
          mx="auto"
          h="60px"
          w="60px"
          icon={
            <Icon
              as={DeleteIcon}
              color={"red"}
              h="40px"
              w="40px"
              style={{
                position: "absolute",
                right: "1%",

                top: 5,
              }}
              onClick={() => {
                if (window.confirm("Are you sure you want to delete?")) {
                  handleSubmitRemove(avatar);
                }
              }}
            />
          }
          // bg={"red"}
        ></IconBox>
      )} */}

      {/* {!imageData && (
        <Text color={textColorSecondary} fontSize="sm">
          Upload Image Here{" "}
        </Text>
      )} */}

      {/* <Input
        type="file"
        accept="image/*"
        aria-hidden="true"
        placeholder="Choose Image"
        variant="filled"
        // bg="red"
        // color="white"
        // height={100}
        // width={200}
        borderRadius="md"
        borderWidth={0}
        fontSize="sm"
        fontWeight="bold"
        ref={inputRef}
        onChange={handleImageChange}
        mb={4}
        _focus={{ outline: "none" }}
        _hover={{ cursor: "pointer" }}
      /> */}

      {/* {imageData && (
        <Button
          type="submit"
          onClick={() => {
            handleSubmit("add");
          }}
          colorScheme="green"
          marginTop="4"
          style={{
            width: "30%",
            padding: 5,
            alignSelf: "center",
          }}
        >
          Add Image
        </Button>
      )}

      {imageData && (
        <Button
          type="submit"
          onClick={() => {
            handleClearImage();
          }}
          colorScheme="red"
          marginTop="4"
          style={{
            width: "30%",
            padding: 5,
            alignSelf: "center",
          }}
        >
          Cancel
        </Button>
      )} */}
    </Card>
  );
}
