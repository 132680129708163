import {
    ListBulletIcon,
    PaperClipIcon,
    UserCircleIcon,
    BuildingLibraryIcon,
    CalendarDaysIcon,
    UsersIcon,
  } from "@heroicons/react/24/outline";
  import { useSelector } from "react-redux";
import Accordion from "./accordion";
import { FC, useEffect, useState } from "react";
import { useGetMtaaQuery } from "generated/graphql";
import { Select ,  Text,
} from "@chakra-ui/react";
import  regionData from 'utils/geo_data.json'


export interface BandaFormData {
  
  address?: string;
  physicalResidency?: string;
 
  region?: string;
  district?: string;
  ward?: string;

  
  mtaaVillage?: string;
  districtPostcode?:  string;
  wardPostcode?:   string;

}

interface FormErrors {
  [key: string]: string;
}
interface DashboardProps {
    address: BandaFormData;
  }
  

  const Dashboard_Mini: FC<DashboardProps> = ({ address }) => {
    const boxStyle = " border-1 rounded-md flex flex-col ";
    const { user }: IUser | any = useSelector((state: UserState) => state.user);
  
    const [formData, setFormData] = useState<BandaFormData>({
    
      address: "",
      physicalResidency: "",
    
   
      mtaaVillage: "",
      districtPostcode:  "",
      wardPostcode:   "",
  
      
    });
  
    const [errors, setErrors] = useState<FormErrors>({});
  
  

    const { loading, error, data, refetch } = useGetMtaaQuery({
      fetchPolicy: "network-only",
      variables: {
        mtaaInput: {
          wardPostCode:formData.wardPostcode,
          name: `${formData.mtaaVillage}_${formData.ward}` ,
        },
      },
    });

    const handleChange = (e: any) => {
      const { name, value, type, checked } = e.target;
  
      const newValue = type === "checkbox" ? checked : value;
  
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "",
        formStatus: "",
      }));
  
    
    };

    useEffect(()=>{

      setFormData((prevState) => ({
        ...prevState,
        district: "",
       ward: "",
      }));
  
    },[formData.region])
  
  
    useEffect(()=>{
    setFormData((prevState) => ({
        ...prevState,
       ward: "",
      }));
    } ,[formData.district])


    useEffect(()=>{
      setFormData((prevState) => ({
          ...prevState,
         mtaaVillage: "",
        }));
      } ,[formData.ward])
  

    
    
console.log(address);
console.log(formData);



    return (
      <div className="">
        <div className="w-full px-3  sm:px-6 text-xl text-gray-800 leading-normal">
        



         { address    && <div className="grid sm:grid-cols-2 mt-12 md:grid-cols-2 mb-5 auto-rows-[30px] gap-2 my-1">
            <div className={`${boxStyle} md:col-span-1 `}>
              <h5 className="text-xl ml-2 font-bold leading-none text-violet-600 underline ">
               Download pdf here
                <span className="text-gray-900"></span>
              </h5>
            </div>
            <div className={`${boxStyle} md:col-span-1  items-end`}>
              <h5 className="text-xl ml-2 font-bold leading-none text-green-600 ">
              { address?.region}
                <span className="text-gray-900"></span>
              </h5>
            </div>
          </div>}
  
          { address   &&
          <div className="grid grid-cols-2 sm:grid-cols-4  auto-rows-[90px] gap-2 my-1">
            <div
              className={`flex ${boxStyle}  bg-green-500 sm:col-span-1 md:col-span-1`}
            >
              <div className="flex justify-between m-2">
                <BuildingLibraryIcon
                  width={"20px"}
                  className="p-1 shadow bg-white rounded text-green-400"
                  height={"20px"}
                />
                <ListBulletIcon color="white" width={"15px"} height={"15px"} />
              </div>
  
              <div className="flex justify-between m-2">
                <div className="">
                  <p className="text-sm text-gray-600 justify-between">Banda</p>
                  <p className="text-md text-white font-semibold justify-between ">
                    3
                  </p>
                </div>
  
                <div className="">
                  <p className="text-sm text-gray-600 justify-between mt-5">
                    More
                  </p>
                  {/* <p className="text-md text-white font-semibold justify-between ">412</p> */}
                </div>
              </div>
            </div>
  
            <div
              className={`${boxStyle} rounded bg-yellow-400 sm:col-span-1 md:col-span-1`}
            >
              <div className="flex justify-between m-2">
                <UserCircleIcon
                  width={"20px"}
                  className="p-1 shadow bg-white rounded text-yellow-400"
                  height={"20px"}
                />
                <ListBulletIcon color="white" width={"15px"} height={"15px"} />
              </div>
  
              <div className="flex justify-between m-2">
                <div className="">
                  <p className="text-sm text-gray-600 justify-between">Jembe</p>
                  <p className="text-md text-white font-semibold justify-between ">
                    4
                  </p>
                </div>
  
                <div className="">
                  <p className="text-sm text-gray-600 justify-between mt-5">
                    More
                  </p>
                  {/* <p className="text-md text-white font-semibold justify-between ">412</p> */}
                </div>
              </div>
  
              <div></div>
            </div>
            <div
              className={`${boxStyle} bg-neutral-800 sm:col-span-1 md:col-span-1`}
            >
              <div className="flex justify-between m-2">
                <CalendarDaysIcon
                  width={"20px"}
                  className="p-1 shadow bg-white rounded text-neutral-400"
                  height={"20px"}
                />
                <ListBulletIcon color="white" width={"15px"} height={"15px"} />
              </div>
  
              <div className="flex justify-between m-2">
                <div className="">
                  <p className="text-sm text-gray-200 justify-between">Reservation</p>
                  <p className="text-md text-white font-semibold justify-between ">
                    0
                  </p>
                </div>
  
                <div className="">
                  <p className="text-sm text-gray-200 justify-between mt-5">
                    More
                  </p>
                  {/* <p className="text-md text-white font-semibold justify-between ">412</p> */}
                </div>
              </div>
            </div>
            <div
              className={`${boxStyle} bg-blue-400 sm:col-span-1 md:col-span-1`}
            >
              <div className="flex justify-between m-2">
                <UsersIcon
                  width={"20px"}
                  className="p-1 shadow bg-white rounded text-blue-400"
                  height={"20px"}
                />
                <ListBulletIcon color="white" width={"15px"} height={"15px"} />
              </div>
  
              <div className="flex justify-between m-2">
                <div className="">
                  <p className="text-sm text-gray-800 justify-between">
                    Attendees
                  </p>
                  <p className="text-md text-white font-semibold justify-between ">
                    841
                  </p>
                </div>
  
                <div className="">
                  <p className="text-sm text-gray-800 justify-between mt-5">
                    More
                  </p>
                  {/* <p className="text-md text-white font-semibold justify-between ">412</p> */}
                </div>
              </div>
            </div>
          </div>}
  
          { address  && <>
        <div className="grid grid-cols-1  mb-0 mt-7 gap-2 my-1">
            <div className={`${boxStyle} md:col-span-1 `}>
              <h5 className="text-xl ml-2 font-bold leading-none mb-5 text-violet-600 ">
                <span className="text-gray-900">{ address?.region} details</span>
              </h5>
            </div>
          </div>
  
          <div className={`${boxStyle}  md:col-span-1`}>
            <div className="mt-5 shadow-2xl grid md:grid-cols-1 rounded-xl gap-2 my-1">
              <div
                className={`${boxStyle} p-3 rounded-xl bg-violet-200 md:col-span-1`}
              >
                <div className="grid md:grid-cols-1 rounded-xl  gap-2 my-1">
                  <div className={`${boxStyle} p-3 bg-violet-200 md:col-span-1`}>
                    <div>
                      <div className=" p-2 rounded-xl bg-violet-50 ">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                       { address?.region}
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                          Local goverment details.
                        </p>
                      </div>
                      <div className="mt-6 border-t border-violet-300">
                        <dl className="divide-y divide-violet-300">
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Mwenyekiti
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              +25571212121
                            </dd>
                          </div>
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Mtendaji 1
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            +25571212125
                            </dd>
                          </div>
                          {/* <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Women
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              1000
                            </dd>
                          </div>
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Men
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              1213
                            </dd>
                          </div> */}
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Mtendaji 2
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              +25571251245
                            </dd>
                          </div>
                          {/* <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Watoto
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              123{" "}
                            </dd>
                          </div>
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              wazee
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              123{" "}
                            </dd>
                          </div> */}
                          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                              Attachments
                            </dt>
                            <dd className="mt-2 rounded-xl bg-violet-300 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <ul
                                role="list"
                                className="divide-y divide-violet-400 rounded-xl border border-violet-400"
                              >
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      aria-hidden="true"
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                      <span className="truncate font-medium">
                                        sensa_2022.pdf
                                      </span>
                                      <span className="flex-shrink-0 text-gray-400">
                                        2.4mb
                                      </span>
                                    </div>
                                  </div>
                                  <div className="ml-4 flex-shrink-0">
                                    <a
                                      
                                      className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Download
                                    </a>
                                  </div>
                                </li>
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      aria-hidden="true"
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                      <span className="truncate font-medium">
                                        Other.pdf
                                      </span>
                                      <span className="flex-shrink-0 text-gray-400">
                                        4.5mb
                                      </span>
                                    </div>
                                  </div>
                                  <div className="ml-4 flex-shrink-0">
                                    <a
                                      
                                      className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Download
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className={`${boxStyle} p-3 bg-violet-300  rounded-xl  md:col-span-1`}
                  >
                    <Accordion />
                  </div> */}
                </div>
              </div>
  
             
            </div>
  
           { false  && 
           <>
           <div className="grid sm:grid-cols-2 md:grid-cols-2 mb-0 mt-7 gap-2 my-1">
              <div className={`${boxStyle} md:col-span-1 `}>
                <h5 className="text-xl ml-2 font-bold leading-none mb-5 text-violet-600 ">
                  <span className="text-gray-900">
                  { address?.region}{" "}Calendar overview
                  </span>
                </h5>
              </div>
            </div>
  
           
  
            <div className="mt-7 grid md:grid-cols-1  auto-rows gap-1 my-1">
              <div
                className={`${boxStyle} bg-violet-300 p-3 rounded-xl   md:col-span-1`}
              >
                {/* <!-- component --> */}
                <div className="lg:flex lg:h-full lg:flex-col">
                  <header className="flex items-center justify-between border-b  px-6 py-4 lg:flex-none">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                      <time dateTime="2022-01">January 2022</time>
                    </h1>
                    <div className="flex items-center">
                      <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                        <button
                          type="button"
                          className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous month</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                        >
                          Today
                        </button>
                        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                        <button
                          type="button"
                          className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                        >
                          <span className="sr-only">Next month</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="hidden md:ml-4 md:flex md:items-center">
                        <div className="relative">
                          <button
                            type="button"
                            className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            id="menu-button"
                            aria-expanded="false"
                            aria-haspopup="true"
                          >
                            Month view
                            <svg
                              className="-mr-1 h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
  
                          {/* <!--
              Dropdown menu, show/hide based on menu state.
  
              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
                          <div
                            className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex={-1}
                          >
                            <div className="py-1" role="none">
                              {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                              <a
                                
                                className="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabIndex={-1}
                                id="menu-item-0"
                              >
                                Day view
                              </a>
                              <a
                                
                                className="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabIndex={-1}
                                id="menu-item-1"
                              >
                                Week view
                              </a>
                              <a
                                
                                className="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabIndex={-1}
                                id="menu-item-2"
                              >
                                Month view
                              </a>
                              <a
                                
                                className="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabIndex={-1}
                                id="menu-item-3"
                              >
                                Year view
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ml-6 h-6 w-px bg-gray-300"></div>
                        <button
                          type="button"
                          className="ml-6 rounded-md bg-violet-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500"
                        >
                          Add event
                        </button>
                      </div>
                      <div className="relative ml-6 md:hidden">
                        <button
                          type="button"
                          className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500"
                          id="menu-0-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                        >
                          <span className="sr-only">Open menu</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                          </svg>
                        </button>
  
                        {/* <!--
            Dropdown menu, show/hide based on menu state.
  
            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          --> */}
                        <div
                          className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-violet-300 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-0-button"
                          tabIndex={-1}
                        >
                          <div className="py-1" role="none">
                            {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-0"
                            >
                              Create event
                            </a>
                          </div>
                          <div className="py-1" role="none">
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-1"
                            >
                              Go to today
                            </a>
                          </div>
                          <div className="py-1" role="none">
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-2"
                            >
                              Day view
                            </a>
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-3"
                            >
                              Week view
                            </a>
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-4"
                            >
                              Month view
                            </a>
                            <a
                              
                              className="text-gray-700 block px-4 py-2 text-sm"
                              role="menuitem"
                              tabIndex={-1}
                              id="menu-0-item-5"
                            >
                              Year view
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                    <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                      <div className="flex justify-center bg-white py-2">
                        <span>M</span>
                        <span className="sr-only sm:not-sr-only">on</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>T</span>
                        <span className="sr-only sm:not-sr-only">ue</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>W</span>
                        <span className="sr-only sm:not-sr-only">ed</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>T</span>
                        <span className="sr-only sm:not-sr-only">hu</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>F</span>
                        <span className="sr-only sm:not-sr-only">ri</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>S</span>
                        <span className="sr-only sm:not-sr-only">at</span>
                      </div>
                      <div className="flex justify-center bg-white py-2">
                        <span>S</span>
                        <span className="sr-only sm:not-sr-only">un</span>
                      </div>
                    </div>
                    <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                      <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                        {/* <!--
            Always include: "relative py-2 px-3"
            Is current month, include: "bg-white"
            Is not current month, include: "bg-gray-50 text-gray-500"
          --> */}
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          {/* <!--
              Is today, include: "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
            --> */}
                          <time dateTime="2021-12-27">27</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2021-12-28">28</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2021-12-29">29</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2021-12-30">30</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2021-12-31">31</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-01">1</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-01">2</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-03">3</time>
                          <ol className="mt-2">
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Banda event ccbrt
                                </p>
                                <time
                                  dateTime="2022-01-03T10:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  10AM
                                </time>
                              </a>
                            </li>
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Kikao usafi
                                </p>
                                <time
                                  dateTime="2022-01-03T14:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  2PM
                                </time>
                              </a>
                            </li>
                          </ol>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-04">4</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-05">5</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-06">6</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-07">7</time>
                          <ol className="mt-2">
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Kagua bandajamii
                                </p>
                                <time
                                  dateTime="2022-01-08T18:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  6PM
                                </time>
                              </a>
                            </li>
                          </ol>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-08">8</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-09">9</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-10">10</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-11">11</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time
                            dateTime="2022-01-12"
                            className="flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                          >
                            12
                          </time>
                          <ol className="mt-2">
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Mualiko kikao wilayani
                                </p>
                                <time
                                  dateTime="2022-01-25T14:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  2PM
                                </time>
                              </a>
                            </li>
                          </ol>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-13">13</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-14">14</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-15">15</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-16">16</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-17">17</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-18">18</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-19">19</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-20">20</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-21">21</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-22">22</time>
                          <ol className="mt-2">
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Wilayani seminar
                                </p>
                                <time
                                  dateTime="2022-01-22T15:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  3PM
                                </time>
                              </a>
                            </li>
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Reservation mualiko banda
                                </p>
                                <time
                                  dateTime="2022-01-22T19:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  7PM
                                </time>
                              </a>
                            </li>
                          </ol>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-23">23</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-24">24</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-25">25</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-26">26</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-27">27</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-28">28</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-29">29</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-30">30</time>
                        </div>
                        <div className="relative bg-white px-3 py-2">
                          <time dateTime="2022-01-31">31</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-01">1</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-02">2</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-03">3</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-04">4</time>
                          <ol className="mt-2">
                            <li>
                              <a  className="group flex">
                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                  Vijana seminar
                                </p>
                                <time
                                  dateTime="2022-02-04T21:00"
                                  className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                >
                                  9PM
                                </time>
                              </a>
                            </li>
                          </ol>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-05">5</time>
                        </div>
                        <div className="relative bg-gray-50 px-3 py-2 text-gray-500">
                          <time dateTime="2022-02-06">6</time>
                        </div>
                      </div>
                      <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {/* <!--
            Always include: "flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10"
            Is current month, include: "bg-white"
            Is not current month, include: "bg-gray-50"
            Is selected or is today, include: "font-semibold"
            Is selected, include: "text-white"
            Is not selected and is today, include: "text-indigo-600"
            Is not selected and is current month, and is not today, include: "text-gray-900"
            Is not selected, is not current month, and is not today: "text-gray-500"
          --> */}
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          {/* <!--
              Always include: "ml-auto"
              Is selected, include: "flex h-6 w-6 items-center justify-center rounded-full"
              Is selected and is today, include: "bg-indigo-600"
              Is selected and is not today, include: "bg-gray-900"
            --> */}
                          <time dateTime="2021-12-27" className="ml-auto">
                            27
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2021-12-28" className="ml-auto">
                            28
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2021-12-29" className="ml-auto">
                            29
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2021-12-30" className="ml-auto">
                            30
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2021-12-31" className="ml-auto">
                            31
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-01" className="ml-auto">
                            1
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-02" className="ml-auto">
                            2
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-03" className="ml-auto">
                            3
                          </time>
                          <span className="sr-only">2 events</span>
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-04" className="ml-auto">
                            4
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-05" className="ml-auto">
                            5
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-06" className="ml-auto">
                            6
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-07" className="ml-auto">
                            7
                          </time>
                          <span className="sr-only">1 event</span>
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-08" className="ml-auto">
                            8
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-09" className="ml-auto">
                            9
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-10" className="ml-auto">
                            10
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-11" className="ml-auto">
                            11
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 font-semibold text-indigo-600 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-12" className="ml-auto">
                            12
                          </time>
                          <span className="sr-only">1 event</span>
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-13" className="ml-auto">
                            13
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-14" className="ml-auto">
                            14
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-15" className="ml-auto">
                            15
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-16" className="ml-auto">
                            16
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-17" className="ml-auto">
                            17
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-18" className="ml-auto">
                            18
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-19" className="ml-auto">
                            19
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-20" className="ml-auto">
                            20
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-21" className="ml-auto">
                            21
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 font-semibold text-white hover:bg-gray-100 focus:z-10"
                        >
                          <time
                            dateTime="2022-01-22"
                            className="ml-auto flex h-6 w-6 items-center justify-center rounded-full bg-gray-900"
                          >
                            22
                          </time>
                          <span className="sr-only">2 events</span>
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-23" className="ml-auto">
                            23
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-24" className="ml-auto">
                            24
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-25" className="ml-auto">
                            25
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-26" className="ml-auto">
                            26
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-27" className="ml-auto">
                            27
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-28" className="ml-auto">
                            28
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-29" className="ml-auto">
                            29
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-30" className="ml-auto">
                            30
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-white px-3 py-2 text-gray-900 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-01-31" className="ml-auto">
                            31
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-01" className="ml-auto">
                            1
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-02" className="ml-auto">
                            2
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-03" className="ml-auto">
                            3
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-04" className="ml-auto">
                            4
                          </time>
                          <span className="sr-only">1 event</span>
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            <span className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-05" className="ml-auto">
                            5
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                        <button
                          type="button"
                          className="flex h-14 flex-col bg-gray-50 px-3 py-2 text-gray-500 hover:bg-gray-100 focus:z-10"
                        >
                          <time dateTime="2022-02-06" className="ml-auto">
                            6
                          </time>
                          <span className="sr-only">0 events</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className={`${boxStyle} mt-10 shadow-2xl md:col-span-1 bg-white rounded-xl`}>
                <div className="w-full  overflow-auto max-w p-4    rounded sm:p-8 ">
                  <div className="flex items-center justify-between mb-4">
                    <h5 className="text-xl font-bold leading-none text-gray-900 ">
                     Jembe
                    </h5>
                    <a
                      
                      className="text-sm font-medium text-blue-600 hover:underline "
                    >
                      View all
                    </a>
                  </div>
                  <div className="flow-root">
                    <ul role="list" className="divide-y divide-gray-200 ">
                      {[1, 2, 3].map(() => {
                        return (
                          <li className="py-3 sm:py-4">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <img
                                  className="w-8 h-8 rounded-full"
                                  src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
                                  alt="Neil image"
                                />
                              </div>
                              <div className="flex-1 min-w-0 ms-4">
                                <p className="text-sm font-medium text-gray-900 truncate ">
                                  Jovin Kyollike
                                </p>
                                <p className="text-sm text-gray-500 truncate ">
                                  +25576200000
                                </p>
                              </div>
                              <a 
                                    href={"https://bandagov.vercel.app/portal/jembe"} 
                                   target="_blank" rel="noreferrer"


className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                View
                              </a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="w-full   overflow-auto max-w p-4  rounded sm:p-8 ">
                  <div className="flex items-center justify-between mb-4">
                    <h5 className="text-xl font-bold leading-none text-gray-900 ">
                      Sponsors connected
                    </h5>
                    <a
                      
                      className="text-sm font-medium text-blue-600 hover:underline "
                    >
                      View all
                    </a>
                  </div>
                  <div className="flow-root">
                    <ul role="list" className="divide-y divide-gray-200 ">
                      {[1, 2, 3].map(() => {
                        return (
                          <li className="py-3 sm:py-4">
                            <div className="flex items-center">
                              <div className="flex-shrink-0">
                                <img
                                  className="w-8 h-8 rounded-full"
                                  src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
                                  alt="Neil image"
                                />
                              </div>
                              <div className="flex-1 min-w-0 ms-4">
                                <p className="text-sm font-medium text-gray-900 truncate ">
                                  Zutrax 
                                </p>
                                <p className="text-sm text-gray-500 truncate ">
                                  +255678000111
                                </p>
                              </div>
                              <a            
                                   onClick={()=>{

                                   }}
 className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                View
                              </a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
             </>}
          </div>
  </>}
          {/* <div className="grid sm:grid-cols-2 md:grid-cols-2 mb-0 mt-7 gap-2 my-1">
            <div className={`${boxStyle} md:col-span-1 `}>
              <h5 className="text-xl ml-2 font-bold leading-none mb-5 text-violet-600 ">
                <span className="text-gray-900">{user.name}Banda and Jembe</span>
              </h5>
            </div>
          </div>
  
          <div className=" grid md:grid-cols-2  mt-1 gap-2 my-1">
            <div
              className={`${boxStyle} md:col-span-1  rounded-xl bg-white border`}
            >
              <div className="w-full overflow-auto max-w p-4 rounded sm:p-8 ">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 ">
                    Jembe list
                  </h5>
                  <a
                    
                    className="text-sm font-medium text-blue-600 hover:underline "
                  >
                    View all
                  </a>
                </div>
                <div className="flow-root">
                  <ul role="list" className="divide-y divide-gray-200 ">
                    {[1, 2, 3, 4, 5, 6, 7].map(() => {
                      return (
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <img
                                className="w-8 h-8 rounded-full"
                                src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
                                alt="Neil image"
                              />
                            </div>
                            <div className="flex-1 min-w-0 ms-4">
                              <p className="text-sm font-medium text-gray-900 truncate ">
                                John juma
                              </p>
                              <p className="text-sm text-gray-500 truncate ">
                                +255763123123
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                              View
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
  
            <div
              className={`${boxStyle} md:col-span-1  rounded-xl bg-white border`}
            >
              <div className="w-full  overflow-auto max-w p-4    rounded sm:p-8 ">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 ">
                    Banda in area
                  </h5>
                  <a
                    
                    className="text-sm font-medium text-blue-600 hover:underline "
                  >
                    View all
                  </a>
                </div>
                <div className="flow-root">
                  <ul role="list" className="divide-y divide-gray-200 ">
                    {[1, 2, 3, 4, 5, 6, 7].map(() => {
                      return (
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <img
                                className="w-8 h-8 rounded-full"
                                src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg"
                                alt="Neil image"
                              />
                            </div>
                            <div className="flex-1 min-w-0 ms-4">
                              <p className="text-sm font-medium text-gray-900 truncate ">
                                Unyama banda
                              </p>
                              <p className="text-sm text-gray-500 truncate ">
                                A0012
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                              View
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  
  export default Dashboard_Mini;
  