import * as React from "react";
import "./SponsorItemm.css";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { IoImageOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { userInfo } from "os";
import { useSelector } from "react-redux";
import { formatDateTime } from "views/site/helpers";

type Props = {
  sponsor: any;
  removeCart: (sponsor: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
};

export const SponsorItemm: React.FC<Props> = ({
  sponsor,
  selected,
  removeCart,
  handleShow,
}) => {
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  return (
    <Card
      p="10px"
      backgroundColor="#f2ebfb"
      borderRadius="10px"
      _hover={{
        borderColor: "#b66ee8",
        padding: "10px",
        borderWidth: "1px",
      }}
    >
      <Flex direction="column" justify="center">
        <Box mb="10px" position="relative">
          <Image src={sponsor.image} w="100%" h="auto" borderRadius="10px" />
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            width="100%"
            mb="auto"
          >
            <Flex direction="column" width="100%">
              <Flex direction="row" align="center" h="50px" w="100%">
                {
                // sponsor?.coverImage?.trim() === "" 
                //|| 
                !sponsor?.coverImage 
                // &&
                // false
                ? (
                  <Icon
                    as={IoImageOutline}
                    w="30px"
                    h="30px"
                    ml="10px"
                    mt="10px"
                    alignSelf="center"
                  />
                ) : (
                  <Image
                    src={sponsor?.coverImage}
                    w="40px"
                    h="40px"
                    borderRadius="full"
                    objectFit="cover"

                    ml="10px"
                    mt="10px"
                  />
                )}
                <Button
                  color={textColor}
                  justifyContent="start"
                  alignItems="center"
                  variant="link"
                  ml="10px"
                  mt="10px"
                  display="flex"
                  onClick={() => {
                    localStorage.setItem(
                      "sponsorItem",
                      JSON.stringify(sponsor)
                    );

                    if(user.sponsorId === sponsor.id){
                      history.push(`/site/sponsor_profile?id=${sponsor?.id}&zIx=890`, {
                        itemData: "qqsad",
                        update: true,
                      });
                      

                    }else{
                      history.push(`/site/sponsor_details?id=${sponsor?.id}&zIx=890`, {
                        itemData: "qqsad",
                        update: true,
                      });
                    }

 
                  }}
                >
                  <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >{sponsor.isCompany ? sponsor.companyName : sponsor.anonymous ? "anonymous user" : sponsor.sponsorName === "" ?  "anonymous": sponsor.sponsorName}
                  {/* {!sponsor.isCompany
                      ? sponsor.sponsorName
                        ? sponsor.sponsorName
                        : "Anonymous_" + sponsor.id?.substring(0, 4)
                      : sponsor.companyName
                      ? sponsor.companyName
                      : "Anonymous_" + sponsor.id?.substring(0, 4)} */}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >  <Text
          fontWeight="500"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          wordBreak="break-word"
          color={textColor}
          textAlign="start"
          _hover={{
            textDecoration: "underline",
          }}
          fontSize="14px"
        >{sponsor?.industry}  </Text>
             {/* <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >20 reservations</Text> */}
                    <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >{sponsor?.region }</Text>
                    
                    <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >{sponsor?.district}</Text>
                    
                    <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >{sponsor?.ward} </Text>
                     <Text
                    fontWeight="500"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    wordBreak="break-word"
                    color={textColor}
                    textAlign="start"
                    _hover={{
                      textDecoration: "underline",
                    }}
                    fontSize="14px"
                  >since {formatDateTime(sponsor?.createdAt)} </Text>

                    
                     
                            </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
