import * as React from "react";
import "./BandaItem.css";
// import { navigateThePage } from "../../redux.store/navigation/actionCreators";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import {
  IoImageOutline,
} from "react-icons/io5";

import { useHistory } from "react-router-dom";
import { MdVerified, MdVerifiedUser } from "react-icons/md";
import Information from "views/site/profileSponsor/components/Information";

type Props = {
  banda: any;
  removeCart: (banda: ICart) => void;
  selected?: boolean;
  handleShow?: () => void;
  setBanda?: (details: any) => void;
  ongezaKikundi: (value:boolean)=> void;

};

export const BandaItem: React.FC<Props> = ({
  banda,
  selected,
  removeCart,
  handleShow,
  ongezaKikundi,
  setBanda,
}) => {
  const history = useHistory();

  
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("navy.700", "white");

  const textColorBid = useColorModeValue("brand.500", "white");
 
  console.log(banda);

  const funct =()=>{
    setBanda(banda)
    ongezaKikundi(true)
  }


  return (
    <Card 
    p="20px" 
    bg={"#f2ebfb"}
    _hover={{
      borderColor:"#d4bbfc",
      borderWidth:"2px",
      padding:"17px",
    }}
     borderRadius={"10px"}
     >
      <Flex direction={{ base: "column" }} justify="center">
     
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column">
            <Icon

as={MdVerifiedUser} 
width="15px"
 height="15px"
 color="green"
 position={"absolute"}
 alignSelf={"end"}
 fontSize="12px"
 fontWeight="500"
 borderRadius="70px"
//  marginLeft={"50%"}
 mt={"-15px"} 
 />
 <Flex
                direction={"row"}
                style={{
                  alignSelf: "center",
                  //  marginLeft:"17%",
                  // display:"flex",
                  //   backgroundColor:"red",
                  //   justifyContent:"center",
                  //   alignContent:"center",
                  height: "auto",
                  width: "100%",
                }}
              >
                {banda?.coverImage?.trim() === "" || !banda?.coverImage ? (
                  <Icon
                    as={IoImageOutline}
                    w="30px"
                    h="30px"
                    style={{
                      alignSelf: "center",
                      //  marginLeft:"17%",
                      // backgroundColor:"green",
                      // marginBottom:50,
                      height: 30,
                      // width:"5%",
                    }}
                  />
                ) : (
                  <Image
                    src={banda?.coverImage}
                    w={"40px"}
                    h={"40px"}
                    borderRadius="40px"
                    objectFit="cover"

                  />
                )}
                <Button
              onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(banda));
                history.push(`/site/banda_details?id=${banda?.id}&zIx=890`);

                // history.push("/site/banda_details", {
                //   itemData: "qqsad",
                //   update: true,
                // });
              }}
              variant="link"
              height={"auto"}
              // bg={"red"}
                  color={textColorBid}
                  fontSize={"18px"}
                  mt="2px"
                  fontWeight="bold"
                  me="14px"
                  marginLeft={"10px"}
                ><Text
                color={textColorBid}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                wordBreak={"break-word"}
                // fontSize={{
                //   base: "16px",
                // }}
                fontWeight="bold"
                me="14px"
                // alignSelf={"end"}
              >{banda?.bandaName?.toUpperCase()}
                </Text> 

                 
                </Button>
            
                
              </Flex>
              <Text
                color="gray"
                fontSize={{
                  base: "16px",
                }}
                fontWeight="bold"
                me="14px"
                // alignSelf={"end"}
              >                  {banda.bandaCode}

              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"column"}
            // align={{
            //   base: "center",
            //   md: "start",
            //   lg: "center",
            //   xl: "start",
            //   "2xl": "center",
            // }}
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mt="25px"
          >
            {/* <Text
              fontWeight="700"
              color={"gray.400"}
              style={{
                textTransform: "uppercase",
                fontSize: 16,
              }}
            >
              {JSON.parse(banda?.tvSize)?.length} Tv of{" "}
              {JSON.parse(banda?.tvSize)?.toString()}
            </Text> */}
          
          <Link
            // bg="#003459"
            color="green.400"
            _hover={{ color: "black" }}
            // _active={{ bg: "#f4f4f4" }}
            // _focus={{ bg: "#f4f4f4" }}
            fontWeight="500"
            fontSize="14px"
            // alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // href={"https://bandagov.vercel.app/dashboard"} 
            // target="_blank" // Opens the link in a new tab
            onClick={()=>{
              funct()}}
           rel="noopener noreferrer"
          >{banda?.region ? banda?.region : 0}{banda?.district ? " , "+ banda?.district : ""}{banda?.ward ? " , "+ banda?.ward : ""}
  
          </Link>

          <Link
            // bg="#003459"
            color="green.400"
            _hover={{ color: "black" }}
            // _active={{ bg: "#f4f4f4" }}
            // _focus={{ bg: "#f4f4f4" }}
            fontWeight="500"
            fontSize="14px"
            // alignSelf={"end"}
            variant={"link"}
            // py="30px"
            // width={"50px"}
            mt={"0px"}
            // px="47px"
            // me="38px"
            // href={"https://sensa.nbs.go.tz/publications"} 
            // target="_blank" // Opens the link in a new tab
            onClick={()=>{
              funct()}}
           rel="noopener noreferrer"
          >
            {banda?.physicalResidency ?  banda?.physicalResidency : ""}
  
          </Link>


            {/* <Text fontWeight="700" fontSize="sm" color={"gray.400"}>
            {banda?.physicalResidency ?  banda?.physicalResidency : ""}
            </Text> */}
           

            {/* <Text fontWeight="700" fontSize="sm" color={"gray.400"}>
              {banda?.physicalResidency ?  banda?.physicalResidency : ""}
            </Text> */}
            {/* <Text fontWeight="700" fontSize="sm" color={"gray.400"}>
              {banda?.decoders
                ? "  " + JSON.parse(banda?.decoders)?.toString()
                : " "}
            </Text> */}


            <SimpleGrid
            style={{
              marginTop: 20,
              marginBottom: 30,
            }}
            // columns={5}
            minChildWidth={"100px"}
            gap="10px"
          >
           {banda?.capacity && <Information
              boxShadow={cardShadow}
              title="capacity"
              value={banda?.capacity}
            />}
            {banda?.tvSize && <Information
              boxShadow={cardShadow}
              title="Screens"
              value={banda?.tvSize ? `${JSON.parse(banda?.tvSize)?.length} Size ${JSON.parse(banda?.tvSize)?.toString()}`:""}
            />}
           {banda?.decoders&& <Information
              boxShadow={cardShadow}
              title="Decoders"
              value={banda?.decoders ? JSON.parse(banda?.decoders).toString():""}
              // value={"banda?.decoders ? JSON.parse(banda?.decoders).toString():"}
            />}
           {banda?.eventsFunds && <Information
              boxShadow={cardShadow}
              title="Reservations"
              value={
                `${banda?.eventsFunds.length} ${banda?.eventsFunds.length > 12 ? "+": ""}`
              }
            />}
            {banda?.bandaSize && <Information
              boxShadow={cardShadow}
              title="Size"
              value={
                `${banda?.bandaSize}`
              }
            />}
              {banda?.typeOfSeat && <Information
              boxShadow={cardShadow}
              title="Seat type"
              value={
                `${banda?.typeOfSeat}`
              }
            />}
          </SimpleGrid>

           

            {/* <Button
              onClick={() => {
                // console.log(banda);
                // console.log(banda);
                // console.log(banda);
                localStorage.setItem("bandaItem", JSON.stringify(banda));

                history.push("/site/banda_details", {
                  itemData: "qqsad",
                  update: true,
                });
              }}
              variant="darkBrand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              px="24px"
              py="5px"
              alignSelf={"end"}
              marginRight={"-3%"}
            >
              Visit
            </Button> */}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
