// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import {
  Image,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  useAddUserProfileImageMutation,
  useSponsorEventsListQuery,
  useSponsorQuery,
} from "generated/graphql";
import imageHolder from "assets/images/imageHolder.png";
import { formatDateTime } from "../helpers";
import { useHistory } from "react-router-dom";
import {
  MdBarChart,
  MdContentPaste,
  MdDoorbell,
  MdPhotoLibrary,
} from "react-icons/md";
import Modal from "./components/components/Modal";

// Assets
import avatar from "assets/images/imageHolder.png";
import { useSelector } from "react-redux";
import { useUserQuery } from "generated/graphql";
import ProfileBanner from "./components/ProfileBanner";
import Hosting from "../sponsor_details/components/Hosting";
import PencilIcon from "./components/components/PencilIcon";
import AWS from "aws-sdk";
import ContentsList from "./components/contentsList";
import NotificationsList from "./components/notificationsList";
import GalleryDiv from "./Gallery";

export interface FormData {
  title: string;
  description: string;
  link: string;

  verifiedBy: string;
  duration: string;

  price: string;
  currency: string;

  contentType: string;
  languages: string;
  categories: string;
}

interface FormErrors {
  [key: string]: string;
}

export default function Overview() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "description is required",
      // }));
    } else if (!textRegex.test(description)) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "Wrong Residency",
      // }));
    } else {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "",
      // }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const validateLink = (link: string) => {
    const textRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/;

    if (!link.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is required",
      }));
    } else if (!textRegex.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    link: "",
    contentType: "",
    languages: "",
    categories: "",
    price: "0",
    currency: "Tsh",
    verifiedBy: "",
    duration: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "title":
        validateTitle(value);
        break;
      case "description":
        validateDescription(value);
        break;
      case "link":
        validateLink(value);
        break;
      default:
        break;
    }
  };

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const history = useHistory();
  const userData = useUserQuery({
    fetchPolicy: "network-only",
    variables: {
      getUserProfileInput: {
        id: user.id,
      },
    },
  });

  const sponsorEvents = useSponsorEventsListQuery({
    fetchPolicy: "network-only",
    variables: {
      getContentInput: {
        contentId: user.sponsorId, // sponsor?.id,
        pageSize: 100,
        page: 0,
      },
    },
  });

  const storedCartItemString = localStorage.getItem("sponsorItem");

  // Convert the string back to an object
  const storedCartItem = JSON.parse(storedCartItemString);

  const [imageView, setImageView] = useState("");
  const [first, setFirst] = useState(false);
  const [refreshSelected, setRefreshSelected] = useState(false);

  const contentData = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: user.sponsorId,
      },
    },
  });
  console.log(user);
  console.log(user);
  console.log(user);

  const [isEdit, setIsEdit] = useState(false);
  const [appMode, setAppMode] = useState("reservations");
  const addedItemMiddle = (data: any) => {
    console.log(data);
    setRefreshSelected(!refreshSelected);
  };

  useEffect(() => {
    contentData.refetch();
    //setFirst(false)
  }, [refreshSelected]);

  useEffect(() => {
    if (!first && contentData?.data?.sponsor?.media.length > 0) {
      setImageView(contentData?.data?.sponsor?.media[0]);
      //   setFirst(true)
    }
  }, [contentData, refreshSelected]);
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [coverImageMode, setCoverImageMode] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const avatarUrl = useRef(imageHolder);
  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar = (imgSrc: string) => {
    // Update the avatar URL reference
    avatarUrl.current = imgSrc;

    // Convert the updated avatar URL to a File object
    fetch(imgSrc)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a File object with the blob and assign it to state
        const file = new File([blob], "avatar.jpg", { type: "image/jpeg" });
        setImageFile(file);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const [profileImageService] = useAddUserProfileImageMutation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [uploadingProcess, setUploadingProcess] = useState(false);

  const handleSubmit = async (action: string, type: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      if (action === "add") {
        imageUrll = await uploadFile(imageFile);
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrll = "";
      setImageUrl(null);
      setImageFile(null);
      setImageData(null);
    }

    let imageUrlData = imageUrl;

    if (action === "delete") {
      imageUrlData = "";
      imageUrll = "";
      setImageUrl(null);
      setImageData(null);
      setImageFile(null);
    }

    try {
      const response = await profileImageService({
        variables: {
          imageInput: {
            id: user.sponsorId,
            url: imageUrll,
          },
        },
      });

      if (
        response.data &&
        response.data.addUserProfileImage &&
        !response.data.addUserProfileImage.status
      ) {
        console.log(response.data.addUserProfileImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.addUserProfileImage);
        if ((action = "delete")) {
          // setRemovedSuccessfully(true);
          setImageUrl(null);
          setImageData(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        // refresh(true);
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      return;
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = `https://${key}`;
      setImageUrl(imageUrl);
      return data.Location;
      // alert("File uploaded successfully.");
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  return (
    <div>
      {!userData.loading || !sponsorEvents.loading || !contentData.loading ? (
        <Box mt={{ base: "80px", md: "50px", xl: "50px" }}>
          {/* Main Fields */}

          {isEdit ? (
            <Card
              className="w-full "
              mb={{ base: "0px", lg: "20px" }}
              mt={{ base: "130px", md: "80px", xl: "80px" }}
            >
              <ProfileBanner
                gridArea="1 / 1 / 2 / 1"
                banner={"banner"}
                item={contentData?.data?.sponsor}
                refresh={() => {
                  contentData?.refetch();
                }}
                backgroundImage={contentData?.data?.sponsor?.backgroundImage}
                avatar={contentData?.data?.sponsor?.coverImage}
                name={contentData?.data?.sponsor?.companyID}
                job={contentData?.data?.sponsor?.companyID}
                posts={0}
                followers={0}
                following="0"
              />

              <div
                className="flex items-center gap-4"
                style={{ alignSelf: "self-end" }}
              >
                <button
                  onClick={() => setIsEdit(false)}
                  className="rounded-full border border-solid border-gray-300 bg-gray-50 py-3 px-4 text-sm font-semibold text-gray-900 shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-50 hover:bg-gray-100 hover:border-gray-300"
                >
                  Cancel Edit
                </button>
                {/* <button
                        className="rounded-full border border-solid border-indigo-600 bg-indigo-600 py-3 px-4 text-sm font-semibold text-white whitespace-nowrap shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:bg-indigo-700 hover:border-indigo-700">Request Fund</button> */}
              </div>
            </Card>
          ) : (
            <Card
              className="w-full "
              mb={{ base: "0px", lg: "20px" }}
              mt={{ base: "130px", md: "80px", xl: "80px" }}
              alignItems="center"
            >
              <Card
                bg="#3d0066"
                mt={"5px"}
                padding={"0px"}
                // boxShadow={cardShadow}
                margin={"0px"}
                // borderRadius={"15px"}
              >
                {/* <Image 
        src="https://pagedone.io/asset/uploads/1705471739.png" 
        alt="cover-image" 
        className="w-full  top-0 left-0 z-0"
        width={"100%"}
        height={"200px"}
        borderRadius={"15px"}
          objectFit="cover"

        
        /> */}
                <div className="relative">
                  <Image
                    // src="https://pagedone.io/asset/uploads/1705471739.png"
                    src={contentData?.data?.sponsor?.backgroundImage ? (contentData?.data?.sponsor?.backgroundImage as string) : "https://pagedone.io/asset/uploads/1705471739.png"}
                    alt="cover-image"
                    className="w-full  top-0 left-0 z-0"
                    width={"100%"}
                    height={"400px"}
                    borderRadius={"15px"}
                    objectFit="cover"
                  />
                  {false && (
                    <button
                      className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[.35rem] rounded-full bg-white hover:bg-gray-100 border border-gray-600"
                      title="Change photo"
                      onClick={() => {
                        setCoverImageMode("cover");
                        // setModalOpen(true)
                      }}
                      style={{
                        alignSelf: "end",
                      }}
                    >
                      <PencilIcon />
                    </button>
                  )}
                </div>
              </Card>
              <div className="w-full mx-auto px-6 md:px-8">
                <div className="flex items-start relative z-0 mb-2.5">
                  {/* <Image
              src={contentData?.data?.sponsor?.coverImage ? (contentData?.data?.sponsor?.coverImage as string) : imageHolder}

              // src="https://pagedone.io/asset/uploads/1705471668.png" alt="user-avatar-image" 
              className="border-4 border-solid border-white rounded-full"
              width={"120px"}
              height={"120px"}
              borderColor={contentData?.data?.sponsor?.coverImage ? "#fff" : "#333"}
                          objectFit={"cover"}

              alignSelf={"start"}
              marginTop={"-60px"}
              /> */}
                  <div className="relative">
                    <Image
                      // src={avatarUrl.current}
                      src={
                        contentData?.data?.sponsor?.coverImage
                          ? (contentData?.data?.sponsor?.coverImage as string)
                          : imageHolder
                      }
                      alt="Avatar"
                      className="w-[120px] h-[120px]  border-2 border-4 border-solid border-white rounded-full "
                      style={{
                        border: "5px solid #d100d1",
                        borderRadius: 60,
                      }}
                      objectFit={"cover"}
                      alignSelf={"start"}
                      marginTop={"-60px"}
                    />
                    {false && (
                      <button
                        className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[.35rem] rounded-full bg-white hover:bg-gray-100 border border-gray-600"
                        title="Change photo"
                        onClick={() => {
                          setCoverImageMode("profile");
                          // setModalOpen(true)
                        }}
                      >
                        <PencilIcon />
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex  flex-col  max-sm:gap-5 items-center justify-between mb-5">
                  <div className=" w-full p-0 m-0">
                    <h3 className=" font-manrope font-bold text-3xl leading-10 text-gray-900 mb-2">
                      {contentData?.data?.sponsor?.isCompany
                        ? contentData?.data?.sponsor?.companyName || "Anonymous"
                        : contentData?.data?.sponsor?.sponsorName ||
                          "Anonymous_" +
                            contentData?.data?.sponsor?.id?.substring(0, 4)}
                    </h3>
                    {user?.bandaCode !== "" && (
                      <h3
                        onClick={() => {
                          history.push("/auth/sign_in_bandajamii");
                        }}
                        className="  font-manrope font-bold text-2xl leading-10 text-gray-900 mb-2"
                      >
                        Registered banda {user?.bandaCode}
                        <span className="ml-5 hidden font-manrope font-bold text-md leading-10 text-gray-900 mb-2">
                          Login with banda password
                        </span>
                      </h3>
                    )}

                    {!contentData?.data?.sponsor?.isCompany && (
                      <Text
                        color="white"
                        fontSize="md"
                        mb="4px"
                        textAlign="center"
                      >
                        {contentData?.data?.sponsor?.address}
                      </Text>
                    )}
                    <Text
                      color="white"
                      fontSize="md"
                      mb="4px"
                      textAlign="center"
                    >
                      {contentData?.data?.sponsor?.physicalResidency}
                    </Text>
                    <p className="font-normal text-sm text-base leading-7 text-gray-500  ">
                      {contentData?.data?.sponsor?.industry}
                    </p>
                    {/* <p className="font-normal text-sm text-base leading-7 text-gray-500 ">Bio</p> */}
                    <p className="font-normal text-sm text-base leading-7 text-gray-500 ">
                      {contentData?.data?.sponsor?.address}
                      {contentData?.data?.sponsor?.physicalResidency}
                    </p>
                    <p className="font-normal text-sm text-base leading-7 text-gray-500 ">
                      {contentData?.data?.sponsor?.region}{" "}
                      {contentData?.data?.sponsor?.district}{" "}
                      {contentData?.data?.sponsor?.ward}
                    </p>
                    <p className="font-normal text-sm text-base leading-7 text-gray-500  ">
                      Since{" "}
                      {formatDateTime(contentData?.data?.sponsor?.createdAt)}
                    </p>

                    
                  </div>
                  <ul className="flex items-start py-8 gap-0 sm:gap:5">
                      <li>
                        <a
                          onClick={() => setAppMode("reservations")}
                          className={`flex items-center gap-2 cursor-pointer group ${
                            appMode === "reservations"
                              ? "text-orange-500 font-bold"
                              : "text-gray-400"
                          }`}
                        >
                          <Icon
                            as={MdBarChart}
                            width="20px"
                            height="20px"
                            color="inherit"
                          />
                          <span className="font-medium text-base leading-7">
                            {" "}
                            Reservations
                          </span>
                        </a>
                      </li>
                      {user.contentStatus && (
                        <li>
                          <a
                            onClick={() => setAppMode("contents")}
                            className={`flex items-center gap-2 cursor-pointer group ${
                              appMode === "contents"
                                ? "text-orange-500 font-bold"
                                : "text-gray-400"
                            }`}
                          >
                            <Icon
                              as={MdContentPaste}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                            <span className="font-medium text-base leading-7">
                              {" "}
                              Contents
                            </span>
                          </a>
                        </li>
                      )}

                      <li>
                        <a
                          onClick={() => setAppMode("gallery")}
                          className={`flex items-center gap-2 cursor-pointer group ${
                            appMode === "gallery"
                              ? "text-orange-500 font-bold"
                              : "text-gray-400"
                          }`}
                        >
                          <Icon
                            as={MdPhotoLibrary}
                            width="20px"
                            height="20px"
                            color="inherit"
                          />
                          <span className="font-medium text-base leading-7">
                            {" "}
                            Gallery
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setAppMode("notifications")}
                          className={`flex items-center gap-2 cursor-pointer group ${
                            appMode === "notifications"
                              ? "text-orange-500 font-bold"
                              : "text-gray-400"
                          }`}
                        >
                          <Icon
                            as={MdDoorbell}
                            width="20px"
                            height="20px"
                            color="inherit"
                          />
                          <span className="font-medium text-base leading-7">
                            Notifications
                          </span>
                        </a>
                      </li>

                      {/* <li>
            <a
                onClick={() => setAppMode("messages")}
                className={`flex items-center gap-2 cursor-pointer group ${appMode === "messages" ? "text-orange-500 font-bold" : "text-gray-400"}`}
            >
                <Icon as={MdMessage} width="20px" height="20px" color="inherit" />
                <span className="font-medium text-base leading-7">Messages</span>
            </a>
        </li> */}
                    </ul>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => setIsEdit(true)}
                      className="rounded-full border border-solid border-gray-300 bg-gray-50 py-3 px-4 text-sm font-semibold text-gray-900 shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-50 hover:bg-gray-100 hover:border-gray-300"
                    >
                      Edit
                    </button>
                    {/* <button
                        className="rounded-full border border-solid border-indigo-600 bg-indigo-600 py-3 px-4 text-sm font-semibold text-white whitespace-nowrap shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:bg-indigo-700 hover:border-indigo-700">Request Fund</button> */}
                  </div>
                </div>

                <div className="flex items-end justify-end gap-5">
                  {/* <a href="javascript:;"
                    className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1115_412)">
                            <path className="fill-blue-400 transition-all duration-500 group-hover:fill-white"
                                d="M20 10.2391C20 9.56523 19.9333 8.86958 19.8222 8.21741H10.2V12.0652H15.7111C15.4889 13.3044 14.7556 14.3913 13.6667 15.087L16.9556 17.587C18.8889 15.8261 20 13.2609 20 10.2391Z"
                                fill="" />
                            <path className="fill-green-400 transition-all duration-500 group-hover:fill-white"
                                d="M10.2 19.9783C12.9556 19.9783 15.2667 19.087 16.9556 17.5652L13.6667 15.087C12.7556 15.6957 11.5778 16.0435 10.2 16.0435C7.53337 16.0435 5.28893 14.2826 4.46671 11.9348L1.08893 14.4783C2.82226 17.8479 6.33337 19.9783 10.2 19.9783Z"
                                fill="#34A353" />
                            <path className="fill-yellow-400 transition-all duration-500 group-hover:fill-white"
                                d="M4.46673 11.913C4.0445 10.6739 4.0445 9.32608 4.46673 8.08695L1.08895 5.52173C-0.355496 8.34782 -0.355496 11.6739 1.08895 14.4783L4.46673 11.913Z"
                                fill="#F6B704" />
                            <path className="fill-red-400 transition-all duration-500 group-hover:fill-white"
                                d="M10.2 3.97827C11.6445 3.95653 13.0667 4.5 14.1112 5.47827L17.0223 2.6087C15.1778 0.913046 12.7334 2.58834e-06 10.2 0.0217417C6.33337 0.0217417 2.82226 2.15218 1.08893 5.52174L4.46671 8.08696C5.28893 5.7174 7.53337 3.97827 10.2 3.97827Z"
                                fill="#E54335" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1115_412">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a href="javascript:;"
                    className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1115_52)">
                            <path className="fill-indigo-600 transition-all duration-500 group-hover:fill-white"
                                d="M10.0001 20C15.523 20 20.0001 15.5228 20.0001 10C20.0001 4.47715 15.523 0 10.0001 0C4.47727 0 0.00012207 4.47715 0.00012207 10C0.00012207 15.5228 4.47727 20 10.0001 20Z"
                                fill="" />
                            <path className="fill-white transition-all duration-500 group-hover:fill-indigo-700"
                                d="M13.2516 3.06946H11.0364C9.72179 3.06946 8.25958 3.62236 8.25958 5.52793C8.266 6.1919 8.25958 6.82779 8.25958 7.54345H6.73877V9.96352H8.30665V16.9305H11.1877V9.91754H13.0893L13.2613 7.53666H11.1381C11.1381 7.53666 11.1428 6.47754 11.1381 6.16997C11.1381 5.41693 11.9216 5.46005 11.9688 5.46005C12.3416 5.46005 13.0666 5.46114 13.2527 5.46005V3.06946H13.2516V3.06946Z"
                                fill="" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1115_52">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
                <a href="javascript:;"
                    className="p-3 rounded-full border border-solid border-gray-300 bg-gray-50 group transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
                    <svg className="stroke-red-600 transition-all duration-500 group-hover:stroke-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1667 5.83333V5.875M9.16673 17.5H10.8334C13.9761 17.5 15.5474 17.5 16.5237 16.5237C17.5001 15.5474 17.5001 13.976 17.5001 10.8333V9.16667C17.5001 6.02397 17.5001 4.45262 16.5237 3.47631C15.5474 2.5 13.9761 2.5 10.8334 2.5H9.16673C6.02403 2.5 4.45268 2.5 3.47637 3.47631C2.50006 4.45262 2.50006 6.02397 2.50006 9.16667V10.8333C2.50006 13.976 2.50006 15.5474 3.47637 16.5237C4.45268 17.5 6.02403 17.5 9.16673 17.5ZM13.3334 10C13.3334 11.8409 11.841 13.3333 10.0001 13.3333C8.15911 13.3333 6.66673 11.8409 6.66673 10C6.66673 8.15905 8.15911 6.66667 10.0001 6.66667C11.841 6.66667 13.3334 8.15905 13.3334 10Z"
                            stroke="" stroke-width="1.6" stroke-linecap="round" />
                    </svg>
                </a> */}
                  {/* <a href="javascript:;"
                    className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-red-600 transition-all duration-500 group-hover:fill-white"
                            d="M1.40288 6.21319C1.48321 4.97646 2.47753 4.00723 3.71535 3.9459C5.5078 3.8571 8.06973 3.75 10.0001 3.75C11.9304 3.75 14.4923 3.8571 16.2848 3.9459C17.5226 4.00723 18.5169 4.97646 18.5972 6.21319C18.6742 7.39808 18.7501 8.85604 18.7501 10C18.7501 11.144 18.6742 12.6019 18.5972 13.7868C18.5169 15.0235 17.5226 15.9928 16.2848 16.0541C14.4923 16.1429 11.9304 16.25 10.0001 16.25C8.06973 16.25 5.5078 16.1429 3.71535 16.0541C2.47753 15.9928 1.48321 15.0235 1.40288 13.7868C1.32591 12.6019 1.25006 11.144 1.25006 10C1.25006 8.85604 1.32591 7.39808 1.40288 6.21319Z"
                            fill="#FC0D1B" />
                        <path className="fill-white transition-all duration-500 group-hover:fill-indigo-700" d="M8.12506 7.5V12.5L13.1251 10L8.12506 7.5Z" fill="white" />
                    </svg>
                </a> */}
                  {/* <a href="javascript:;"
                    className="p-3 rounded-full border border-solid border-gray-300 group bg-gray-50 transition-all duration-500 hover:bg-indigo-700 hover:border-indigo-700">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle className="transition-all duration-500 group-hover:fill-white" cx="10.0001" cy="10" r="8.75" fill="url(#paint0_linear_1115_481)" />
                        <path className="transition-all duration-500 group-hover:fill-indigo-700"
                            d="M14.3667 6.38049C14.4446 5.87707 13.9659 5.47972 13.5183 5.67625L4.60307 9.59053C4.28208 9.73146 4.30556 10.2177 4.63848 10.3237L6.47703 10.9092C6.82792 11.0209 7.20789 10.9631 7.5143 10.7514L11.6594 7.88767C11.7844 7.80131 11.9207 7.97904 11.8139 8.08914L8.83013 11.1654C8.54069 11.4638 8.59814 11.9695 8.94629 12.1878L12.2869 14.2827C12.6616 14.5176 13.1436 14.2816 13.2137 13.8288L14.3667 6.38049Z"
                            fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_1115_481" x1="10.0001" y1="1.25" x2="10.0001" y2="18.75"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#37BBFE" />
                                <stop offset="1" stop-color="#007DBB" />
                            </linearGradient>
                        </defs>
                    </svg>
                </a> */}
                </div>
              </div>
            </Card>
          )}

          {!isEdit && (
            <Card className="w-full mt-6 ">
              {appMode === "gallery" && (
                <>
                  
        <GalleryDiv />
                </>
              )}

              {appMode === "contents" && (
                <>
                  <Text
                    // color="white"
                    fontSize="xl"
                    mb="4px"
                    fontWeight={"bold"}
                    textAlign="start"
                  >
                    Contents
                  </Text>

                  <div>
                    {!userData.loading ? (
                      
                        <ContentsList
                          banner={"banner"}
                          avatar={avatar}
                          name="Jovin Jestas"
                          job="Developer"
                          posts="17"
                          followers="9"
                          following="274"
                        />
                        
                    ) : (
                      <div>
                        <div className="flex justify-center mt-20 ">
                          <div
                            className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                            role="status"
                            aria-label="loading"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {appMode === "reservations" && (
                <>
                  <Text
                    // color="white"
                    fontSize="xl"
                    mb="4px"
                    fontWeight={"bold"}
                    textAlign="start"
                  >
                    Reservations
                  </Text>

                  
                        {sponsorEvents?.data?.sponsorEventsList?.map(
                          (item: any) =>
                            !item?.isPromo && (
                              <Hosting
                                key={item?.id}
                                boxShadow={cardShadow}
                                mb="20px"
                                image="Project1"
                                item={item}
                                ranking={item?.createdAt}
                                dateStart={item?.startDateTime}
                                id={item?.content?.id}
                                eventType={item?.eventType}
                                title={item?.title}
                                category={item?.description}
                                added="itemExist"
                                away="Napoli"
                                home=""
                                dateEnd={item?.endDateTime}
                                sponsorName={item?.content?.author?.username}
                                sponsorId=""
                                link=""
                              />
                            )
                        )}
                     
                </>
              )}

              {appMode === "notifications" && (
                <>
                  <Text
                    // color="white"
                    fontSize="xl"
                    mb="4px"
                    fontWeight={"bold"}
                    textAlign="start"
                  >
                    Notifications
                  </Text>

                  <NotificationsList />
                </>
              )}

              {appMode === "messages" && (
                <>
                  <Text
                    // color="white"
                    fontSize="xl"
                    mb="4px"
                    fontWeight={"bold"}
                    textAlign="start"
                  >
                    Messages
                  </Text>

                  <Grid
                    templateColumns={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      //  "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                      base: "1fr",
                      lg: "repeat(2, 1fr)",
                      "2xl": "1fr",
                    }}
                    gap={{ base: "20px", xl: "20px" }}
                  >
                    <Card
                      mb={{ base: "0px", "2xl": "20px" }}
                      style={{
                        height: "100%",
                      }}
                      overflow={"auto"}
                    >
                      <SimpleGrid minChildWidth="150px" spacing="1px">
                        {sponsorEvents?.data?.sponsorEventsList?.map(
                          (item: any) =>
                            !item?.isPromo && (
                              <Hosting
                                key={item?.id}
                                boxShadow={cardShadow}
                                mb="20px"
                                image="Project1"
                                item={item}
                                ranking={item?.createdAt}
                                dateStart={item?.startDateTime}
                                id={item?.content?.id}
                                eventType={item?.eventType}
                                title={item?.title}
                                category={item?.description}
                                added="itemExist"
                                away="Napoli"
                                home=""
                                dateEnd={item?.endDateTime}
                                sponsorName={item?.content?.author?.username}
                                sponsorId=""
                                link=""
                              />
                            )
                        )}
                      </SimpleGrid>
                    </Card>
                  </Grid>
                </>
              )}
            </Card>
          )}

          {false && (
            <Card>
              {/* CROPING IMAGE */}
              <div className=" text-gray-400  ">
                <div className="flex flex-col items-center pt-12">
                  <div className="relative">
                    <img
                      src={avatarUrl.current}
                      alt="Avatar"
                      className="w-[100%] h-[100%]  border-2 "
                      style={{
                        border: "5px solid #d100d1",
                        borderRadius: 8,
                      }}
                    />
                    <button
                      className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[.35rem] rounded-full bg-gray-800 hover:bg-gray-700 border border-gray-600"
                      title="Change photo"
                      onClick={() => setModalOpen(true)}
                    >
                      <PencilIcon />
                    </button>
                  </div>
                  <h2 className="text-black font-bold mt-6">Add Cover image</h2>
                  <p className="text-gray-500 text-xs mt-2">
                    Image should be less than 10Mb Png,Jpeg or Jpg
                  </p>

                  {/* {!uploadedSuccess ||
      errors.responseError ||
      errors.title ||
      errors.description ||
      errors.link ? (
        <Button
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          colorScheme="green"
          marginTop="4"
          style={{
            width: "30%",
            alignSelf: "center",
          }}
        >
          Submit
        </Button>
      ) : (
        <>
          <Card bg={cardShadow} {...rest}>
            <Box
              style={{
                width: 300,
                marginBottom: 30,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                Your content is on review , Please kindly wait for 2-24 hrs
              </Text>
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                UPLOADED SUCCESSFULY
              </Text>
            </Box>
            <Button
              type="submit"
              onClick={() => {
                setUploadedSuccess(false);
                setFormData({
                  title: "",
                  description: "",
                  link: "",
                  contentType: "",
                  languages: "",
                  price: "0",
                  currency: "Tsh",
                  categories: "",
                  duration: "",
                  verifiedBy: "",
                });
                setImageData(null);
                setImageFile(null);
                handleClearImage();
              }}
              colorScheme="blackAlpha"
              marginTop="4"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              Upload another New Content
            </Button>
          </Card>
        </>
      )} */}

                  {errors.responseError && (
                    <Text style={{ color: "red" }}>Error </Text>
                  )}

                  {/* {imageFile && (
        <div>
          <p>File Name: {imageFile.name}</p>
          <p>File Size: {imageFile.size} bytes</p>
          <p>File Type: {imageFile.type}</p>
        </div>
      )} */}

                  {modalOpen && (
                    <Modal
                      updateAvatar={updateAvatar}
                      closeModal={() => setModalOpen(false)}
                    />
                  )}
                </div>
              </div>
            </Card>
          )}
        </Box>
      ) : (
        <div className="flex justify-center mt-20 h-screen">
          <div
            className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}
