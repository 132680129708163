import { useState } from "react";
import { NavLink, Redirect, Route, Switch, useHistory } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

// Layout components
import { SidebarContext } from "contexts/SidebarContext";
import NavBarHeaderSite from "components_app/NavBarHeaderWebsite/NavBarHeaderSite";
import { FaChevronLeft } from "react-icons/fa";
import filterRoutes from "routes";
import { useSelector } from "react-redux";

// Custom Chakra theme
export default function Landing({ data }: any) {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const routes = filterRoutes({user: user});;
  const history = useHistory();

  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const getRoute = () => {
    return window.location.pathname !== "/landing/full-screen-maps";
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/landing") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const authBg = useColorModeValue("white", "navy.900");
  document.documentElement.dir = "ltr";
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          {getRoute() ? (
            <Box mx="auto" minH="100vh">
              <Switch>
                {getRoutes(routes)}
                <Redirect
                  from="/"
                  to="/landing/landing
                  "
                />
              </Switch>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
