import { Box, Grid } from "@chakra-ui/react";
import ColumnsTable from "./components/ColumnsTable";
import BannerSide from "../exploreBanda/components/BannerSide";
import Banner from "../exploreBanda/components/Banner";

export default function SavedCart() {
  return (
    <Box pt={{ base: "160px", md: "90px", xl: "90px" }}>
      <Grid
        mb={10}
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner />
        <BannerSide />
      </Grid>
      <ColumnsTable />
    </Box>
  );
}
