// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { useCreateEventMutation } from "generated/graphql";
import { useState } from "react";
// Assets
import { MdAddBox, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import imageHolder from "assets/images/imageHolder.png";

interface FormErrors {
  [key: string]: string;
}

export default function AddsEventItem(props: {
  category: string;
  title: string;
  added: boolean;
  addedItem?: (data: boolean) => void;
  id: string;
  dateStart: string;
  away: string;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const {
    addedItem,
    id,
    title,
    category,
    dateStart,
    added,
    away,
    ranking,
    link,
    image,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [createEvent] = useCreateEventMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

  const handleSubmit = async () => {
    const dateStartInDate = new Date(dateStart);
    dateStartInDate.setMinutes(dateStartInDate.getMinutes() + 90);
    const endDateTime = dateStartInDate.toISOString();

    let uploadingData = {
      contentId: id,
      title: title,
      eventType: "BASIC",
      published: true,
      bandaId: user.id,
      startDateTime: "",
      endDateTime: "",
      breakFrequency: 1,
      breakDurationInMins: 15,
    };

    try {
      const response = await createEvent({
        variables: {
          createEventInput: uploadingData,
        },
      });

      if (
        response.data &&
        response.data.createEvent &&
        !response.data.createEvent.status
      ) {
        // console.log(response.data.createEvent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: response?.data?.createEvent?.message,
        }));
        return;
      } else {
        // console.log(response.data.createEvent);
        setUploadedSuccess(true);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        addedItem(response?.data?.createEvent?.status);
      }
      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  return (
    <>
      {!uploadedSuccess ? (
        <Card bg={bg} {...rest} p="14px">
          <Flex align="center" direction={{ base: "column", md: "row" }}>
            <Image
              h="80px"
              w="80px"
              src={imageHolder}
              borderRadius="8px"
              me="20px"
              objectFit="cover"
            />
            <Box mt={{ base: "10px", md: "0" }}>
              <Text
                color={textColorPrimary}
                fontWeight="500"
                fontSize="md"
                mb="4px"
              >
                {title}
              </Text>
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                {category}
              </Text>
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Date {ranking} Feb 2024 •{" "}
              </Text>

              {errors.responseError && (
                <Text fontWeight="500" color={"red"} fontSize="sm" me="4px">
                  {errors?.responseError}
                </Text>
              )}
            </Box>
            <Link
              // href={link}
              variant="no-hover"
              me="16px"
              ms="auto"
              p="0px !important"
            >
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                colorScheme="green"
                marginTop="0"
                style={{
                  width: "30%",
                  fontSize: 14,
                  borderRadius: 10,
                  height: 30,
                  padding: 10,
                  alignSelf: "center",
                }}
              >
                ADD
              </Button>
            </Link>
          </Flex>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
