// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { useSponsorDashboardQuery } from "generated/graphql";
import { MdAddTask, MdFileCopy, MdTv, MdVideocam } from "react-icons/md";
import { useSelector } from "react-redux";
import CheckTable from "views/site/rtl/components/CheckTable";
import ComplexTable from "views/site/sponsorAnalysis/components/ComplexTable";
import DailyTraffic from "views/site/sponsorAnalysis/components/DailyTraffic";
import PieCard from "views/site/sponsorAnalysis/components/PieCard";
import Tasks from "views/site/sponsorAnalysis/components/Tasks";
import TotalSpent from "views/site/sponsorAnalysis/components/TotalSpent";
import WeeklyRevenue from "views/site/sponsorAnalysis/components/WeeklyRevenue";
import tableDataCheck from "views/site/sponsorAnalysis/variables/tableDataCheck";
import tableDataComplex from "views/site/sponsorAnalysis/variables/tableDataComplex";

export default function SponnsorAnalysis() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const contentData = useSponsorDashboardQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: user.sponsorId,
      },
    },
  });

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      {!contentData.loading ? (
        <div>
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
              lg: 4,
              // "2xl": 6
            }}
            gap="20px"
            mb="20px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdVideocam}
                      color={brandColor}
                    />
                  }
                />
              }
              name="CONTENTS"
              value={contentData?.data?.sponsorDashboard?.content}
              bg="#dec0f1"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Icon w="32px" h="32px" as={MdTv} color={brandColor} />}
                />
              }
              name="RESERVATIONS"
              value={contentData?.data?.sponsorDashboard?.reservations}
              bg="#5d72ed"
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Icon w="32px" h="32px" as={MdTv} color={brandColor} />}
                />
              }
              name="AMOUNT"
              bg="#92dce5"
              value={`Tsh${contentData?.data?.sponsorDashboard?.amount}`}
            />
            <MiniStatistics
              bg="#ffe047"
              growth="+0%"
              name="PEOPLE"
              value={contentData?.data?.sponsorDashboard?.people}
            />
            {/* <MiniStatistics
          endContent={
            <Flex me="-16px" mt="10px">
              <FormLabel htmlFor="balance">
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id="balance"
                variant="mini"
                mt="5px"
                me="0px"
                defaultValue="content"
              >
                <option value="content">CONTENT</option>
                <option value="ads">ADS</option>
              </Select>
            </Flex>
          }
          name="Active Projects"
          value="3"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Favourate"
          value="5"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name="Total Projects"
          value="2935"
        /> */}
          </SimpleGrid>

          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
              lg: 2,
              //  "2xl": 3
            }}
            gap="20px"
            mb="20px"
          >
            {" "}
            <TotalSpent />
            <WeeklyRevenue />
          </SimpleGrid>
        </div>
      ) : (
        <div>
        <div className="flex justify-center mt-20 h-screen p-12">
          <div
            className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      )}
    </Box>
  );
}
