// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import {
  useCreateEventMutation,
  useRemoveEventMutation,
} from "generated/graphql";
import { useState } from "react";
// Assets
import { MdAddBox, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import imageHolder from "assets/images/imageHolder.png";

interface FormErrors {
  [key: string]: string;
}

export default function Project(props: {
  category: string;
  title: string;
  home: string;
  removedItem?: (data: boolean) => void;
  id: string;
  dateStart: string;
  away: string;
  ranking: number | string;
  link: string;
  image: string;
  [x: string]: any;
}) {
  const {
    id,
    title,
    removedItem,
    category,
    dateStart,
    home,
    away,
    ranking,
    link,
    image,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [removeEvent] = useRemoveEventMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await removeEvent({
        variables: {
          deleteEvent: {
            eventId: id,
          },
        },
      });

      if (
        response.data &&
        response.data.removeEvent &&
        !response.data.removeEvent.status
      ) {
        console.log(response.data.removeEvent);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        return;
      } else {
        // console.log(response.data.removeEvent);
        setRemovedSuccessfully(response.data.removeEvent.status);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));

        removedItem(response.data.removeEvent.status);
      }

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
    }
  };

  return (
    <>
      {!removedSuccessfully || true ? (
        <Card bg={bg} {...rest} p="14px">
          <Flex align="center" direction={{ base: "column", md: "row" }}>
            <Image
              h="80px"
              w="80px"
              src={imageHolder}
              borderRadius="8px"
              me="20px"
              objectFit="cover"
            />
            <Box mt={{ base: "10px", md: "0" }}>
              <Text
                color={textColorPrimary}
                fontWeight="500"
                fontSize="md"
                mb="4px"
              >
                {title}
              </Text>
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                {category}
              </Text>
              <Text
                fontWeight="500"
                color={textColorSecondary}
                fontSize="sm"
                me="4px"
              >
                Date {ranking} Feb 2024 •{" "}
              </Text>

              {errors.responseError && (
                <Text fontWeight="500" color={"red"} fontSize="sm" me="4px">
                  Adding Event Failed
                </Text>
              )}
            </Box>
            <Link
              // href={link}
              variant="no-hover"
              me="16px"
              ms="auto"
              p="0px !important"
            >
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                colorScheme="red"
                marginTop="0"
                style={{
                  fontSize: 12,
                  borderRadius: 10,
                  height: 30,
                  padding: 10,
                  width: 80,
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                CANCEL
              </Button>
            </Link>
          </Flex>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
