// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  MdAddAPhoto,
  MdEmojiEvents,
  MdHdrPlus,
  MdPublish,
  MdRemove,
} from "react-icons/md";
import imageHolder from "assets/images/imageHolder.png";
import {
  useAddBandaGalleryImageMutation,
  useAddBandaProfileImageMutation,
  useRemoveBandaGalleryImageMutation,
} from "generated/graphql";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IoAdd, IoCloudUploadOutline } from "react-icons/io5";
import AWS from "aws-sdk";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { DeleteIcon } from "@chakra-ui/icons";

interface FormErrors {
  [key: string]: string;
}

export default function Banner(props: {
  banner?: string;
  avatar?: string;
  name?: string;
  job?: string;
  posts?: number | string;
  followers?: number | string;
  following?: number | string;
  [x: string]: any;
}) {
  const { banner, avatar, name, job, posts, followers, following, ...rest } =
    props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [galleryeService] = useAddBandaGalleryImageMutation();
  const [removeBandaGalleryImage] = useRemoveBandaGalleryImageMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Card mb={{ base: "0px", lg: "10px" }} alignItems="center" {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >EventName on BandaAbc at 21:22:3212</Text>

      {avatar && (
        <Image
          src={avatar}
          boxSize="100%"
          objectFit="cover"

          // w={{ base: "100%", "3xl": "100%" }}
          // h={{ base: "100%", "3xl": "100%" }}
          // style={{
          //   border:"none",
          // }}
          // borderRadius="20px"
        />
      )}
    </Card>
  );
}
