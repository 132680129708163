import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import "./BandaJamiiPost.css";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button, FormLabel } from "@chakra-ui/react";

type Props = {
  cart: any;
  removeCart: (cart: ICart) => void;
  addToContent?: (cart: IBanda, value: number, typeIn: string) => void;
  removeFromList: (cart: IBanda) => void;
  selectedFrequency: (value: number, cart: IBanda) => void;
  isPromoted: (value: boolean, cart: IBanda) => void;
  promotedValue?: boolean;
  selectedPrice: (value: number, cart: IBanda) => void;
  defSelectedPrice: number;
};

export const SelectedBandaUmizaInPromo: React.FC<Props> = ({
  cart,
  removeFromList,
  selectedFrequency,
  selectedPrice,
  isPromoted,
  promotedValue,
  defSelectedPrice,
  removeCart,
  addToContent,
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  // const navigate = useNavigate();
  const [frequency, setFrequency] = React.useState(cart.frequency);

  const [amount, setAmount] = React.useState(parseInt(cart.price));
  const [isExpanded, setIsExpanded] = React.useState(false);

  // Change handler for the Amount field
  const handleAmountChange = (e: any) => {
    const amountValue = parseInt(e.target.value);
    setAmount(amountValue);
  };

  React.useEffect(() => {
    selectedPrice(amount, cart);
  }, [amount]);

  React.useEffect(() => {
    if(cart.type === "banda"){

    if(!(JSON.parse(cart.dates).length > 0)){
      removeFromList(cart)
    }
  }
  }, [cart]);

  // const decreaseFrequency = () => {
  //   setFrequency((prevFrequency: number) =>
  //     prevFrequency > 0 ? prevFrequency - 1 : 0
  //   );
  //   selectedFrequency(frequency)
  // };

  // console.log(cart);

  const [promoteChecked, setPromoteChecked] = React.useState(cart.isPromoted);
  const getTypeColor = (type: any) => {
    switch (type) {
      case "busket":
        return "#ffc2d1";
      case "banda":
        return "blue";
      case "region":
        return "#c7f9cc";
      case "district":
          return "gray";  
      default:
        return "#ade8f4"; // banda is undefined
    }
  };
  return (
    <div
      className="cart"
      style={{
        marginTop: 5,
        backgroundColor: "#f2ebfb",
        marginBottom: 5,
        // border:"1px solid #725ac1",
        border: "1px solid #a2d6f9",
        borderRadius: 5,
        width: "98%",
        paddingTop: 10,
        marginLeft: "1%",
        height: 110,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          //marginTop: 0,
          marginBottom: -10,
          // backgroundColor:"yellow",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            marginTop: 20,
            height: 50,
            backgroundColor: getTypeColor(cart.type), // Function to determine color based on type
            width: 8,
          }}
        />
        <div
          style={{
            marginTop: 0,
            marginBottom: 4,
            height: 50,
            width: "40%",
            left: 0,
            paddingLeft: 20,
            top: 0,
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "pink",
          }}
        >
          <div>
          <p className="small text-lg font-semibold">{cart.name ? cart.name.split('_')[0] : ""}</p>
          <p className="small text-lg font-semibold">{!cart.name ? cart.ward.split('_')[0] : ""}</p>
          <p className="small text-lg font-semibold">
            {(!cart.ward && !cart.name )? cart.district.split('_')[0]+`${cart.ward ? " , ":""}` :""}
            </p>
            <p className="small text-lg font-semibold">
            {(!cart.ward && !cart.name && !cart.district )? cart.region: ""}            </p>




          <p className="small">{cart.region? cart.region+`${cart.district ? " , ":""}`: ""}{cart.district? cart.district.split('_')[0]+`${cart.ward ? " , ":""}` :""}{cart.ward ? cart.ward.split('_')[0] : ""}{", "}{cart.name ? cart.name.split('_')[0] : ""}</p>
        {cart.type === "banda" &&  <h1 className="small">{JSON.parse(cart.dates).length}</h1>}

            {!(cart.type === "busket") && cart.type !== "banda" && (
              <h1
                style={{
                  fontSize: 12,
                  margin: 0,
                  padding: 0,
                  fontWeight: "bold",
                }}
              >
                {cart.frequency}{" "} Banda
              </h1>
            )}

            {!(cart.type === "busket") && cart.type !== "banda" && (
              <div
                style={{
                  // position:"absolute",
                  // top:10,
                  marginLeft: "40%",
                  marginTop: -25,
                  height: 30,
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  style={{
                    margin: 0,
                    // backgroundColor:"#333",
                    borderRadius: 5,
                    marginRight: 20,
                    marginLeft: 20,
                    padding: 0,
                  }}
                  className="small"
                  onClick={() => {
                    console.log(frequency);

                    setFrequency((prevFrequency: number) =>
                      prevFrequency > 0 ? prevFrequency - 1 : 0
                    );
                    selectedFrequency(frequency - 1, cart);
                  }}
                >
                  <FaMinus />
                </button>
                <button
                  style={{
                    margin: 0,
                    // backgroundColor:"#333",
                    borderRadius: 5,
                    padding: 0,
                  }}
                  onClick={() => {
                    console.log(frequency);
                    
                    setFrequency((prevFrequency: number) => prevFrequency + 1);
                    selectedFrequency(frequency + 1, cart);
                  }}
                >
                  <FaPlus />
                </button>
              </div>
            )}
          </div>
        </div>
        {cart.type === "busket" && (
          <>
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              name="amount"
              style={{
                height: 30,
                borderRadius: 4,
                border: "1px solid #7f7f7f",
                color:"black",
              }}
            />
            <p style={{ paddingLeft: 5 }}> Amount</p>
          </>
        )}
        <Button
         variant={"link"}
         color={"red"}
          className="small"
          onClick={() => {
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            console.log("cacsadcasdcacd")
            removeFromList(cart);
          }}
          style={{
            // backgroundColor: "#d52941",
            width: "20%",
            height: 30,
            color: "red",
            marginRight: "1%",
            marginLeft: "50%",
            marginTop: "2%",
            alignSelf: "end",
            fontSize: 12,
            borderRadius: 5,
          }}
        >
          Remove
        </Button>
      </div>

      {/* {!(cart.type === "busket") && (
        <div
          style={{
            display: "flex",
            marginTop: 5,
            marginLeft: 30,
            height: 20,
            flexDirection: "row",
          }}
        >
          <FormLabel
            style={{
              marginTop: 10,
              fontSize:12,
            }}
          >
            Promote<input
            type="checkbox"
            style={{
              marginLeft: 10,
            }}
          
            name="contentOwnership"
            checked={promoteChecked}
            onChange={() => {
              const dt = promoteChecked;
              setPromoteChecked(!promoteChecked);
              isPromoted(!dt, cart);
            }}
          />
          </FormLabel>
          
        </div>
      )} */}
    </div>
  );
};
