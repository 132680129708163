// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/site/profileContent/components/Banner";

// Assets
import avatar from "assets/images/imageHolder.png";
import AllAdds from "./components/AllAdds";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useUserQuery } from "generated/graphql";

export interface ContentFormData {
  title?: string;
  body?: string;
}

export interface ContentFormDataResponse {
  error?: string;
  success?: string;
}

export default function ProfileContent() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const userData = useUserQuery({
    fetchPolicy: "network-only",
    variables: {
      getUserProfileInput: {
        id: user.id,
      },
    },
  });

  console.log("*********************8888");
  console.log("*********************8888");
  console.log("*********************8888");
  console.log(userData);
  // console.log(user.id);
  // console.log(user.id);
  console.log("*********************8888");
  console.log("*********************8888");
  console.log("*********************8888");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          //  "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={"banner"}
          avatar={avatar}
          bandaAccountInfo={
            userData.data?.user?.accountFeedBacks
              ? JSON.parse(userData.data?.user?.accountFeedBacks)
              : []
          }
          name={
            userData.data?.user?.username
              ? userData.data?.user?.username
              : userData.data?.user?.email
          }
          job="" //"Developer"
          posts={userData.data?.user?.contents.length.toString()}
          followers={"0"}
          following={" "}
        />

        <AllAdds
          banner={"banner"}
          avatar={avatar}
          name="Jovin Jestas"
          job="Developer"
          posts="17"
          followers="9"
          following="274"
        />
      </Grid>
    </Box>
  );
}
