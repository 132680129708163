import { Box, Grid, SimpleGrid, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useColorModeValue } from "@chakra-ui/react";
// Custom components
import { Button, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import "./content.css";
import { useHistory, useLocation } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import {
  addCart,
  removeCart,
  updateCart,
} from "redux.store/cart/actionCreators";
import Project1 from "assets/images/imageHolder.png";

import {
  useGetContentQuery,
  useMyContentEventsListQuery,
} from "generated/graphql";
import Hosting from "./components/Hosting";
import { formatDateTime, formatDateTimeShort } from "../helpers";
import Information from "./components/Information";
import Banner from "../marketplace/components/Banner";
import BannerSide from "../marketplace/components/BannerSide";
// Custom components
const people = [
  {
    name: "Leslie Alexander",
    email: "leslie.alexander@example.com",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    name: "Michael Foster",
    email: "michael.foster@example.com",
    role: "Co-Founder / CTO",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    name: "Dries Vincent",
    email: "dries.vincent@example.com",
    role: "Business Relations",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: false,
  },
  {
    name: "Lindsay Walton",
    email: "lindsay.walton@example.com",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    name: "Courtney Henry",
    email: "courtney.henry@example.com",
    role: "Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    name: "Tom Cook",
    email: "tom.cook@example.com",
    role: "Director of Product",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    lastSeen: null,
  },
];

export default function Content() {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

  const storedCartItemString = localStorage.getItem("contentItem");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location.state);
  const contentIDd = queryParams.get("id");
  // Convert the string back to an object

  const { carts }: readonly ICart[] | any = useSelector(
    (state: CartsState) => state.carts,
    shallowEqual
  );
  //#Check remove any
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  //@ts-ignore
  const [reservationData, setReservationData] = useState<ICart>([]);
  const [first, setFirst] = useState(true);


 

  const contentData = useGetContentQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getContentInput: {
        contentId: contentIDd 
      },
    },
  });

  const activeOn = useMyContentEventsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getMyBandaEventsInput: {
        contentId:contentIDd,
        page: 1,
        pageSize: 10,
        // filterQuery,
      },
    },
  });

  console.log("activeOn");
  console.log(activeOn);
  console.log("activeOn");
  //console.log(contentData);


 

 

  const deleteCart = React.useCallback(
    (cart: ICart) => dispatch(removeCart(cart)),
    [dispatch, removeCart]
  );

 

  const idExists = carts.some(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );
  const foundCart = carts.find(
    (item: any) => item.id === contentData?.data?.getContent?.id
  );

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!contentData.loading ? (
        <>
          <section
            className="py-5  pt-12
 bg-white md:py-5  antialiased "
            style={{
              backgroundColor: "#10002b",
              borderRadius: 10,
              height: "fit-content",
            }}
          >
            <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-16">
                <div
                  className="shrink-0 max-w-md lg:max-w-lg mx-auto "
                  style={{
                    backgroundColor: "#10002b",
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    h="auto"
                    style={{ backgroundColor: "#333", borderRadius: 10 }}
                  >
                    <Box
                      w="100%"
                      // h="450px"
                      bgSize="cover"
                      display={"flex"}
                      alignSelf={"center"}
                    >
                      <img
                        src={contentData?.data?.getContent?.coverImage}
                        className={"centerImg"}
                        style={{ borderRadius: 10 }}
                      ></img>
                    </Box>
                  </Box>
                </div>

                <div className="mt-6 sm:mt-8 lg:mt-0">
                  <h1
                    className="text-3xl font-semibold text-white sm:text-2xl "
                    style={{ textAlign: "start" }}
                  >
                    {contentData?.data?.getContent?.title}
                  </h1>
                  <div className="mt-4 items-center gap-4 flex hover:underline">
                    <p
                      onClick={() => {
                        history.push(
                          `/site/cnt_owner_page?id=${contentData?.data?.getContent?.author?.id}&zIx=890`
                        );
                      }}
                      className="text-sm font-extrabold text-white  "
                    >
                      content by{" "}
                      {contentData?.data?.getContent?.author?.username}
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-2 mt-0">
                    <div className="flex mt-2 items-center gap-1">
                      {/* SVG stars remain unchanged */}

                      <svg
                        className="w-5 h-5 text-green-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.285 6.709a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L9 16.586l10.293-10.293a1 1 0 0 1 1.414 0z" />
                      </svg>

                      {/* Additional star SVGs are unchanged */}
                    </div>
                    {/* <p className="text-sm font-medium leading-none text-gray-500 ">
              (5.0)
            </p> */}
                    <p className="text-sm font-medium leading-none text-white  hover:text-md ">
                      34 Reservations
                    </p>
                    <a
                      href={contentData?.data?.getContent?.link}
                      className="text-sm font-medium leading-none text-white underline hover:no-underline hover:text-md "
                    >
                      Link
                    </a>
                  </div>

                  <div className="mt-6 sm:gap-4 sm:items-center sm:flex sm:mt-8">
                    {/* <a  className="flex items-center justify-center py-2.5 px-5 text-sm font-medium text-white focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 " role="button">
            <svg className="w-5 h-5 -ms-2 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z" />
            </svg>
            Add to favorites
          </a> */}

                    <a
                      onClick={(e) => {
                        // addToCart();
                        if (!user.loginStatus) {
                          history.push({
                            pathname: "/auth/sign-in",
                          });

                          return;
                        }
                        if (idExists) {
                          localStorage.setItem(
                            "reservationDataContent",
                            JSON.stringify({
                              reservationData: foundCart,
                              update: true,
                            })
                          );

                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: true,
                            })
                          );
                        } else {
                          localStorage.setItem(
                            "contentItem",
                            JSON.stringify({
                              content: contentData?.data?.getContent,
                              update: false,
                            })
                          );
                        }

                        history.push(`/site/reserve_order?id=${contentData?.data?.getContent?.id}&update=${idExists}`);

                     
                      }}
                      className="text-white mt-4 sm:mt-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5  focus:outline-none  flex items-center justify-center"
                      role="button"
                    >
                      <svg
                        className="w-5 h-5 -ms-2 me-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6"
                        />
                      </svg>
                      Add to cart
                    </a>
                  </div>

                  <hr className="my-6 md:my-8 border-gray-200 " />

                  <SimpleGrid
                    style={{
                      marginTop: 20,
                      marginBottom: 30,
                    }}
                    columns={2}
                    gap="20px"
                  >
                    {contentData?.data?.getContent?.verifiedBy !== "None" && (
                      <Information
                        boxShadow={cardShadow}
                        // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                        title="Verifier"
                        value={contentData?.data?.getContent?.verifiedBy}
                      />
                    )}
                    <Information
                      boxShadow={cardShadow}
                      // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                      title="Uploaded"
                      value={formatDateTime(
                        contentData?.data?.getContent?.createdAt
                      )}
                    />
                    <Information
                      boxShadow={cardShadow}
                      title="Language"
                      value={contentData?.data?.getContent?.languages}
                    />
                  </SimpleGrid>

                  <p
                    className="mb-6 text-gray-200 text-sm font-normal		"
                    style={{ textAlign: "start" }}
                  >
                    {contentData?.data?.getContent?.description}{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>

          {activeOn?.data?.myContentEventsList?.length > 0 && (
            <section
              className="
 bg-white md:py-0  antialiased mt-5"
              style={{
                //backgroundColor:"#10002b",
                borderRadius: 10,
                height: "fit-content",
              }}
            >
              <div className="max-w-screen-xl mx-auto 2xl:px-0">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-16">
                  <div
                    className="shrink-0 "
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 10,
                      marginBottom: 20,
                    }}
                  >
                    {/* <Text
            // color={"white"}
            fontWeight="bold"
            fontSize="24px"
            mt="10px"
            mb="4px"
          >Reservations</Text> */}
                    <ul role="list" className="divide-y divide-gray-200">
                      {activeOn?.data?.myContentEventsList?.map((item: any) => {
                        return (
                          <Hosting
                            boxShadow={cardShadow}
                            mb="20px"
                            image={Project1}
                            ranking="1"
                            item={item.banda}
                            dateStart={item?.startDateTime}
                            dateEnd={item?.endDateTime}
                            id={item.id}
                            sponsorImage={item?.sponsor?.coverImage}
                            link="#"
                            title={item?.banda?.bandaCode}
                            sponsorId={item?.sponsor?.id}
                            category={
                              item?.sponsor?.anonymous
                                ? "anonymous"
                                : item?.sponsor?.isCompany
                                ? item?.sponsor?.companyName.trim() !== ""
                                  ? item?.sponsor?.companyName
                                  : "anonymous"
                                : item?.sponsor?.sponsorName.trim() !== ""
                                ? item?.sponsor?.sponsorName
                                : "anonymous"
                            }
                            sponsorName={
                              item?.sponsor?.anonymous === true
                                ? "anonymous"
                                : item?.sponsor?.isCompany
                                ? item?.sponsor?.companyName.trim() !== ""
                                  ? item?.sponsor?.companyName
                                  : "anonymous"
                                : item?.sponsor?.sponsorName.trim() !== ""
                                ? item?.sponsor?.sponsorName
                                : "anonymous"
                            }
                            home={"Barcelona"}
                            away={"Napoli"}
                          />
                        );
                      })}
                    </ul>
                  </div>

                  <div className="mt-6 sm:mt-8 lg:mt-0">
                    <Grid
                      mb={3}
                      templateColumns={{
                        base: "1fr",
                        lg: "repeat(2, 1fr)",
                        //  "2xl": "1.34fr 1.62fr 1fr",
                      }}
                      gap={{ base: "10px", xl: "10px" }}
                    >
                      <Banner />
                      <BannerSide />
                      <Banner />
                      <BannerSide />
                    </Grid>
                    <Banner />
                    <div className="mt-2" />
                    <BannerSide />
                    <div className="mt-2" />
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        <div
          className="flex flex-col items-start space-y-3"
          style={{ marginTop: 200 }}
        >
          <p className="h-20 w-1/2 mt-5 bg-gray-200 rounded-lg animate-gradient-x"></p>
          <p className="h-4 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>

          <p className="h-4 w-1/2 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>
          <p className="h-4 w-1/2 mt-5 bg-gray-200 rounded-full animate-gradient-x"></p>

          <ul className="mt-5 space-y-3 w-full">
            <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
            <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
            <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
            <li className="w-full h-4 bg-gray-200 rounded-full animate-gradient-x"></li>
          </ul>
        </div>
      )}

      {false && (
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Card mb={{ base: "0px", "2xl": "20px" }} backgroundColor={"#240046"}>
            <Text
              color={"white"}
              fontWeight="bold"
              fontSize="24px"
              mt="10px"
              mb="4px"
            >
              {contentData?.data?.getContent?.title?.toUpperCase()}
            </Text>
            {contentData?.data?.getContent?.coverImage && (
              <div>
                {contentData?.data?.getContent?.coverImage.trim() !== "" && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    // h="100vh"
                    h="auto"
                    // bg="gray.100" // Background color for better visualization
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="100%"
                      // h="100vh"
                      h="auto"
                      // bg="gray.100" // Background color for better visualization
                    >
                      <img
                        src={contentData?.data?.getContent?.coverImage}
                        className={"centerImgX"}

                        // maxW="100%" // Ensures the image does not exceed the box width
                        // maxH="auto" // Ensures the image does not exceed the box height
                        // objectFit="contain"
                        // borderRadius="20px"
                      />
                    </Box>
                  </Box>
                )}
              </div>
            )}

            <Button
              color={"white"}
              onClick={() => {
                history.push(
                  `/site/cnt_owner_page?id=${contentData?.data?.getContent?.author?.id}&zIx=890`
                );
              }}
              fontSize="16px"
              variant={"link"}
              me="26px"
              alignSelf={"start"}
              mb="0px"
              mt="20px"
            >
              {contentData?.data?.getContent?.author?.username}
            </Button>
            <Text color={"green"} me="2px" mb="4px" mt="2px">
              {/* {contentData?.data?.getContent?.categories[0]?.name} */}
            </Text>
            <Link
              // bg="#003459"
              color="#4fea74"
              _hover={{ color: "#fff" }}
              fontWeight="500"
              fontSize="14px"
              alignSelf={"end"}
              variant={"link"}
              // py="30px"
              width={"50px"}
              mt={"-20px"}
              // px="47px"
              // me="38px"
              href={contentData?.data?.getContent?.link}
              target="_blank" // Opens the link in a new tab
              rel="noopener noreferrer"
            >
              Link{" "}
            </Link>
            <Text
              color={textColorSecondary}
              fontSize="md"
              me="26px"
              mb="40px"
              mt="20px"
            >
              {contentData?.data?.getContent?.description}
            </Text>

            <SimpleGrid
              style={{
                marginTop: 20,
                marginBottom: 30,
              }}
              columns={2}
              gap="20px"
            >
              {contentData?.data?.getContent?.verifiedBy && (
                <Information
                  boxShadow={cardShadow}
                  // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                  title="Verifi"
                  value={contentData?.data?.getContent?.verifiedBy}
                />
              )}
              <Information
                boxShadow={cardShadow}
                // title="Do you want to BusketFund  or cancel busketfund  bellow input to add it"
                title="Uploaded"
                value={formatDateTime(contentData?.data?.getContent?.createdAt)}
              />
              <Information
                boxShadow={cardShadow}
                title="Language"
                value={contentData?.data?.getContent?.languages}
              />
            </SimpleGrid>

            <SimpleGrid
              style={{
                marginTop: 20,
                marginBottom: 30,
                display: "flex",
                justifyContent: "center",
              }}
              columns={2}
              gap="20px"
            >
              <Link
                mt={{
                  base: "0px",
                  md: "10px",
                  lg: "0px",
                  xl: "10px",
                  "2xl": "0px",
                }}
                style={{ alignSelf: "center" }}
              >
                {user.loginStatus ? (
                  <>
                    {user?.sponsorStatus === "sponsor" &&
                    user?.mode !== "banda" ? (
                      <Button
                        onClick={(e) => {
                          // addToCart();
                          if (idExists) {
                            localStorage.setItem(
                              "reservationDataContent",
                              JSON.stringify({
                                reservationData: foundCart,
                                update: true,
                              })
                            );

                            localStorage.setItem(
                              "contentItem",
                              JSON.stringify({
                                content: contentData?.data?.getContent,
                                update: true,
                              })
                            );
                          } else {
                            localStorage.setItem(
                              "contentItem",
                              JSON.stringify({
                                content: contentData?.data?.getContent,
                                update: false,
                              })
                            );
                          }

                          history.push(`/site/reserve_order?id=${contentData?.data?.getContent?.id}&update=${idExists}`);

                     
                        }}
                        bg="#09bc8a"
                        color="white"
                        _hover={{ bg: "#01755c" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                        py="30px"
                        px="27"
                        me="38px"
                      >
                        Reserve
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => {
                          // addToCart();

                          if (user?.sponsorStatus !== "sponsor") {
                            history.push("/site/register_sponsor");
                          } else {
                            window.alert(
                              `Please log out and log in your sponsor account  ${user.mode} ?`
                            );
                          }
                        }}
                        bg="#09bc8a"
                        color="white"
                        _hover={{ bg: "#01755c" }}
                        _active={{ bg: "white" }}
                        _focus={{ bg: "white" }}
                        fontWeight="500"
                        fontSize="14px"
                        py="30px"
                        px="27"
                        me="38px"
                      >
                        Reserve
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={(e) => {
                      // addToCart();

                      history.push("/auth/sign-in");
                    }}
                    bg="#09bc8a"
                    color="white"
                    _hover={{ bg: "#01755c" }}
                    _active={{ bg: "white" }}
                    _focus={{ bg: "white" }}
                    fontWeight="500"
                    fontSize="14px"
                    py="30px"
                    px="27"
                    me="38px"
                  >
                    Reserve
                  </Button>
                )}
              </Link>
            </SimpleGrid>
          </Card>

          <Card
            maxHeight={"400px"}
            overflowX={"auto"}
            margin={"0px"}
            padding={"5px"}
            //  backgroundColor={"red"}
          >
            <Card
              maxHeight={"650px"}
              overflowX={"auto"}
              margin={"0px"}
              padding={"0px"}
            >
              <SimpleGrid
                // style={{
                //   // marginTop: 20,
                //   marginBottom: 30,
                //   display: "flex",
                //   justifyContent: "center",
                // }}
                minChildWidth={"250px"}
                gap="10px"
              >
                {activeOn?.data?.myContentEventsList?.length > 0 ? (
                  <>
                    {activeOn?.data?.myContentEventsList?.map((item: any) => {
                      return (
                        <Hosting
                          boxShadow={cardShadow}
                          mb="20px"
                          image={Project1}
                          ranking="1"
                          item={item.banda}
                          dateStart={item?.startDateTime}
                          sponsorImage={item?.sponsor?.coverImage}
                          dateEnd={item?.endDateTime}
                          id={item.id}
                          link="#"
                          title={item?.banda?.bandaCode}
                          sponsorId={item?.sponsor?.id}
                          category={
                            item?.sponsor?.anonymous
                              ? "anonymous"
                              : item?.sponsor?.isCompany
                              ? item?.sponsor?.companyName.trim() !== ""
                                ? item?.sponsor?.companyName
                                : "anonymous"
                              : item?.sponsor?.sponsorName.trim() !== ""
                              ? item?.sponsor?.sponsorName
                              : "anonymous"
                          }
                          sponsorName={
                            item?.sponsor?.anonymous === true
                              ? "anonymous"
                              : item?.sponsor?.isCompany
                              ? item?.sponsor?.companyName.trim() !== ""
                                ? item?.sponsor?.companyName
                                : "anonymous"
                              : item?.sponsor?.sponsorName.trim() !== ""
                              ? item?.sponsor?.sponsorName
                              : "anonymous"
                          }
                          home={"Barcelona"}
                          away={"Napoli"}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <Text
                      color={textColorPrimary}
                      fontWeight="bold"
                      fontSize="2xl"
                      mt="10px"
                      mb="4px"
                    >
                      No any Reservation made
                    </Text>
                  </>
                )}
              </SimpleGrid>
            </Card>
          </Card>
        </SimpleGrid>
      )}
    </Box>
  );
}
