// Chakra imports
import {
  Box,
  Button,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Image,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { error } from "console";
import AWS from "aws-sdk";
import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import "react-image-crop/dist/ReactCrop.css";
import imageHolder from "assets/images/imageHolder.png";

import {
  ContentType,
  useCreateContentMutation,
  useRegistrationBandaMutation,
} from "generated/graphql";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { filtersLabels } from "views/site/marketplace";
import Profile from "./components/components/Profile";
import PencilIcon from "./components/components/PencilIcon";
import Modal from "./components/components/Modal";
import { useHistory } from "react-router-dom";
const CROP_AREA_ASPECT = 4 / 5;

export interface FormData {
  title: string;
  description: string;
  link: string;
  verifiedBy: string;
  duration: string;
  price: string;
  currency: string;
  contentType: string;
  languages: string;
  categories: string;
}

interface FormErrors {
  [key: string]: string;
}

// Assets
export default function Upload(props: { [x: string]: any }) {
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const { ...rest } = props;
  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const validateDescription = (description: string) => {
    const textRegex = /^(.+)$/;
    if (!description.trim()) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "description is required",
      // }));
    } else if (!textRegex.test(description)) {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "Wrong Residency",
      // }));
    } else {
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   description: "",
      // }));
    }
  };

  const validateTitle = (title: string) => {
    const textRegex = /^(.+)$/;
    if (!title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
    } else if (!textRegex.test(title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "",
      }));
    }
  };

  const validateLink = (link: string) => {
    const textRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/;

    if (!link.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is required",
      }));
    } else if (!textRegex.test(link)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "link is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        link: "",
      }));
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    link: "",
    contentType: "",
    languages: "",
    categories: "",
    price: "0",
    currency: "Tsh",
    verifiedBy: "",
    duration: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [uploadedSuccess, setUploadedSuccess] = useState(false);

 

  const [createContent] = useCreateContentMutation();
  const [load, setLoad] = useState(false);

 
  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

 
  const readFile = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string));
      reader.addEventListener("error", reject);
      reader.readAsDataURL(file);
    });
  };


  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = document.createElement("img");
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  
  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const avatarUrl = useRef(imageHolder);


  const history = useHistory();

  

  return (
  
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          >
            <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Upload your contents or advertisements.
              <br />
              Here !
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">Upload contents or ads </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
              onClick={() => {
                
                history.push(`/site/upload_content`);

              }}
                // href="/upload_content"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
               Content
              </a>

              <a
              onClick={() => {
           
                history.push(`/site/upload_promo`); //upload_content my_adds

              }}
                // href="/my_adds"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Advertisments
              </a>


              

              {/* <a   onClick={() => {
           
           history.push(`/site/register`);
          //  history.push(`/site/register_content`);

         }}
         className="text-sm font-semibold leading-6 text-white">
                Register content <span aria-hidden="true">→</span>
              </a> */}
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            {/* <img
              alt="App screenshot"
              src="https://tailwindui.com/plus/img/component-images/dark-project-app-screenshot.png"
              width={1824}
              height={1080}
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
            /> */}
          </div>
        </div>
      </div>
  );
}
