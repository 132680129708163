import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import 'localStorage' from redux-persist
import {thunk} from "redux-thunk";
// import {thunkMiddleware} from "redux-thunk";
import rootReducer from "./rootReducer";
// import { configureStore } from '@reduxjs/toolkit';

// Define the persist config
const persistConfig = {
  key: "root", // The key to use in localStorage
  storage, // Use 'localStorage' as the storage backend
  // whitelist: ['user'],
  // blacklist: ["carts"],
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer and middleware (thunk in this case)
const store = createStore(
  persistedReducer,
  applyMiddleware(
    // thunkMiddleware
    thunk
    //  encryptionMiddleware
  )
);

// Persist the store
export const persistor = persistStore(store);

export default store;
