import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Text,
  Button,
  useColorModeValue,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import Banner from "views/site/registerSponsor/components/Banner";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import Card from "components/card/Card";
import {
  useAutoLoginMobileMutation,
  useRegistrationSponsorMutation,
  useSponsorQuery,
} from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { navigateThePage } from "redux.store/navigation/actionCreators";
import { Dispatch } from "redux";
import {
  loginUser,
  logoutUser,
  registerPartnership,
} from "redux.store/user/actionCreators";
import {
  findCurrentAccountStateByKey,
  isInList,
  pushOperation,
} from "../helpers";
// import districts from "assets/geo/Districts.json";
// import regions from "assets/geo/Regions.json";
// import wards from "assets/geo/Wards.json";
import regionData from "utils/geo_data.json";
import { jwtDecode } from "jwt-decode";

export const filtersLabels = [
  { mode: "Technology", label: "Technology" },
  { mode: "Finance", label: "Finance" },
  { mode: "Healthcare", label: "Afya" },
  { mode: "Manufacturing", label: "Manufacturing" },
  { mode: "Retail", label: "Retail" },
  { mode: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { mode: "Energy", label: "Energy" },
  {
    mode: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { mode: "Real Estate", label: "Real Estate" },
  { mode: "Education", label: "Education" },
  { mode: "Entertainment and Media", label: "Entertainment and Media" },
  { mode: "Agriculture", label: "Agriculture" },
  {
    mode: "Government and Public Sector",
    label: "Government and Public Sector",
  },
  { mode: "Construction", label: "Construction" },
  { mode: "Professional Services", label: "Professional Services" },
  { mode: "Mining", label: "Mining" },
  { mode: "Automotive", label: "Automotive" },
  { mode: "Telecommunications", label: "Telecommunications" },
  { mode: "Sports", label: "Sports" },
  { mode: "Biotechnology", label: "Biotechnology" },
  { mode: "Pharmaceuticals", label: "Pharmaceuticals" },
  { mode: "Fashion and Apparel", label: "Fashion and Apparel" },
  { mode: "Food and Beverage", label: "Food and Beverage" },
  {
    mode: "Information Technology (IT) Services",
    label: "Information Technology (IT) Services",
  },
  { mode: "Legal Services", label: "Legal Services" },
  { mode: "Marketing and Advertising", label: "Marketing and Advertising" },
  { mode: "Consumer Goods Services", label: "Consumer Goods Services" },
  {
    mode: "Advisory and Financial Services",
    label: "Advisory and Financial Services",
  },
  { mode: "Wholesale", label: "Wholesale" },
  { mode: "Administration", label: "Administration" },
  {
    mode: "Business Support and Waste Management Services",
    label: "Business Support and Waste Management Services",
  },
  {
    mode: "Other Services (except Public Administration)",
    label: "Other Services (except Public Administration)",
  },
  { mode: "Music", label: "Music" },
  { mode: "Other", label: "Other" },
];
export interface FormData {
  username?: string; // no need
  companyName?: string;
  idType?: string;
  email: string; // no need
  phoneNumber: string;
  companyId: string;
  address: string;
  physicalResidency: string;
  nationalID?: string;
  industry?: string;
  anonymous?: boolean;
  region: string;
  district: string;
  ward: string;
  mtaaVillage: string;
  contentOwnership?: boolean;
}

interface FormErrors {
  [key: string]: string;
}

export default function RegisterSponsor() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  async function save(key: string, value: any): Promise<void> {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`${key} saved successfully.`);
    } catch (error: any) {
      console.error(`Error saving ${key}: ${error.message}`);
    }
  }

  // Function to validate a phoneNumber number
  const validatePhone = (phoneNumber: string) => {
    const phoneRegex = /^(\+\d{1,3}\d{9,12}|07\d{8})$/;
    if (!phoneNumber.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Phone number is required",
      }));
    } else if (!phoneRegex.test(phoneNumber)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phoneNumber number format",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }
  };

  const validateNIDA = (text: string) => {
    const textRegex = /^\d{20}$/;
    if (!text.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text is required",
      }));
    } else if (!textRegex.test(text)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "Text must contain exactly 20 numbers",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nationalID: "",
      }));
    }
  };

  const validatePhysicalResidency = (physicalResidency: string) => {
    const textRegex = /^(.+)$/;
    if (!physicalResidency.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "physical Residency is required",
      }));
    } else if (!textRegex.test(physicalResidency)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "Wrong Residency",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        physicalResidency: "",
      }));
    }
  };

  const validateAddress = (address: string) => {
    const textRegex = /^(.+)$/;
    if (!address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is required",
      }));
    } else if (!textRegex.test(address)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "address is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "",
      }));
    }
  };

  const validateUsername = (bandaName: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;
    if (!bandaName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is required",
      }));
    } else if (!textRegex.test(bandaName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "banda Name is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bandaName: "",
      }));
    }
  };
  const validateCompanyName = (ownerName: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;

    if (!ownerName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "owner Name Name is required",
      }));
    } else if (!textRegex.test(ownerName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "owner Name Name is invalid",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ownerName: "",
      }));
    }
  };

  const validateIndustry = (industry: string) => {
    const textRegex = /^[a-zA-Z]+( [a-zA-Z]+){1,}$/;

    if (!industry.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "Industry is required",
      }));
    } else if (!textRegex.test(industry)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "industry must not be empty",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "",
      }));
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };
  const [formData, setFormData] = useState<FormData>({
    username: "",
    companyName: "",
    idType: "",
    email: "",
    industry: "",
    phoneNumber: "",
    companyId: "",
    region: "",
    district: "",
    ward: "",
    address: "",
    physicalResidency: "",
    nationalID: "",
    anonymous: false,
    mtaaVillage: "",
    contentOwnership: false,
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      responseError: "",
    }));

    // Validate the field based on its name
    switch (name) {
      case "nationalID":
        validateNIDA(value);
        break;
      case "phoneNumber":
        validatePhone(value);
        break;
      case "physicalResidency":
        validatePhysicalResidency(value);
        break;
      case "address":
        validateAddress(value);
        break;
      case "email":
        validateEmail(value);
        break;
      case "username":
        validateUsername(value);
        break;
      // case "companyName":
      //   validateCompanyName(value);
      // case "idType":
      //   validateCompanyName(value);

      // break;
      case "industry":
        validateIndustry(value);

        break;
      default:
        break;
    }
  };

  const [isCompany, setIsCompany] = useState(false);

  const isDataCompany = (e: any) => {
    console.log(e);
    console.log(e);
    console.log(e);
    setIsCompany(e);
  };

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const { user }: IUser | any = useSelector((state: UserState) => state.user);
  //Partnership cancel

  //Login
  const registerPartnershipService = React.useCallback(
    (user: IUser) => dispatch(registerPartnership(user)),
    [dispatch]
  );
  const addSponsorState = (user: IUser) => {
    registerPartnershipService(user);
  };

  const [registerSponsor] = useRegistrationSponsorMutation();
  const [userId, setUserId] = useState(user.id);
  const [loading, setLoading] = useState(false);

  const { error, data, refetch } = useSponsorQuery({
    fetchPolicy: "network-only",
    variables: {
      getSponsorProfileInput: {
        id: userId,
      },
    },
  });

  const loginService = React.useCallback(
    (user: IUser) => dispatch(loginUser(user)),
    [dispatch]
  );
  const loginX = (user: IUser) => {
    loginService(user);
  };

  const logoutService = React.useCallback(
    () => dispatch(logoutUser()),
    [dispatch]
  );

  const logout = (user: IUser) => {
    logoutService();
    localStorage.setItem("accessToken", "");
    history.push("/landing/landing");
  };
  const [autoLogin] = useAutoLoginMobileMutation();

  const loginF = async () => {
    //login user

    if (!errors.email && !errors.password) {
      try {
        const response = await autoLogin({
          variables: {
            autoLoginMobileInput: {
              userId: user.id,
              inputData: "",
            },
          },
        });

        console.log(response);

        if (
          response.data &&
          response.data.autoLoginMobile &&
          !response.data.autoLoginMobile.log
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "Login failed",
          }));

          return;
        } else {
          // Clear the error for loginMobile if login is successful or no loginMobile property is present
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginResponseError: "",
          }));
        }

        const accessToken: any = response.data?.autoLoginMobile.access_token;
        const refreshToken: any = response.data?.autoLoginMobile.refresh_token;
        const userdata: any = jwtDecode(accessToken);
        const accountStatus: any =
          response.data?.autoLoginMobile.account_status;
        const isLegFromQuery: any = response.data?.autoLoginMobile.log; //boolean

        console.log(userdata);
        console.log(userdata.status);

        save("accountStatus", accountStatus);
        save("accessToken", accessToken);
        save("refreshToken", refreshToken);
        console.log("accssTokenFromStore");

        // return

        //update redux state
        // localStorage.setItem("refreshToken", refreshToken);

        loginX({
          id: userdata.id,
          name: userdata.username,
          loginStatus: userdata.status.login,
          email: userdata.email,
          bandaCode: userdata.bandaCode,
          sponsorId: userdata?.sponsorId,
          accessType: userdata.status.isContentOwner ? "content" : "none",
          sponsorStatus: userdata.status.isSponsor ? "sponsor" : "none",
          contentStatus: userdata.status.isContentOwner,
          accountStat: !userdata.accountStat
            ? JSON.stringify([])
            : userdata.accountStat,
          mode: "none",
          bandaStatus: userdata.status.isBanda ? "banda" : "none",
          language: user.language,
          darkMode: user.darkMode,
          loading: false,
        });

        // setSuccess(true);

        // history.push(`/site/explore`);
        // setLoadingState(false)
        setLoading(false);
        history.push("/site/register");
      } catch (error) {
        console.log("LOGIN FAILED ");
        setErrors((prevErrors) => ({
          ...prevErrors,
          loginResponseError: "Login failed",
        }));
        // setLoadingState(false)
        setLoading(false);
        history.push("/site/register");
      }
    }
    // setLoadingState(false)
  };

  const handleSubmit = async () => {
    setLoading(true);
    validateAddress(formData.address);
    validatePhysicalResidency(formData.physicalResidency);
    validateNIDA(formData.nationalID);
    validatePhone(formData.phoneNumber);
    validateUsername(formData.username);
    validateCompanyName(formData.companyName);
    validateIndustry(formData.industry);
    validateEmail(formData.email);

    console.log(formData);

    if (
      isCompany &&
      (formData.companyName.trim() === "" || errors.companyName)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Please provide a valid company name",
      }));
      return;
    }

    //   if (
    //     !isCompany &&
    //     (formData.username.trim() === "" || errors.username)
    //   ) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       responseError: "Please provide a valid sponsor name",
    //     }
    //   )
    // );
    //     return;
    //   }

    if (
      !isCompany &&
      (formData.phoneNumber.trim() === "" || errors.phoneNumber)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Please provide a valid phone number",
      }));
      return;
    }

    if (isCompany && (formData.companyId.trim() === "" || errors.companyId)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Please provide company ID",
      }));
      return;
    }

    let uploadingData = {};
    // if (!(
    //   !isCompany &&
    //   !(
    //     errors.Address
    //     ||
    //     errors.physicalResidency
    //      ||
    //     errors.nationalID
    //     ||
    //     errors.phoneNumber
    //     //  ||
    //     // errors.username
    //     //||
    //     // errors.email
    //   )
    // )) {
    //   uploadingData = {
    //     usernameSponsor: formData?.username?.trim(),
    //     username: formData?.username,
    //     userId: user.id,
    //     nationalID: formData?.nationalID,
    //     email: formData?.email,
    //     phoneNumber: formData?.phoneNumber,
    //     address: formData?.address,
    //     physicalResidency: formData?.physicalResidency,
    //   };
    // }
    //  else if (
    //   isCompany &&
    //   !(
    //     errors.Address &&
    //     errors.physicalResidency &&
    //     errors.industry &&
    //     errors.phoneNumber &&
    //     errors.companyName &&
    //     errors.email
    //   )
    // ) {

    console.log("REGISTER COMPANY");
    console.log("REGISTER COMPANY");
    console.log("REGISTER COMPANY");
    console.log("REGISTER COMPANY");
    console.log("REGISTER COMPANY");

    uploadingData = {
      username: formData?.username, //no need
      userId: user.id,
      companyName: formData?.companyName,
      industry: formData?.industry,
      email: formData?.email, //no need
      phoneNumber: formData?.phoneNumber,
      idType: formData.idType,
      address: formData?.address,
      physicalResidency: formData?.physicalResidency,
      usernameSponsor: formData?.companyName, //no need
      nationalID: formData?.nationalID,
      companyID: formData?.companyId,
      region: formData?.region,
      district: formData?.district,
      ward: formData?.ward,
      organisationDoc: "link to doc ",
      isCompany: isCompany,
      anonymous: formData?.anonymous,
      contentOwnership: user.contentStatus ? true : formData?.contentOwnership,
    };

    try {
      // console.log("NO ERROR");
      // console.log("NO ERROR");
      // console.log("NO ERROR");

      const response = await registerSponsor({
        variables: {
          registerSponsorInput: uploadingData,
        },
      });

      console.log("response");
      console.log("response");
      console.log(response);
      console.log("response");
      // localStorage.setItem("accessToken", "");

      if (response.data?.registrationSponsor.log) {
        // localStorage.setItem("refreshToken", "");
        // loginX({
        //   id: user.id,
        //   name: user.name,
        //   loginStatus: user.loginStatus,

        //   email: user.email,
        //   accessType: user.accessType, //user.role === "SPONSOR" ? "sponsor" :"content",
        //   accountStat: response.data.registrationSponsor.mode,
        //   contentStatus: user.contentStatus,
        //   mode: user.mode,
        //   language: user.language,
        //   darkMode: user.darkMode,
        //   loading: false,
        // });

        // Reset form fields after submission if needed

        setFormData({
          username: "",
          companyName: "",
          idType: "",
          email: "",
          phoneNumber: "",
          address: "",
          region: "",
          district: "",
          ward: "",
          physicalResidency: "",
          nationalID: "",
          industry: "",
          companyId: "",
          mtaaVillage: "",

          // organisationDoc: "",
        });

        setUserId(user.id);
        // logout(user);
        loginF();
      } else {
        setLoading(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: response.data.registrationSponsor.mode,
        }));

        history.push("/site/register");
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      history.push("/site/register");
      setLoading(false);
    }
  };

  const [latestSponsorApplication, setLatestSponsorApplication] =
    useState(null);

  useEffect(() => {
    if (user && user.accountStat) {
      const latest = findCurrentAccountStateByKey(
        JSON.parse(user.accountStat),
        "SponsorApplication"
      );
      setLatestSponsorApplication(latest);
    }
  }, [user]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      district: "",
      ward: "",
    }));
  }, [formData.region]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ward: "",
    }));
  }, [formData.district]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid
        mb="20px"
        gridTemplateColumns={{
          xl: "repeat(1, 1fr)",
          // "2xl": "1fr 0.46fr"
        }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        {!loading ? (
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
          >
            {/* <Banner
            isDataCompany={isDataCompany}
            accountStatus={latestSponsorApplication?.status}
          /> */}
            {!(latestSponsorApplication?.status === "PENDING") && (
              <Box>
                {!loading ? (
                  <div>
                    <Card mb={{ base: "0px", "2xl": "20px" }}>
                      <Text
                        style={{
                          marginTop: 10,
                          marginBottom: 50,
                        }}
                        color={textColor}
                        fontSize="2xl"
                        ms="24px"
                        fontWeight="700"
                      >
                        Sponsor account
                      </Text>

                      <Flex align="start" mb={"30px"}>
                        <Button
                          bg={isCompany ? "green.500" : "transparent"}
                          color={isCompany ? "white" : "black"}
                          _hover={{ bg: "#1a759f" }}
                          _active={{ bg: "green.500" }}
                          // _focus={{ bg: "white" }}
                          fontWeight="800"
                          fontSize="14px"
                          py="20px"
                          px="17"
                          me="18px"
                          onClick={() => {
                            setIsCompany(true);
                          }}
                        >
                          Organisation
                        </Button>
                        <Button
                          bg={!isCompany ? "green.500" : "transparent"}
                          color={!isCompany ? "white" : "black"}
                          _hover={{ bg: "#1a759f" }}
                          _active={{ bg: "green.500" }}
                          fontWeight="800"
                          fontSize="14px"
                          py="20px"
                          px="17"
                          me="18px"
                          onClick={() => {
                            setIsCompany(false);
                          }}
                        >
                          Private
                        </Button>
                      </Flex>

                      <SimpleGrid minChildWidth="300px" spacing="20px">
                        {isCompany ? (
                          <div>
                            <FormLabel>Organisation Name</FormLabel>
                            <Input
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        ) : (
                          <div>
                            <FormLabel>Sponsor Name</FormLabel>
                            <Input
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        )}

                        <div>
                          {isCompany ? (
                            <div>
                              {errors.idType && (
                                <Text style={{ color: "red" }}>
                                  Wrong Format
                                </Text>
                              )}
                              <FormLabel>Id Type :</FormLabel>

                              <Select
                                name="idType"
                                value={formData.idType}
                                onChange={handleChange}
                                placeholder="Select idType"
                              >
                                {[
                                  { mode: "COMPANY_ID", label: "COMPANY ID" },
                                  { mode: "TIN", label: "TIN" },
                                  { mode: "NGO", label: "NGO" },
                                ].map((cat: any) => (
                                  <option key={cat.mode} value={cat.mode}>
                                    {cat.label}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          ) : (
                            <div>
                              {errors.idType && (
                                <Text style={{ color: "red" }}>
                                  Wrong Format
                                </Text>
                              )}
                              <FormLabel>Id Type :</FormLabel>

                              <Select
                                name="idType"
                                value={formData.idType}
                                onChange={handleChange}
                                placeholder="Select idType"
                              >
                                {[
                                  { mode: "NIDA", label: "NIDA" },
                                  // { mode: "TIN", label: "TIN" },
                                  // { mode: "TIN", label: "TIN" },
                                  { mode: "PASSPORT", label: "PASSPORT" },
                                  {
                                    mode: "DRIVER_LICENCE",
                                    label: "DRIVER LICENCE",
                                  },
                                ].map((cat: any) => (
                                  <option key={cat.mode} value={cat.mode}>
                                    {cat.label}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          )}
                        </div>

                        {isCompany ? (
                          <div>
                            <FormLabel>Company ID/ TIN</FormLabel>
                            <Input
                              type="text"
                              name="companyId"
                              value={formData.companyId}
                              onChange={handleChange}
                            />
                          </div>
                        ) : (
                          <div>
                            <FormLabel>ID Number</FormLabel>
                            <Input
                              type="text"
                              name="nationalID"
                              value={formData.nationalID}
                              onChange={handleChange}
                            />
                          </div>
                        )}

                        {/* <div>
                      {errors.email && (
                        <Text style={{ color: "red" }}>
                          Wrong Format of email
                        </Text>
                      )}
                      <FormLabel>Email:</FormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div> */}

                        <div>
                          {errors.phoneNumber && (
                            <Text style={{ color: "red" }}>
                              Wrong Format of Phone number
                            </Text>
                          )}
                          <FormLabel>Phone Number</FormLabel>
                          <Input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>

                        <div>
                          <FormLabel>Postal address</FormLabel>
                          <Input
                            type="text"
                            name="address"
                            placeholder="PO Box 000"
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </div>

                        {/* AREA LOCATION SELECTION */}

                        <div>
                          {errors.region && (
                            <Text style={{ color: "red" }}>
                              {errors.region}
                            </Text>
                          )}

                          <Select
                            name="region"
                            value={formData.region}
                            onChange={handleChange}
                            placeholder="Select region"
                          >
                            {regionData?.map((cat: any) => (
                              <option key={cat.region} value={cat.region}>
                                {cat.region}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div>
                          {errors.district && (
                            <Text style={{ color: "red" }}>
                              {errors.district}
                            </Text>
                          )}

                          <Select
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            placeholder="Select district"
                          >
                            {regionData
                              ?.find(
                                (cat: any) => cat.region === formData.region
                              )
                              ?.data?.map((cat_district: any) => (
                                <option
                                  key={
                                    formData.region +
                                    "_" +
                                    cat_district.district
                                  }
                                  // value={cat_district.district}
                                  value={
                                    cat_district.district +
                                    "_" +
                                    cat_district.postCode
                                  }
                                >
                                  {cat_district.district}
                                </option>
                              ))}
                          </Select>
                        </div>

                        <div>
                          {errors.ward && (
                            <Text style={{ color: "red" }}>{errors.ward}</Text>
                          )}
                          <Select
                            name="ward"
                            value={formData.ward}
                            onChange={handleChange}
                            placeholder="Select ward"
                          >
                            {regionData
                              ?.find(
                                (cat: any) => cat.region === formData.region
                              )
                              // @ts-ignore

                              ?.data?.find(
                                (cat_district: any) =>
                                  cat_district.district +
                                    "_" +
                                    cat_district.postCode ===
                                  formData.district
                              )
                              ?.data?.map((cat_ward: any) => {
                                console.log("cat_ward");
                                console.log(cat_ward);
                                console.log("cat_ward");

                                return (
                                  <option
                                    key={
                                      formData.district + "_" + cat_ward.ward
                                    }
                                    // value={cat_ward.ward}
                                    value={
                                      cat_ward.ward + "_" + cat_ward.postCode
                                    }
                                  >
                                    {cat_ward.ward}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <div>
                          {errors.mtaaVillage && (
                            <Text style={{ color: "red" }}>
                              {errors.mtaaVillage}
                            </Text>
                          )}
                          <Select
                            name="mtaaVillage"
                            value={formData.mtaaVillage}
                            onChange={handleChange}
                            placeholder="Select mtaa"
                          >
                            {regionData
                              // @ts-ignore
                              ?.find(
                                (cat: any) => cat?.region === formData.region
                              )
                              // @ts-ignore
                              ?.data?.find(
                                (cat_district: any) =>
                                  `${cat_district?.district}_${cat_district?.postCode}` ===
                                  formData.district
                              )
                              ?.data?.find(
                                (cat_ward: any) =>
                                  `${cat_ward?.ward}_${cat_ward?.postCode}` ===
                                  formData.ward
                              )
                              ?.data?.map((mtaaVillage: any) => (
                                <option
                                  key={
                                    formData.district +
                                    "_" +
                                    mtaaVillage.mtaaVillage
                                  }
                                  value={
                                    mtaaVillage.mtaaVillage
                                      ? `Mtaa/Kijiji_${mtaaVillage.mtaaVillage}`
                                      : //@ts-ignore
                                        `Kitongoji_${mtaaVillage.kitongoji}`
                                  }
                                >
                                  <span className="text-blue-300">
                                    {mtaaVillage.mtaaVillage
                                      ? "Mtaa/Kijiji cha"
                                      : "Kitongoji cha"}{" "}
                                  </span>
                                  {mtaaVillage.mtaaVillage}
                                  {
                                    //@ts-ignore

                                    mtaaVillage.kitongoji
                                  }
                                </option>
                              ))}
                          </Select>
                        </div>
                        {/*  */}

                        {isCompany && (
                          <div>
                            <FormLabel>Physical address</FormLabel>
                            <Input
                              type="text"
                              name="physicalResidency"
                              placeholder="21 Mizengo Street Kinondoni"
                              value={formData.physicalResidency}
                              onChange={handleChange}
                            />
                          </div>
                        )}

                        {isCompany && (
                          <div>
                            <FormLabel>Industry</FormLabel>
                            <Select
                              name="industry"
                              value={formData.industry}
                              onChange={handleChange}
                              placeholder="Select Industry"
                            >
                              {filtersLabels.map((cat: any) => (
                                <option key={cat.mode} value={cat.mode}>
                                  {cat.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        )}

                        {!user.contentStatus && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <FormLabel>
                              Do you also want to setup a content ownership
                              account
                            </FormLabel>
                            <input
                              type="checkbox"
                              name="contentOwnership"
                              checked={formData.contentOwnership}
                              onChange={handleChange}
                            />
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <FormLabel>Anyonymous </FormLabel>
                          <input
                            type="checkbox"
                            name="anonymous"
                            checked={formData.anonymous}
                            onChange={handleChange}
                          />
                        </div>
                      </SimpleGrid>
                    </Card>
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                      colorScheme="green"
                      marginTop="4"
                    >
                      Submit
                    </Button>
                    {errors.responseError && (
                      <Text style={{ color: "red" }}>
                        {errors.responseError}
                      </Text>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-center mt-20 h-screen">
                      <div
                        className="animate-spin inline-block w-16 h-16 border-8 border-current border-t-transparent text-violet-700 rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            )}
          </Flex>
        ) : (
          <div>
            <p>LOADING ...</p>
          </div>
        )}
      </Grid>
    </Box>
  );
}
