import React, { useState } from "react";

const Accordion = () => {
  const [activeItem, setActiveItem] = useState<string | null>("1");
  const [nestedActiveItem, setNestedActiveItem] = useState<string | null>(null);

  const toggleAccordion = (id: string) => {
    setActiveItem(activeItem === id ? null : id);
  };

  const toggleNestedAccordion = (id: string) => {
    setNestedActiveItem(nestedActiveItem === id ? null : id);
  };

  return (
    <div id="accordion-nested-parent" data-accordion="collapse" className="z-50 bg-white p-3 rounded-3xl h-full ">
      <p id="accordion-collapse-heading-1" className="text-md">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500    gap-3"
          data-accordion-target="#accordion-collapse-body-1"
          aria-expanded={activeItem === "1"}
          aria-controls="accordion-collapse-body-1"
          onClick={() => toggleAccordion("1")}
        >
          <span>Details</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${
              activeItem === "1" ? "rotate-180" : ""
            } shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </p>
      <div
        id="accordion-collapse-body-1"
        className={activeItem === "1" ? "" : "hidden"}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5  ">
          <p className="mb-2 text-sm text-gray-500 ">Wilaya Temeke </p>
          <p className="mb-2 text-gray-500 text-sm">
            Mkoa wa{" "}
            <a
              href="/docs/getting-started/introduction/"
              className="text-blue-600  hover:underline"
            >
              Dar es salaam
            </a>{" "}
            2024
          </p>
          <p className="mb-4 text-md text-gray-500 ">Details</p>
          <div id="accordion-nested-collapse" data-accordion="collapse">
            <p id="accordion-nested-collapse-heading-1" className="text-sm">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 rounded-t-xl font-medium rtl:text-right text-gray-500   gap-3"
                data-accordion-target="#accordion-nested-collapse-body-1"
                aria-expanded={nestedActiveItem === "1"}
                aria-controls="accordion-nested-collapse-body-1"
                onClick={() => toggleNestedAccordion("1")}
              >
                <span>Kata</span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    nestedActiveItem === "1" ? "rotate-180" : ""
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </p>
            <div
              id="accordion-nested-collapse-body-1"
              className={nestedActiveItem === "1" ? "" : "hidden"}
              aria-labelledby="accordion-nested-collapse-heading-1"
            >
              <div className="p-5 border text-sm border-b-0 border-gray-200 ">
                <p className="text-gray-500 ">Mawassdk ,k </p>
              </div>
            </div>
            <p id="accordion-nested-collapse-heading-2" className="text-sm">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-0 focus:ring-gray-200  gap-3"
                data-accordion-target="#accordion-nested-collapse-body-2"
                aria-expanded={nestedActiveItem === "2"}
                aria-controls="accordion-nested-collapse-body-2"
                onClick={() => toggleNestedAccordion("2")}
              >
                <span>Mawasiliano</span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    nestedActiveItem === "2" ? "rotate-180" : ""
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </p>
            <div
              id="accordion-nested-collapse-body-2"
              className={nestedActiveItem === "2" ? "" : "hidden"}
              aria-labelledby="accordion-nested-collapse-heading-2"
            >
              <div className="p-5 border border-b-0 border-gray-200 ">
                <p className="text-gray-500 text-sm ">hdvkuhgvskughckahcasc </p>
              </div>
            </div>
            <p id="accordion-nested-collapse-heading-3" className="text-sm">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-0 focus:ring-gray-200  gap-3"
                data-accordion-target="#accordion-nested-collapse-body-3"
                aria-expanded={nestedActiveItem === "3"}
                aria-controls="accordion-nested-collapse-body-3"
                onClick={() => toggleNestedAccordion("3")}
              >
                <span>Taarifa</span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    nestedActiveItem === "3" ? "rotate-180" : ""
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </p>
            <div
              id="accordion-nested-collapse-body-3"
              className={nestedActiveItem === "3" ? "" : "hidden"}
              aria-labelledby="accordion-nested-collapse-heading-3"
            >
              <div className="p-5 border border-gray-200 ">
                <p className="mb-2 text-gray-500 text-sm">
                  sbhdkjhsb,fhbsj,fs{" "}
                </p>
                <p className="mb-2 text-gray-500 text-sm">jbdclsfhbhsf</p>
                <ul className="ps-5 text-gray-500 list-disc ">
                  <li>
                    <a href="#" className="text-blue-600  hover:underline">
                      dakjhdkjs
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      rel="nofollow"
                      className="text-blue-600  hover:underline"
                    >
                      sfsfdvsdv
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p id="accordion-collapse-heading-2" className="text-md">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-sm rtl:text-right text-gray-500  focus:ring-0 focus:ring-gray-200  gap-3"
          data-accordion-target="#accordion-collapse-body-2"
          aria-expanded={activeItem === "2"}
          aria-controls="accordion-collapse-body-2"
          onClick={() => toggleAccordion("2")}
        >
          <span>Serikali ya mtaa Yombo Vituka</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${
              activeItem === "2" ? "rotate-180" : ""
            } shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </p>
      <div
        id="accordion-collapse-body-2"
        className={activeItem === "2" ? "" : "hidden"}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <div className="p-5 border border-gray-200 ">
          <p className="mb-2 text-gray-500  text-sm">
            2024 mwenyekiti Sambi ....{" "}
          </p>
          <p className="text-gray-500 text-sm">
            mawasiliano{" "}
            <a
              href="https://flowbite.com/figma/"
              className="text-blue-600  hover:underline"
            >
              2024
            </a>{" "}
            20200100201
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
