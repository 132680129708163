import * as React from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import "./BandaJamiiPost.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useFilterAreasQuery } from "generated/graphql";
import { useState } from "react";

type Props = {
  cart: any;
  mode: string;
  removeCart: (cart: ICart) => void;
  addToContent: (cart: IBanda, type: string) => void;
  reservationData: any;
  addToContentWard: (cart: IBanda, type: string) => void;
};

interface Ward {
  district?: string;
  id?: string;
  region?: string;
  ward?: string;
  name?: string;
}

export const AreaBased: React.FC<Props> = ({
  cart,
  removeCart,
  addToContent,
  addToContentWard,
  mode,
  reservationData,
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [selectedDistrict, setSelectedDistrict] = React.useState<string>("");
  const [selectedRegion, setSelectedRegion] = React.useState<string>("");

  const [activeWards, setActiveWards] = React.useState<Ward[] | undefined>(
    undefined
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { loading, error, data, refetch } = useFilterAreasQuery({
    fetchPolicy: "network-only",
    variables: {
      feedInputData: {
        userId: user.id,
        page,
        pageSize,
        category: mode,
      },
    },
  });

  const isNameAlreadyExists = (data: string) => {
    // Check if any item in reservationData has the same name
    return reservationData?.bandaumizaList?.some(
      (item: any) => item.name === data
    );
  };

  const isTitleAlreadyExists = (data: string) => {
    // Check if any item in reservationData has the same name
    return reservationData?.bandaumizaList?.some(
      (item: any) => item.name === data
    );
  };

  const isDistrictAdded = (data: string) => {
    // Check if any item in reservationData has the same name
    return reservationData?.bandaumizaList?.some(
      (item: any) => item.name === data
    );
  };

  const cartExist = isTitleAlreadyExists(cart.regionName);

  return (
    <div
      className="cart"
      style={{
        marginTop: 5,
        marginBottom: 5,
        borderBottom: "1px solid #03071e",
        backgroundColor: "gray",
        width: "100%",
        minHeight: 40,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: 0,
          marginBottom: 0,
          // backgroundColor: "red",

          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            // marginTop: 10,
            // marginBottom: 4,
            // height: 50,
            width: "90%",
            left: 0,
            paddingLeft: 20,
            top: 0,
            display: "flex",

            flexDirection: "column",
            // backgroundColor: "pink",
          }}
        >
          <h1
            className={"mt-2"}
            style={{
              fontSize: 13,
              color: "white",
            }}
          >
            {cart.regionName}
          </h1>
        </div>
        {mode === "REGION" ? (
          <>
            {cartExist ? (
              <button
                onClick={() => {
                  // removeCart(cart);
                }}
                style={{
                  textDecoration: "underline",
                  width: "30%",
                  height: 30,
                  color: "green",
                  fontSize: 12,
                  marginLeft: "15%",
                  marginTop: "2%",
                  borderRadius: 5,
                }}
              >
                Added
              </button>
            ) : (
              <button
                onClick={() => {
                  addToContent(
                    {
                      title: "",
                      // frequency:1,
                      district: "",
                      id: cart.regionName,
                      region: cart.regionName,
                      ward: "",
                      name: cart.regionName,
                      dates: JSON.stringify([]),
                      isPromoted: false,
                    },
                    "region"
                  );
                }}
                style={{
                  // backgroundColor: "#04a777",
                  width: "30%",
                  textDecoration: "underline",
                  fontWeight: "600",
                  height: 30,
                  color: "#ccc",
                  marginRight: "3%",
                  fontSize: 12,
                  marginTop: "2%",
                  borderRadius: 5,
                }}
              >
                Sponsored
              </button>
            )}
          </>
        ) : (
          <button
            onClick={() => {
              if (cart.regionName === selectedRegion) {
                setSelectedRegion("");
              } else {
                setSelectedRegion(cart.regionName);
              }
            }}
            style={{
              display: "flex",
              justifyContent: "end",
              alignContent: "end",
              // color: "#aadbff",

              width: "27%",
              height: 20,
              // marginRight: "3%",
              // marginLeft:"10%",
              // marginTop:"8%",
              borderRadius: 5,
            }}
            className="mt-3"
          >
            {selectedRegion === cart.regionName ? (
              <FaAngleUp color={"#ccc"} size={"20px"} />
            ) : (
              <FaAngleDown color={"#ccc"} size={"20px"} />
            )}
          </button>
        )}
      </div>

      <div
        style={{
          backgroundColor: "#6b9080",
          width: "100%",
          maxHeight: 300,
          overflowY: "auto",
          // marginRight:"3%",
          // marginLeft:"10%",
          // marginTop:"8%",
          // borderRadius: 5,
        }}
      >
        {!(mode === "REGION") && selectedRegion === cart.regionName ? (
          data?.filterAreas?.map((district: any) => {
            const districtExist = isDistrictAdded(district.districtName);
            if (district.regionName === cart.regionName) {
              return (
                <div
                  style={{
                    minHeight: 60,
                    marginBottom: 2,
                    // borderBottom: "#333",
                    borderBottomWidth: 2,
                    width: "100%",
                    // marginLeft:"4%",
                    backgroundColor: "gray",

                    borderBottom: "1px solid #d6d2d2",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{
                        paddingLeft: 20,
                        width: "60%",
                        color: "white",
                        marginLeft: "4%",

                        fontSize: 13,
                      }}
                    >
                      {district.districtName}
                    </p>
                    {mode === "WARD" ? (
                      <button
                        onClick={() => {
                          if (district.districtName === selectedDistrict) {
                            setSelectedDistrict("");
                          } else {
                            setSelectedDistrict(district.districtName);
                          }
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignContent: "end",
                          // backgroundColor: "#aadbff",
                          width: "32%",
                          // height: 30,
                          marginRight: "6%",
                          // marginLeft:"10%",
                          // marginTop:"8%",
                          borderRadius: 5,
                        }}
                      >
                        {selectedDistrict === district.districtName ? (
                          <FaAngleUp color={"white"} width={"20px"} />
                        ) : (
                          <FaAngleDown color={"white"} width={"20px"} />
                        )}
                      </button>
                    ) : (
                      <>
                        {districtExist ? (
                          <button
                            onClick={() => {
                              // addToContent(district,"area");
                            }}
                            style={{
                              textDecoration: "underline",
                              width: "20%",
                              height: 30,
                              color: "green",
                              marginRight: "3%",
                              marginLeft: "10%",
                              marginTop: "2%",
                              fontSize: 12,
                              borderRadius: 5,
                            }}
                          >
                            Added
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              addToContent(
                                {
                                  title: "",
                                  // frequency:1,
                                  district: district.districtName,
                                  id: district.districtName,
                                  region: district.regionName,
                                  ward: "",
                                  name: district.districtName,
                                  dates: JSON.stringify([]),
                                  isPromoted: false,
                                },

                                "district"
                              );
                            }}
                            style={{
                              textDecoration: "underline",
                              fontWeight: "600",
                              width: "20%",
                              // height: 30,
                              color: "#ccc",
                              marginRight: "3%",
                              marginLeft: "10%",
                              marginTop: "2%",
                              fontSize: 12,
                              borderRadius: 5,
                            }}
                          >
                            Sponsored
                          </button>
                        )}
                      </>
                    )}
                  </div>

                  <div>
                    {mode === "WARD" &&
                      selectedDistrict === district.districtName &&
                      data?.filterAreas?.map((ward: any) => {
                        // console.log(ward);

                        const wardExist = isNameAlreadyExists(ward.wardName);
                        if (ward.districtName === selectedDistrict) {
                          return (
                            <div
                              style={{
                                // borderBottom: "#333",
                                borderBottomWidth: 2,
                                width: "100%",
                                height: 60,
                                // marginLeft:"4%",
                                marginBottom: 2,
                                flexDirection: "row",
                                backgroundColor: "#333",
                                borderBottom: "1px solid #d6d2d2",

                                display: "flex",
                                // overflowY: "auto",
                              }}
                            >
                              <p
                                style={{
                                  paddingLeft: 20,
                                  width: "60%",
                                  color: "white",
                                  fontSize: 14,
                                  marginLeft: "8%",
                                }}
                              >
                                {ward.wardName}
                              </p>

                              {wardExist ? (
                                <button
                                  onClick={() => {
                                    // addToContentWard(ward,"area");
                                  }}
                                  style={{
                                    textDecoration: "underline",
                                    width: "20%",
                                    height: 30,
                                    color: "green",
                                    marginRight: "3%",
                                    marginLeft: "10%",
                                    marginTop: "2%",
                                    fontSize: 12,
                                    borderRadius: 5,
                                  }}
                                >
                                  Added
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    // addToContent(ward, "area");
                                    addToContentWard(
                                      {
                                        title: "",
                                        // frequency:1,
                                        district: ward.districtName,
                                        id: ward.wardName,
                                        region: ward.regionName,
                                        ward: ward.wardName,
                                        name: ward.wardName,
                                        dates: JSON.stringify([]),
                                        isPromoted: false,
                                      },
                                      "ward"
                                    );
                                  }}
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: "600",
                                    width: "20%",
                                    // height: 30,
                                    color: "#ccc",

                                    marginRight: "3%",
                                    marginLeft: "10%",
                                    marginTop: "2%",
                                    fontSize: 12,
                                    borderRadius: 5,
                                  }}
                                >
                                  Sponsored
                                </button>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              );
            }
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
