"use client";

import { FC, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  DocumentCheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Dashboard_Mini, { BandaFormData } from "./BGIS_Mini";

interface DrawerProps {
  setOpen: (value: boolean) => void;
  open: boolean;
  address: BandaFormData;
}
export const BgisModal: FC<DrawerProps> = ({ setOpen, open ,address}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed h-full inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 h-full w-full overflow-y-auto">
        <div className="flex w-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative  w-[80%] item-center  transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white  px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              {/* <div className="md:flex md:flex-col md:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-cyan-100 sm:mx-0 sm:h-10 sm:w-10">
                  <DocumentCheckIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-cyan-600"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Taarifa za kikundi
                  </DialogTitle>

                  <div className="mt-2">
                    <p className="text-sm min-w-[300px] text-gray-500">
                      Weka taarifa za kikundi
                    </p>

           




                  </div>
                </div>
              </div> */}

                 
                    {/* Contents */}



                    <Dashboard_Mini address={address}/>

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="inline-flex w-full justify-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 sm:ml-3 sm:w-auto"
              >
                Close
              </button>
              {/* <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Close
              </button> */}
            </div>

            </div>
            
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
