// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import SwitchField from "components/fields/SwitchField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { findCurrentBandaStatus } from "views/site/helpers";

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string;
  bandaAccountInfo: any;
  job: string;
  posts: number | string;
  followers: number | string;
  following: number | string;
  [x: string]: any;
}) {
  const {
    bandaAccountInfo,
    banner,
    avatar,
    name,
    job,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log("bandaAccountInfo");
  console.log("bandaAccountInfo");
  console.log(bandaAccountInfo);
  console.log("bandaAccountInfo");
  console.log("bandaAccountInfo");

  const [bandaList, setBandaList] = useState(bandaAccountInfo); //

  useEffect(() => {
    if (bandaAccountInfo) {
      setBandaList(bandaAccountInfo);
    }
  }, [bandaAccountInfo]);

  const history = useHistory();

  return (
    <Card
      mb={{ base: "0px", lg: "20px" }}
      alignItems="center"
      {...rest}
      style={{
        minHeight: 400,
      }}
    >
      <Box
        // bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>

      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Saved Events
          </Text>
        </Flex>
        <Flex mx="auto" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Banda Marked
          </Text>
        </Flex>
      </Flex>

      <Flex align="center" w="100%" justify="space-between" mb="30px" mt="50px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="1xl"
          mb="4px"
        >
          Notifications
        </Text>
        <Button
          style={{
            // backgroundColor:"red",
            margin: 0,
            marginLeft: -0,
          }}
          onClick={() => {
            history.push("/site/register");
          }}
        >
          <Text
            w="max-content"
            // color={"violet"}
            fontSize="sm"
            fontWeight="700"
            // me="100px"
            style={{
              fontSize: 16,
            }}
          >
            <Text as="span">REGISTRATION</Text>
          </Text>
        </Button>
      </Flex>

      {/* 
<Flex align='center' w='100%' justify='space-between' mb='30px' mt='50px' >
				<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
					Notifications
				</Text>
			</Flex>
 */}

      <Box
        style={{
          minHeight: 100,
          width: "100%",
          // backgroundColor:"red",
        }}
      >
        {bandaList
          .filter((item: any) => {
            return item.key === "BandaApplication" && item.status === "ACTIVE";
          })
          .map((item: any) => {
            return (
              <>
                <SwitchField
                  reversed={true}
                  fontSize="sm"
                  mb="10px"
                  id="0"
                  label={`Banda ${item.details} is active. Login with bandaCode and password`}
                />
              </>
            );
          })
          .slice(0, 4)}
        {bandaList
          .filter((item: any) => {
            return (
              item.key === "ContentOwnerApplication" &&
              item.status === "PENDING"
            );
          })
          .map((item: any) => {
            return (
              <>
                <SwitchField
                  reversed={true}
                  fontSize="sm"
                  mb="10px"
                  id="0"
                  label={`Your Content ownership account is activated`}
                />
              </>
            );
          })
          .slice(0, 4)}
        {bandaList
          .filter((item: any) => {
            return (
              item.key === "SponsorApplication" && item.status === "PENDING"
            );
          })
          .map((item: any) => {
            return (
              <>
                <SwitchField
                  reversed={true}
                  fontSize="sm"
                  mb="10px"
                  id="0"
                  label={`Your Sponsor account is activated`}
                />
              </>
            );
          })
          .slice(0, 4)}
        <SwitchField
          reversed={true}
          fontSize="sm"
          mb="10px"
          id="0"
          label={` You can now register Bandajamii , Content owning or Sponsoring account`}
        />
        <SwitchField
          reversed={true}
          fontSize="sm"
          mb="10px"
          id="0"
          label={`Welcome to BandaJamii users `}
        />
      </Box>
    </Card>
  );
}
