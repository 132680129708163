import * as actionTypes from "./actionTypes";

// POSTS
export function addCart(cart: ICart) {
  console.log("#############ADDD CART");
  console.log("#############ADDD CART");
  console.log("#############ADDD CART");
  console.log("#############ADDD CART");
  
  const action: CartsActions = {
    type: actionTypes.ADD_ARTICLE,
    cart,
  };

  return simulateHttpRequest(action);
}

export function clearCart() {
  const action: CartsActionClear = {
    type: actionTypes.CLEAR_CART,
  };
  return (dispatch: DispatchTypeClear) => {
      dispatch(action);
  };
}

export function updateCart(cart: ICart) {
  const action: CartsActions = {
    type: actionTypes.UPDATE_ARTICLE,
    cart,
  };

  return simulateHttpRequest(action);
}
export function activeCart(cart: ICart) {
  const action: CartsActions = {
    type: actionTypes.ACTIVE_CART,
    cart,
  };

  return simulateHttpRequest(action);
}


export function removeCart(cart: ICart) {
  const action: CartsActions = {
    type: actionTypes.REMOVE_ARTICLE,
    cart,
  };
  return simulateHttpRequest(action);
}

export function simulateHttpRequest(action: CartsActions) {

  return (dispatch: DispatchType) => {
    setTimeout(() => {
      dispatch(action);
    }, 100);
  };
}

