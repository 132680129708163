import {
  Box,
  Button,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import { useMyPaidCartQuery } from "generated/graphql";
import * as React from "react";
// Assets
import { MdCancel, MdCheckCircle, MdClose, MdOutlineError } from "react-icons/md";
import { useSelector } from "react-redux";
import { formatDateTime } from "views/site/helpers";

type RowObj = {
  name: string;
  type: string;
  status: string;
  date: string;
  progress: number;
};

const columnHelper = createColumnHelper<any>();

// const columns = columnsDataCheck;
export default function ComplexTable() {
  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [page, setPage] = React.useState(1);
  const [pageTemp, setPageTemp] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [category, setCategory] = React.useState("all");


  const [orderViewItem, setOrderViewItem] = React.useState({});
  const [orderViewItemId, setOrderViewItemId] = React.useState("");
  const [orderView, setOrderView] = React.useState(false);

  React.useEffect(() => {
    const selectedItem = contentData?.data?.myPaidCart?.find(
      (item: any) => item.id === orderViewItemId
    );

    console.log("setting");
    console.log("setting");
    console.log(selectedItem);
    console.log("setting");
    console.log("setting");

    setOrderViewItem(selectedItem);

  }, [orderViewItemId, orderView]);


  const contentData = useMyPaidCartQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      ordersInput: {
        userId: user.id,
        page,
        pageSize,
        category,
      },
    },
  });
  const foundCart = (id: string) =>
    contentData?.data?.myPaidCart?.find((item: any) => item.id === id);

  console.log(contentData?.data?.myPaidCart);
  
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = contentData?.data?.myPaidCart ? contentData?.data?.myPaidCart : [];
  React.useEffect(()=>{
    setData(() => [...defaultData])
  },[contentData?.data?.myPaidCart])

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          INVOICE
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
        <Button
          onClick={() => {
            // foundCart(info.getValue()).activated
            setOrderView(true);
            setOrderViewItemId(info.getValue());
          }}
          color={textColor}
          fontSize="sm"
          fontWeight="700"
        > {foundCart(info.getValue())?.invoiceId?.toUpperCase()}
        </Button>
      </Flex>
      ),
    }),
 
  
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDateTime(info.getValue())}
        </Text>
      ),
    }),

    columnHelper.accessor("totalAmount", {
      id: "totalAmount",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Amount
        </Text>
      ),
      cell: (info) => (
        <Text           color={"green.400"}
        fontSize="large" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor("currency", {
      id: "currency",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          CURRENCY
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor("orderSteps", {
      id: "orderSteps",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
      <>{ 
      info.getValue() === "SUCCESS" && <Text color={textColor} fontSize="sm" fontWeight="700">
          PAID
        </Text>
      }</>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
   <>
   {!orderView ? <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        {/* <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >My</Text> */}

        {/* <Menu /> */}
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
    :
    <Card flexDirection="column" w="100%" px="0px">
                <Card bg={"f9f9f9"} flexDirection="column" w="100%" px="0px">
                  <Flex 
                //  justifyContent={"space-between"}
                width={"100%"} 
                  flexDirection={"column"}>
                    <Text
                      justifyContent="space-between"
                      align="center"
                      marginLeft={"30px"}
                      fontWeight={"bold"}
                      textAlign={"start"}
                      fontSize={{ sm: "13px", lg: "16px" }}
                      color={
                        //@ts-ignore
                       // orderViewItem?.isPaid
                        true ? "blue.400" : "gray.400"
                      }
                    >RECEIPT </Text>
                    <Button
                      onClick={() => {
                        setOrderView(false);
                        setOrderViewItem({});

                      }}
                      p="0px"
                      width={"30px"}
                      alignContent={"center"}
                      alignSelf={"end"}
                      display={"flex"}
                      justifyContent={"center"}
                      height={"30px"}
                    >
                      <Icon
                        as={MdClose}
                        color="black"
                        // w="18px"
                        alignSelf={"center"}
                        // h="18px"
                        // me="10px"
                      />
                    </Button>
                  </Flex>

                  <Flex
                    // justifyContent={"space-between"}
                    flexDirection={"column"}
                  >
                    {/* <div
              style={{
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                borderRadius: "10px",
                maxWidth: "400px",
                margin: "auto",
                position: "relative",
                zIndex: 999,
              }}
            >
             
            </div> */}
                    <Flex
                      flexDirection={"row"}
                      w="100%"
                      mb="20px"
                      style={{ marginLeft: "0%", height: "140px" }}
                    >
                      <Flex
                        flexDirection={"column"}
                        w="70%"
                        // bg={"pink"}
                        paddingLeft={"3%"}
                        // justifyContent={"center"}
                        mb="20px"
                      >
                        <Text
                          align={"start"}
                          marginTop={"30px"}
                          fontSize="20px"
                          fontWeight="800"
                          color={textColor}
                        >
                          BANDAJAMII
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Physical residency
                        </Text>
                        <Text
                          // justifyContent="space-between"
                          // align="center"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          Address
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection={"column"}
                        w="30%"
                        // bg={"red"}
                        paddingTop={"2%"}
                        paddingRight={"1%"}
                        paddingLeft={"10%"}
                        mb="20px"
                        style={{ marginLeft: "0%", height: "100px" }}
                      >
                        <Text
                          align="start"
                          fontSize="sm"
                          fontWeight="600"
                          color={textColor}
                        >
                          Invoice Details{" "}
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            orderViewItem?.id.toUpperCase()
                          }
                        </Text>
                        <Text
                          justifyContent="space-between"
                          align="start"
                          // marginLeft={"30px"}
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {
                            //@ts-ignore
                            formatDateTime(orderViewItem?.createdAt)
                          }
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"3%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"95%"}
                      justifyContent={"space-between"}
                    >
                      <div>
                        <Text fontSize="14px" color={textColor}>
                          Billing to {user.name}
                        </Text>

                        <Text
                          fontSize="24px"
                          fontWeight="600"
                          color={textColor}
                        >
                          {
                            //@ts-ignore
                            orderViewItem?.itemName?.toUpperCase()
                          }
                        </Text>
                      </div>

                      <Text fontSize="24px" fontWeight="600" color={textColor}>
                        {
                          //@ts-ignore
                          orderViewItem?.totalAmount
                        }{" "}
                        {
                          //@ts-ignore
                          orderViewItem?.currency
                        }
                      </Text>
                    </Flex>

                    <Text
                      justifyContent="space-between"
                      align="start"
                      marginLeft={"3%"}
                      marginTop={"3%"}
                      marginBottom={"1%"}
                      fontSize={{ sm: "14px", lg: "16px" }}
                      color="gray.400"
                      // borderBottomWidth={"1px"}
                      // borderColor={"gray.300"}
                    >
                      Description
                      {/* Description:{ price , currency  ,promoted ,number of quantityu} */}
                    </Text>

                    <Flex
                      // bg={"#f7f7f7"}
                      marginLeft={"10%"}
                      padding={"10px"}
                      flexDirection="column"
                    >
                      {
                        //@ts-ignore
                        orderViewItem?.bucketFund?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              {item.orderType === "BUCKET" ? (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              ) : (
                                <Text
                                  fontSize="md"
                                  mb={"20px"}
                                  fontWeight="600"
                                  color={textColor}
                                >
                                  {item.frequency}
                                  {" banda @ "}
                                  {item.amount}{" "}
                                  {item.isPromoted ? "+ 3000" : ""}
                                </Text>
                              )}
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        orderViewItem?.events?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.bandaCode}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {" banda for "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        orderViewItem?.promoBucket?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }

                      {
                        //@ts-ignore
                        orderViewItem?.promoEvent?.map((item: any) => {
                          return (
                            <Flex
                              justifyContent={"space-between"}
                              width={"97%"}
                              borderBottomColor={"gray.200"}
                              borderBottomWidth={"1px"}
                            >
                              <Text
                                fontSize="md"
                                mb={"20px"}
                                fontWeight="600"
                                color={textColor}
                                display={"flex"}
                                flexDirection={"row"}
                              >
                                {item.specifiedArea}{" "}
                                {item.isPromoted && (
                                  <Text ml={"30px"} color={"green"}>
                                    promoted
                                  </Text>
                                )}
                              </Text>

                              <Text
                                fontSize="md"
                                fontWeight="600"
                                color={textColor}
                              >
                                {item.frequency}
                                {" banda @ "}
                                {item.amount} {item.isPromoted ? "+ 3000" : ""}
                              </Text>
                            </Flex>
                          );
                        })
                      }
                    </Flex>

                    <Flex
                      w="100%"
                      marginLeft={"2%"}
                      // bg={"#f5f5f5"}
                      mb="20px"
                      flexDirection="row"
                      width={"93%"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="14px" color={textColor}>
                        Total
                      </Text>

                      <Flex flexDirection={"column"}>
                        <Text fontSize="16px" color={textColor}>
                          {
                            //@ts-ignore
                            orderViewItem?.totalAmount
                          }{" "}
                          {
                            //@ts-ignore
                            orderViewItem?.currency
                          }
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>

              <Button
                onClick={() =>
                 { 
                 //  generatePDF(targetRef, { filename: "page.pdf" })
                  }
                  }
                fontSize="12px"
                color={textColor}
              >
                Download Receipt pdf{" "}
              </Button>
            </Card>}
            </>
  );
}
