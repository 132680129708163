import * as actionTypes from "./actionTypes";
import axios from "axios";

// POSTS
export function addPromo(promo: ICart) {
  const action: PromoActions = {
    type: actionTypes.ADD_PROMO,
    promo,
  };

  return simulateHttpRequest(action);
}

export function updatePromo(promo: ICart) {
  const action: PromoActions = {
    type: actionTypes.UPDATE_PROMO,
    promo,
  };

  return simulateHttpRequest(action);
}


export function removePromo(promo: ICart) {
  const action: PromoActions = {
    type: actionTypes.REMOVE_PROMO,
    promo,
  };
  return simulateHttpRequest(action);
}


  export function clearCartPromo() {
    const action: CartsActionClear = {
      type: actionTypes.CLEAR_CART_PROMO,
    };
    return (dispatch: DispatchTypeClear) => {
        dispatch(action);
    };
  }
export function simulateHttpRequest(action: PromoActions) {

  return (dispatch: PromoDispatchType) => {
    setTimeout(() => {
      dispatch(action);
    }, 100);
  };
}

