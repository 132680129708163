// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import {
  MdAddAPhoto,
  MdEmojiEvents,
  MdHdrPlus,
  MdPublish,
  MdRemove,
} from "react-icons/md";
import imageHolder from "assets/images/imageHolder.png";
import {
  useAddBandaGalleryImageMutation,
  useAddBandaProfileImageMutation,
  useAddSponsorGalleryImageMutation,
  useRemoveBandaGalleryImageMutation,
  useRemoveSponsorGalleryImageMutation,
} from "generated/graphql";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IoAdd, IoCloudUploadOutline } from "react-icons/io5";
import AWS from "aws-sdk";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { DeleteIcon } from "@chakra-ui/icons";

interface FormErrors {
  [key: string]: string;
}

export default function Banner(props: {
  banner?: string;
  avatar?: string;
  name?: string;
  job?: string;
  posts?: number | string;
  followers?: number | string;
  following?: number | string;
  [x: string]: any;
}) {
  const {
    refresh,
    banner,
    avatar,
    name,
    job,
    posts,
    followers,
    following,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const { user }: IUser | any = useSelector((state: UserState) => state.user);

  const [galleryeService] = useAddSponsorGalleryImageMutation();
  const [removeBandaGalleryImage] = useRemoveSponsorGalleryImageMutation();
  const [errors, setErrors] = useState<FormErrors>({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);

  const handleSubmitRemove = async (imageData: string) => {
    setUploadingProcess(true);

    try {
      const response = await removeBandaGalleryImage({
        variables: {
          imageInput: {
            id: user.id,
            url: imageData,
          },
        },
      });

      if (
        response.data &&
        response.data.removeSponsorGalleryImage &&
        !response.data.removeSponsorGalleryImage.status
      ) {
        console.log(response.data.removeSponsorGalleryImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event removing failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.removeSponsorGalleryImage);

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
        // setImageView("false")
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Remove failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };

  const handleSubmit = async (action: string) => {
    setUploadingProcess(true);
    let imageUrll = "";

    try {
      if (action === "add") {
        imageUrll = await uploadFile(imageFile);
      }
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Error uploading",
      }));
      setUploadingProcess(false);
      imageUrll = "";
      setImageUrl(null);
      setImageFile(null);
      setImageData(null);
    }

    let imageUrlData = imageUrl;

    if (action === "delete") {
      imageUrlData = "";
      imageUrll = "";
      setImageUrl(null);
      setImageData(null);
      setImageFile(null);
    }

    try {
      const response = await galleryeService({
        variables: {
          galleryInput: {
            id: user.sponsorId,
            url: imageUrll,
          },
        },
      });

      if (
        response.data &&
        response.data.addSponsorGalleryImage &&
        !response.data.addSponsorGalleryImage.status
      ) {
        console.log(response.data.addSponsorGalleryImage);

        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "Event adding failed",
        }));
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);

        return;
      } else {
        console.log(response.data.addSponsorGalleryImage);
        if ((action = "delete")) {
          setRemovedSuccessfully(true);
          setImageUrl(null);
          setImageData(null);
        }

        // Clear the error for loginMobile if login is successful or no loginMobile property is present
        setErrors((prevErrors) => ({
          ...prevErrors,
          responseError: "",
        }));
        refresh(true);
        setUploadingProcess(false);
        setImageUrl(null);
        setImageData(null);
      }
      setUploadingProcess(false);
      setImageUrl(null);
      setImageData(null);

      return;
    } catch (e) {
      console.log(e);

      setErrors((prevErrors) => ({
        ...prevErrors,
        responseError: "Registration failed",
      }));
      setUploadingProcess(false);
      setImageData(null);
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result); // Set the image data
      };
      reader.readAsDataURL(file);
      setImageFile(file); // Set the image file
    }
  };

  const handleClearImage = () => {
    setImageData(null);
    setImageFile(null);
    setImageUrl(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear file input value
    }
  };

  const [uploadingProcess, setUploadingProcess] = useState(false);

  const uploadFile = async (image: File | null) => {
    if (!image) {
      console.error("No image to upload");
      setImageData(null);
      setImageFile(null);
      return "";
    }

    const S3_BUCKET = "zutraxbanda";
    const REGION = "eu-north-1";
    const currentTime = new Date().getTime();

    AWS.config.update({
      accessKeyId: "AKIAXQIQAKXLYGWR3YHM",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const key = `itemid_manage_${user.id}/${currentTime}_${image.name}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: image,
    };

    const upload = s3.upload(params).promise();

    try {
      const data = await upload;
      console.log(data);
      const imageUrl = data.Location; //`https://${key}`;
      setImageUrl(imageUrl);
      setImageFile(null);
      setImageData(null);
      // alert("File uploaded successfully.");
      return imageUrl;
    } catch (err) {
      setImageUrl("");
      console.error("Error uploading file:", err);
      setImageFile(null);
      setImageData(null);
      // alert("Error uploading file. Please try again later.");
      return "";
    }
  };

  if (uploadingProcess) {
    return (
      <Card
        mb={{ base: "0px", lg: "20px" }}
        alignItems="center"
        {...rest}
        style={{
          height: 350,
        }}
      >
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          Loading ....{" "}
        </Text>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          mt="10px"
        >
          {errors.responseError}
        </Text>
      </Card>
    );
  }

  return (
    <Card mb={{ base: "0px", lg: "10px" }} alignItems="center">
      {avatar && (
        <Image
          src={imageData ? (imageData as string) : avatar}
          boxSize="100%"
          objectFit="cover"

          // w={{ base: "100%", "3xl": "100%" }}
          // h={{ base: "100%", "3xl": "100%" }}
          // style={{
          //   border:"none",
          // }}
          // borderRadius="20px"
        />
      )}

      {!(!avatar || avatar?.trim() === "") && !imageData && (
        <IconBox
          mx="auto"
          h="60px"
          w="60px"
          icon={
            <Icon
              as={DeleteIcon}
              color={"red"}
              h="40px"
              w="40px"
              style={{
                position: "absolute",
                right: "1%",

                top: 5,
              }}
              onClick={() => {
                if (window.confirm("Are you sure you want to delete?")) {
                  handleSubmitRemove(avatar);
                }
              }}
            />
          }
          // bg={"red"}
        ></IconBox>
      )}

      {!imageData && (
        <Text color={textColorSecondary} fontSize="sm">
          Upload Image Here{" "}
        </Text>
      )}

      <Input
        type="file"
        accept="image/*"
        aria-hidden="true"
        placeholder="Choose Image"
        variant="filled"
        // bg="red"
        // color="white"
        // height={100}
        // width={200}
        borderRadius="md"
        borderWidth={0}
        fontSize="sm"
        fontWeight="bold"
        ref={inputRef}
        onChange={handleImageChange}
        mb={4}
        _focus={{ outline: "none" }}
        _hover={{ cursor: "pointer" }}
      />

      {imageData && (
        <Button
          type="submit"
          onClick={() => {
            handleSubmit("add");
          }}
          colorScheme="green"
          marginTop="4"
          style={{
            width: "30%",
            padding: 5,
            alignSelf: "center",
          }}
        >
          Add Image
        </Button>
      )}

      {imageData && (
        <Button
          type="submit"
          onClick={() => {
            handleClearImage();
          }}
          colorScheme="red"
          marginTop="4"
          style={{
            width: "30%",
            padding: 5,
            alignSelf: "center",
          }}
        >
          Cancel
        </Button>
      )}
    </Card>
  );
}
