import "ol/ol.css";
import { useEffect, useRef, useState } from "react";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Style, Fill, Circle as CircleStyle, Icon } from "ol/style";
import { fromLonLat } from "ol/proj";
import { Feature } from "ol";
import { Point } from "ol/geom";

const MapAreas = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const vectorSource = new VectorSource(); // Source for the points
  const [selectedCenter, setSelectedCenter] = useState<{ name: string; coords: number[] } | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const centers = [
    // 30 points in Dar es Salaam
    { name: "Center 1", coords: [39.2894, -6.8165] },
    { name: "Center 2", coords: [39.2920, -6.8120] },
    { name: "Center 3", coords: [39.2905, -6.8140] },
    { name: "Center 4", coords: [39.2900, -6.8200] },
    { name: "Center 5", coords: [39.2875, -6.8185] },
    { name: "Center 6", coords: [39.2850, -6.8150] },
    { name: "Center 7", coords: [39.2830, -6.8125] },
    { name: "Center 8", coords: [39.2800, -6.8100] },
    { name: "Center 9", coords: [39.2825, -6.8050] },
    { name: "Center 10", coords: [39.2902, -6.8250] },
    { name: "Center 11", coords: [39.2950, -6.8205] },
    { name: "Center 12", coords: [39.2935, -6.8260] },
    { name: "Center 13", coords: [39.2910, -6.8300] },
    { name: "Center 14", coords: [39.2880, -6.8320] },
    { name: "Center 15", coords: [39.2865, -6.8340] },
    { name: "Center 16", coords: [39.2840, -6.8350] },
    { name: "Center 17", coords: [39.2820, -6.8360] },
    { name: "Center 18", coords: [39.2810, -6.8370] },
    { name: "Center 19", coords: [39.2805, -6.8380] },
    { name: "Center 20", coords: [39.2790, -6.8390] },
    
    // Additional points in other regions
    // Bukoba: 5 centers
    { name: "Bukoba Center 1", coords: [31.1343, -1.3283] },
    { name: "Bukoba Center 2", coords: [31.1360, -1.3250] },
    { name: "Bukoba Center 3", coords: [31.1350, -1.3300] },
    { name: "Bukoba Center 4", coords: [31.1380, -1.3310] },
    { name: "Bukoba Center 5", coords: [31.1330, -1.3290] },

    // Arusha: 5 centers
    { name: "Arusha Center 1", coords: [36.6868, -3.3862] },
    { name: "Arusha Center 2", coords: [36.6890, -3.3840] },
    { name: "Arusha Center 3", coords: [36.6880, -3.3880] },
    { name: "Arusha Center 4", coords: [36.6850, -3.3855] },
    { name: "Arusha Center 5", coords: [36.6875, -3.3900] },

    // Njombe: 4 centers
    { name: "Njombe Center 1", coords: [34.5470, -8.1261] },
    { name: "Njombe Center 2", coords: [34.5480, -8.1280] },
    { name: "Njombe Center 3", coords: [34.5460, -8.1240] },
    { name: "Njombe Center 4", coords: [34.5450, -8.1270] },

    // Dodoma: 5 centers
    { name: "Dodoma Center 1", coords: [35.7402, -6.3031] },
    { name: "Dodoma Center 2", coords: [35.7410, -6.3050] },
    { name: "Dodoma Center 3", coords: [35.7380, -6.3020] },
    { name: "Dodoma Center 4", coords: [35.7390, -6.3000] },
    { name: "Dodoma Center 5", coords: [35.7420, -6.3070] },

    // Mbeya: 4 centers
    { name: "Mbeya Center 1", coords: [33.4460, -8.8910] },
    { name: "Mbeya Center 2", coords: [33.4480, -8.8950] },
    { name: "Mbeya Center 3", coords: [33.4470, -8.8890] },
    { name: "Mbeya Center 4", coords: [33.4490, -8.8920] },

    // Mwanza: 4 centers
    { name: "Mwanza Center 1", coords: [32.9000, -2.5149] },
    { name: "Mwanza Center 2", coords: [32.9010, -2.5155] },
    { name: "Mwanza Center 3", coords: [32.9020, -2.5135] },
    { name: "Mwanza Center 4", coords: [32.9030, -2.5160] },

    // Tanga: 4 centers
    { name: "Tanga Center 1", coords: [39.0999, -5.0636] },
    { name: "Tanga Center 2", coords: [39.1010, -5.0610] },
    { name: "Tanga Center 3", coords: [39.1005, -5.0600] },
    { name: "Tanga Center 4", coords: [39.1020, -5.0640] },

    // Moshi: 4 centers
    { name: "Moshi Center 1", coords: [37.3415, -3.3402] },
    { name: "Moshi Center 2", coords: [37.3400, -3.3390] },
    { name: "Moshi Center 3", coords: [37.3420, -3.3420] },
    { name: "Moshi Center 4", coords: [37.3430, -3.3410] },
  ];

  useEffect(() => {
    if (!mapRef.current) return;

    // Add point features for each center
    centers.forEach(center => {
      const feature = new Feature({
        geometry: new Point(fromLonLat(center.coords)), // Use Point for the coordinates
      });
      feature.setStyle(new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: 'red' }), // Red fill
        }),
      }));
      vectorSource.addFeature(feature);
    });

    const mapObj = new Map({
      target: mapRef.current,
      view: new View({
        center: fromLonLat([35.7402, -6.3031]), // Centered on Dodoma
        zoom: 7,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: vectorSource,
        }),
      ],
    });

    mapObj.on("click", (event) => {
      mapObj.forEachFeatureAtPixel(event.pixel, (feature) => {
        const centerData = feature.getProperties();
        setSelectedCenter({ name: centerData.name, coords: centerData.coords });
        setIsModalOpen(true);
      });
    });


    return () => {
      mapObj.dispose(); // Properly dispose of the map instance
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCenter(null);
  };

  return (
    <div>
      <div className="map" ref={mapRef} style={{ width: "100%", height: "130vh" }} />
      {isModalOpen && selectedCenter && (
        <div className="modal">
          <div className="modal-content">
            <h2>{selectedCenter.name}</h2>
            <p>Coordinates: </p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapAreas;
